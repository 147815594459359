import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  fetchAIAnnotationRunsStore,
  resetNewAIAnnotationRun,
  setIsCreatingNewAIAnnotationRunModalOpen,
} from "store/aiManagerSlice";
import { postAIAnnotationRun } from "helpers/apis/aiAnnotationRun";

interface Props {
  isCreatingNewAIAnnotationRun: boolean;
  setIsCreatingNewAIAnnotationRun: (value: boolean) => void;
}

const NewAIAnnotationRunAction = ({
  isCreatingNewAIAnnotationRun,
  setIsCreatingNewAIAnnotationRun,
}: Props) => {
  const dispatch = useAppDispatch();

  const aiManagerSlice = useAppSelector((state) => state.aiManagerSlice);
  const { newAIAnnotationRun } = aiManagerSlice;

  const onClose = () => {
    dispatch(setIsCreatingNewAIAnnotationRunModalOpen(false));
    dispatch(resetNewAIAnnotationRun());
  };

  const handleCreateNewAIModel = () => {
    if (
      newAIAnnotationRun.ml_annotation_model === null ||
      newAIAnnotationRun.ml_annotation_model.id === "" ||
      newAIAnnotationRun.dataset === null ||
      newAIAnnotationRun.subset === null ||
      newAIAnnotationRun.name.value === ""
    )
      return;

    postAIAnnotationRun(
      {
        name: newAIAnnotationRun.name.value,
        dataset_id: newAIAnnotationRun.dataset.id,
        subset_id: newAIAnnotationRun.subset.id,
        ml_annotation_model_id: newAIAnnotationRun.ml_annotation_model.id,
      },
      dispatch,
      setIsCreatingNewAIAnnotationRun,
    ).then(() => {
      onClose();
      dispatch(fetchAIAnnotationRunsStore());
    });
  };

  const checkIfDisabled = () => {
    return (
      isCreatingNewAIAnnotationRun ||
      newAIAnnotationRun.ml_annotation_model === null ||
      newAIAnnotationRun.dataset === null ||
      newAIAnnotationRun.subset === null ||
      newAIAnnotationRun.name.value === ""
    );
  };

  return (
    <div className="w-full flex justify-end gap-x-2 pt-6">
      <button
        className="button-layer bg-transparent"
        onClick={onClose}
        disabled={isCreatingNewAIAnnotationRun}
        data-test="close_button"
      >
        Close
      </button>
      <button
        className="button-dark-gray-layer w-fit bg-paletteBlack-2"
        onClick={handleCreateNewAIModel}
        disabled={checkIfDisabled()}
        data-test="launch_ai_annotation_run_button"
      >
        Launch AI annotation run
      </button>
    </div>
  );
};

export default NewAIAnnotationRunAction;

import _ from "lodash";
import { useAppSelector } from "store/hooks";
import { statisticsStateTypes } from "store/statisticsSlice";

interface Props {
  selectedChart: keyof statisticsStateTypes["charts"] | null;
  setSelectedChart: (
    newSelectedChart: keyof statisticsStateTypes["charts"],
  ) => void;
}

const ChartsMenu = ({ selectedChart, setSelectedChart }: Props) => {
  const chartsList = useAppSelector((state) => state.statisticsSlice.charts);

  const renderChartList = () => {
    return _.map(chartsList, (chartValue, chartKey) => (
      <div
        key={chartKey}
        // TODO: remove conditional, when qa_results_per_attribute done
        className={`button-select-layer px-4 py-3 
        ${selectedChart === chartKey && "bg-black text-white"}
        ${
          chartKey === "qa_results_per_attribute" ? "text-paletteGray-5" : ""
        } `}
        onClick={() => {
          // TODO: remove conditional, when qa_results_per_attribute done
          if (chartKey != "qa_results_per_attribute") {
            setSelectedChart(chartKey as keyof statisticsStateTypes["charts"]);
          }
        }}
      >
        {chartValue?.name}
      </div>
    ));
  };

  return (
    <div className="h-full p-4 flex flex-col gap-y-4 border-r-[1px]">
      <div className="px-4 py-3 text-paletteGray-10">Charts</div>
      {renderChartList()}
    </div>
  );
};

export default ChartsMenu;

import FormErrorCircle from "components/Inputs/Form/ErrorCircle";
import FormErrorLabel from "components/Inputs/Form/ErrorLabel";
import FormLabel from "components/Inputs/Form/Label";
import { validateTextInput } from "components/Inputs/Form/formInputsHelpers";
import _ from "lodash";
import { TextFieldModel } from "models/form.model";

interface Props {
  field: TextFieldModel;
  value: string;
  handleOnChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: TextFieldModel,
    isErrored?: boolean,
    error?: string | null,
  ) => void;
  autoFocus?: boolean;
  tabIndex?: number;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const TextInput = ({
  field,
  value,
  handleOnChange,
  autoFocus,
  tabIndex,
  onKeyDown,
}: Props) => {
  const { type, placeholder, disabled } = field;

  const renderBody = () => {
    const sameLine = field?.style?.labelAndFieldOnTheSameLine;
    if (sameLine) {
      return renderSameLineAlignment();
    }

    return renderMultiLineAlignment();
  };

  const renderMultiLineAlignment = () => {
    return (
      <div key={field?.key} className={`w-full ${field?.style?.wrapper}`}>
        <FormLabel field={field} />
        {renderInput()}
        <FormErrorLabel
          error={field?.error ?? null}
          validationError={validateTextInput(field)}
        />
      </div>
    );
  };

  const renderSameLineAlignment = () => {
    return (
      <div
        key={field?.key}
        className={`flex justify-between items-center
          ${field?.style?.wrapper}
      `}
      >
        <div className="w-1/2">
          <FormLabel field={field} />
        </div>
        <div className="w-1/2 flex items-center">
          {renderInput()}
          <FormErrorCircle
            error={field?.error ?? null}
            validationError={validateTextInput(field)}
          />
        </div>
      </div>
    );
  };

  const renderInput = () => {
    if (type === "text") {
      return renderTextInput();
    } else if (type === "textarea") {
      return renderTextArea();
    }
  };

  const renderTextInput = () => {
    const validationError = validateTextInput(field);
    const isError = !_.isNull(validationError);
    const inputClassName = field?.style?.field;
    return (
      <input
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={(event) => handleOnChange(event, field)}
        onBlur={(event) =>
          handleOnChange(event, field, isError, validationError)
        }
        className={`input-text ${inputClassName ? inputClassName : ""}`}
        disabled={disabled}
        autoFocus={autoFocus}
        tabIndex={tabIndex}
        onKeyDown={onKeyDown}
        data-test={`input_${field?.key}_field`}
      />
    );
  };

  const renderTextArea = () => {
    const validationError = validateTextInput(field);
    const isError = !_.isNull(validationError);
    const inputClassName = field?.style?.field;
    return (
      <textarea
        value={value}
        placeholder={placeholder}
        onChange={(event) => handleOnChange(event, field)}
        onBlur={(event) =>
          handleOnChange(event, field, isError, validationError)
        }
        className={`input-text ${inputClassName ? inputClassName : ""}`}
        disabled={disabled}
        tabIndex={0}
      />
    );
  };

  return renderBody();
};

export default TextInput;

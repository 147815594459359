import { toast } from "react-toastify";

import { ReactComponent as InfoIcon } from "assets/info.svg";
import { ReactComponent as CheckMarkIcon } from "assets/check_mark.svg";
import { ReactComponent as WarningIcon } from "assets/warning.svg";

import { ToastOptions } from "react-toastify/dist/types";

export interface CustomToasterProps {
  title: string;
  text: string;
}

export type CustomToasterSeverity = "success" | "info" | "warning" | "error";

export const ToasterBody = ({ title, text }: CustomToasterProps) => {
  return (
    <div data-test="snackbar_component">
      <p>{title}</p>
      <p data-test="snackbar_message">{text}</p>
    </div>
  );
};

const toaster = (myProps: CustomToasterProps, toastProps: ToastOptions) =>
  toast(<ToasterBody {...myProps} />, { ...toastProps });

toaster.success = (myProps: CustomToasterProps, toastProps?: ToastOptions) =>
  toast.success(<ToasterBody {...myProps} />, {
    ...toastProps,
    icon: (
      <CheckMarkIcon className="w-3 h-3 text-paletteGreen" strokeWidth={2} />
    ),
  });

toaster.info = (myProps: CustomToasterProps, toastProps?: ToastOptions) =>
  toast.info(<ToasterBody {...myProps} />, {
    ...toastProps,
    icon: <InfoIcon className="w-3 h-3 text-paletteBlue" strokeWidth={2} />,
  });

toaster.warning = (myProps: CustomToasterProps, toastProps?: ToastOptions) =>
  toast.warning(<ToasterBody {...myProps} />, {
    ...toastProps,
    icon: (
      <WarningIcon className="w-3 h-3 text-paletteYellow" strokeWidth={2} />
    ),
  });

toaster.error = (myProps: CustomToasterProps, toastProps?: ToastOptions) =>
  toast.error(<ToasterBody {...myProps} />, {
    ...toastProps,
    icon: <InfoIcon className="w-3 h-3 text-paletteRed" strokeWidth={2} />,
  });

toaster.promise = (
  promise: Promise<unknown>,
  messages: {
    pending: string;
    success: string;
    error: string;
  },
  toastProps: ToastOptions,
) =>
  toast.promise(
    promise,
    {
      pending: messages.pending,
      success: messages.success,
      error: messages.error,
    },
    toastProps,
  );

export default toaster;

import { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";

import { useHotkeys } from "react-hotkeys-hook";
import { isEmpty } from "lodash";

import { SendFilterModel } from "models/filter.model";
import ExplorationMedia from "./ExplorationMedia";
import ExplorationMediaObjects from "./ExplorationMediaObjects";
import {
  determineCountKeyFromParam,
  fetchMediaObjectsFrequencyStore,
  fetchCountStore,
} from "store/metaDataSlice";
import { setSelectedView } from "store/appSlice";
import addSubsetToQuery from "helpers/functions/addSubsetToQuery";
import {
  AnnotatableEnum,
  SelectedViewModel,
  VisibilityStatus,
} from "models/global.model";
import FilterSorterDrawer from "sections/SidebarMenu/FiltersDialog";
import {
  fetchActiveDataset,
  fetchDatasetSliceData,
} from "helpers/functions/datasets/datasetHelpers";
import ExplorationInstances from "Pages/Exploration/ExplorationInstances";
import { getExplorationSelectedViewFromRoute } from "routes/routesHelper";

const ExplorationPage = () => {
  // -------------------------------------- Common Logic: -----------------------------------------
  const params: any = useParams();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const selectedView = useAppSelector((state) => state.appSlice.selectedView);
  const dataSetData = useAppSelector((state) => state.datasetSlice);
  const metaData = useAppSelector((state) => state.metaDataSlice);

  // When initialising component, get last selected View from sessionStorage and apply it.
  // If no View is in sessionStorage, the MediaView is selected by default.
  useEffect(() => {
    const selectedViewFromSessionStorage =
      sessionStorage.getItem("selectedView");
    // If selectedView is already on storage, use it (unless it's Media)
    if (
      selectedViewFromSessionStorage !== null &&
      selectedViewFromSessionStorage !== AnnotatableEnum.Media
    ) {
      dispatch(
        setSelectedView(selectedViewFromSessionStorage as SelectedViewModel),
      );
    }
    // If selectedView is not on storage, use the one from the route (unless it's Media)
    else {
      const selectedViewFromRoute = getExplorationSelectedViewFromRoute(
        history.location.pathname,
      );
      if (selectedViewFromRoute === AnnotatableEnum.Media) return;
      dispatch(setSelectedView(selectedViewFromRoute));
    }
  }, []);

  // Fetch new Dataset/Subset info and store it in redux: dataSetData.activeDataSet
  // Update when: The URL id or subset_id change
  useEffect(() => {
    if (dataSetData.activeDataSet !== null) {
      return;
    }
    fetchActiveDataset(dispatch, {
      datasetId: params?.id,
      subset_id: params?.subset_id,
      visibility_statuses: [
        VisibilityStatus.Visible,
        VisibilityStatus.CreatingSubset,
      ],
    });
  }, [params?.id, params?.subset_id]);

  // Fetch the parent dataset of the current subset and store it in redux: dataSetData.activeParentDataset
  //          (if the current dataset is not subset it fetch the same dataset)
  // Fetch all the subsets of the current dataset/subset
  // Update when: New dataset/subset if active (dataSetData.activeDataSet)
  useEffect(() => {
    fetchDatasetSliceData(dispatch, dataSetData);
  }, [dataSetData?.activeDataSet]);

  // Fetch the metaData for the current dataset/subset and selectedView
  useEffect(() => {
    const APIBodyInit: SendFilterModel[] = [];
    const APIBody: SendFilterModel[] = addSubsetToQuery(
      params?.subset_id,
      APIBodyInit,
    );

    const countDataKey = determineCountKeyFromParam(selectedView);
    if (countDataKey && isEmpty(metaData?.[countDataKey])) {
      dispatch(
        fetchCountStore({
          query: { runID: params.id },
          APIBody: APIBody,
          selectedView: selectedView,
        }),
      );
    }
  }, [params?.id, params?.subset_id, selectedView]);

  // Fetch the metaData for the current dataset/subset
  useEffect(() => {
    const APIBodyInit: SendFilterModel[] = [];
    const APIBody: SendFilterModel[] = addSubsetToQuery(
      params?.subset_id,
      APIBodyInit,
    );

    if (isEmpty(metaData.mediaObjectsFrequency)) {
      dispatch(
        fetchMediaObjectsFrequencyStore({
          query: { runID: params.id, subset_id: params?.subset_id },
          APIBody: APIBody,
        }),
      );
    }
  }, [params?.id, params?.subset_id]);

  const renderPage = () => {
    switch (selectedView) {
      case AnnotatableEnum.Media:
        return <ExplorationMedia />;
      case AnnotatableEnum.Instance:
        return <ExplorationInstances />;
      case AnnotatableEnum.MediaObject:
        return <ExplorationMediaObjects />;
      default:
        return null;
    }
  };

  // ---------------------------- Hotkeys: ------------------------------

  useHotkeys("h", () => history.push("/"));

  // -------------------------------------- HTML: -----------------------------------------
  return (
    <div className="w-full h-full p-4 pt-3 pr-0">
      <div className="w-full h-full">{renderPage()}</div>

      <FilterSorterDrawer runID={params.id} />
    </div>
  );
};

export default ExplorationPage;

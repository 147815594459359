import MoreButton from "components/Buttons/MoreButton";
import StandardPopoverWrapper from "components/Popovers/StandardPopoverWrapper";

import { ReactComponent as DeleteIcon } from "assets/delete.svg";
import { ReactComponent as PaperIcon } from "assets/paper.svg";
import { ReactComponent as EditIcon } from "assets/edit.svg";
import { PipelineModel } from "models/pipelines.model";

export type PipelineManagerPopoverConfirmDialogState = {
  show: boolean;
  action: "archive" | "duplicate";
  pipelineID: string | null;
};

type Props = {
  pipeline: PipelineModel;
  setConfirmDialogState: (
    newState: PipelineManagerPopoverConfirmDialogState,
  ) => void;
  setRenameInputID: (id: string | null) => void;
};

const PipelineManagerTableActions = ({
  pipeline,
  setConfirmDialogState,
  setRenameInputID,
}: Props) => {
  const actions = [
    {
      label: "Rename",
      onItemClick: () => setRenameInputID(pipeline?.id),
      icon: <EditIcon strokeWidth={2} />,
    },
    {
      label: "Duplicate",
      onItemClick: () =>
        setConfirmDialogState({
          show: true,
          action: "duplicate",
          pipelineID: pipeline?.id,
        }),
      icon: <PaperIcon />,
    },
    {
      label: "Archive",
      onItemClick: () =>
        setConfirmDialogState({
          show: true,
          action: "archive",
          pipelineID: pipeline?.id,
        }),

      className: "text-paletteRed",
      icon: <DeleteIcon />,
    },
  ];

  return (
    <StandardPopoverWrapper
      id={pipeline?.id}
      buttonElement={<MoreButton />}
      itemsList={actions}
    />
  );
};

export default PipelineManagerTableActions;

import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import SelectedItemsPopover from "components/Popovers/SelectedItemsPopover";
import StandardHeader from "sections/Headers/StandardHeader";
import SingleSliderInput from "components/Inputs/Form/SingleSliderInput";
import { ReactComponent as GridSmallIcon } from "assets/grid_small.svg";
import { ReactComponent as GridBigIcon } from "assets/grid_big.svg";
import { ReactComponent as UnpinIcon } from "assets/unpin.svg";

import { AnnotatableEnum, SelectedViewModel } from "models/global.model";
import { setSelectedView } from "store/appSlice";
import TagsAddPopOver from "components/Popovers/TagsAddPopover";
import SubsetCreationPopover from "components/Popovers/SubsetCreationPopover";
import FiltersDropdown from "Pages/Exploration/FiltersDropdown";
import FiltersList from "Pages/Exploration/FiltersList";
import SortList from "Pages/Exploration/SortList";
import _ from "lodash";
import StandardTabs, { StandardTabModel } from "components/Tabs/StandardTabs";
import { getExplorationURLWithSearchParams } from "helpers/functions/filters/filtersHelpers";
import { ExplorationScreenRouteModel } from "models/routes.model";
import { useHistory, useParams } from "react-router-dom";
import { SingleSliderFieldModel } from "models/form.model";
import NotificationButton from "components/Buttons/NotificationButton";

interface Props {
  numOfItemPerRow: SingleSliderFieldModel;
  setNumOfItemPerRow: (newVal: SingleSliderFieldModel) => void;
}

const ExplorationHeader = ({ numOfItemPerRow, setNumOfItemPerRow }: Props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const params: ExplorationScreenRouteModel = useParams();

  const selectedView = useAppSelector((state) => state.appSlice.selectedView);
  const filterData = useAppSelector((state) => state.filterDataSlice);
  const sortData = useAppSelector((state) => state.sortDataSlice);
  const dataSetData = useAppSelector((state) => state.datasetSlice);

  const [areFiltersPinned, setAreFiltersPinned] = useState(false);

  const [selectedItemsAnchorEl, setSelectedItemsAnchorEl] =
    useState<HTMLElement | null>(null);
  const [addTagsAnchorEl, setAddTagsAnchorEl] = useState<HTMLElement | null>(
    null,
  );
  const [createSubsetAnchorEl, setCreateSubsetAnchorEl] =
    useState<HTMLElement | null>(null);

  const [tabs, setTabs] = useState<StandardTabModel[]>([]);

  const renderNumberOfItemsPerRow = () => {
    return (
      <div
        className="flex flex-row items-center gap-x-2 text-paletteBlack-1"
        data-test="exploration_grid_size_element"
      >
        <div
          className="button-select-layer w-fit p-[10px]"
          data-test="exploration_grid_size_element_smaller"
        >
          <GridSmallIcon
            width={20}
            height={20}
            className="cursor-pointer"
            onClick={() => {
              if (numOfItemPerRow.value > 4)
                setNumOfItemPerRow({
                  ...numOfItemPerRow,
                  value: numOfItemPerRow.value - 1,
                });
            }}
          />
        </div>
        <div
          className="w-20 flex items-center"
          data-test="exploration_grid_size_element_slider"
        >
          <SingleSliderInput
            field={numOfItemPerRow}
            value={numOfItemPerRow.value}
            handleOnChange={(event) =>
              setNumOfItemPerRow({
                ...numOfItemPerRow,
                value: parseFloat(event.target.value),
              })
            }
          />
        </div>
        <div
          className="button-select-layer w-fit p-[10px]"
          data-test="exploration_grid_size_element_larger"
        >
          <GridBigIcon
            width={20}
            height={20}
            className="cursor-pointer"
            onClick={() => {
              if (numOfItemPerRow.value < 10)
                setNumOfItemPerRow({
                  ...numOfItemPerRow,
                  value: numOfItemPerRow.value + 1,
                });
            }}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    setTabs([]);
    const tab_list: StandardTabModel[] = [];
    if (dataSetData?.activeDataSet) {
      if (dataSetData?.activeDataSet?.num_medias > 0) {
        tab_list.push({
          label: "Media",
          value: AnnotatableEnum.Media,
        });
      }
      if (dataSetData?.activeDataSet?.num_instances > 0) {
        tab_list.push({
          label: "Instances",
          value: AnnotatableEnum.Instance,
        });
      }
      if (dataSetData?.activeDataSet?.num_media_objects > 0) {
        tab_list.push({
          label: "Objects",
          value: AnnotatableEnum.MediaObject,
        });
      }
      setTabs(tab_list);
    }
  }, [dataSetData?.activeDataSet]);

  const renderSelectedItems = () => {
    return (
      <SelectedItemsPopover
        anchorEl={selectedItemsAnchorEl}
        handlePopoverOpen={(event) =>
          setSelectedItemsAnchorEl(event.currentTarget)
        }
        handlePopoverClose={() => setSelectedItemsAnchorEl(null)}
      />
    );
  };

  const renderTabSelection = () => {
    return (
      <div className="w-[270px]">
        <StandardTabs
          tabs={tabs}
          selectedTab={selectedView}
          setSelectedTab={(tab) => {
            dispatch(setSelectedView(tab as SelectedViewModel));
            const urlWithParams = getExplorationURLWithSearchParams(
              tab as SelectedViewModel,
              filterData,
              sortData,
              params,
            );

            history.push(urlWithParams);
          }}
        />
      </div>
    );
  };

  const renderAddTags = () => {
    return (
      <TagsAddPopOver
        anchorEl={addTagsAnchorEl}
        handlePopoverOpen={(event) => setAddTagsAnchorEl(event.currentTarget)}
        handlePopoverClose={() => setAddTagsAnchorEl(null)}
        // disabled={selectedView === AnnotatableEnum.Instance}
      />
    );
  };

  const renderCreateSubset = () => {
    return (
      <SubsetCreationPopover
        anchorEl={createSubsetAnchorEl}
        handlePopoverOpen={(event) =>
          setCreateSubsetAnchorEl(event.currentTarget)
        }
        handlePopoverClose={() => setCreateSubsetAnchorEl(null)}
      />
    );
  };

  const renderPinnedFilters = () => {
    if (areFiltersPinned === false) return null;

    const sorter = sortData?.[selectedView]?.[0];
    const areSortersEmpty = _.isEmpty(sorter);
    const height = areSortersEmpty ? 104 : 72;

    return (
      <div className="pt-3 flex">
        <div className="w-full flex flex-col gap-y-1">
          <div className="text-sm text-paletteGray-8">Filtered by:</div>
          <div className="flex-1 min-w-0">
            <div
              className="flex flex-wrap gap-1 overflow-y-scroll rounded-lg"
              style={{ maxHeight: `${height}px` }}
            >
              <FiltersList />
            </div>
          </div>
        </div>
        <div
          className="button-select-layer w-auto h-fit text-paletteGray-10"
          onClick={() => setAreFiltersPinned(false)}
        >
          <UnpinIcon width={20} height={20} />
        </div>
      </div>
    );
  };

  const renderPinnedSort = () => {
    const sorter = sortData?.[selectedView]?.[0];

    if (areFiltersPinned === false || _.isEmpty(sorter)) return null;

    return (
      <div className="pt-1">
        <div className="flex-col gap-y-1">
          <div className="text-sm text-paletteGray-8">Sorted by:</div>
          <div>
            <SortList />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-y-2">
      <StandardHeader>
        <div className="flex gap-x-1 items-center">
          {renderNumberOfItemsPerRow()}
          {renderSelectedItems()}
          <div className="h-6 w-px bg-paletteGray-4 mx-3"></div>
          <NotificationButton />
        </div>
      </StandardHeader>

      <div className="flex gap-x-2 mr-[9px]">
        {renderTabSelection()}
        <div className="flex-1 min-w-0">
          <FiltersDropdown
            areFiltersPinned={areFiltersPinned}
            setAreFiltersPinned={setAreFiltersPinned}
          />
        </div>
        {renderAddTags()}
        {renderCreateSubset()}
      </div>

      {renderPinnedFilters()}
      {renderPinnedSort()}
    </div>
  );
};

export default ExplorationHeader;

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Backdrop from "@mui/material/Backdrop";

import version from "helpers/version";
import { ReactComponent as Logo } from "assets/logo_big.svg";
import { ReactComponent as CloseICon } from "assets/close.svg";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  setIsOpen: (isOpen: boolean) => void;
}

const AppInfo = ({ isOpen, onClose, setIsOpen }: Props) => {
  return (
    <Backdrop open={isOpen}>
      <Dialog maxWidth={"sm"} open={isOpen} onClose={onClose}>
        <DialogContent>
          <div className="w-full h-full">
            <div className="flex justify-end">
              <button
                className="button-layer p-[6px]"
                onClick={() => setIsOpen(false)}
              >
                <CloseICon width={12} height={12} />
              </button>
            </div>
            <div className="w-full h-full px-6 py-1 flex flex-col justify-between">
              <div className="w-full flex justify-center">
                <Logo />
              </div>
              <div className="flex justify-center text-paletteGray-10">
                Version: {version}
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </Backdrop>
  );
};

export default AppInfo;

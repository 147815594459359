export interface AnnotationRunModel {
  id: string;
  pipeline_revision_id: string;
  dataset_id: string;
  subset_id: string;
  name: string;
  owner: string;
  goliat_project_id: string;
  user_group: string;
  created_at: string;
  config: AnnotationRunConfig;
  annotation_run_url: string;
  status: AnnotationRunStatusEnum;
  annotation_run_type: AnnotationRunTypeEnum;
  is_multinode?: boolean;
  nodes: AnnotationRunNodeModel[];
}

export interface AnnotationRunConfig {
  color_map: Record<string, string> | null;
}

export interface AnnotationRunNodeModel {
  id: string;
  pipeline_node_revision_id: string | null;
  name: string;
  config: AnnotationRunNodeConfig;
  goliat_project_id: string | null;
  goliat_node_id: string | null;
  status: AnnotationRunNodeStatusEnum;
  created_at: string; // You can replace string with a Date type if you prefer
  owner: string;
}

export interface AnnotationRunNodeConfig {
  visualisation_config_id: string | null;
  reference_data_id: string | null;
  initial_attribute_id: string | null;
}

export interface AnnotationRunCreate {
  name: string;
  config: AnnotationRunConfig | null;
  dataset_id: string | null;
  subset_id: string | null;
  pipeline_id: string | null;
  nodes: AnnotationRunNodeCreate[];
}

export interface AnnotationRunNodeCreate {
  config: AnnotationRunNodeConfig | null;
  pipeline_node_id: string | null;
}

export enum AnnotationRunStatusEnum {
  CREATED = "created",
  CREATION_FAILED = "creation_failed",
  POST_PROCESSING = "post_processing",
  POST_PROCESSING_FAILED = "post_processing_failed",
  AI_ANNOTATION_FAILED = "ai_annotation_failed",
  STARTED = "started",
  ANNOTATION_DONE = "annotation_done",
  PARTIALLY_DONE = "partially_done",
  DONE = "done",
}
export enum AnnotationRunNodeStatusEnum {
  CREATED = "created",
  STARTED = "started",
  POST_PROCESSING = "post_processing",
  POST_PROCESSING_FAILED = "post_processing_failed",
  AI_ANNOTATION_FAILED = "ai_annotation_failed",
  AUTO_ANNOTATION_FAILED = "auto_annotation_failed",
  CREATION_FAILED = "creation_failed",
  ANNOTATION_DONE = "annotation_done",
  POST_PROCESSING_DONE = "post_processing_done",
}

export enum AnnotationRunTypeEnum {
  MANUAL = "manual",
  AI = "ai",
}

import { useEffect, useState } from "react";
import CreateNewPipelineButton from "Pages/PipelineManager/CreateNewPipelineButton";
import { PipelineModel } from "models/pipelines.model";
import { fetchPipelines } from "helpers/apis/pipelines";
import { useAppDispatch } from "store/hooks";
import ManagerFilters from "sections/ManagerFilters";
import PipelineManagerTable from "Pages/PipelineManager/PipelineManagerTable";
import { ManagerFiltersModel } from "models/global.model";
import { filterByCreatedAt } from "sections/ManagerFilters/mangerFiltersHelpers";
import { useKeycloak } from "@react-keycloak/web";
import Header from "sections/Headers/GeneralHeader";
import { ReactComponent as PipelineManagerIcon } from "assets/pipeline_manager.svg";

import _ from "lodash";
import PipelineAndAnnotationRunTabs, {
  ManagerTabsOptions,
} from "sections/PipelineAndAnnotationRunTabs";
import SearchBar from "components/Inputs/SearchBar";

const PipelinesManager = () => {
  const keycloak = useKeycloak();
  const dispatch = useAppDispatch();

  const [pipelines, setPipelines] = useState<PipelineModel[]>([]);
  const [selectedTabValue, setSelectedTabValue] = useState<ManagerTabsOptions>(
    ManagerTabsOptions.all,
  );
  const [appliedFilters, setAppliedFilters] = useState<ManagerFiltersModel>({
    createdAt: null,
  });
  const [searchValue, setSearchValue] = useState<string>("");

  const [isLoading, setIsLoading] = useState(false);

  // Fetch pipelines
  useEffect(() => {
    fetchPipelines(setIsLoading).then((data) => {
      setPipelines(data);
    });
  }, []);

  const setCreatedDateFilter = (newValue: ManagerFiltersModel["createdAt"]) => {
    setAppliedFilters({
      ...appliedFilters,
      createdAt: newValue,
    });
  };

  const filterPipelines = () => {
    let filteredPipelines = pipelines;

    // Filter by created at
    if (appliedFilters.createdAt !== null) {
      filteredPipelines = filterByCreatedAt(
        pipelines,
        appliedFilters.createdAt,
      ) as PipelineModel[];
    }

    // Filter by tab
    if (selectedTabValue === ManagerTabsOptions.me) {
      filteredPipelines = _.filter(
        filteredPipelines,
        (pipeline) =>
          pipeline?.owner === keycloak?.keycloak?.idTokenParsed?.sub,
      );
    } else if (selectedTabValue === ManagerTabsOptions.team) {
      filteredPipelines = _.filter(
        filteredPipelines,
        (pipeline) =>
          pipeline?.owner !== keycloak?.keycloak?.idTokenParsed?.sub,
      );
    }

    return filteredPipelines;
  };

  return (
    <div className="mx-auto full-height-with-margin bg-white flex flex-col self-center border-[1px] border-paletteGray-5 rounded-xl m-1 mr-1">
      <Header
        title="Pipelines"
        icon={<PipelineManagerIcon className="w-5 h-5 text-white" />}
        iconBgcolor="bg-gradient-to-b from-paletteOrange-light to-paletteOrange"
      >
        <CreateNewPipelineButton />
      </Header>
      <div className="px-[24px] min-h-0 py-4 flex-1 flex flex-col">
        <div className="w-full flex justify-between">
          <div className="w-fit">
            <PipelineAndAnnotationRunTabs
              items={pipelines}
              selectedTabValue={selectedTabValue}
              setSelectedTabValue={setSelectedTabValue}
              labelSuffix="pipelines"
            />
          </div>
          <div className="min-w-0 flex-1 flex flex-row-reverse items-center gap-x-2">
            <div className="w-[500px]">
              <SearchBar
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                placeholder="Search pipeline name"
              />
            </div>
            <ManagerFilters
              appliedFilters={appliedFilters}
              setCreatedDateFilter={setCreatedDateFilter}
            />
          </div>
        </div>
        <div className="w-full flex-1 min-h-0 pt-4" data-test="table_grid_area">
          <PipelineManagerTable
            searchValue={searchValue}
            pipelines={filterPipelines()}
            setPipelines={setPipelines}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default PipelinesManager;

import { useEffect, useState } from "react";
import { matchPath, useHistory } from "react-router-dom";

import { ReactComponent as QMLogo } from "assets/QMLogo.svg";
import DownloadDialog from "sections/SidebarMenu/DownloadDialog";
import { useKeycloak } from "@react-keycloak/web";
import FeedbackDialog from "sections/SidebarMenu/FeedbackDialog";
import { checkIfUserHavePermission } from "helpers/keycloakHelpers";
import SidebarIcon from "sections/SidebarMenu/SidebarIcon";
import { useAppDispatch, useAppSelector } from "store/hooks";
import _ from "lodash";
import AccountPopover from "components/Popovers/AccountPopover";
import HelpDialog from "Pages/Exploration/HelpDialog";
import {
  getExplorationURLWithSearchParams,
  searchParamsToSort,
} from "helpers/functions/filters/filtersHelpers";
import {
  determineActiveSortKeyFromParam,
  setActiveSortData,
  setSortData,
} from "store/sortDataSlice";
import {
  allUsersRoutes,
  customContractUsersRoutes,
  internalUsersRoutes,
} from "routes";
import LicenseDialog from "components/Dialogs/LicenseDialog";
import { DatasetModel } from "models/dataset.model";

const SidebarMenu = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { keycloak } = useKeycloak();
  const [accountAnchorEl, setAccountAnchorEl] = useState<HTMLElement | null>(
    null,
  );

  const datasetSlice = useAppSelector((state) => state.datasetSlice);
  const selectedView = useAppSelector((state) => state.appSlice.selectedView);
  const filterData = useAppSelector((state) => state.filterDataSlice);
  const sortData = useAppSelector((state) => state.sortDataSlice);

  const [isLicenseDialogOpen, setIsLicenseDialogOpen] = useState(false);
  const [activeDataset, setActiveDataset] = useState<DatasetModel>();
  // set active dataset
  useEffect(() => {
    // check whether active dataset has license
    if (
      !_.isNull(datasetSlice?.activeDataSet) &&
      !_.isNull(datasetSlice?.activeDataSet?.license)
    ) {
      setActiveDataset(datasetSlice?.activeDataSet);
    }
    // check whether parent dataset has license
    else if (
      !_.isNull(datasetSlice?.activeParentDataset) &&
      !_.isNull(datasetSlice?.activeParentDataset?.license)
    ) {
      setActiveDataset(datasetSlice?.activeParentDataset);
    }
  }, [
    datasetSlice?.activeDataSet?.license,
    datasetSlice?.activeParentDataset?.license,
  ]);

  // open LicenseDialog if license not accepted
  useEffect(() => {
    const isLoading = _.isUndefined(datasetSlice?.activeDataSet?.id);
    const parentDataset = activeDataset?.parent_dataset || activeDataset?.id;

    const hasAcceptedLicense =
      keycloak?.idTokenParsed?.accepted_licenses?.includes(parentDataset);
    if (!isLoading && activeDataset?.license && !hasAcceptedLicense) {
      setIsLicenseDialogOpen(true);
    }
  }, [activeDataset, datasetSlice]);

  // Set initial sort data from params if it exists
  useEffect(() => {
    const currentSort = sortData?.[selectedView];
    if (currentSort?.length > 0) {
      return;
    }

    let currentActiveSort = [];
    const activeSortKey = determineActiveSortKeyFromParam(selectedView);
    if (_.isUndefined(currentSort) && !_.isUndefined(activeSortKey)) {
      currentActiveSort = sortData?.[activeSortKey];
    }
    if (currentActiveSort.length > 0) {
      return;
    }

    const sortFromParams = searchParamsToSort();
    if (sortFromParams.length === 0) {
      return;
    }

    dispatch(setSortData({ selectedView: selectedView, data: sortFromParams }));
    dispatch(
      setActiveSortData({ selectedView: selectedView, data: sortFromParams }),
    );
  }, [selectedView]);

  const route = history.location.pathname;

  const isActive = (icon: string) => {
    const matchState = {
      exact: true,
      strict: false,
    };
    let active = false;

    switch (true) {
      case icon === "datasets" && route === "/":
        active = true;
        break;
      case icon === "exploration" &&
        (!_.isNull(
          matchPath(route, {
            path: allUsersRoutes.explorationMediaPage.path,
            ...matchState,
          }),
        ) ||
          !_.isNull(
            matchPath(route, {
              path: allUsersRoutes.explorationObjectPage.path,
              ...matchState,
            }),
          ) ||
          !_.isNull(
            matchPath(route, {
              path: allUsersRoutes.explorationInstancePage.path,
              ...matchState,
            }),
          )):
        active = true;
        break;
      case icon === "attributes" &&
        !_.isNull(
          matchPath(route, {
            path: "/attributes/:dataset_id/:subset_id",
            ...matchState,
          }),
        ):
        active = true;
        break;
      case icon === "statistics" &&
        !_.isNull(
          matchPath(route, {
            path: "/statistics/:dataset_id/:subset_id",
            ...matchState,
          }),
        ):
        active = true;
        break;
      case icon === "tags" &&
        !_.isNull(
          matchPath(route, {
            path: "/tags/:dataset_id/:subset_id",
            ...matchState,
          }),
        ):
        active = true;
        break;
      case icon === "subsets" &&
        !_.isNull(
          matchPath(route, {
            path: "/subsets/:dataset_id/:subset_id",
            ...matchState,
          }),
        ):
        active = true;
        break;
      case icon === "exploration" &&
        !_.isNull(
          matchPath(route, {
            path: "/main/:dataset_id/:subset_id",
            ...matchState,
          }),
        ):
        active = true;
        break;
      case icon === "internal_tools" &&
        !_.isNull(
          matchPath(route, {
            path: internalUsersRoutes.internalUserToolsPage.path,
            ...matchState,
          }),
        ):
        active = true;
        break;
      case icon === "pipeline_manager" &&
        !_.isNull(
          matchPath(route, {
            path: customContractUsersRoutes.pipelineManagerPage.path,
            ...matchState,
          }),
        ):
        active = true;
        break;
      case icon === "annotation_runs" &&
        !_.isNull(
          matchPath(route, {
            path: customContractUsersRoutes.annotationRunManagerPage.path,
            ...matchState,
          }),
        ):
        active = true;
        break;
      case icon === "ai_manager" &&
        !_.isNull(
          matchPath(route, {
            path: customContractUsersRoutes.aiManagerPage.path,
            ...matchState,
          }),
        ):
        active = true;
        break;
      default:
        break;
    }
    return active;
  };

  const getSubsetString = () => {
    return datasetSlice?.activeParentDataset?.id ===
      datasetSlice?.activeDataSet?.id
      ? "main_dataset"
      : datasetSlice?.activeDataSet?.id;
  };

  const handleExplorationClick = () => {
    const datasetID = datasetSlice?.activeParentDataset?.id;
    const subsetID = getSubsetString();
    if (_.isUndefined(datasetID) || _.isUndefined(subsetID)) {
      return;
    }

    const urlWithParams = getExplorationURLWithSearchParams(
      selectedView,
      filterData,
      sortData,
      {
        id: datasetID,
        subset_id: subsetID,
      },
    );

    history.push(urlWithParams);
  };

  const handleIconClick = (icon: string) => {
    const datasetID = datasetSlice?.activeParentDataset?.id;
    const subsetID = getSubsetString();
    if (_.isUndefined(datasetID) || _.isUndefined(subsetID)) {
      return;
    }

    history.push(
      `/${icon}/${datasetSlice?.activeParentDataset?.id}/${getSubsetString()}`,
    );
  };

  return (
    <nav
      className="flex flex-col justify-between items-center bg-[#5E5D5D] w-[60px] full-height-with-margin p-2 z-[1300] border-[1px] rounded-xl m-1 mb-1"
      data-test="sidebar"
    >
      <div>
        <div
          className="p-[7px] mb-2 flex justify-center items-center border-[1px] 
          rounded-lg cursor-pointer
          text-paletteGray-4 border-paletteGray-8 
          hover:text-paletteGray-2 hover:border-paletteGray-5
            
          "
          aria-label="Logo"
          onClick={() => history.push("/")}
        >
          <QMLogo />
        </div>
        <div>
          <SidebarIcon
            icon="datasets"
            active={isActive("datasets")}
            onClick={() => history.push("/")}
          />
          <SidebarIcon
            icon="exploration"
            active={isActive("exploration")}
            disabled={_.isNull(datasetSlice?.activeDataSet)}
            onClick={handleExplorationClick}
          />
          <SidebarIcon
            icon="subsets"
            active={isActive("subsets")}
            disabled={_.isNull(datasetSlice?.activeDataSet)}
            onClick={() => handleIconClick("subsets")}
          />
          <SidebarIcon
            icon="tags"
            active={isActive("tags")}
            disabled={_.isNull(datasetSlice?.activeDataSet)}
            onClick={() => handleIconClick("tags")}
          />
          {checkIfUserHavePermission(
            keycloak,
            "accessAttributeManagerPage",
          ) && (
            <SidebarIcon
              icon="attributes"
              active={isActive("attributes")}
              disabled={_.isNull(datasetSlice?.activeDataSet)}
              onClick={() => handleIconClick("attributes")}
            />
          )}
          {checkIfUserHavePermission(keycloak, "accessStatisticsPage") && (
            <SidebarIcon
              icon="statistics"
              active={isActive("statistics")}
              disabled={_.isNull(datasetSlice?.activeDataSet)}
              onClick={() => handleIconClick("statistics")}
            />
          )}
          {checkIfUserHavePermission(keycloak, "accessDownloadFeature") && (
            <DownloadDialog />
          )}
          {checkIfUserHavePermission(keycloak, "accessInternalToolsPage") && (
            <SidebarIcon
              icon="internal_tools"
              active={isActive("internal_tools")}
              disabled={_.isNull(datasetSlice?.activeDataSet)}
              onClick={() => handleIconClick("internal-tools")}
            />
          )}
          {checkIfUserHavePermission(keycloak, "accessPipelineManagerPage") && (
            <SidebarIcon
              icon="pipelines"
              active={isActive("pipeline_manager")}
              onClick={() =>
                history.push(customContractUsersRoutes.pipelineManagerPage.path)
              }
            />
          )}
          {checkIfUserHavePermission(
            keycloak,
            "accessAnnotationRunManagerPage",
          ) && (
            <SidebarIcon
              icon="annotation_runs"
              active={isActive("annotation_runs")}
              onClick={() => {
                history.push(
                  customContractUsersRoutes.annotationRunManagerPage.path,
                );
              }}
            />
          )}
          {checkIfUserHavePermission(keycloak, "accessAIManagerPage") && (
            <SidebarIcon
              icon="ai_manager"
              active={isActive("ai_manager")}
              onClick={() =>
                history.push(customContractUsersRoutes.aiManagerPage.path)
              }
            />
          )}
        </div>
      </div>
      <div>
        <div className="mb-1 flex flex-col items-center">
          <HelpDialog />
          {checkIfUserHavePermission(keycloak, "accessFeedBackFeature") && (
            <FeedbackDialog />
          )}
        </div>
        <AccountPopover
          anchorEl={accountAnchorEl}
          handlePopoverOpen={(
            event: React.MouseEvent<HTMLElement, MouseEvent>,
          ) => setAccountAnchorEl(event.currentTarget)}
          handlePopoverClose={() => setAccountAnchorEl(null)}
        />
        {activeDataset && (
          <LicenseDialog
            openDialog={isLicenseDialogOpen}
            setOpenDialog={setIsLicenseDialogOpen}
            dataset={activeDataset}
            targetPath={history.location.pathname}
            title="License Information"
          />
        )}
      </div>
    </nav>
  );
};

export default SidebarMenu;

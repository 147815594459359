import { AxiosError, AxiosResponse } from "axios";
import snackbarHelper from "helpers/snackbarHelperFn";
import {
  APIFetchAxios,
  APIPatchWithBodyAxios,
  APIPostWithBodyAxios,
  APIPutWithBodyAxios,
} from "routes/Auth";
import _ from "lodash";
import { TagModel, TagTypeModel } from "models/global.model";
import { SendFilterModel } from "models/filter.model";
import { Dispatch } from "@reduxjs/toolkit";
import toaster from "components/UtilComponents/CustomToaster";

/**
 * An endpoint to fetch tags for a given dataset id with optional query parameters
 * @param datasetID The id of the dataset
 * @param query Optional query parameters
 * @returns A list of tags
 */
export const fetchTagsAPI = async (
  datasetID: string,
  query?: SendFilterModel[],
): Promise<AxiosResponse<TagModel[]>> => {
  const response = await APIFetchAxios(`/datasets/${datasetID}/tags`, {
    query: query,
  });
  return response;
};

/**
 * An endpoint to create a new tag
 * @param datasetID The id of the dataset
 * @param name The name of the new tag
 * @returns The response
 */
export const putCreateTag = async (
  datasetID: string,
  name: string,
  database_object_type: TagTypeModel,
  setIsLoading?: (isLoading: boolean) => void,
) => {
  setIsLoading && setIsLoading(true);
  const response = APIPutWithBodyAxios(`/datasets/${datasetID}/tags`, {
    name: name,
    database_object_type: database_object_type,
  })
    .then((newTag) => {
      setIsLoading && setIsLoading(false);
      return newTag;
    })
    .catch(() => {
      setIsLoading && setIsLoading(false);
      return Promise.reject();
    });

  let errorMsg = `Something went wrong! Tag "${name}" creation failed!`;
  if (response instanceof Error) {
    errorMsg = response.message;
  }

  toaster.promise(
    response,
    {
      pending: `Creating new tag "${name}"!`,
      success: `Tag "${name}" successfully created!`,
      error: errorMsg,
    },
    { autoClose: 2000, toastId: "tag_creation_message" },
  );

  return response;
};

/**
 * An endpoint to change the color of a tag
 * @param datasetID The id of the dataset the tag belongs to
 * @param tagID The id of the tag that will change color
 * @param name The new name
 * @param color The new color in color hex
 * @returns The tag with the changed color
 */
export const postUpdateTag = async (
  query: { datasetID: string; tagID: string; name?: string; color?: string },
  dispatch: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
) => {
  const { datasetID, tagID, name, color } = query;
  setIsLoading && setIsLoading(true);
  await APIPatchWithBodyAxios(`/datasets/${datasetID}/tags/${tagID}`, {
    name: name || null,
    color: color || null,
  })
    .then(() => {
      snackbarHelper("Tag updated successfully!");
      setIsLoading && setIsLoading(false);
      return Promise.resolve();
    })
    .catch((error: AxiosError<any>) => {
      const errorDetail = error?.response?.data?.detail;
      snackbarHelper(
        `Tag update: ${errorDetail}` || "Tag updating failed!",
        "error",
      );
      setIsLoading && setIsLoading(false);
      return Promise.reject();
    });
};

/**
 * An endpoint to assign or unassign tags.
 * If a query is provided all elements that match the query are assigned.
 * If an annotatable id is provided only the one annotatable is assigned.
 *
 * @param operation Whether to assign or unassign
 * @param APIParams An object containing datasetId tagId a query and an annotatable id,
 *  database_object_type and optionally for attributes a parent_annotatable_id
 * @returns The response
 */
export const postTagAssignOrUnassign = (
  operation: "assign" | "unassign",
  APIParams: {
    datasetId: string;
    database_object_type: TagTypeModel;
    tagId: string;
    query?: SendFilterModel[];
    database_object_id?: string;
    parent_annotatable_id?: string;
  },
  setIsLoading?: (newIsLoadingState: boolean) => void,
) => {
  setIsLoading && setIsLoading(true);
  const response = APIPostWithBodyAxios(
    `/datasets/${APIParams?.datasetId}/tags:${operation}`,
    {
      database_object_type: APIParams.database_object_type,
      tag_id: APIParams.tagId,
      database_object_id: APIParams?.database_object_id || null,
      parent_annotatable_id: APIParams?.parent_annotatable_id || null,
      query: APIParams?.query || null,
    },
  )
    .then(() => setIsLoading && setIsLoading(false))
    .catch(() => setIsLoading && setIsLoading(false));

  // check if response is an error and Get the error message from the response
  let errorMsg = "Something went wrong! Tag assign/un-assign failed!";
  if (response instanceof Error) {
    errorMsg = response.message;
  }

  toaster.promise(
    response,
    {
      pending: `${_.upperFirst(operation)}ing a tag to an item!`,
      success: `${_.upperFirst(operation)}ing a tag to an item is successful!`,
      error: errorMsg,
    },
    { autoClose: 2000, toastId: "tag_assignment_message" },
  );

  return response;
};

/**
 * An endpoint to create task inputs for a subset
 * @param params The params to post the reference for
 * @param params.datasetId The dataset ID of the subset to create reference data for
 * @param params.tagId The tag ID of the subset to create reference data for
 * @param body The body to post the subset reference data
 * @param body.crop Whether to use the crop or the entire media
 * @param body.color_map The mapping from object_category to color
 * @param dispatch The dispatch function
 * @param displaySnackbar Whether to display a snackbar on success or error
 * @param setIsLoading Set the loading state of the component
 */
export const PostTagReferenceData = async (
  params: { datasetId: string; tagId: string },
  body: { crop: boolean; color_map: Record<string, string> },
  dispatch: Dispatch,
  displaySnackbar: boolean = true,
  setIsLoading?: (isLoading: boolean) => void,
) => {
  setIsLoading && setIsLoading(true);
  const { datasetId, tagId } = params;
  return APIPostWithBodyAxios(
    `/datasets/${datasetId}/tags/${tagId}/referenceData/`,
    body,
  )
    .then(() => {
      if (displaySnackbar) {
        snackbarHelper("Successfully created Reference Data!");
      }
      setIsLoading && setIsLoading(false);
      return Promise.resolve();
    })
    .catch((error: AxiosError<any>) => {
      const errorDetail = error?.response?.data?.detail;
      if (displaySnackbar) {
        snackbarHelper(
          `Attributes archive: ${errorDetail}` ||
            "An error occurred during the creation of the reference data.",
          "error",
        );
      }
      setIsLoading && setIsLoading(false);
      return Promise.reject();
    });
};

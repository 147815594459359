import { useEffect, useState } from "react";
import _ from "lodash";
import SortButton, { SortButtonModel } from "components/Buttons/SortButton";
import Loading from "components/UtilComponents/Loading";
import SubsetSubTable from "components/Table/DatasetTable/SubsetSubTable";
import { ReactComponent as ArrowSmallDownIcon } from "assets/arrow_small_down.svg";
import { ReactComponent as ArrowSmallUpIcon } from "assets/arrow_small_up.svg";
import { DatasetModel } from "models/dataset.model";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { fetchSubsetInformationForDataset } from "store/dashboardSlice";
import SearchBar from "components/Inputs/SearchBar";
import TooltipTruncateEllipsis from "components/Tooltips/TooltipTruncateEllipsis";

/**
 * @param field - The field to be displayed in the column
 * @param headerName - The name to be displayed in the header
 * @param span - The span of the column (in percentage)
 * @param sortable - Whether the column is sortable or not
 * @param className - The class name to be applied to the column
 * @param headerClassName - The class name to be applied to the header
 * @param cell - The cell to be displayed in the column
 */
export interface DatasetTableColumn {
  field: string;
  headerName: string;
  span?: number;
  sortable?: boolean;
  className?: string;
  headerClassName?: string;
  cell?: (row: unknown) => JSX.Element;
}

/**
 * @param rows - The rows to be displayed in the table
 * @param columns - The columns to be displayed in the table
 * @param onRowClick - A callback function to be called when a row is clicked
 * @param defaultSortKey - The default sort key to be used when the table is first rendered
 * @param isLoading - Whether the table is loading or not
 */
interface Props {
  rows: DatasetModel[];
  columns: DatasetTableColumn[];
  onRowClick?: (row: unknown) => void;
  defaultSort?: SortButtonModel;
  isLoading?: boolean;
  enableSearch?: boolean;
}

const DatasetTable = ({
  rows,
  columns,
  onRowClick,
  defaultSort,
  isLoading,
  enableSearch,
}: Props) => {
  const [sortBy, setSortBy] = useState<SortButtonModel>({
    name: null,
    direction: null,
  });

  const dispatch = useAppDispatch();

  const [searchValue, setSearchValue] = useState<string>("");
  const [expandedSubsets, setExpandedSubsets] = useState<string[]>([]);
  const dashboardData = useAppSelector((state) => state.dashboardSlice.data);

  // Set the default sort
  useEffect(() => {
    defaultSort && setSortBy(defaultSort);
  }, []);

  /**
   * Adjust the expandedSubsets array on the click of the expand/collapse button
   * @param datasetID - The ID of the dataset
   */
  const handleExpandSubsetClick = (datasetID: string) => {
    // If the subset is already expanded, collapse it
    if (_.includes(expandedSubsets, datasetID)) {
      setExpandedSubsets(_.without(expandedSubsets, datasetID));
    }
    // If the subset is not expanded, expand it
    else {
      setExpandedSubsets([...expandedSubsets, datasetID]);
      if (_.isEmpty(dashboardData?.[datasetID]?.subsets)) {
        dispatch(fetchSubsetInformationForDataset({ datasetID: datasetID }));
      }
    }
  };

  // Render the header of the table (the column names) and the sort buttons
  const renderHeader = () => {
    return (
      <div className="w-full flex py-2 px-4 pr-14 text-paletteGray-9">
        {_.map(columns, (col, key) => {
          const columnSpan = col?.span || 100 / columns?.length;
          return (
            <div
              key={key}
              className={`flex items-center gap-x-2 span
                 ${col?.headerClassName}`}
              style={{ width: `${columnSpan}%` }}
              data-test={
                col?.headerName && col.headerName.trim() !== ""
                  ? `${col.headerName}_column`
                  : undefined
              }
            >
              {col?.headerName}
              {_.isUndefined(col?.sortable) && renderSortIcon(col)}
            </div>
          );
        })}
      </div>
    );
  };

  // Render the sort button
  const renderSortIcon = (col: DatasetTableColumn) => {
    return (
      <SortButton
        sortBy={{ name: col?.field, direction: sortBy?.direction }}
        setSortBy={setSortBy}
        selected={sortBy?.name === col?.field}
      />
    );
  };

  // Render the rows of the table (the data)
  const renderRows = () => {
    const filteredRows = _.filter(rows, (row) => {
      return _.includes(_.toLower(row?.name as string), _.toLower(searchValue));
    });

    let sortedRows = filteredRows;
    const sortName = sortBy?.name;
    if (!_.isNull(sortName) && !_.isNull(sortBy?.direction)) {
      sortedRows = _.orderBy(
        filteredRows,
        [
          //Todo: replace with a type
          (row: any) => {
            if (_.isString(row[sortName])) {
              return _.toLower(row[sortName] as string);
            }
            return row[sortName];
          },
        ],
        [sortBy?.direction],
      );
    }
    return _.map(sortedRows, (row, key) => {
      return (
        <div
          key={key}
          className={`text-paletteBlack-2 w-full flex flex-col py-3 px-4 border-[1px] rounded-[8px] text-lg
            border-paletteGray-4 hover:border-paletteGray-5 ${
              onRowClick && "cursor-pointer"
            }`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onRowClick && onRowClick(row);
          }}
        >
          <div
            role="button"
            className="flex justify-center h-[55px] pr-2"
            style={{ overflow: "hidden", scrollbarGutter: "stable" }}
          >
            {renderRowCells(row)}
          </div>
          {_.includes(expandedSubsets, row.id) &&
          dashboardData?.[row?.id].subsets !== null ? (
            <SubsetSubTable subsets={dashboardData?.[row?.id].subsets} />
          ) : (
            <></>
          )}
        </div>
      );
    });
  };

  // Render the cells of the table (the data) for each row
  const renderRowCells = (row: DatasetModel) => {
    return _.map(columns, (col, key) => {
      const columnSpan = col?.span || 100 / columns?.length;

      return (
        <div
          key={key}
          className={`flex flex-col justify-center gap-x-2 span translate-all
                    ${col?.headerClassName}`}
          style={{ width: `${columnSpan}%` }}
          data-test={
            col?.headerName && col.headerName.trim() !== ""
              ? `${col.headerName}_cell`
              : undefined
          }
        >
          {renderCellValue(row, col)}
          {renderExpandSubsetsButton(col, row)}
        </div>
      );
    });
  };

  const renderCellValue = (row: DatasetModel, col: DatasetTableColumn) => {
    // If the cell is a custom cell, use the cell function to render the cell
    // Else, use the field to render the cell
    const cellValue = col?.cell ? col?.cell(row) : _.get(row, col?.field);

    if (_.isElement(cellValue)) {
      return cellValue;
    }

    if (_.isString(cellValue) || _.isNumber(cellValue)) {
      return (
        <TooltipTruncateEllipsis
          className="pr-3"
          data-test={`${col?.headerName}_value`}
        >
          {cellValue}
        </TooltipTruncateEllipsis>
      );
    }

    return cellValue;
  };

  const renderExpandSubsetsButton = (
    col: DatasetTableColumn,
    dataset: DatasetModel,
  ) => {
    if (col?.field === "name") {
      return (
        <div
          role="button"
          aria-label="expand subsets"
          className="flex items-center hover:underline px-2 pt-[5.5px]"
          data-test="expand_subsets"
          onClick={(e) => {
            e.stopPropagation();
            handleExpandSubsetClick(dataset.id);
          }}
        >
          Show subsets{" "}
          {_.includes(expandedSubsets, dataset.id) ? (
            <ArrowSmallUpIcon className="w-[10px] h-[10px] ml-1 text-paletteGray-8" />
          ) : (
            <ArrowSmallDownIcon className="w-[10px] h-[10px] ml-1 text-paletteGray-8" />
          )}
        </div>
      );
    }
  };

  const renderSearchBar = () => {
    if (enableSearch) {
      return (
        <div className="mb-9">
          <SearchBar
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            autoFocus={true}
          />
        </div>
      );
    }
  };

  // Render the body of the table
  const renderBody = () => {
    // If the table is loading, show the loading component
    if (!_.isUndefined(isLoading) && isLoading) {
      return (
        <div className="h-1/2">
          <Loading />
        </div>
      );
    }
    // If the table is not loading and there is no data, show the no data found message
    else if (_.isEmpty(rows)) {
      return (
        <div
          className="text-paletteGray-10 text-center"
          data-test="empty_table"
        >
          No data found
        </div>
      );
    }
    // If the table is not loading and there is data, show the table
    else {
      return (
        <div className="h-full flex flex-col" data-test="dataset_list">
          {renderSearchBar()}
          {renderHeader()}
          <div
            className="flex-1 overflow-auto flex flex-col gap-y-2"
            data-test="dataset_grid"
          >
            {renderRows()}
          </div>
        </div>
      );
    }
  };

  return renderBody();
};

export default DatasetTable;

import PipelineConfigurationActions from "Pages/PipelineDesigner/PipelineConfiguration/Configuration/PipelineConfigurationActions";
import PipelineConfigurationTabs from "Pages/PipelineDesigner/PipelineConfiguration/Configuration/PipelineConfigurationTabs";
import PipelineConfigurations from "Pages/PipelineDesigner/PipelineConfiguration/Configuration/PipelineConfigurations";
import UserInterfaceConfigurations from "Pages/PipelineDesigner/PipelineConfiguration/Configuration/UserInterfaceConfigurations";
import { PipelineDesignerConfigurationTabsEnum } from "models/pipelines.model";
import SelectData from "Pages/PipelineDesigner/PipelineConfiguration/Configuration/SelectData";
import SelectReferenceData from "Pages/PipelineDesigner/PipelineConfiguration/Configuration/SelectReferenceData";
import { checkIfUserHavePermission } from "helpers/keycloakHelpers";
import keycloak from "keycloak";
import SelectInitialAttribute from "Pages/PipelineDesigner/PipelineConfiguration/Configuration/SelectInitialAttribute";

interface Props {
  selectedTab: PipelineDesignerConfigurationTabsEnum;
  setSelectedTab: (tab: PipelineDesignerConfigurationTabsEnum) => void;
}

const Configurations = ({ selectedTab, setSelectedTab }: Props) => {
  const renderTabContent = () => {
    switch (selectedTab) {
      case PipelineDesignerConfigurationTabsEnum.UserInterface:
        return <UserInterfaceConfigurations />;
      case PipelineDesignerConfigurationTabsEnum.Configuration:
        return <PipelineConfigurations />;
      default:
        return null;
    }
  };

  return (
    <div
      className="w-full h-full bg-white border-[1px] border-paletteGray-5 rounded-lg 
        flex flex-col"
    >
      <div className="w-full p-2 border-b-[1px] border-paletteGray-4">
        <PipelineConfigurationTabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </div>
      <div className="w-full min-h-0 flex-1">{renderTabContent()}</div>
      <div
        className="px-6 py-[20px] bg-white border-t-[1px] border-b-[1px]
        border-paletteGray-5
                  flex flex-col gap-y-2"
      >
        <SelectData />
        {checkIfUserHavePermission(
          keycloak,
          "accessPipelineReferenceDataFeature",
        ) && <SelectReferenceData />}
        <SelectInitialAttribute />
      </div>
      <div className="w-full p-2 border-t-[1px] border-paletteGray-4">
        <PipelineConfigurationActions />
      </div>
    </div>
  );
};

export default Configurations;

import StandardPopoverWrapper from "components/Popovers/StandardPopoverWrapper";
import { ReactComponent as DeleteIcon } from "assets/delete.svg";
import { ReactComponent as Checkmark } from "assets/check_mark.svg";
import { ReactComponent as MoreIcon } from "assets/more.svg";
import { ReactComponent as NotificationIconGrayed } from "assets/notification_bell_ring_grayed.svg";
import DOMPurify from "dompurify";
import { NotificationModel } from "models/websockets.model";
import {
  deleteNotificationStore,
  markAsReadStore,
} from "store/notificationsSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";

interface props {
  notification: NotificationModel;
}

const NotificationBox = ({ notification }: props) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.notificationSlice.loading);

  const Title = () => {
    return (
      <h5 className="flex flex-col font-medium pb-4 ">{notification.title}</h5>
    );
  };

  const NotificationContent = () => {
    return (
      <div
        className="text-sm"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(notification.details),
        }}
      />
    );
  };

  const MessageBody = () => {
    return (
      <div className="pl-2 w-full" aria-label={notification.title}>
        <Title />
        <NotificationContent />
      </div>
    );
  };
  const UnreadIndicator = () => {
    return (
      <div className="w-8 flex justify-center">
        <div
          className={`w-[6px] h-[6px] rounded-xl ${
            notification.read_at == null ? "bg-[#32ade6]" : "bg-transparent"
          }`}
        />
      </div>
    );
  };

  const markReadingStateAction = () => {
    if (notification.read_at === null) {
      return {
        label: "Mark as read",
        onItemClick: () => {
          if (!loading) {
            dispatch(
              markAsReadStore({
                notification_id: notification.id,
                read: true,
              }),
            );
          }
        },
        icon: <Checkmark className="text-paletteGray-7 w-[16px] h-[16px]" />,
      };
    } else {
      return {
        label: "Mark as unread",
        onItemClick: () => {
          if (!loading) {
            dispatch(
              markAsReadStore({
                notification_id: notification.id,
                read: false,
              }),
            );
          }
        },
        icon: (
          <NotificationIconGrayed className="text-paletteGray-7 w-[16px] h-[16px]" />
        ),
      };
    }
  };

  const getActionsList = () => {
    const actions = [
      markReadingStateAction(),
      {
        label: "Delete",
        onItemClick: () =>
          dispatch(
            deleteNotificationStore({ notification_id: notification.id }),
          ),
        className: "text-paletteRed",
        icon: <DeleteIcon />,
      },
    ];

    return actions;
  };

  const ActionsPopover = () => {
    return (
      <div className="w-16 text-black" onClick={(e) => e.stopPropagation()}>
        <StandardPopoverWrapper
          id={"test"}
          buttonElement={
            <MoreIcon className="hover:bg-transparent w-[20px] h-[20px] cursor-pointer" />
          }
          itemsList={getActionsList()}
        />
      </div>
    );
  };

  const ActionButton = () => {
    let component: JSX.Element = <></>;
    if (notification.severity === "error") {
      component = (
        <div className="mx-8 italic text-red-400 text-md mt-4">
          <a href="mailto:support@quality-match.com">
            Contact customer support
          </a>
        </div>
      );
    } else {
      component = notification?.route ? (
        <button
          className="mx-8 w-[195px] animate-none button-layer hover:bg-[#2C2C2C] hover:text-white bg-[#2C2C2C] text-white mt-4 h-8"
          onClick={() => {
            const newWnd = window.open(
              notification?.route,
              "_blank",
              "noopener,noreferrer",
            );
            if (newWnd) {
              newWnd.opener = null;
            }
          }}
        >
          {" "}
          {notification?.route_label || "Take me there"}
        </button>
      ) : (
        <></>
      );
    }
    return component;
  };

  const handleClick = () => {
    if (notification.read_at === null && !loading) {
      dispatch(
        markAsReadStore({ notification_id: notification.id, read: true }),
      );
    }
  };

  return (
    <article
      id={notification.id}
      className={`py-6 border-t-[1px] ${
        notification?.read_at === null && "bg-[#f2f2f7] cursor-pointer"
      } ${
        notification.severity === "error"
          ? "text-red-400"
          : "text-paletteGray-13"
      }`}
      onClick={() => handleClick()}
    >
      <div className="flex w-full items-center justify-center">
        <UnreadIndicator />
        <MessageBody />
        <ActionsPopover />
      </div>
      <ActionButton />
    </article>
  );
};

export default NotificationBox;

import { Dispatch } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import snackbarHelper from "helpers/snackbarHelperFn";
import {
  APIDeleteAxios,
  APIFetchAxios,
  APIPatchWithBodyAxios,
  APIPostWithBodyAxios,
} from "routes/Auth";
import {
  AIAnnotationRunCreate,
  AIAnnotationRunModel,
} from "models/aiAnnotationRun.model";

/**
 * An endpoint to fetch AI Annotation runs
 * @param dispatch The dispatch function from the redux store
 * @returns A list of AI Annotation runs
 */
export const fetchAIAnnotationRuns = async (
  dispatch?: Dispatch,
): Promise<AIAnnotationRunModel[]> => {
  const response = await APIFetchAxios("/aiAnnotationRuns").catch((error) => {
    dispatch && snackbarHelper("AI Annotation runs fetching failed!", "error");
    return Promise.reject(error);
  });
  return response?.data;
};

/**
 * An endpoint to fetch an AI annotation run
 * @param params.aiAnnotationRunID The AI annotation run ID
 * @param dispatch The dispatch function from the redux store
 * @returns The AI annotation run
 */
export const fetchAIAnnotationRun = async (
  params: { aiAnnotationRunID: string },
  dispatch: Dispatch,
): Promise<AIAnnotationRunModel> => {
  const response = await APIFetchAxios(
    `/aiAnnotationRuns/${params?.aiAnnotationRunID}`,
  ).catch((error) => {
    snackbarHelper("AI annotation run fetching failed!", "error");
    return Promise.reject(error);
  });
  return response?.data;
};

/**
 * An endpoint to create an AI annotation run
 * @param aiAnnotationRun.name the name of the AI annotation run
 * @param aiAnnotationRun.dataset_id the dataset ID
 * @param aiAnnotationRun.subset_id the subset ID
 * @param aiAnnotationRun.ml_annotation_model_id the ML annotation model ID
 * @param dispatch
 * @param setIsLoading
 * @returns The created AI annotation run
 */
export const postAIAnnotationRun = async (
  aiAnnotationRun: AIAnnotationRunCreate,
  dispatch: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
): Promise<AIAnnotationRunModel> => {
  const response = await APIPostWithBodyAxios(
    "/aiAnnotationRuns",
    aiAnnotationRun,
  ).catch((error) => {
    snackbarHelper(
      `AI annotation run creation failed: ${error.response.data.detail}`,
      "error",
      6000,
    );
    return Promise.reject(error);
  });
  setIsLoading && setIsLoading(false);
  snackbarHelper("AI annotation run created successfully!");
  return response?.data;
};

/**
 * An endpoint to delete an AI annotation run
 * @param params.aiAnnotationRunID The AI annotation run ID
 * @param dispatch
 * @param setIsLoading
 */
export const deleteAIAnnotationRun = async (
  params: { aiAnnotationRunID: string },
  dispatch: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
) => {
  setIsLoading && setIsLoading(true);
  await APIDeleteAxios(`/aiAnnotationRuns/${params.aiAnnotationRunID}`)
    .then(() => {
      snackbarHelper("AI annotation run archived successfully!");
      setIsLoading && setIsLoading(false);
      return Promise.resolve();
    })
    .catch((error: AxiosError<any>) => {
      const errorDetail = error?.response?.data?.detail;
      snackbarHelper(
        `AI annotation run archive: ${errorDetail}` ||
          "AI annotation run archive failed!",
        "error",
      );
      setIsLoading && setIsLoading(false);
      return Promise.reject();
    });
};

/**
 * An endpoint to update an AI annotation run
 * @param aiAnnotationRunID The AI annotation run ID
 * @param body.name The name of the AI annotation run
 * @param body.user_group The user group of the AI annotation run
 * @param dispatch
 * @param setIsLoading
 * @returns The updated AI annotation run
 */
export const patchAIAnnotationRun = async (
  aiAnnotationRunID: string,
  body: {
    name?: string;
    user_group?: string;
  },
  dispatch: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
): Promise<AIAnnotationRunModel> => {
  setIsLoading && setIsLoading(true);
  const response = await APIPatchWithBodyAxios(
    `/aiAnnotationRuns/${aiAnnotationRunID}`,
    body,
  ).catch((error: AxiosError<any>) => {
    const errorDetail = error?.response?.data?.detail;
    snackbarHelper(
      `AI annotation run update: ${errorDetail}` ||
        "AI annotation run update failed!",
      "error",
    );
    setIsLoading && setIsLoading(false);
    return Promise.reject();
  });
  snackbarHelper("AI annotation run updated successfully!");
  setIsLoading && setIsLoading(false);
  return response?.data;
};

import { ArrayFieldTemplateProps } from "@rjsf/utils";
import { ReactComponent as DeleteIcon } from "assets/delete.svg";
import { ReactComponent as ArrowDown } from "assets/arrow_small_down.svg";
import DescriptionComponent from "components/RJSF/DescriptionComponent";
import RequiredTemplate from "components/RJSF/RequiredTemplate";

const ArrayFieldTemplate = (props: ArrayFieldTemplateProps) => {
  return (
    <div
      className={`my-2 py-2 px-1 border-b-[1px] border-paletteGray-3
       last:border-b-0 
      ${
        props?.uiSchema?.["ui:showFullBorder"]
          ? "border-2 last:border-b-2 rounded"
          : ""
      }
    `}
    >
      {props?.title && (
        <div className="flex gap-x-1 pl-1">
          {props?.title}
          <RequiredTemplate required={props.required} />
          {props?.schema?.description && (
            <DescriptionComponent description={props.schema.description} />
          )}
        </div>
      )}
      <div className="ml-1 flex flex-col gap-y-2">
        {props?.items?.map((element) => {
          return (
            <div
              className="w-full px-4 py-2 flex gap-x-2 shadow rounded-lg
                border-[1px] border-paletteGray-2"
              key={element?.key}
            >
              <div className="min-w-0 flex-1">{element?.children}</div>

              <div className="w-auto flex flex-col gap-y-2">
                <button
                  title="Move up"
                  className="button-layer bg-transparent disabled:bg-transparent"
                  onClick={element.onReorderClick(
                    element.index,
                    element.index - 1,
                  )}
                  disabled={element.index === 0 || props?.items?.length === 1}
                >
                  <ArrowDown className="w-4 h-4 transform rotate-180" />
                </button>
                <button
                  title="Remove"
                  className="button-layer bg-transparent text-paletteRed"
                  onClick={element.onDropIndexClick(element.index)}
                >
                  <DeleteIcon
                    className="w-4 h-4 text-paletteRed"
                    strokeWidth={2}
                  />
                </button>
                <button
                  title="Move down"
                  className="button-layer bg-transparent disabled:bg-transparent"
                  onClick={element.onReorderClick(
                    element.index,
                    element.index + 1,
                  )}
                  disabled={
                    element.index === props.items.length - 1 ||
                    props?.items?.length === 1
                  }
                >
                  <ArrowDown className="w-4 h-4" />
                </button>
              </div>
            </div>
          );
        })}
      </div>

      {props?.canAdd && (
        <div className="w-full my-2 flex flex-row-reverse">
          <button
            onClick={props?.onAddClick}
            className="button-layer w-1/2"
            data-test="add_new_item_pipeline_designer"
          >
            Add new item
          </button>
        </div>
      )}
    </div>
  );
};

export default ArrayFieldTemplate;

import { useState } from "react";
import _ from "lodash";
import isURLStillValid from "helpers/functions/isURLStillValid";
import {
  InstanceModel,
  MediaModel,
  MediaObjectModel,
  MediaObjectRawModel,
} from "models/exploration.model";
import { LazyLoadImage } from "react-lazy-load-image-component";

import VideoPlayerLabel from "components/Labels/VideoPlayerLabel";
import CustomCheckbox from "components/Inputs/Checkbox";

import { ReactComponent as MaximizeIcon } from "assets/maximize.svg";
import { AnnotatableEnum, SelectedViewModel } from "models/global.model";

import MediaIcon from "assets/image.svg";
import PointCloudIcon from "assets/point_cloud.svg";
import { Mediatype } from "models/dataset.model";
import { GeometriesEnum } from "models/geometries.model";

type Props = {
  type: SelectedViewModel;
  item: MediaModel | InstanceModel | MediaObjectRawModel;
  url: string;
  itemIndex: number;
  isSelected: boolean;
  hoveredIndex: number;
  setHoveredIndex: (index: number) => void;
  pinnedIndex: number | null;
  setPinnedIndex: (index: number | null) => void;
  onExpandClick?: () => void;
  onCheckboxClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onItemURLExpire?: (
    id: string,
  ) => Promise<MediaModel | InstanceModel | MediaObjectRawModel>;
  isItemBeingMultiSelected?: boolean;
};

const ThumbnailCard = ({
  type,
  item,
  url,
  itemIndex,
  isSelected,
  hoveredIndex,
  setHoveredIndex,
  pinnedIndex,
  setPinnedIndex,
  onExpandClick,
  onCheckboxClick,
  onItemURLExpire,
  isItemBeingMultiSelected,
}: Props) => {
  const [updatedURL, setUpdatedURL] = useState<null | string>(null);
  const [didTryToFetchNewURL, setDidTryToFetchNewURL] = useState(false);

  const selectURL = () => {
    const urlToLoad = url;
    let icon = MediaIcon;
    if (
      (type === AnnotatableEnum.Media &&
        (item as MediaModel)?.media_type === Mediatype.point_cloud) ||
      (type === AnnotatableEnum.MediaObject &&
        (item as MediaObjectModel)?.media_object_type ===
          GeometriesEnum.CuboidCenterPoint)
    ) {
      icon = PointCloudIcon;
    }
    if (
      _.isUndefined(urlToLoad) ||
      urlToLoad?.length === 0 ||
      urlToLoad === "https://nothing.none"
    )
      return icon;

    if (_.isNull(updatedURL)) {
      return urlToLoad;
    }
    return updatedURL;
  };

  // TODO: This is not the best way to do this. We should have a better way to
  //       handle this.
  // Update the item URL if it is expired
  const updateURL = () => {
    // Try to fetch a new URL if:
    // 1. We have a function to call when the URL is expired
    // 2. We haven't tried to fetch a new URL yet
    // 3. The URL is expired
    if (onItemURLExpire && !didTryToFetchNewURL && !isURLStillValid(url)) {
      setDidTryToFetchNewURL(true);
      onItemURLExpire(item?.id).then((response) => {
        let newUrl: string | null = null;
        if (type === AnnotatableEnum.Media) {
          const media = response as MediaModel;
          newUrl = media?.thumbnails?.["140x113"];
        } else if (type === AnnotatableEnum.MediaObject) {
          const mediaObject = response as MediaObjectRawModel;
          newUrl = mediaObject?.crop_url;
        }
        if (newUrl) {
          setUpdatedURL(newUrl);
        }
      });
    }
  };

  const renderDimLayer = () => {
    if (isItemBeingMultiSelected) {
      return <div className="absolute inset-0 bg-paletteOrange opacity-30" />;
    }

    if (hoveredIndex === itemIndex) {
      return <div className="absolute inset-0 bg-black opacity-30" />;
    }
  };

  const renderSelectCheckbox = () => {
    if (_.isUndefined(onCheckboxClick)) return;

    if (isSelected || hoveredIndex === itemIndex || pinnedIndex === itemIndex) {
      return (
        <div
          className={`absolute top-2 left-2 
        ${!isSelected && "animate-fade70 opacity-70"}`}
        >
          <CustomCheckbox
            size={32}
            checked={isSelected}
            onChange={(event) => {
              onCheckboxClick(event);
            }}
          />
        </div>
      );
    }
  };

  const renderExpandIcon = () => {
    if (_.isUndefined(onExpandClick)) return;

    if (
      pinnedIndex === itemIndex ||
      (_.isNull(pinnedIndex) && hoveredIndex === itemIndex)
    ) {
      return (
        <div
          className="absolute top-2 right-2 p-2 bg-paletteBlack-1
            text-white rounded-lg hover:bg-paletteBlack-2 animate-fade70 opacity-70"
          onClick={onExpandClick}
          data-test="enter_detail_view"
        >
          <MaximizeIcon width={28} height={28} />
        </div>
      );
    }
  };

  const renderIndex = () => {
    return (
      <div
        className="absolute bottom-[6px] left-[6px] bg-paletteGray-2 opacity-60 rounded
        px-[2.5px] text-sm text-paletteBlack-2 font-medium"
      >
        {itemIndex + 1}
      </div>
    );
  };

  const renderNumberOfReferenceBoxes = () => {
    if (type === AnnotatableEnum.MediaObject) {
      return;
    }
    let numBBsRefAttr = _.find(item?.attributes, ["id", "total_num_objects"]);
    // Fallback to num_bbs_reference
    if (_.isUndefined(numBBsRefAttr)) {
      numBBsRefAttr = _.find(item?.attributes, ["id", "num_bbs_reference"]);
    }
    const numberOfRefBBs = numBBsRefAttr?.value;
    if (_.isNumber(numberOfRefBBs) && numberOfRefBBs > 0) {
      return (
        <div
          className="absolute bottom-[6px] right-[6px] rounded flex items-center gap-x-[2px]
          px-[2.5px] bg-paletteBlack-1
          text-sm text-white font-medium animate-fade70 opacity-70"
        >
          <div className="w-2 h-2 rounded-full bg-[#1DD3B3]" />
          {numberOfRefBBs}
        </div>
      );
    }
  };
  const source = selectURL();
  return (
    <div
      className={`min-h-[100px] cursor-pointer transition-all duration-200 ease-in-out
        border-[3px]  ${
          pinnedIndex === itemIndex ? "border-paletteOrange" : "border-white"
        }`}
      onMouseOver={() => {
        setHoveredIndex(itemIndex);
      }}
      onClick={() => {
        if (_.isNull(pinnedIndex)) {
          setPinnedIndex(itemIndex);
        } else {
          setPinnedIndex(null);
        }
      }}
    >
      <div
        className={`min-h-[100px] relative flex justify-center items-center transition-all duration-500 ease-in-out bg-paletteWhite ${
          source !== url ? "border-[1px] border-paletteGray-5" : ""
        }`}
      >
        <LazyLoadImage
          effect="blur"
          width="100%"
          height="100%"
          src={selectURL()}
          placeholder={<div className="h-[100px]" />}
          onError={() => updateURL()}
          className={`${(_.isUndefined(url) || url !== source) && "p-[30%]"}`}
          alt={item?.id}
        />
        {renderDimLayer()}
        <VideoPlayerLabel />
        {renderSelectCheckbox()}
        {renderExpandIcon()}
        {renderIndex()}
        {renderNumberOfReferenceBoxes()}
      </div>
    </div>
  );
};

export default ThumbnailCard;

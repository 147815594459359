import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import pipelineConfigurationsForm from "Pages/PipelineDesigner/PipelineConfiguration/Configuration/PipelineConfigurations/PipelineConfigurationsForm";
import { validateForm } from "components/Forms/formHelper";
import { FormFieldModel, FormModel, TextFieldModel } from "models/form.model";
import {
  PipelineModel,
  PipelineNodeModel,
  VisualisationTypeEnum,
} from "models/pipelines.model";
import _ from "lodash";
import { SubsetTypeModel } from "models/global.model";
import initialFormData from "schemas/UserInterfaceInitFormData";
import { annotationUIs, UICategories } from "schemas/annotationUIs";
import {
  LegacyUIsEnum,
  UICategoriesType,
  UIsEnum,
} from "schemas/annotationUIs.model";
import { Mediatype } from "../models/dataset.model";

export interface DatasetIdAndNameModel {
  id: string;
  name: string;
  num_medias: number;
  num_media_objects: number;
  num_instances: number;
  subset_type: SubsetTypeModel;
}

interface ReferenceDataConfigModel {
  id: string;
  name: string;
}

interface InitialAttributeConfigModel {
  id: string;
  name: string;
}

interface VisualisationConfigModel {
  id: string | null;
}

export interface pipelineDesignerStateTypes {
  isShowingLivePreview: boolean;
  isEditingUIConfig: boolean;
  // Annotation run data
  annotationRunName: TextFieldModel;
  dataset: DatasetIdAndNameModel | null;
  subset: DatasetIdAndNameModel | null;
  referenceData: ReferenceDataConfigModel | null;
  initialAttribute: InitialAttributeConfigModel | null;
  visualisationConfig: VisualisationConfigModel | null;
  colorMap: Record<string, string> | null;
  // Pipeline data
  pipelineName: TextFieldModel;
  // Node data (TODO: make node data as list when we add multi-node pipelines)
  uiCategory: UICategoriesType | null;
  uiType: UIsEnum | null;
  subsetTypeWhitelist: SubsetTypeModel[] | null;
  configurationsForm: FormModel;
  guiFormData: Record<string, unknown> | null;
  // Active Pipeline and node(s)
  pipeline: PipelineModel | null;
  pipelineNode: PipelineNodeModel | null;
  // Validation state
  validation: {
    isDataSelected: boolean | null;
    isGeometryColorsSelected: boolean | null;
    isReferenceDataValid: boolean | null;
    isConfigurationsFormValid: boolean | null;
    isGUISettingsFormValid: boolean | null;
    isUiTypeSelected: boolean | null;
    isMinNumberSmallerOrEqualToMaxNumber: boolean | null;
    isWpTasksSmallerOrEqualToSubsetCount: boolean | null;
    isPipelineNameValid: boolean | null;
  };
}

const initialState = {
  isShowingLivePreview: false,
  isEditingUIConfig: true,
  annotationRunName: {
    type: "text",
    key: "name",
    placeholder: "Enter annotation run name...",
    value: "",
    required: true,
  },
  dataset: null,
  subset: null,
  referenceData: null,
  initialAttribute: null,
  visualisationConfig: null,
  colorMap: null,
  pipelineName: {
    type: "text",
    key: "name",
    placeholder: "Enter pipeline name...",
    value: "",
    isErrored: undefined,
    required: true,
    settings: {
      minimumTextLength: 3,
    },
  },
  uiCategory: null,
  uiType: null,
  subsetTypeWhitelist: null,
  configurationsForm: pipelineConfigurationsForm,
  guiFormData: initialFormData,
  pipeline: null,
  pipelineNode: null,
  validation: {
    isDataSelected: null,
    isGeometryColorsSelected: null,
    isReferenceDataValid: null,
    isConfigurationsFormValid: null,
    isGUISettingsFormValid: null,
    isUiTypeSelected: null,
    isMinNumberSmallerOrEqualToMaxNumber: null,
    isWpTasksSmallerOrEqualToSubsetCount: null,
    isPipelineNameValid: null,
  },
} as pipelineDesignerStateTypes;

export const pipelineDesignerSlice = createSlice({
  name: "pipelineDesigner",
  initialState,
  reducers: {
    setIsShowingLivePreview: (state, action: PayloadAction<boolean>) => {
      state.isShowingLivePreview = action.payload;
    },
    setIsEditingUIConfig: (state, action: PayloadAction<boolean>) => {
      state.isEditingUIConfig = action.payload;
    },
    setAnnotationRunName: (state, action: PayloadAction<TextFieldModel>) => {
      state.annotationRunName = action.payload;
    },
    setDataset: (
      state,
      action: PayloadAction<DatasetIdAndNameModel | null>,
    ) => {
      state.dataset = action.payload;
      state.initialAttribute = null;
    },
    setSubset: (state, action: PayloadAction<DatasetIdAndNameModel | null>) => {
      state.subset = action.payload;
    },
    setReferenceData: (
      state,
      action: PayloadAction<ReferenceDataConfigModel | null>,
    ) => {
      state.referenceData = action.payload;
    },
    setInitialAttribute: (
      state,
      action: PayloadAction<InitialAttributeConfigModel | null>,
    ) => {
      state.initialAttribute = action.payload;
    },
    setVisualisationConfig: (
      state,
      action: PayloadAction<VisualisationConfigModel | null>,
    ) => {
      state.visualisationConfig = action.payload;
    },
    setColorMap: (state, action: PayloadAction<Record<string, string>>) => {
      state.colorMap = action.payload;
    },
    setPipelineName: (state, action: PayloadAction<TextFieldModel>) => {
      state.pipelineName = action.payload;
    },
    setUIcategory: (state, action: PayloadAction<UICategoriesType>) => {
      state.uiCategory = action.payload;
    },
    setUIType: (state, action: PayloadAction<UIsEnum | null>) => {
      state.guiFormData = null;
      state.uiType = action.payload;
    },
    setSubsetTypeWhitelist: (
      state,
      action: PayloadAction<SubsetTypeModel[] | null>,
    ) => {
      state.subsetTypeWhitelist = action.payload;
    },
    setConfigurationsForm: (state, action: PayloadAction<FormModel>) => {
      state.configurationsForm = action.payload;
    },
    setConfigurationsFormField: (
      state,
      action: PayloadAction<FormFieldModel>,
    ) => {
      const key = action.payload.key;
      state.configurationsForm[key] = action.payload;
    },
    setGUIFormData: (state, action: PayloadAction<Record<string, unknown>>) => {
      state.guiFormData = action.payload;
    },
    setPipeline: (state, action: PayloadAction<PipelineModel>) => {
      state.pipelineName.value = action.payload?.name;
      state.pipeline = action.payload;
    },
    setPipelineNode: (state, action: PayloadAction<PipelineNodeModel>) => {
      const pipelineConfigs = _.pickBy(action.payload?.config, (value, key) => {
        return key !== "gui_settings";
      });
      let newConfigurationsForm = {};
      _.map(pipelineConfigs, (value, key) => {
        newConfigurationsForm = {
          ...newConfigurationsForm,
          [key]: {
            ...state.configurationsForm[key],
            value,
          },
        };
      });
      const postProcessingUiKey = action.payload?.config
        ?.gui_type as LegacyUIsEnum;
      const guiType = _.findKey(
        LegacyUIsEnum,
        (value) => value === postProcessingUiKey,
      ) as UIsEnum;
      const guiCategory = _.findKey(UICategories, (value) =>
        value.includes(guiType),
      ) as UICategoriesType;

      const subsetTypeWhitelist =
        annotationUIs[guiType as UIsEnum]?.mediaTypeOptions[
          pipelineConfigs.media_type as Mediatype
        ]?.[pipelineConfigs.visualisation_type as VisualisationTypeEnum] ?? [];

      let isAllUiConfigurationsSelected = false;
      if (
        guiCategory &&
        guiType &&
        pipelineConfigs.media_type &&
        pipelineConfigs.visualisation_type
      ) {
        isAllUiConfigurationsSelected = true;
      }

      state.uiType = guiType;
      state.uiCategory = guiCategory;
      state.subsetTypeWhitelist = subsetTypeWhitelist;
      state.isEditingUIConfig = !isAllUiConfigurationsSelected;
      state.guiFormData = action.payload?.config?.gui_settings;
      state.configurationsForm = newConfigurationsForm;
      state.pipelineNode = action.payload;
    },
    setValidations: (
      state,
      action: PayloadAction<pipelineDesignerStateTypes["validation"]>,
    ) => {
      if (!action.payload.isConfigurationsFormValid) {
        const fromValidation = validateForm(state.configurationsForm);

        state.configurationsForm = fromValidation.newFormState;
      }
      state.validation = action.payload;
    },
    resetPipelineDesignerSlice: () => initialState,
  },
  extraReducers: (builder) => {
    //  todo
  },
});

export const {
  setIsShowingLivePreview,
  setIsEditingUIConfig,
  setAnnotationRunName,
  setDataset,
  setSubset,
  setReferenceData,
  setInitialAttribute,
  setVisualisationConfig,
  setColorMap,
  setPipelineName,
  setUIcategory,
  setUIType,
  setSubsetTypeWhitelist,
  setConfigurationsForm,
  setConfigurationsFormField,
  setGUIFormData,
  setPipeline,
  setPipelineNode,
  setValidations,
  resetPipelineDesignerSlice,
} = pipelineDesignerSlice.actions;
export default pipelineDesignerSlice.reducer;

import _ from "lodash";
import {
  FilterCategoryEnum,
  FilterModel,
  SavedFilterModel,
} from "models/filter.model";
import { useAppSelector } from "store/hooks";
import { ReactComponent as BackIcon } from "assets/arrow_small_left.svg";

import { getFilterCategoryCount } from "sections/SidebarMenu/FiltersDialog/FiltersTab/FiltersTabHelperFunctions";
import {
  determineFilterKeyFromParam,
  determineSavedFilterKeyFromParam,
} from "store/filterDataSlice";
import groupMLAttributesOrAnnotatedAttributes from "helpers/functions/filters/groupMLAttributesOrAnnotatedAttributes";
import { renameFilterCategory } from "helpers/functions/filters/filtersHelpers";
import { transformString } from "components/utilFunctions";

interface Props {
  selectedCategory: FilterCategoryEnum | null;
  setSelectedCategory: (category: FilterCategoryEnum | null) => void;
  onItemClick: (
    filter: FilterModel | SavedFilterModel,
    selectedFilterCategory: FilterCategoryEnum,
  ) => void;
}

const FiltersMenuList = ({
  selectedCategory,
  setSelectedCategory,
  onItemClick,
}: Props) => {
  const selectedView = useAppSelector((state) => state.appSlice.selectedView);
  const filterData = useAppSelector((state) => state.filterDataSlice);
  const allAttributes = useAppSelector(
    (state) => state.datasetSlice.attributes,
  );

  const renderTitle = () => {
    if (!selectedCategory) {
      return null;
    }

    const label = renameFilterCategory(selectedCategory);
    return (
      <div className="mb-1 py-[6px] flex items-center gap-x-2 text-paletteBlack-2">
        <div
          className="px-1 cursor-pointer"
          onClick={() => setSelectedCategory(null)}
        >
          <BackIcon width={16} height={16} />
        </div>
        {label}
        <div className="text-paletteGray-8">
          {getFilterCategoryCount(selectedCategory, filterData, selectedView)}
        </div>
      </div>
    );
  };

  const renderFilters = () => {
    const filters = filterData?.[determineFilterKeyFromParam(selectedView)];
    const savedFilters =
      filterData?.[determineSavedFilterKeyFromParam(selectedView)];

    if (!filters || !savedFilters) {
      return null;
    }

    let selectedFilters: (FilterModel | SavedFilterModel)[] = [];

    // Set selectedFilters based on selectedCategory
    switch (selectedCategory) {
      case FilterCategoryEnum.Saved_Filter:
        selectedFilters = savedFilters;
        break;
      case FilterCategoryEnum.Auto_Attribute:
        selectedFilters = _.filter(filters, [
          "attribute_group",
          "auto_attribute",
        ]);
        break;
      case FilterCategoryEnum.Initial_Attribute:
        selectedFilters = _.filter(filters, [
          "attribute_group",
          "initial_attribute",
        ]);
        break;
      case FilterCategoryEnum.Annotation_Attribute: {
        selectedFilters = groupMLAttributesOrAnnotatedAttributes(
          filters,
          FilterCategoryEnum.Annotation_Attribute,
        );
        break;
      }
      case FilterCategoryEnum.ML_Annotation_Attribute: {
        selectedFilters = groupMLAttributesOrAnnotatedAttributes(
          filters,
          FilterCategoryEnum.ML_Annotation_Attribute,
        );
        break;
      }
      case FilterCategoryEnum.Subsets_And_Tags: {
        selectedFilters = _.filter(filters, [
          "attribute_group",
          "subsets_and_tags",
        ]);
        break;
      }
      case FilterCategoryEnum.Text_Filter: {
        selectedFilters = _.filter(filters, ["type", "SEARCH_BY_TEXT"]);
      }
    }
    return _.map(selectedFilters, (filter) => {
      let name = filter?.name;
      if (
        selectedCategory === FilterCategoryEnum.Annotation_Attribute ||
        selectedCategory === FilterCategoryEnum.ML_Annotation_Attribute
      ) {
        const _filter = filter as FilterModel;
        name =
          _.find(allAttributes, ["id", _filter.attribute_id])?.name ||
          _filter?.attribute_name;
      }
      return (
        <div
          key={`filter_menu_${filter?.id}_${filter?.name}`}
          className="button-layer justify-start px-4 py-[11.5px]"
          onClick={() =>
            selectedCategory && onItemClick(filter as any, selectedCategory)
          }
          data-test={`${filter?.name}_filter`}
        >
          {selectedCategory === FilterCategoryEnum.Auto_Attribute ||
          selectedCategory === FilterCategoryEnum.Initial_Attribute ||
          selectedCategory === FilterCategoryEnum.Text_Filter
            ? transformString(name)
            : name}
        </div>
      );
    });
  };

  return (
    <div className="">
      {renderTitle()}
      <div className="flex flex-col gap-y-1" data-test="filter_category_list">
        {renderFilters()}
      </div>
    </div>
  );
};

export default FiltersMenuList;

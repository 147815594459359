import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import FormErrorLabel from "components/Inputs/Form/ErrorLabel";
import FormLabel from "components/Inputs/Form/Label";
import { validateSelectInput } from "components/Inputs/Form/formInputsHelpers";
import useWindowDimensions from "helpers/hooks/useWindowDimensions";
import {
  SelectFieldIcons,
  SelectFieldModel,
  SelectFieldOptionModel,
} from "models/form.model";
import { ReactComponent as ArrowDown } from "assets/arrow_small_down.svg";
import { ReactComponent as AiIcon } from "assets/ai.svg";
import TextInput from "components/Inputs/Form/TextInput";
import FormErrorCircle from "components/Inputs/Form/ErrorCircle";

type Props = {
  field: SelectFieldModel;
  value: string;
  handleOnChange: (
    event: React.ChangeEvent<HTMLSelectElement>,
    field: SelectFieldModel,
    isErrored?: boolean,
    error?: string | null,
  ) => void;
  tabIndex?: number;
};

const SimpleSelect = ({ field, value, handleOnChange, tabIndex }: Props) => {
  const addButtonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const screenSize = useWindowDimensions();

  // Dropdown states
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState<{
    top: number;
    bottom: number;
    left: number;
    position: "down" | "up";
  }>({
    top: 0,
    bottom: 0,
    left: 0,
    position: "down",
  });

  const defaultDropdownWidth = 336;
  const dropdownHeight = 250;

  const [searchValue, setSearchValue] = useState("");
  const [dropdownWidth, setDropdownWidth] = useState(defaultDropdownWidth);
  let filteredOptions = field?.options;

  // Handle width of dropdown menu
  useEffect(() => {
    if (addButtonRef?.current) {
      const buttonWidth = addButtonRef?.current?.offsetWidth;
      buttonWidth === 0
        ? setDropdownWidth(defaultDropdownWidth)
        : setDropdownWidth(buttonWidth);
    }
  }, []);

  // Handle click away or scroll to close
  useEffect(() => {
    function handleClickOrScrollOutside(event: any) {
      if (dropdownRef?.current) {
        const temp = dropdownRef as any;
        if (
          !temp?.current?.contains(event.target) &&
          !addButtonRef?.current?.contains(event.target)
        ) {
          handleCloseDropdown();
        }
      }
    }

    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape" || event.key === "Tab") {
        handleCloseDropdown();
      }
    };

    // Bind the event listener
    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOrScrollOutside);
      document.addEventListener("wheel", handleClickOrScrollOutside);
      document.addEventListener("keydown", handleKeyPress);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOrScrollOutside);
      document.removeEventListener("wheel", handleClickOrScrollOutside);
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [showDropdown]);

  const handleCloseDropdown = () => {
    setShowDropdown(false);
    setSearchValue("");
    setDropdownPosition({
      top: 0,
      bottom: 0,
      left: 0,
      position: "down",
    });
  };

  const renderBody = () => {
    const sameLine = field?.style?.labelAndFieldOnTheSameLine;
    if (sameLine) {
      return renderSameLineAlignment();
    }

    return renderMultiLineAlignment();
  };

  const renderMultiLineAlignment = () => {
    return (
      <div key={field?.key} className={`w-full ${field?.style?.wrapper}`}>
        <FormLabel field={field} />
        {renderAddButton()}
        {showDropdown && renderDropdown()}
        <FormErrorLabel
          error={field?.error ?? null}
          validationError={validateSelectInput(field)}
        />
      </div>
    );
  };

  const renderSameLineAlignment = () => {
    return (
      <div
        key={field?.key}
        className={`flex justify-between items-center
          ${field?.style?.wrapper}
      `}
      >
        <div className="w-1/2">
          <FormLabel field={field} />
        </div>
        <div className="w-1/2 flex items-center">
          {renderAddButton()}
          {showDropdown && renderDropdown()}
          <FormErrorCircle
            error={field?.error ?? null}
            validationError={validateSelectInput(field)}
          />
        </div>
      </div>
    );
  };

  const renderAddButton = () => {
    const labelOfValue = _.find(field?.options, (item) => item.value === value)
      ?.label;
    const placeholder = field?.placeholder || "--Select item--";
    const inputClassName = field?.style?.field;
    return (
      <button
        ref={addButtonRef}
        className={`w-full h-[28] flex items-center py-2 px-4 space-x-2 text-sm
         bg-paletteGray-3 hover:bg-paletteGray-5 rounded cursor-pointer
         ${inputClassName ? inputClassName : ""}
         `}
        data-test="cropping_configuration_selector"
        onClick={() => {
          handleOpenDropdown(addButtonRef);
        }}
        tabIndex={tabIndex}
      >
        <div
          id="tags-selector-add-button"
          className={`min-w-0 flex-1 flex justify-start ${
            field?.value?.length > 0 ? "" : "text-paletteGray-9"
          }`}
          data-test="dropdown_selection_field"
        >
          {field?.value?.length > 0 ? labelOfValue : placeholder}
        </div>
        <ArrowDown
          className={`w-4 h-4 text-paletteGray-7
              ${showDropdown ? "transform rotate-180" : "transform rotate-0"}`}
          data-test="selection_arrow"
        />
      </button>
    );
  };

  const renderDropdown = () => {
    let verticalPosition = {};
    // Use bottom position if dropdown is rendered above the select
    if (dropdownPosition.position === "up" && dropdownPosition.bottom !== 0) {
      verticalPosition = {
        bottom: dropdownPosition.bottom,
      };
    }
    // Use top position if dropdown is rendered below the select
    else if (
      dropdownPosition.position === "down" &&
      dropdownPosition.top !== 0
    ) {
      verticalPosition = {
        top: dropdownPosition.top,
      };
    }

    return (
      <div
        id="dropdown-tags-selector"
        data-test="selector_dropdown"
        ref={dropdownRef}
        className="fixed bg-white shadow-[0_0px_20px_rgba(0,0,0,0.2)] rounded-[8px] mb-4
          flex flex-col"
        style={{
          width: `${dropdownWidth}px`,
          maxHeight: `${dropdownHeight}px`,
          zIndex: 999,
          left: dropdownPosition.left,
          ...verticalPosition,
        }}
      >
        {renderSearchInput()}
        {renderOptions()}
      </div>
    );
  };

  const renderSearchInput = () => {
    return (
      <TextInput
        field={{
          type: "text",
          key: "select_search",
          placeholder: "Search...",
          value: searchValue,
          required: false,
          error: null,
          style: {
            field: "border-none",
          },
        }}
        value={searchValue}
        handleOnChange={(event) => {
          setSearchValue(event.target.value);
        }}
        tabIndex={0}
        autoFocus={true}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            handleOnOptionClick(filteredOptions[0]);
          }
        }}
      />
    );
  };

  const renderIcons = (icon: SelectFieldIcons | undefined) => {
    if (icon === undefined) {
      return null;
    }
    if (icon === SelectFieldIcons.AiIcon) {
      return <AiIcon className="w-4 h-4 ml-1 text-palettePurple" />;
    }
    return null;
  };

  const renderOptions = () => {
    if (searchValue) {
      filteredOptions = _.filter(field?.options, (item) => {
        return _.includes(item?.label.toLowerCase(), searchValue.toLowerCase());
      });
    }

    return (
      <div
        id="dropdown-tags-selector-options"
        className="w-full min-h-1 flex-1 overflow-y-auto p-1"
        data-test="dropdown_selection_list"
      >
        {_.map(filteredOptions, (item) => {
          return (
            <div
              className={`button-select-layer ${
                value === item?.value ? "bg-paletteGray-3" : ""
              }
                ${item?.bgColor || ""}
              `}
              key={item?.value}
              onClick={() => handleOnOptionClick(item)}
            >
              {item?.label} {renderIcons(item?.icon)}
            </div>
          );
        })}
      </div>
    );
  };

  const handleOnOptionClick = (item: SelectFieldOptionModel) => {
    const validationError = validateSelectInput(field);
    const isError = !_.isNull(validationError);
    handleOnChange(
      // as we are only interested in the value
      {
        target: {
          value: item.value,
        },
      } as React.ChangeEvent<HTMLSelectElement>,
      field,
      isError,
      validationError,
    );
    handleCloseDropdown();
  };

  const handleOpenDropdown = (
    clickedRef: React.RefObject<HTMLButtonElement>,
  ) => {
    if (!field?.disabled) {
      if (!showDropdown) {
        if (clickedRef.current) {
          const newPosition = clickedRef?.current?.getBoundingClientRect();
          const left = newPosition?.left;
          setShowDropdown(true);

          // Render dropdown under select
          if (
            newPosition?.top + newPosition?.height + dropdownHeight <
            screenSize?.height
          ) {
            setDropdownPosition({
              top: newPosition?.top + newPosition?.height,
              bottom: 0,
              left: left,
              position: "down",
            });
          }
          // Render dropdown above select
          else {
            setDropdownPosition({
              top: 0,
              bottom: screenSize?.height - newPosition?.bottom + 20,
              left: left,
              position: "up",
            });
          }
        }
      } else handleCloseDropdown();
    }
  };

  return renderBody();
};

export default SimpleSelect;

import { NotificationModel } from "models/websockets.model";
import { ReactComponent as Checkmark } from "assets/check_mark.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  fetchNotificationsStore,
  fetchUnreadNotificationsStore,
} from "store/notificationsSlice";
import NotificationBox from "./NotificationBox";
import _ from "lodash";
import { useCallback, useEffect } from "react";

const UnreadNotificationList = () => {
  const dispatch = useAppDispatch();
  const { unreadNotifications, unreadHasMore, loading } = useAppSelector(
    (state) => state.notificationSlice,
  );

  useEffect(() => {
    if (
      unreadNotifications.length < 7 &&
      unreadNotifications.length > 0 &&
      unreadHasMore
    ) {
      dispatch(
        fetchUnreadNotificationsStore({
          reqBody: {
            skip: unreadNotifications.length,
            limit: 10,
          },
        }),
      );
    }
  }, [unreadNotifications]);

  const loadMore = useCallback(() => {
    if (unreadHasMore) {
      dispatch(
        fetchUnreadNotificationsStore({
          reqBody: {
            skip: unreadNotifications.length,
            limit: 10,
          },
        }),
      );
    }
  }, [dispatch, unreadHasMore, unreadNotifications.length]);

  if (loading && unreadNotifications.length === 0) {
    return (
      <div className="h-full flex justify-center items-center">Loading...</div>
    );
  }

  if (unreadNotifications.length === 0) {
    return (
      <div className="w-full h-full flex flex-col font-medium justify-center items-center">
        <div>You've read all notifications!</div>
        <Checkmark className="w-6 h-6 mt-4" />
      </div>
    );
  }

  return (
    <InfiniteScroll
      hasMore={unreadHasMore}
      loader={
        <div className="h-full flex justify-center items-center">
          Loading...
        </div>
      }
      dataLength={unreadNotifications.length}
      next={loadMore}
      scrollableTarget={"unreadNotificationList"}
    >
      {_.map(unreadNotifications, (notification) => (
        <NotificationBox
          key={notification.id}
          notification={notification as NotificationModel}
        />
      ))}
    </InfiniteScroll>
  );
};

export default UnreadNotificationList;

import {
  NotificationCountsModel,
  NotificationModel,
} from "models/websockets.model";
import {
  APIDeleteAxios,
  APIFetchAxios,
  APIPatchWithBodyAxios,
} from "routes/Auth";

/**
 * An endpoint to fetch the notifications
 * @param skip The number of notifications to skip
 * @param limit The number of notifications to fetch
 * @returns The notifications
 */
export const fetchNotifications = async (
  skip: number,
  limit: number,
  read?: boolean,
): Promise<NotificationModel[]> => {
  const response = await APIFetchAxios("/notifications", { skip, limit, read });
  return response?.data;
};

/**
 * An endpoint to fetch the unread notifications
 * @param skip The number of notifications to skip
 * @param limit The number of notifications to fetch
 * @returns The unread notifications
 */
export const fetchUnreadNotifications = async (
  skip: number,
  limit: number,
): Promise<NotificationModel[]> => {
  const response = await APIFetchAxios("/notifications", {
    skip,
    limit,
    read: false,
  });
  return response?.data;
};

/**
 * An endpoint to fetch the notification counts
 * @returns The notification counts
 */
export const fetchNotificationCounts =
  async (): Promise<NotificationCountsModel> => {
    const response = await APIFetchAxios("/notifications:count");
    return response?.data;
  };

/**
 * An endpoint to mark a notification as read
 * @param notificationID The notification ID
 * @param read The read state
 * @returns The updated notification
 */
export const markNotificationAsRead = async (
  notificationID: string,
  read: boolean,
): Promise<NotificationModel> => {
  const response = await APIPatchWithBodyAxios(
    `/notifications/${notificationID}?read=${read}`,
    {},
  );
  return response?.data;
};

/**
 * An endpoint to mark all notifications as read
 * @param read The read state of the notifications
 */
export const markAllNotificationsAsRead = async (
  read: boolean,
): Promise<NotificationModel[]> => {
  const response = await APIPatchWithBodyAxios(
    `/notifications?read=${read}`,
    {},
  );
  return response?.data;
};

/**
 * An endpoint to delete a notification
 * @param notificationID The notification ID
 * @returns The deleted notifications ID
 */
export const deleteNotification = async (
  notificationID: string,
): Promise<string> => {
  const response = await APIDeleteAxios(`/notifications/${notificationID}`);
  return response?.data;
};

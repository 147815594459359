import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import { geometryColors } from "helpers/colors";
import { ReactComponent as EyeIcon } from "assets/eye.svg";
import {
  CategoriesFiltersModel,
  GeometriesFiltersModel,
} from "models/detailsScreen.model";
import { ReactComponent as ArrowLongLeftIcon } from "assets/arrow_long_left.svg";
import _ from "lodash";
import CustomCheckbox from "components/Inputs/Checkbox";
import {
  setDetailsScreenFiltersObjectCategory,
  setDetailsScreenGeometry,
} from "store/detailsScreenSlice";
import { snakeCaseToText } from "components/utilFunctions";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { MediaObjectModel } from "models/exploration.model";
import { GeometriesEnum } from "models/geometries.model";
import SwitchComp from "components/Filters/Switch";
import { colorOfCategory } from "helpers/functions/hermes2dPainter/convertMediaObjectModelToBaseShape";

interface Props {
  currentMediaObjects?: MediaObjectModel[];
}

const ToggleGeometries = ({ currentMediaObjects }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const detailsScreenSettings = useAppSelector(
    (state) => state.detailsScreenSlice,
  );

  const detailsScreenSlice = useAppSelector(
    (state) => state.detailsScreenSlice,
  );

  const dispatch = useAppDispatch();

  const [availableGeometriesSet, setAvailableGeometriesSet] = useState<
    Set<GeometriesEnum>
  >(new Set());

  // Get available geometries from currentMediaObjects
  const getAvailableGeometries = () => {
    const availableGeometries = new Set<GeometriesEnum>();
    if (currentMediaObjects) {
      currentMediaObjects.forEach((mediaObject) => {
        // Check qm_data for geometries
        if (mediaObject?.qm_data && _.isArray(mediaObject?.qm_data)) {
          mediaObject?.qm_data?.forEach((geometry) => {
            if (geometry.type) {
              availableGeometries.add(geometry.type);
            }
          });
        }
        // Check reference_data for geometries
        if (mediaObject?.reference_data && mediaObject?.reference_data.type) {
          availableGeometries.add(mediaObject.reference_data.type);
        }
      });
    }

    return availableGeometries;
  };

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    const availableGeometries = getAvailableGeometries();
    setAvailableGeometriesSet(availableGeometries);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const [selectedGeometry, setSelectedGeometry] = useState<
    keyof typeof listOfItems | null
  >(null);
  const [isSelectingObjectClasses, setIsSelectingObjectClasses] =
    useState(false);

  const listOfItems: {
    [key: string]: { label: string; key: keyof GeometriesFiltersModel }[];
  } = {
    "Bounding boxes": [
      { label: "QM", key: "bbs_qm" },
      { label: "Reference", key: "bbs_reference" },
    ],
    "Key points": [
      { label: "QM", key: "keypoints_qm" },
      { label: "Reference", key: "keypoints_reference" },
    ],
    /*
    Polygons: [
      { label: "QM", key: "polygons_qm" },
      { label: "Reference", key: "polygons_reference" },
    ],*/
    Polylines: [
      { label: "QM", key: "polylines_qm" },
      { label: "Reference", key: "polylines_reference" },
    ],
  };

  const renderContent = () => {
    if (!_.isNull(selectedGeometry)) return renderSources();

    if (isSelectingObjectClasses) return renderObjectClassesMenu();

    return renderItems();
  };

  const renderItems = () => {
    return (
      <div>
        {renderGeometries()}
        {renderObjectClasses()}
      </div>
    );
  };

  const renderGeometries = () => {
    const filteredEntries = _.pickBy(listOfItems, (value, key) => {
      if (key === "Bounding boxes") {
        return (
          availableGeometriesSet.has(GeometriesEnum.BoundingBox2D) ||
          availableGeometriesSet.has(GeometriesEnum.BoundingBox2DAggregation)
        );
      }
      if (key === "Key points") {
        return availableGeometriesSet.has(GeometriesEnum.Point2D);
      }
      if (key === "Polylines") {
        return availableGeometriesSet.has(
          GeometriesEnum.Polyline2DFlatCoordinates,
        );
      }
    });

    return _.map(filteredEntries, (item, key) => (
      <div
        key={key}
        className="button-select-layer"
        onClick={() => setSelectedGeometry(key)}
      >
        {key}
      </div>
    ));
  };

  const renderObjectClasses = () => {
    return (
      <div
        className="button-select-layer"
        onClick={() => setIsSelectingObjectClasses(true)}
      >
        Object classes
      </div>
    );
  };

  const renderSources = () => {
    if (_.isNull(selectedGeometry)) return <div>No geometry selected</div>;

    const listOfSubitems = listOfItems[selectedGeometry];

    return (
      <div>
        {renderBack()}
        <div className="flex flex-col gap-1">
          {_.map(listOfSubitems, (subItem) => {
            return renderSourceButton(
              subItem?.label,
              subItem?.key,
              subItem?.label === "QM"
                ? geometryColors?.qm
                : geometryColors?.reference,
            );
          })}
        </div>
      </div>
    );
  };

  const renderSourceButton = (
    label: string,
    key: keyof GeometriesFiltersModel,
    color: string,
  ) => {
    return (
      <div
        key={key}
        className="button-select-layer justify-between my-1 cursor-default"
      >
        <div className="flex items-center">
          {!detailsScreenSlice?.geometries.color_by_class && (
            <div
              className="w-3 h-3 rounded-sm"
              style={{ backgroundColor: color }}
            />
          )}
          <div className="pl-3">{label}</div>
        </div>
        <CustomCheckbox
          size={20}
          checked={detailsScreenSettings?.geometries?.[key]}
          onChange={(event) => {
            const newVal = event?.target?.checked;
            dispatch(
              setDetailsScreenGeometry({
                key: key,
                value: newVal,
              }),
            );
          }}
        />
      </div>
    );
  };

  const renderObjectClassesMenu = () => {
    return (
      <div>
        {renderBack()}
        {renderCategoriesFilter()}
      </div>
    );
  };

  const renderBack = () => {
    return (
      <div className="w-full p-3 pb-2 mb-1 flex gap-3 items-center text-paletteBlack-1 font-bold">
        <ArrowLongLeftIcon
          width="12"
          height="12"
          onClick={() => {
            setSelectedGeometry(null);
            setIsSelectingObjectClasses(false);
          }}
          className="cursor-pointer"
        />
        <div className="text-xs">BACK</div>
      </div>
    );
  };

  const renderCategoriesFilter = () => {
    const categoriesGroupsRaw = _.countBy(
      currentMediaObjects,
      "object_category",
    );
    let categoriesGroups: { [key: string]: number } = {};
    _.forEach(categoriesGroupsRaw, (count, key) => {
      let id = key;
      if (key === "undefined") {
        id = "no_category";
      }
      categoriesGroups = { ...categoriesGroups, [id]: count };
    });

    let categories: CategoriesFiltersModel = {};
    _.map(currentMediaObjects, (object) => {
      const categoryID = object?.object_category || "no_category";
      const categoryName = object?.object_category_name || "No category";
      categories = {
        ...categories,
        [categoryID]: {
          id: categoryID,
          name: categoryName,
          enable: true,
          count: categories?.[categoryID]?.count + 1 || 1,
        },
      };
    });
    return _.map(categories, (category, key) => {
      const is_category_enabled =
        detailsScreenSettings?.objectCategories?.[category?.id]?.enable;
      return (
        <div
          key={key}
          className="button-select-layer justify-between my-1 py-[10px]"
        >
          <div className="flex items-center">
            {detailsScreenSlice?.geometries.color_by_class && (
              <div
                className="w-3 h-3 rounded-sm"
                style={{
                  backgroundColor: colorOfCategory(
                    category.id,
                    currentMediaObjects,
                  ),
                }}
              />
            )}
            <div className="pl-3">{` ${snakeCaseToText(category?.name)}`} </div>
          </div>
          <CustomCheckbox
            size={20}
            checked={is_category_enabled}
            onChange={() => {
              dispatch(
                setDetailsScreenFiltersObjectCategory({
                  ...category,
                  enable: !is_category_enabled,
                }),
              );
            }}
          />
        </div>
      );
    });
  };
  const renderCheckBox = (label: string, key: keyof GeometriesFiltersModel) => {
    if (selectedGeometry) {
      return null; // Return null to hide the checkbox
    }

    return (
      <div className="px-3 py-3 flex justify-between items-center">
        <div className="mr-2">{label}</div>

        <SwitchComp
          size="sm"
          checked={detailsScreenSettings?.geometries?.[key]}
          onChange={() =>
            dispatch(
              setDetailsScreenGeometry({
                key: key,
                value: !detailsScreenSettings?.geometries?.[key],
              }),
            )
          }
        />
      </div>
    );
  };

  return (
    <div>
      <div
        className={`icon-button-layer-transparent ${
          open && "bg-paletteGray-5"
        }`}
        onClick={handlePopoverOpen}
        data-test="detail_view_eye_button"
      >
        <EyeIcon width={20} height={20} />
      </div>

      <Popover
        id="media_objects_filter_id"
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        PaperProps={{
          classes: {
            root: "rounded-lg shadow-dropdown",
          },
        }}
      >
        <div className="w-[205px] p-1">{renderContent()}</div>
        <div className="border-t-[1px] border-paletteGray-3" />
        {!isSelectingObjectClasses &&
          renderCheckBox("Color by class", "color_by_class")}
        {!isSelectingObjectClasses && renderCheckBox("3D view", "three_d_view")}
        {!isSelectingObjectClasses &&
          renderCheckBox("Hide everything", "hide_all")}
      </Popover>
    </div>
  );
};

export default ToggleGeometries;

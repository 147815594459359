import { ReactComponent as Datasets } from "assets/datasets.svg";
import { ReactComponent as Exploration } from "assets/exploration.svg";
import { ReactComponent as Subsets } from "assets/subsets.svg";
import { ReactComponent as Tags } from "assets/tags.svg";
import { ReactComponent as Attributes } from "assets/attributes.svg";
import { ReactComponent as Statistics } from "assets/statistics.svg";
import { ReactComponent as Download } from "assets/download.svg";
import { ReactComponent as Hotkeys } from "assets/hotkeys.svg";
import { ReactComponent as Feedback } from "assets/feedback.svg";
import { ReactComponent as InternalTools } from "assets/gear.svg";
import { ReactComponent as PipelineManager } from "assets/pipeline_manager.svg";
import { ReactComponent as AnnotationRuns } from "assets/annotation_runs.svg";
import { ReactComponent as AIManager } from "assets/ai.svg";
import Tooltip from "@mui/material/Tooltip";
import { snakeCaseToText } from "components/utilFunctions";

interface Props {
  icon: keyof typeof iconMap;
  disabled?: boolean;
  active?: boolean;
  onClick?: () => void;
  small?: boolean;
}

export const iconMap = {
  datasets: Datasets,
  exploration: Exploration,
  subsets: Subsets,
  tags: Tags,
  attributes: Attributes,
  statistics: Statistics,
  download: Download,
  hotkeys: Hotkeys,
  feedback: Feedback,
  internal_tools: InternalTools,
  pipelines: PipelineManager,
  annotation_runs: AnnotationRuns,
  ai_manager: AIManager,
};

const SidebarIcon = ({
  icon,
  disabled = false,
  active = false,
  onClick,
  small = false,
}: Props) => {
  const IconComponent = iconMap?.[icon];

  return (
    <Tooltip
      title={snakeCaseToText(icon)}
      placement="right"
      disableHoverListener={disabled}
    >
      <span>
        <button
          type="button"
          aria-label={snakeCaseToText(icon)}
          name={icon}
          className={`flex justify-center ${
            small ? "p-1 rounded-1 mb-2" : "p-3 rounded-2"
          } items-center mt-[2px] 
            bg-paletteGray-11 text-paletteGray-7 
            hover:bg-paletteGray-12 hover:text-paletteGray-5
            active:bg-paletteGray-13 active:text-paletteGray-2
            disabled:bg-paletteGray-11 disabled:text-paletteGray-7 disabled:opacity-40 
          ${
            active
              ? "bg-paletteGray-13 text-paletteGray-2 hover:text-paletteGray-2"
              : ""
          } `}
          data-test={`sidebar_${icon}`}
          disabled={disabled}
          onClick={onClick}
        >
          <IconComponent className="w-[20px] h-[20px]" />
        </button>
      </span>
    </Tooltip>
  );
};

export default SidebarIcon;

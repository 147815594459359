import Form from "components/Forms/Form";
import _ from "lodash";
import { FormModel, SelectFieldModel } from "models/form.model";
import { ReactComponent as CloseIcon } from "assets/close.svg";
import { useAppSelector } from "store/hooks";
import { AnnotatableEnum } from "models/global.model";

type Props = {
  attributesList: { [key: string]: FormModel };
  setAttributesList: (attributesList: { [key: string]: FormModel }) => void;
  attributeSelection: SelectFieldModel;
  setAttributeSelection: (attributeSelection: SelectFieldModel) => void;
};

const AttributeForms = ({
  attributesList,
  setAttributesList,
  attributeSelection,
  setAttributeSelection,
}: Props) => {
  const objectAttributes = useAppSelector(
    (state) =>
      state.datasetSlice.attributes?.filter(
        (attribute) =>
          attribute.annotatable_type === AnnotatableEnum.MediaObject,
      ),
  );

  const renderAttributeList = () => {
    return _.map(attributesList, (attribute, key) => {
      return (
        <div
          className="p-4 pr-2 my-4 border-2 rounded-lg shadow-lg flex gap-x-2"
          key={"form" + key}
        >
          <div className="min-w-0 flex-1">
            <Form
              formData={attribute}
              setFormData={(newForm) =>
                setAttributesList({ ...attributesList, [key]: newForm })
              }
            />
          </div>
          {renderDeleteButton(key)}
        </div>
      );
    });
  };

  const renderDeleteButton = (attributeID: string) => {
    return (
      <div
        className="cursor-pointer text-paletteGray-7"
        onClick={() => deleteAttribute(attributeID)}
      >
        <CloseIcon width={12} height={12} strokeWidth={3} />
      </div>
    );
  };

  const deleteAttribute = (attributeID: string) => {
    // Remove the attribute from the attributes list
    const newAttributesList = _.omit(attributesList, attributeID);
    setAttributesList(newAttributesList);

    // Add the deleted attribute back to the attribute selection
    const attribute = _.find(objectAttributes, { id: attributeID });
    if (attribute) {
      setAttributeSelection({
        ...attributeSelection,
        options: [
          ...attributeSelection.options,
          { value: attribute.id, label: attribute.name },
        ],
      });
    }
  };

  return (
    <div className="min-h-0 flex-1 overflow-y-auto p-4">
      {renderAttributeList()}
    </div>
  );
};

export default AttributeForms;

import { useEffect } from "react";
import { useAppSelector } from "store/hooks";

import { isEmpty } from "lodash";
import { ToastContainer, Slide } from "react-toastify";

import { ReactComponent as CloseIcon } from "assets/close.svg";
import { CloseButtonProps } from "react-toastify/dist/components";
import "react-toastify/dist/ReactToastify.css";
import toaster from "components/UtilComponents/CustomToaster";

const SnackbarComp = () => {
  const dashboardError = useAppSelector((state) => state?.dashboardSlice.error);
  const filtersError = useAppSelector((state) => state.filterDataSlice.error);
  const mediaExplorationError = useAppSelector(
    (state) => state?.explorationMediaSlice.error,
  );
  const mediaObjectsExplorationError = useAppSelector(
    (state) => state?.explorationMediaObjectsSlice.error,
  );

  useEffect(() => {
    if (!isEmpty(dashboardError?.message)) {
      toaster.error({
        title: "Dashboard",
        text:
          dashboardError?.message || "Something went wrong fetching datasets!",
      });
    }
  }, [dashboardError]);

  useEffect(() => {
    if (!isEmpty(filtersError?.message)) {
      toaster.error({
        title: "Filters",
        text:
          filtersError?.message ||
          "Something went wrong while fetching filters!",
      });
    }
  }, [filtersError]);

  useEffect(() => {
    if (!isEmpty(mediaExplorationError?.message)) {
      toaster.error({
        title: "Media exploration",
        text:
          mediaExplorationError?.message ||
          "Something went wrong on media explorations fetching!",
      });
    }
  }, [mediaExplorationError]);

  useEffect(() => {
    if (!isEmpty(mediaObjectsExplorationError?.message)) {
      toaster.error({
        title: "Media objects exploration",
        text:
          mediaObjectsExplorationError?.message ||
          "Something went wrong on media objects explorations fetching!",
      });
    }
  }, [mediaObjectsExplorationError]);

  return (
    <ToastContainer
      transition={Slide}
      className="mt-16"
      limit={4}
      pauseOnFocusLoss={true}
      closeButton={(closeToast: CloseButtonProps) => (
        <div onClick={closeToast.closeToast}>
          <CloseIcon
            className="w-3 h-3 cursor-pointer"
            data-test="close_snackbar_button"
          />
        </div>
      )}
    />
  );
};

export default SnackbarComp;

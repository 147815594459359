import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "store/hooks";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import DataTable, { DataTableColumn } from "components/Table/DataTable";
import _ from "lodash";
import {
  AnnotationRunModel,
  AnnotationRunNodeModel,
  AnnotationRunStatusEnum,
  AnnotationRunTypeEnum,
} from "models/annotationRun.model";
import {
  deleteAnnotationRun,
  fetchAnnotationRun,
  fetchAnnotationRuns,
  patchAnnotationRun,
} from "helpers/apis/annotationRun";

import AnnotationRunManagerTableAction, {
  AnnotationRunManagerPopoverConfirmDialogState,
} from "Pages/AnnotationRunManager/AnnotationRunManagerTableAction";
import EditName from "components/Inputs/EditName";
import { ReactComponent as AIicon } from "assets/ai.svg";
import TooltipTruncateEllipsis from "components/Tooltips/TooltipTruncateEllipsis";
import UserGroupSelection from "components/UtilComponents/UserGroupSelection";
import NodesTable from "Pages/AnnotationRunManager/NodesTable";
import AnnotationRunStatusChip from "Pages/AnnotationRunManager/AnnotationRunStatusChip";
import { AnnotationRunOverviewDialogState } from "sections/AnnotationRunOverview";

type Props = {
  annotationRuns: AnnotationRunModel[];
  setAnnotationRuns: (annotationRuns: AnnotationRunModel[]) => void;
  searchValue: string;
  annotationRunNodes: { [key: string]: AnnotationRunNodeModel[] };
  setAnnotationRunNodes: (annotationRunNodes: {
    [key: string]: AnnotationRunNodeModel[];
  }) => void;
  setOverviewDialog: (newState: AnnotationRunOverviewDialogState) => void;
};

const AnnotationRunManagerTable = ({
  annotationRuns,
  setAnnotationRuns,
  searchValue,
  annotationRunNodes,
  setAnnotationRunNodes,
  setOverviewDialog,
}: Props) => {
  const dispatch = useAppDispatch();

  const [confirmDialogState, setConfirmDialogState] =
    useState<AnnotationRunManagerPopoverConfirmDialogState>({
      show: false,
      action: "archive",
      annotationRunID: null,
    });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingNodes, setIsLoadingNodes] = useState(false);
  const [loadingNodesForAnnotationRunID, setLoadingNodesForAnnotationRunID] =
    useState<string | null>(null);

  const inputRefArray = useRef<{ [key: string]: HTMLInputElement }>({});
  const [renameInputID, setRenameInputID] = useState<string | null>(null);

  // focus on the input field when the editInputID changes
  useEffect(() => {
    if (renameInputID) {
      inputRefArray.current?.[renameInputID]?.focus();
    }
  }, [renameInputID]);

  const tableColumns: DataTableColumn[] = [
    {
      field: "name",
      headerName: "Annotation run name",
      span: 25,
      cell: (row) => {
        const annotationRun = row as AnnotationRunModel;
        return (
          <div className="flex overflow-hidden pr-3">
            <EditName
              item={row as Record<string, any>}
              inputRefArray={inputRefArray}
              editInputID={renameInputID}
              setEditInputID={setRenameInputID}
              handleRename={(newName: string) => {
                handleRenameAnnotationRun(annotationRun?.id, newName);
              }}
              withToolTip={true}
              fontSize="text-normal"
            />
            {annotationRun.annotation_run_type === AnnotationRunTypeEnum.AI && (
              <AIicon className="ml-2 text-palettePurple" />
            )}
          </div>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Created",
      span: 15,
      cell: (row) => {
        const annotationRun = row as AnnotationRunModel;
        const date = new Date(annotationRun?.created_at);
        return (
          <TooltipTruncateEllipsis className="pr-3" data-test="Created_value">
            {date.toLocaleString()}
          </TooltipTruncateEllipsis>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      span: 15,
      cell: (row) => {
        const annotationRun = row as AnnotationRunModel;
        return <AnnotationRunStatusChip status={annotationRun.status} />;
      },
    },
    {
      field: "user_group",
      headerName: "Team",
      span: 15,
      cell: (row) => {
        const annotationRun = row as AnnotationRunModel;
        return (
          <UserGroupSelection
            selectedUserGroup={annotationRun?.user_group}
            onChanges={(newUserGroup: string) =>
              patchAnnotationRun(
                annotationRun?.id,
                { user_group: newUserGroup },
                dispatch,
                setIsLoading,
              ).then(() => updateAnnotationRuns())
            }
          />
        );
      },
    },
    {
      field: "",
      headerName: "Actions",
      sortable: false,
      span: 30,
      cell: (row) => {
        const annotationRun = row as AnnotationRunModel;
        return (
          <div
            onClick={(e) => e.stopPropagation()}
            className="w-full flex flex-row-reverse justify-start"
          >
            <AnnotationRunManagerTableAction
              annotationRun={annotationRun}
              setConfirmDialogState={setConfirmDialogState}
              setRenameInputID={setRenameInputID}
              updateAnnotationRuns={updateAnnotationRuns}
            />
          </div>
        );
      },
    },
  ];

  // A function to call the delete annotationRun endpoint
  const archiveAnnotationRun = () => {
    if (_.isNull(confirmDialogState.annotationRunID)) return;
    deleteAnnotationRun(
      { annotationRunID: confirmDialogState.annotationRunID },
      dispatch,
      setIsLoading,
    ).then(() => {
      updateAnnotationRuns();
      setConfirmDialogState({ ...confirmDialogState, show: false });
    });
  };

  const handleRenameAnnotationRun = (
    annotationRunID: string,
    newName: string,
  ) => {
    patchAnnotationRun(annotationRunID, { name: newName }, dispatch).then(() =>
      updateAnnotationRuns(),
    );
  };

  const updateAnnotationRuns = () => {
    fetchAnnotationRuns(dispatch).then((data) => {
      setAnnotationRuns(data);
    });
  };

  const renderConfirmationDialog = () => {
    return (
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialogState.show}
        setIsConfirmDialogOpen={(newState) =>
          setConfirmDialogState({ ...confirmDialogState, show: newState })
        }
        text={`Are you sure you want to ${
          confirmDialogState.action
        } the annotationRun 
                "${_.find(annotationRuns, [
                  "id",
                  confirmDialogState.annotationRunID,
                ])?.name}"?`}
        confirmButtonText="Confirm"
        handleOnSuccess={() => archiveAnnotationRun()}
      />
    );
  };

  const handleOnExpandButtonClick = (row: AnnotationRunModel) => {
    const annotationRun = row as AnnotationRunModel;
    setLoadingNodesForAnnotationRunID(annotationRun.id);
    fetchAnnotationRun(
      {
        annotationRunID: annotationRun.id,
      },
      dispatch,
      setIsLoadingNodes,
    ).then((data) => {
      setAnnotationRunNodes({
        ...annotationRunNodes,
        [annotationRun.id]: data.nodes,
      });
    });
  };

  return (
    <>
      <DataTable
        rows={annotationRuns as []}
        columns={tableColumns}
        expandRow={{
          key: "is_multinode",
          text: "Show nodes",
          onExpandClick: (row) =>
            handleOnExpandButtonClick(row as AnnotationRunModel),
          body: (row) => {
            const annotationRun = row as AnnotationRunModel;
            const nodes = annotationRunNodes[annotationRun.id];
            const shouldTheNodesTableBeLoading =
              isLoadingNodes &&
              loadingNodesForAnnotationRunID === annotationRun.id;
            return (
              <NodesTable
                annotationRun={annotationRun}
                nodes={nodes}
                isLoading={shouldTheNodesTableBeLoading}
              />
            );
          },
        }}
        defaultSort={{ name: "created_at", direction: "desc" }}
        isLoading={isLoading}
        searchValue={searchValue}
        extraKeysToSearch={["goliat_project_id"]}
        onRowClick={(row) => {
          const annotationRun = row as AnnotationRunModel;
          setOverviewDialog({
            isOpen: true,
            annotationRun: annotationRun,
          });
        }}
      />
      {renderConfirmationDialog()}
    </>
  );
};

export default AnnotationRunManagerTable;

import { useAppSelector } from "store/hooks";
import { Mediatype } from "models/dataset.model";
import { TagTypeModel } from "models/global.model";
import _ from "lodash";
import { AttributeMetadataModel } from "models/attributes.model";
import { AttributeManagementScreenTabs } from "Pages/AttributeManagement/index";
import { isAttributeAnnotationAttribute } from "helpers/functions/attributes/attributesHelpers";
import StandardTabs from "components/Tabs/StandardTabs";

type Props = {
  attributes: AttributeMetadataModel[];
  selectedTabValue: string;
  onTabClick: (tab: AttributeManagementScreenTabs) => void;
};

const AttributeTabs = ({ attributes, selectedTabValue, onTabClick }: Props) => {
  const mediatype = useAppSelector(
    (state) => state.datasetSlice.activeDataSet?.mediatype,
  );

  const tabs = [
    {
      label: "All",
      value: "all",
      count: _.filter(attributes, function (attribute) {
        return (
          !attribute.archived &&
          isAttributeAnnotationAttribute(attribute.attribute_group)
        );
      })?.length,
    },
    {
      label:
        mediatype === Mediatype.video ? "Video attributes" : "Media attributes",
      value: TagTypeModel.Media,
      count: _.filter(attributes, function (attribute) {
        return (
          !attribute.archived &&
          isAttributeAnnotationAttribute(attribute.attribute_group) &&
          attribute.annotatable_type === TagTypeModel.Media
        );
      })?.length,
    },
    {
      label: "Instance attributes",
      value: TagTypeModel.Instance,
      count: _.filter(attributes, function (attribute) {
        return (
          !attribute.archived &&
          isAttributeAnnotationAttribute(attribute.attribute_group) &&
          attribute.annotatable_type === TagTypeModel.Instance
        );
      })?.length,
    },
    {
      label: "Object attributes",
      value: TagTypeModel.MediaObject,
      count: _.filter(attributes, function (attribute) {
        return (
          !attribute.archived &&
          isAttributeAnnotationAttribute(attribute.attribute_group) &&
          attribute.annotatable_type === TagTypeModel.MediaObject
        );
      })?.length,
    },
  ];

  return (
    <div className="w-full pb-4 pt-4">
      <StandardTabs
        tabs={tabs}
        selectedTab={selectedTabValue}
        setSelectedTab={(tab: any) =>
          onTabClick(tab as AttributeManagementScreenTabs)
        }
      />
    </div>
  );
};

export default AttributeTabs;

import StandardTabs from "components/Tabs/StandardTabs";
import { isAttributeAnnotationAttribute } from "helpers/functions/attributes/attributesHelpers";
import getAttributeFromAttributesMeta from "helpers/functions/getAttributeFromAttributesMeta";
import _ from "lodash";
import { AttributesModel } from "models/attributes.model";
import {
  InstanceModel,
  MediaModel,
  MediaObjectRawModel,
} from "models/exploration.model";
import { AnnotatableEnum } from "models/global.model";
import { useAppSelector } from "store/hooks";

type Props = {
  selectedTab: AnnotatableEnum;
  setSelectedTab: (tab: AnnotatableEnum) => void;
  showMediaOrMediaObject: AnnotatableEnum;
  currentMedia?: MediaModel;
  currentMediaObject?: MediaObjectRawModel;
  currentInstance?: InstanceModel;
};

const InfoPanelTabs = ({
  selectedTab,
  setSelectedTab,
  showMediaOrMediaObject,
  currentMedia,
  currentMediaObject,
  currentInstance,
}: Props) => {
  const selectedView = useAppSelector((state) => state.appSlice.selectedView);
  const attributesMeta = useAppSelector(
    (state) => state.datasetSlice.attributes,
  );

  const renderTabs = () => {
    let label =
      selectedView === AnnotatableEnum.MediaObject
        ? "Object"
        : (selectedView as string);

    if (
      selectedView === AnnotatableEnum.MediaObject &&
      showMediaOrMediaObject === AnnotatableEnum.Media
    ) {
      label = "Media";
    }

    const attributesCount = getAttributesCount();

    return (
      <div className="mb-4">
        <StandardTabs
          tabs={[
            {
              label: label + " details",
              value: selectedView,
            },
            {
              label: "Attributes",
              value: AnnotatableEnum.Attribute,
              count: attributesCount,
            },
          ]}
          selectedTab={selectedTab}
          setSelectedTab={(tab) => setSelectedTab(tab as AnnotatableEnum)}
        />
      </div>
    );
  };

  const getAttributesCount = () => {
    let attributes: AttributesModel[] = [];
    switch (selectedView) {
      case AnnotatableEnum.Media:
        if (currentMedia) {
          attributes = currentMedia.attributes;
        }
        break;
      case AnnotatableEnum.MediaObject:
        if (currentMediaObject) {
          attributes = currentMediaObject.attributes;
        }
        break;
      case AnnotatableEnum.Instance:
        if (currentInstance) {
          attributes = currentInstance.attributes;
        }
        break;
      default:
        console.error("Can't get attribute count, unknown view!");
        break;
    }

    if (attributes.length === 0) {
      return 0;
    }

    const filteredAttributesMeta = _.filter(attributesMeta, (attributeMeta) =>
      isAttributeAnnotationAttribute(attributeMeta?.attribute_group),
    );

    // Filter for only annotation attributes
    const filteredAttributes = _.filter(attributes, (attribute) => {
      const attributeMeta = getAttributeFromAttributesMeta(
        filteredAttributesMeta,
        attribute?.metadata_id,
      );
      if (!attributeMeta) return false;
      return isAttributeAnnotationAttribute(attributeMeta?.attribute_group);
    });

    return filteredAttributes.length;
  };

  return renderTabs();
};

export default InfoPanelTabs;

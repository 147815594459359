import { useAppDispatch, useAppSelector } from "store/hooks";
import { getExplorationMediaPageRoute } from "routes/routesHelper";
import { resetStoreOnSubsetChange } from "store/util/resetStore";
import { useHistory } from "react-router-dom";

const DatasetSubsetCounts = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const datasetSlice = useAppSelector((state) => state.datasetSlice);
  if (!datasetSlice?.activeParentDataset) return null;

  const { name, id } = datasetSlice.activeParentDataset;

  const isInSubset = datasetSlice?.activeDataSet?.name !== name;

  return (
    <div className="pl-2 text-paletteGray-10 flex justify-items-center">
      {isInSubset && (
        <div
          className="cursor-pointer"
          onClick={() => {
            const explorationRoute = getExplorationMediaPageRoute({
              id: id,
              subset_id: "main_dataset",
            });
            history.push(explorationRoute);
            isInSubset === true && resetStoreOnSubsetChange(dispatch);
          }}
        >
          <div className="text-paletteGray-12">
            {datasetSlice?.activeParentDataset?.name}
          </div>
          <div className="text-sm">
            {datasetSlice.activeParentDataset?.num_medias} ‧{" "}
            {datasetSlice.activeParentDataset?.num_media_objects}
          </div>
        </div>
      )}
      {isInSubset && (
        <div className="flex justify-center text-paletteGray-7 px-3">/</div>
      )}
      <div
        className="cursor-pointer"
        onClick={() => {
          const explorationRoute = getExplorationMediaPageRoute({
            id: id,
            subset_id: datasetSlice?.activeDataSet?.id || "main_dataset",
          });
          history.push(explorationRoute);
          isInSubset === true && resetStoreOnSubsetChange(dispatch);
        }}
      >
        <div className="text-paletteGray-14 font-medium cursor-pointer">
          {datasetSlice?.activeDataSet?.name}
        </div>
        <div className="text-sm">
          {datasetSlice.activeDataSet?.num_medias} ‧{" "}
          {datasetSlice.activeDataSet?.num_media_objects}
        </div>
      </div>
    </div>
  );
};

export default DatasetSubsetCounts;

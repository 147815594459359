import NotificationButton from "components/Buttons/NotificationButton";

type Props = {
  title?: string | React.ReactNode;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  iconBgcolor?: string;
};

const Header = ({ title, icon, children, iconBgcolor }: Props) => {
  const renderIcon = () => {
    if (!icon) return null;

    return (
      <div className={`p-[8px] rounded-lg ${iconBgcolor ? iconBgcolor : ""}`}>
        {icon}
      </div>
    );
  };

  return (
    <header className="flex flex-col px-5 py-3 border-b border-paletteGray-5">
      <div className="flex items-center justify-between ">
        <div className="flex items-center gap-x-[10px]">
          {renderIcon()}
          {title && (
            <div role="heading" className="text-lg">
              {title}
            </div>
          )}
        </div>
        <div className="flex items-center">
          {children}
          {children && <div className="h-6 w-px bg-paletteGray-4 mx-3"></div>}
          <NotificationButton />
        </div>
      </div>
    </header>
  );
};

export default Header;

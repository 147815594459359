import { CustomToasterSeverity } from "components/UtilComponents/CustomToaster";
import { AIAnnotationRunModel } from "models/aiAnnotationRun.model";
import { AnnotationRunModel } from "models/annotationRun.model";
import { DatasetModel } from "models/dataset.model";
import { ImportJobsModel } from "models/importJobs.model";
import { MLAnnotationModel } from "models/mlAnnotationModel.model";

export interface WebSocketConnection {
  id: string;
  user_id: string;
}

export enum WebsocketEventEnum {
  DATASET_IMPORT_DONE = "dataset_import_done",
  DATASET_IMPORT_FAILED = "dataset_import_failed",
  SUBSET_CREATION = "subset_creation",
  SUBSET_SPLIT = "subset_split",
  ANNOTATION_RUN_CREATION_FAILED = "annotation_run_creation_failed",
  ANNOTATION_RUN_CREATION_DONE = "annotation_run_creation_done",
  ANNOTATION_RUN_ANNOTATION_DONE = "annotation_run_annotation_done",
  ANNOTATION_RUN_POST_PROCESSING = "annotation_run_post_processing",
  ANNOTATION_RUN_POST_PROCESSING_FAILED = "annotation_run_post_processing_failed",
  ANNOTATION_RUN_POST_PROCESSING_DONE = "annotation_run_post_processing_done",
  ML_ANNOTATION_MODEL_TRAINING_DONE = "ml_annotation_model_training_done",
  ML_ANNOTATION_MODEL_TRAINING_FAILED = "ml_annotation_model_training_failed",
  AI_ANNOTATION_RUN_DONE = "ai_annotation_run_done",
  AI_ANNOTATION_RUN_FAILED = "ai_annotation_run_failed",
  EXPORT_JOB_DONE = "export_job_done",
  EXPORT_JOB_FAILED = "export_job_failed",
  USAGE_REPORT = "usage_report",
}

export enum WebsocketEventGroupEnum {
  ANNOTATION_RUN_UPDATE = "annotation_run_update",
  AI_ANNOTATION_RUN_UPDATE = "ai_annotation_run_update",
  ML_ANNOTATION_MODEL_UPDATE = "ml_annotation_model_update",
  DATASET_IMPORT_UPDATE = "dataset_import_update",
}

export type WebSocketMessageModel =
  | DataUpdateMessageModel
  | UserNotificationMessageModel;

export interface DataUpdateMessageModel {
  type: "data_update_message";
  content: DataUpdateContentModel;
  timestamp: string;
}

export interface DataUpdateContentModel {
  entity_id: string;
  entity_type: string;
  event_type: WebsocketEventEnum;
  severity: CustomToasterSeverity;
  data:
    | DatasetModel
    | AnnotationRunModel
    | AIAnnotationRunModel
    | MLAnnotationModel
    | ImportJobsModel;
}

export interface UserNotificationMessageModel {
  type: "user_notification_message";
  content: NotificationModel;
  timestamp: string;
}

export interface NotificationModel {
  id: string;
  title: string;
  details: string;
  owner: string;
  event_type: WebsocketEventEnum;
  severity: CustomToasterSeverity;
  created_at: string;
  updated_at: string | null;
  archived_at: string | null;
  read_at: string | null;
  route?: string;
  route_label?: string;
  entities?: Record<string, string>;
}

export interface NotificationCountsModel {
  unread: number;
  total: number;
}

import { Dispatch } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import snackbarHelper from "helpers/snackbarHelperFn";
import {
  APIDeleteAxios,
  APIFetchAxios,
  APIPatchWithBodyAxios,
} from "routes/Auth";
import _ from "lodash";
import { AttributeMetadataModel } from "models/attributes.model";
import { SendFilterModel } from "models/filter.model";
import { MetaDataCountModel } from "models/metaData.model";

/**
 * Post request to get attributes metadata for a dataset
 * @param query
 *  @param query.datasetID The dataset ID of the attribute
 *  @param query.database_object_type The database object type of the attribute
 *  @param query.filters Additional filters to apply to the query
 * @returns Promise<AttributeMetadataModel[]> Promise of an array of AttributeMetadataModel
 */
export const fetchAttributesMetaData = async (
  params: {
    datasetID: string;
    query?: SendFilterModel[];
  },
  dispatch?: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
): Promise<AttributeMetadataModel[]> => {
  setIsLoading && setIsLoading(true);

  const response = await APIFetchAxios(
    `/datasets/${params?.datasetID}/attributeMetadata`,
    {
      query: params?.query,
    },
  )
    .then((response) => {
      setIsLoading && setIsLoading(false);
      return response;
    })
    .catch((error: AxiosError<any>) => {
      setIsLoading && setIsLoading(false);
      const errorDetail = error?.response?.data?.detail;
      dispatch &&
        snackbarHelper(
          errorDetail
            ? `Fetching attributes metadata for the selected dataset failed: ${errorDetail}`
            : "Fetching attributes metadata for the selected dataset failed!",
          "error",
        );
      throw error;
    });
  return response?.data;
};

/**
 * An endpoint to fetch metadata for a specific attribute
 * @param params.attributeID The attribute ID of the attribute to fetch metadata for
 * @param params.datasetID The dataset ID of the attribute to fetch metadata for
 * @param params.query Additional filters to apply to the query
 * @param dispatch The Redux dispatch function
 * @param setIsLoading
 * @returns
 */
export const fetchAttributeMetaData = async (
  params: {
    attributeID: string;
    datasetID: string;
    query?: SendFilterModel[];
  },
  dispatch?: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
): Promise<AttributeMetadataModel> => {
  setIsLoading && setIsLoading(true);

  const response = await APIFetchAxios(
    `/datasets/${params?.datasetID}/attributeMetadata/${params?.attributeID}`,
    {
      query: params?.query,
    },
  )
    .then((response) => {
      setIsLoading && setIsLoading(false);
      return response;
    })
    .catch((error: AxiosError<any>) => {
      setIsLoading && setIsLoading(false);
      const errorDetail = error?.response?.data?.detail;
      const errorMessage =
        "Fetching attribute metadata for the selected dataset failed";
      dispatch &&
        snackbarHelper(
          errorDetail ? `errorMessage: ${errorDetail}` : `${errorMessage}!`,
          "error",
        );
      throw error;
    });
  return response?.data;
};

export const fetchAttributesCount = async (
  datasetID: string,
  body: SendFilterModel[],
  dispatch: Dispatch,
): Promise<MetaDataCountModel> => {
  const response = await APIFetchAxios(
    `/datasets/${datasetID}/attributeValues:count`,
    body,
  ).catch((error) => {
    let msg = error?.details;
    if (_.isUndefined(error?.details)) {
      msg = "Something went wrong! Fetching attribute count failed!";
    }
    snackbarHelper(msg, "error");
  });
  return response?.data;
};

/**
 * Post request to archive an attribute
 * @param query Query object with datasetID and attributeID
 * @param query.datasetID Dataset ID of the attribute
 * @param query.attributeID Attribute ID of the attribute to be archived
 * @param dispatch Redux dispatch function
 * @returns Promise<void>
 */
export const deleteAttribute = async (
  query: { datasetID: string; attributeID: string },
  dispatch: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
) => {
  const body: { dataset_id: string; attribute_id: string } = {
    dataset_id: query?.datasetID,
    attribute_id: query?.attributeID,
  };
  setIsLoading && setIsLoading(true);
  await APIDeleteAxios(
    `/datasets/${body.dataset_id}/attributeMetadata/${body.attribute_id}`,
  )
    .then(() => {
      snackbarHelper("Attribute archived successfully!");
      setIsLoading && setIsLoading(false);
      return Promise.resolve();
    })
    .catch((error: AxiosError<any>) => {
      const errorDetail = error?.response?.data?.detail;
      snackbarHelper(
        `Attributes archive: ${errorDetail}` || "Attributes archive failed!",
        "error",
      );
      setIsLoading && setIsLoading(false);
      return Promise.reject();
    });
};

/**
 * Patch request to rename an attribute
 * @param query Query object with datasetID, attributeID and attributeNewName
 * @param query.datasetID Dataset ID of the attribute
 * @param query.attributeID Attribute ID of the attribute to be renamed
 * @param query.attributeNewName New name of the attribute
 * @param dispatch Redux dispatch function
 * @returns Promise<void>
 */
export const patchRenameAttribute = async (
  query: { datasetID: string; attributeID: string; attributeNewName: string },
  dispatch: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
) => {
  setIsLoading && setIsLoading(true);
  await APIPatchWithBodyAxios(
    `/datasets/${query?.datasetID}/attributeMetadata/${query?.attributeID}`,
    query?.attributeNewName,
  )
    .then(() => {
      snackbarHelper("Attribute renamed successfully!");
      setIsLoading && setIsLoading(false);
      return Promise.resolve();
    })
    .catch((error: AxiosError<any>) => {
      const errorDetail = error?.response?.data?.detail;
      snackbarHelper(
        `Attribute rename: ${errorDetail}` || "Attribute renaming failed!",
        "error",
      );
      setIsLoading && setIsLoading(false);
      return Promise.reject();
    });
};

import React from "react";

type Props = {
  label: string;
  onClick?: () => void;
  className?: string;
  icon?: React.ReactNode;
};

const SelectButton = ({ label, onClick, className, icon }: Props) => {
  return (
    <button
      key={`${label}_button`}
      className={`button-select-layer ${className ? className : ""}`}
      onClick={() => onClick && onClick()}
      data-test={`select_${label}_button`}
    >
      {icon && icon}
      {label}
    </button>
  );
};

export default SelectButton;

import { useAppDispatch } from "store/hooks";
import { ReactComponent as Plus } from "assets/plus.svg";
import { setIsCreatingNewAIAnnotationRunModalOpen } from "store/aiManagerSlice";

const CreateNewAIAnnotationRunButton = () => {
  const dispatch = useAppDispatch();

  return (
    <button
      className="button-dark-gray-layer w-fit"
      onClick={() => dispatch(setIsCreatingNewAIAnnotationRunModalOpen(true))}
      data-test="create_new_ai_annotation_run_button"
    >
      <Plus className={"w-4 h-4"} />
      Create new AI annotation run
    </button>
  );
};

export default CreateNewAIAnnotationRunButton;

import { useAppDispatch } from "store/hooks";
import { useState } from "react";
import _ from "lodash";
import {
  StatusLevelModel,
  TransformationJobModel,
} from "models/transformation.model";
import {
  fetchTransformationJobs,
  importJobResults,
} from "helpers/apis/transformations";
import BasicTable from "components/Table/BasicTable";
import BasicButton from "components/Buttons/BasicButton";
import { snakeCaseToText } from "components/utilFunctions";
import AutoIntervalButton from "components/Buttons/AutoIntervalButton";
import snackbarHelper from "helpers/snackbarHelperFn";

interface Props {
  datasetID: string;
}

const JobsTable = ({ datasetID }: Props) => {
  const dispatch = useAppDispatch();

  const [jobs, setJobs] = useState<{ [key: string]: TransformationJobModel }>();
  const [selectedJob, setSelectedJob] = useState<TransformationJobModel>();

  const getJobsForDataset = () => {
    fetchTransformationJobs(datasetID)
      .then((response) => {
        const jobsObject = _.keyBy(response?.data, "job_id");
        setJobs(jobsObject);
        snackbarHelper("Jobs updated successfully!");
      })
      .catch((error) => {
        let msg = error?.details;
        if (_.isUndefined(error?.details)) {
          msg = "Something went wrong! Fetching jobs failed.";
        }
        snackbarHelper(msg, "error");
      });
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      span: 15,
    },
    {
      field: "status",
      headerName: "Status",
      span: 10,
      cell: (params: TransformationJobModel) => (
        <div className="flex">
          {renderStatusLevel(params?.status?.level, params.status.msg)}
        </div>
      ),
    },
    {
      field: "routine_type",
      headerName: "Job type",
      span: 10,
      cell: (params: TransformationJobModel) => (
        <div className="flex">{snakeCaseToText(params?.routine_type)}</div>
      ),
    },
    {
      field: "progress",
      headerName: "Progress",
      span: 6,
      cell: (params: TransformationJobModel) => {
        const percentage = (params?.progress * 100).toFixed(2);
        return <div className="flex">{percentage + " %"}</div>;
      },
    },
    {
      field: "num_tasks",
      headerName: "Tasks",
      span: 5,
    },
    {
      field: "done",
      headerName: "Done",
      span: 5,
    },
    {
      field: "failed",
      headerName: "Failed",
      span: 5,
    },
    {
      field: "in_flight",
      headerName: "Flight",
      span: 5,
    },
    {
      field: "created_at",
      headerName: "Created",
      span: 12,
      cell: (params: TransformationJobModel) => {
        const date = new Date(params.created_at);
        return <div className="flex">{date.toLocaleString()}</div>;
      },
    },
    {
      field: "started_at",
      headerName: "Started at",
      span: 12,
      cell: (params: TransformationJobModel) => {
        const date = new Date(params.started_at);
        return <div className="flex">{date.toLocaleString()}</div>;
      },
    },
    {
      field: "last_task_done_at",
      headerName: "Last task done at",
      span: 12,
      cell: (params: TransformationJobModel) => {
        const date = new Date(params.last_task_done_at);
        return <div className="flex">{date.toLocaleString()}</div>;
      },
    },
    {
      field: "",
      headerName: "",
      span: 6,
      sortable: false,
      cell: (job: TransformationJobModel) => (
        <BasicButton
          label="Import"
          onClick={() => handleImportJobResultsClick(job)}
          disabled={!isJobImportable(job)}
        />
      ),
    },
  ];

  const handleImportJobResultsClick = (job: TransformationJobModel) => {
    snackbarHelper("Importing job results in the background. Please wait.");
    importJobResults(datasetID, job?.job_id)
      .then(() => {
        getJobsForDataset();
      })
      .catch((error) => {
        snackbarHelper(
          error?.details || "Importing job results failed!",
          "error",
        );
      });
  };

  const isJobImportable = (job: TransformationJobModel) => {
    const isJobDoneSuccessfully = job?.num_tasks === job?.done;
    const isJobImported = job?.status?.msg.toLowerCase().includes("import");

    return isJobDoneSuccessfully && !isJobImported;
  };

  const renderStatusLevel = (level: StatusLevelModel, msg: string) => {
    let color = "text-black";
    switch (level) {
      case StatusLevelModel.Error:
        color = "text-paletteRed";
        break;
      case StatusLevelModel.Success:
        color = "text-paletteGreen";
        break;
      case StatusLevelModel.Warning:
        color = "text-paletteYellow";
        break;
      case StatusLevelModel.Information:
        color = "text-paletteBlue";
        break;
      default:
        color = "text-black";
    }
    return <div className={`${color}`}>{msg}</div>;
  };

  return (
    <div className="h-full">
      <div className="flex gap-x-2 pt-[1%] pl-[6%]">
        <AutoIntervalButton
          functionToCall={getJobsForDataset}
          disabled={datasetID?.length === 0}
          label="Update table"
          numberOfRuns={1}
          delay={400}
        />
        <AutoIntervalButton
          functionToCall={() => getJobsForDataset()}
          disabled={datasetID?.length === 0}
        />
      </div>
      <div className="h-3/4">
        <BasicTable
          rows={_.map(jobs, (j) => j) || []}
          columns={columns}
          rowUniqueKey="id"
          fontSize="0"
          hover={false}
          onRowClick={(row: TransformationJobModel) => {
            setSelectedJob(row);
          }}
        />
      </div>
      <div className="grid grid-cols-4 mt-20">
        {_.map(selectedJob, (value, key) => {
          if (_.isString(value) || _.isNumber(value)) {
            return (
              <div key={key} className="text-xs border-2 p-1">
                {snakeCaseToText(key)}: {value}
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default JobsTable;

import { useEffect, useState } from "react";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useParams } from "react-router-dom";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Backdrop from "@mui/material/Backdrop";
import BasicButton from "components/Buttons/BasicButton";
import { BulkAssignTagsPayload } from "models/apis.model";
import { fetchMediaData } from "store/explorationMediaSlice";
import { fetchMediaObjectsData } from "store/explorationMediaObjectsSlice";
import {
  AnnotatableEnum,
  SelectedItemsTypeModel,
  TagModel,
  TagTypeModel,
} from "models/global.model";
import TagsSelector from "components/Inputs/TagsSelector";
import { postTagAssignOrUnassign } from "helpers/apis/tags";
import {
  SendFilterComparisonModel,
  SendFilterModel,
} from "models/filter.model";
import { fetchTags } from "store/datasetSlice";
import { APIPostWithBodyAxios } from "routes/Auth";
import getQueryFromCurrentSelection from "helpers/functions/getQueryFromCurrentSelection";
import { fetchInstanceData } from "store/explorationInstancesSlice";
import {
  getLabelFromSelectedView,
  getSelectedItemsBasedOnSelectedView,
  getTagTypeFromBasedOnSelectedView,
} from "helpers/functions/selectedViewHelpers";
import { determineActiveSortKeyFromParam } from "store/sortDataSlice";
import { isAttributeAnnotationAttribute } from "helpers/functions/attributes/attributesHelpers";
import snackbarHelper from "helpers/snackbarHelperFn";

const scrollLoadAmount = Number(
  process.env.REACT_APP_SCROLL_LOAD_AMOUNT ??
    alert("Config value 'scroll load amount' is not defined"),
);

interface Props {
  selectedItemsType: SelectedItemsTypeModel;
  isOpen: boolean;
  onClose: () => void;
}

const BulkTagAssignment = ({ selectedItemsType, isOpen, onClose }: Props) => {
  const params: any = useParams();
  const dispatch = useAppDispatch();

  const selectedView = useAppSelector((state) => state.appSlice.selectedView);
  const attributesMeta = useAppSelector(
    (state) => state.datasetSlice.attributes,
  );
  const tags = useAppSelector((state) =>
    _.keyBy(state.datasetSlice?.tags, "id"),
  );
  const filterData = useAppSelector((state) => state.filterDataSlice);
  const sortData = useAppSelector((state) => state.sortDataSlice);
  const selectedMedias = useAppSelector(
    (state) => state.explorationMediaSlice.selectedMediaIDs,
  );
  const selectedMediaObjects = useAppSelector(
    (state) => state.explorationMediaObjectsSlice.selectedMediaObjectsIDs,
  );
  const selectedInstances = useAppSelector(
    (state) => state.explorationInstanceSlice.selectedInstanceIDs,
  );
  const activeDataset = useAppSelector(
    (state) => state.datasetSlice.activeDataSet,
  );

  // TODO: specify the keys type/model
  const [attributeForm, setAttributeForm] = useState<{
    [key: string]: string[];
  }>({});
  const [annotatableForm, setAnnotatableForm] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Initialize attributeForm
    let tempArr = {};
    _.map(attributesMeta, (d) => {
      tempArr = { ...tempArr, [d?.id]: [] };
    });
    setAttributeForm(tempArr);
  }, []);

  const handleOnSuccess = (
    shouldRefetchExploration: boolean = false,
    query?: SendFilterModel[],
  ) => {
    snackbarHelper(
      "Bulk assign tags request has been sent successfully. Please wait for the request to complete, This could take a while!",
    );

    if (shouldRefetchExploration) {
      const sortKey = determineActiveSortKeyFromParam(selectedView);
      if (sortKey === undefined) return;
      const requestBody = {
        runId: params?.id,
        reqBody: {
          dataset_id: params?.id,
          query: query,
          limit: scrollLoadAmount,
          skip: 0,
          sort: sortData?.[sortKey],
        },
        subSetId: params?.subset_id,
        reset: true,
      };
      switch (selectedView) {
        case AnnotatableEnum.Media:
          dispatch(fetchMediaData(requestBody));
          break;
        case AnnotatableEnum.MediaObject:
          dispatch(fetchMediaObjectsData(requestBody));
          break;
        case AnnotatableEnum.Instance:
          dispatch(fetchInstanceData(requestBody));
          break;
      }
    }
    setIsLoading(false);
    onClose();
  };

  // Handle on confirm button click
  const handleOnConfirm = () => {
    let query: SendFilterModel[] = [];
    if (selectedItemsType === SelectedItemsTypeModel.all) {
      query = getQueryFromCurrentSelection(
        selectedView,
        filterData,
        params?.subset_id,
      );
    } else if (selectedItemsType === SelectedItemsTypeModel.selected) {
      const ids_filter: SendFilterComparisonModel = {
        attribute: "id",
        query_operator: "in",
        value: getSelectedItemsBasedOnSelectedView(
          selectedView,
          selectedMedias,
          selectedMediaObjects,
          selectedInstances,
        ),
      };
      query = [ids_filter];
    }

    const body: BulkAssignTagsPayload = {
      dataset_id: params?.id,
      attribute_tags: _.pickBy(attributeForm, (i) => !_.isEmpty(i)),
      parent_annotatable_type: selectedView,
      query: query,
    };

    setIsLoading(true);

    _.map(annotatableForm, (tagId) => {
      postTagAssignOrUnassign(
        "assign",
        {
          datasetId: params?.id,
          database_object_type: getTagTypeFromBasedOnSelectedView(selectedView),
          tagId: tagId,
          query: query,
        },
        setIsLoading,
      ).then(() => {
        handleOnConfirmationSuccess(query);
      });
    });

    if (_.size(_.pickBy(attributeForm, (i) => !_.isEmpty(i))) !== 0) {
      snackbarHelper("Assigning tags to attributes...", "info");
      APIPostWithBodyAxios(
        `/datasets/${body?.dataset_id}/attributeValues:bulk_assign_tags`,
        body,
      )
        // Success
        .then(() => {
          // Fetch new Medias
          handleOnSuccess();
        })
        // Throw error
        .catch((error) => {
          snackbarHelper(
            error?.detail ||
              "Looks like assigning the tags failed! Please try again or contact us!",
            "error",
          );
          setIsLoading(false);
        });
    }
  };

  const handleOnConfirmationSuccess = (query: SendFilterModel[]) => {
    if (selectedItemsType === SelectedItemsTypeModel.all) {
      handleOnSuccess(true, query);
    } else if (selectedItemsType === SelectedItemsTypeModel.selected) {
      handleOnSuccess(false, []);
    }
  };

  const renderAnnotatablesBulkAssign = () => {
    return (
      <div className="py-1">
        {selectedView.toString() === "MediaObject" ? "Object" : selectedView}:
        {renderAnnotatableTags(getTagTypeFromBasedOnSelectedView(selectedView))}
      </div>
    );
  };

  const renderAnnotatableTags = (key: TagTypeModel) => {
    return (
      <div key={key} className="flex border-b-2 py-1">
        <div className="w-1/3 ml-auto pt-2">
          <TagsSelector
            items={_.pickBy(
              tags,
              (tag: TagModel) => tag?.database_object_type === key,
            )}
            selectedItems={_.pickBy(tags, (tag) => {
              return _.includes(annotatableForm, tag?.id);
            })}
            handleAddTag={(tag) =>
              setAnnotatableForm([...annotatableForm, tag?.id])
            }
            handleRemoveTag={(tag) => {
              const newItem = _.remove(annotatableForm, (i) => i !== tag?.id);
              setAnnotatableForm(newItem);
            }}
            enableCreateNewTag={{
              type: key,
              datasetID: params?.id,
              onAddNewItemSuccess: (newTagID) => {
                dispatch(fetchTags({ query: { parentDataSetID: params?.id } }));
                setAnnotatableForm([...annotatableForm, newTagID]);
              },
            }}
          />
        </div>
      </div>
    );
  };

  const renderAttributes = () => {
    if (attributesMeta.length === 0) {
      return <div className="w-full flex justify-center">No Attributes.</div>;
    }

    const annotationAttributes = _.filter(attributesMeta, (attributeMeta) =>
      isAttributeAnnotationAttribute(attributeMeta?.attribute_group),
    );
    // Filter the attributes based on the selected tab
    const filteredAttributes = _.filter(
      annotationAttributes,
      (attribute) => attribute?.annotatable_type === selectedView,
    );

    return _.map(filteredAttributes, (attribute, key) => {
      return (
        <div key={key} className="flex border-b-2 py-1">
          <div className="mr-2">{attribute?.name}</div>
          <div className="w-1/3 ml-auto pt-2">
            <TagsSelector
              items={_.pickBy(
                tags,
                (tag: TagModel) =>
                  tag?.database_object_type === TagTypeModel.Attribute,
              )}
              selectedItems={_.pickBy(tags, (tag) => {
                return _.includes(attributeForm[attribute?.id], tag?.id);
              })}
              disabled={isLoading}
              handleAddTag={(tag) => {
                const newValue = attributeForm[attribute?.id]
                  ? [...attributeForm[attribute?.id], tag?.id]
                  : [tag?.id];
                setAttributeForm({
                  ...attributeForm,
                  [attribute?.id]: newValue,
                });
              }}
              handleRemoveTag={(tag) => {
                const newItem = _.remove(
                  attributeForm[attribute?.id],
                  (i) => i !== tag?.id,
                );
                setAttributeForm({
                  ...attributeForm,
                  [attribute?.id]: newItem,
                });
              }}
              enableCreateNewTag={{
                type: TagTypeModel.Attribute,
                datasetID: params?.id,
                onAddNewItemSuccess: (newTagID) => {
                  dispatch(
                    fetchTags({ query: { parentDataSetID: params?.id } }),
                  );
                  setAttributeForm({
                    ...attributeForm,
                    [attribute.id]: [...attributeForm[attribute.id], newTagID],
                  });
                },
              }}
            />
          </div>
        </div>
      );
    });
  };

  const renderSelectedItemsNumber = () => {
    if (selectedItemsType === SelectedItemsTypeModel.selected) {
      const selectedItemsNumber = getSelectedItemsBasedOnSelectedView(
        selectedView,
        selectedMedias,
        selectedMediaObjects,
        selectedInstances,
      );
      return (
        <div className="flex mb-2">{`Selected ${getLabelFromSelectedView(
          selectedView,
          activeDataset,
        )}: ${selectedItemsNumber?.length}`}</div>
      );
    }
  };

  return (
    <div>
      <Backdrop open={isOpen}>
        <Dialog
          fullWidth={true}
          maxWidth={"lg"}
          open={isOpen}
          onClose={onClose}
        >
          <DialogTitle>
            Add tag(s) to {selectedItemsType}{" "}
            {getLabelFromSelectedView(selectedView, activeDataset)}:
          </DialogTitle>
          <DialogContent>
            {renderSelectedItemsNumber()}

            <div className="w-3/4 mx-auto">
              {renderAnnotatablesBulkAssign()}
              <div className="py-1">Attributes:</div>
              {renderAttributes()}
            </div>
            <div className="w-full my-4 flex flex-row-reverse">
              <BasicButton
                label="Confirm"
                onClick={() => handleOnConfirm()}
                className={`px-4 py-2 mx-2 ${
                  isLoading && "disabled:hover:cursor-wait"
                }`}
                disabled={
                  isLoading ||
                  (_.size(_.pickBy(attributeForm, (i) => !_.isEmpty(i))) ===
                    0 &&
                    annotatableForm.length <= 0)
                }
              />
              <BasicButton
                label="Cancel"
                onClick={() => onClose()}
                className="px-4 py-2 mx-2 text-paletteRed hover:bg-paletteRed disabled:hover:cursor-wait"
                disabled={isLoading}
              />
            </div>
          </DialogContent>
        </Dialog>
      </Backdrop>
    </div>
  );
};
export default BulkTagAssignment;

import { useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  determineFilterKeyFromParam,
  setFiltersData,
  updateFilterData,
} from "store/filterDataSlice";
import FilterRouter from "./FilterRouter";
import _ from "lodash";
import { FilterAttributeGroupEnum, FilterModel } from "models/filter.model";
import { ReactComponent as ArrowUpIcon } from "assets/arrow_small_up.svg";
import { ReactComponent as ResetIcon } from "assets/reset.svg";
import { ReactComponent as CloseIcon } from "assets/close.svg";
import SelectAttributeFilter from "components/Filters/SelectAttributeFilter";

interface Props {
  group: FilterModel[];
}

const FilterComponentList = ({ group }: Props) => {
  const dispatch = useAppDispatch();

  const filterData = useAppSelector((state) => state.filterDataSlice);
  const selectedView = useAppSelector((state) => state.appSlice.selectedView);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const renderAccordion = (group: FilterModel[]) => {
    const firstFilter = group?.[0];
    let title = "";
    let isAnnotationAttribute = false;

    switch (firstFilter?.attribute_group) {
      case FilterAttributeGroupEnum.Auto_Attribute:
        title = "Auto attribute";
        break;
      case FilterAttributeGroupEnum.Initial_Attribute:
        title = "Initial attribute";
        break;
      case FilterAttributeGroupEnum.Annotation_Attribute:
        isAnnotationAttribute = true;
        title = "Attribute";
        break;
      case FilterAttributeGroupEnum.ML_Annotation_Attribute:
        isAnnotationAttribute = true;
        title = "AI Attribute";
        break;
      case FilterAttributeGroupEnum.Text_Filter:
        title = "Search";
        break;
      case FilterAttributeGroupEnum.Subsets_And_Tags:
        title = "Subsets, classes and tags";
        break;
      default:
        break;
    }

    return (
      <div className="border-[1px] border-paletteGray-6 rounded-lg">
        <div className="flex justify-between text-paletteGray-8 px-3 py-[9.5px] border-b-[1px] border-paletteGray-3 ">
          {title}
          <div className="flex gap-x-[12px]">
            {renderCollapseButton()}
            {renderResetButton(group)}
            {renderRemoveButton()}
          </div>
        </div>

        <div className="transition-all">
          {isAnnotationAttribute && renderSelectAnnotatedAttribute(firstFilter)}

          {renderFilters(isAnnotationAttribute)}
        </div>
      </div>
    );
  };

  const renderCollapseButton = () => {
    return (
      <button
        className="button-select-layer px-1 py-0 text-paletteGray-10"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <ArrowUpIcon
          className={`w-4 h-4 transition-all ${
            !isCollapsed ? "transform -rotate-180" : ""
          }`}
        />
      </button>
    );
  };

  const renderResetButton = (group: FilterModel[]) => {
    return (
      <button
        className="button-select-layer px-1 py-0 text-paletteGray-10"
        onClick={() => handleResetFilterGroup(group)}
      >
        <ResetIcon width={16} height={16} />
      </button>
    );
  };

  const renderRemoveButton = () => {
    return (
      <button
        className="button-select-layer px-1 py-0 text-paletteGray-10"
        onClick={() => handleResetFilterGroup(group, true)}
        data-test="remove_filter_button"
      >
        <CloseIcon width={16} height={16} />
      </button>
    );
  };

  const handleResetFilterGroup = (
    group: FilterModel[],
    shouldRemove: boolean = false,
  ) => {
    let newFilters = filterData?.[determineFilterKeyFromParam(selectedView)];
    if (newFilters === null) return;

    _.map(group, (filter) => {
      let newObj: FilterModel = { ...filter };
      newObj = {
        ...newObj?.init,
        init: newObj?.init,
      };
      // If shouldRemove is true, set is_visible to false
      if (shouldRemove) {
        newObj = {
          ...newObj,
          is_visible: false,
        };
      }
      newFilters = {
        ...newFilters,
        [filter?.key]: newObj,
      };
    });
    dispatch(setFiltersData({ data: newFilters, type: selectedView }));
  };

  const renderSelectAnnotatedAttribute = (
    annotatedAttributeFilter: FilterModel,
  ) => {
    return (
      <SelectAttributeFilter
        filter={annotatedAttributeFilter}
        handleFilterChange={(newObj) => {
          dispatch(updateFilterData({ data: newObj, type: selectedView }));
        }}
      />
    );
  };

  const renderFilters = (isAnnotationAttribute: boolean) => {
    if (isAnnotationAttribute && isCollapsed) {
      return null;
    }
    return (
      <div className="flex flex-col gap-y-4">
        {_.map(group, (filter) => renderFilter(filter))}
      </div>
    );
  };

  const renderFilter = (filter: FilterModel) => {
    // Display only if filter is visible and not a select attribute
    //  (select attribute is displayed next to the annotated attribute name)
    if (filter?.is_visible === true && filter?.type !== "SELECT_ATTRIBUTE") {
      return (
        <div
          key={filter?.key}
          className="p-4 border-b-[1px] border-paletteGray-4
         last:border-0
        "
        >
          <FilterRouter
            attribute={filter}
            updateFilterData={(newObj, view) =>
              dispatch(updateFilterData({ data: newObj, type: view }))
            }
            isCollapsed={isCollapsed}
            key={filter?.key}
          />
        </div>
      );
    }
  };

  return renderAccordion(group);
};

export default FilterComponentList;

import { useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import _ from "lodash";

import { ReactComponent as FilterLineIcon } from "assets/filter_line.svg";
import { ReactComponent as PinIcon } from "assets/pin.svg";
import { ReactComponent as UnpinIcon } from "assets/unpin.svg";
import FiltersList from "Pages/Exploration/FiltersList";
import SortList from "Pages/Exploration/SortList";

import { setIsFilterDrawerOpen } from "store/appSlice";
import { determineActiveFilterKeyFromParam } from "store/filterDataSlice";
import { determineActiveSortKeyFromParam } from "store/sortDataSlice";

interface Props {
  areFiltersPinned?: boolean;
  setAreFiltersPinned?: (newState: boolean) => void;
}

const FiltersDropdown = ({ areFiltersPinned, setAreFiltersPinned }: Props) => {
  const dispatch = useAppDispatch();

  const mainAreaRef = useRef<HTMLDivElement>(null);

  const selectedView = useAppSelector((state) => state.appSlice.selectedView);
  const filterData = useAppSelector((state) => state.filterDataSlice);
  const sortData = useAppSelector((state) => state.sortDataSlice);

  const filters = filterData?.[determineActiveFilterKeyFromParam(selectedView)];

  const getActiveSorter = () => {
    const key = determineActiveSortKeyFromParam(selectedView);
    if (!key) return null;
    return sortData?.[key]?.[0];
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const renderDefaultButton = () => {
    return (
      <button
        className="button-layer h-10 py-[9.5px]"
        onMouseEnter={() => setIsDropdownOpen(true)}
        data-test="filter_menu_1"
      >
        <FilterLineIcon width={16} height={16} />
        <div className="text-sm hidden xl:block">Filter or sort your data</div>
      </button>
    );
  };

  const renderCompactFilters = () => {
    return (
      <div
        className="px-[11px] pt-[3px] pb-[0.5px] flex items-center gap-x-3 border-[1px] border-l-paletteGray-4 rounded-lg"
        onMouseEnter={() => setIsDropdownOpen(true)}
        data-test="filter_menu_applied"
      >
        <div className="flex ">
          <FilterLineIcon width={16} height={16} />
        </div>
        <div
          className=" flex flex-nowrap justify-center gap-x-1 overflow-x-scroll rounded-lg"
          data-test="filter_list_exploration"
        >
          {!_.isEmpty(filters) && <FiltersList shouldWhiteSpaceNoWrap />}
          {!_.isUndefined(getActiveSorter()) && (
            <SortList shouldWhiteSpaceNoWrap />
          )}
        </div>
      </div>
    );
  };

  const renderDropdown = () => {
    const newPosition = mainAreaRef?.current?.getBoundingClientRect();
    const top = newPosition?.top;
    const display = isDropdownOpen ? "block" : "none";
    const width = newPosition?.width;
    const height = isDropdownOpen ? "auto" : 0;
    return (
      <div
        className={`fixed z-20 flex flex-col gap-y-1
            text-sm text-paletteGray-8 bg-white rounded-lg shadow-dropdown
            `}
        style={{ display: display, width: width, height: height, top: top }}
        onMouseLeave={() => setIsDropdownOpen(false)}
      >
        <div className="w-full p-[2px] flex gap-x-1">
          <button
            className="button-select-layer py-[9.5px] gap-x-2 bg-paletteGray-4 transition-none"
            onClick={() => dispatch(setIsFilterDrawerOpen(true))}
            data-test="filter_menu_2"
          >
            <FilterLineIcon width={16} height={16} />
            Filter or sort your data
          </button>
          <button
            className="button-select-layer w-auto p-2"
            onClick={() =>
              setAreFiltersPinned && setAreFiltersPinned(!areFiltersPinned)
            }
          >
            {areFiltersPinned ? (
              <UnpinIcon width={20} height={20} />
            ) : (
              <PinIcon width={20} height={20} />
            )}
          </button>
        </div>
        <div className="p-1 flex flex-col gap-y-1 ">
          <div className="pl-2 text-sm font-medium">Filtered by:</div>
          <div className="flex flex-wrap gap-1">
            <FiltersList />
          </div>
          <div className="pl-2 text-sm font-medium">Sorted by:</div>
          <SortList />
        </div>
      </div>
    );
  };

  return (
    <div>
      <div ref={mainAreaRef}>
        {/* 
          Show compact filters if:
          - filters are not pinned
          - filters are not empty
          - sorter is not undefined
        */}
        {!areFiltersPinned &&
        (!_.isEmpty(filters) || !_.isUndefined(getActiveSorter()))
          ? renderCompactFilters()
          : renderDefaultButton()}
      </div>
      {renderDropdown()}
    </div>
  );
};

export default FiltersDropdown;

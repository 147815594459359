import { useAppDispatch } from "store/hooks";
import DataTable, { DataTableColumn } from "components/Table/DataTable";
import TooltipTruncateEllipsis from "components/Tooltips/TooltipTruncateEllipsis";
import {
  AnnotationRunModel,
  AnnotationRunNodeModel,
  AnnotationRunNodeStatusEnum,
} from "models/annotationRun.model";
import { getUrlPrefix } from "Pages/AnnotationRunManager/AnnotationRunManagerTableAction";
import AnnotationRunNodeStatusChip from "Pages/AnnotationRunManager/NodesTable/AnnotationRunNodeStatusChip";

type Props = {
  annotationRun: AnnotationRunModel;
  nodes: AnnotationRunNodeModel[];
  isLoading: boolean;
};

const NodesTable = ({ annotationRun, nodes, isLoading }: Props) => {
  const dispatch = useAppDispatch();

  const tableColumns: DataTableColumn[] = [
    {
      field: "name",
      headerName: "",
      span: 25,
      cell: (row) => {
        const annotationRunNode = row as AnnotationRunNodeModel;
        return (
          <TooltipTruncateEllipsis className="pl-2 pr-3">
            {annotationRunNode.name}
          </TooltipTruncateEllipsis>
        );
      },
    },
    {
      field: "created_at",
      headerName: "",
      span: 15,
      cell: (row) => {
        const annotationRunNode = row as AnnotationRunNodeModel;
        const date = new Date(annotationRunNode?.created_at);
        return (
          <TooltipTruncateEllipsis className="pr-3">
            {date.toLocaleString()}
          </TooltipTruncateEllipsis>
        );
      },
    },
    {
      field: "status",
      headerName: "",
      span: 15,
      cell: (row) => {
        const annotationRunNode = row as AnnotationRunNodeModel;
        return (
          <AnnotationRunNodeStatusChip status={annotationRunNode.status} />
        );
      },
    },
    {
      field: "actions",
      headerName: "",
      span: 45,
      cell: (row) => {
        const annotationRunNode = row as AnnotationRunNodeModel;
        return renderExploreResultsButton(annotationRunNode);
      },
    },
  ];

  const renderExploreResultsButton = (
    annotationRunNode: AnnotationRunNodeModel,
  ) => {
    if (
      annotationRunNode.status !==
      AnnotationRunNodeStatusEnum.POST_PROCESSING_DONE
    )
      return <></>;

    return (
      <div className="w-full flex justify-end items-center">
        <button
          onClick={(e) => {
            e.stopPropagation();
            if (annotationRun?.subset_id === null) return;

            getUrlPrefix(annotationRun?.subset_id, dispatch).then(
              (url_prefix) => {
                // AI attributes with no goliat project have the ann run node ID as attr ID
                const attributeID =
                  annotationRunNode.goliat_node_id || annotationRunNode.id;
                const exploreUrl = `${url_prefix}/${annotationRun.dataset_id}/main_dataset?sel_attr__attributes.${attributeID}.dataset_id="${annotationRun.dataset_id}"`;
                const newWnd = window.open(
                  exploreUrl,
                  "_blank",
                  "noopener,noreferrer",
                );
                if (newWnd) {
                  newWnd.opener = null;
                }
              },
            );
          }}
          className="button-layer mr-2"
          data-test="explore_results_button_annotation_run_manager"
        >
          Explore results
        </button>
      </div>
    );
  };

  return (
    <DataTable
      rows={nodes as []}
      columns={tableColumns}
      defaultSort={{ name: "created_at", direction: "desc" }}
      showHeader={false}
      isLoading={isLoading}
      style={{
        rowsYGap: "gap-y-1",
        rowYPadding: "py-2",
      }}
    />
  );
};

export default NodesTable;

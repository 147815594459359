import StandardPopover from "components/Popovers/StandardPopover";
import { getSelectedItemsBasedOnSelectedView } from "helpers/functions/selectedViewHelpers";
import _ from "lodash";
import { AnnotatableEnum } from "models/global.model";
import { unSelectAllSelectedInstanceIDs } from "store/explorationInstancesSlice";
import { unSelectAllSelectedMediaObjectIDs } from "store/explorationMediaObjectsSlice";
import { unSelectAllSelectedMediaIDs } from "store/explorationMediaSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";

type Props = {
  anchorEl: HTMLElement | null;
  handlePopoverOpen: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  handlePopoverClose: () => void;
};

const SelectedItemsPopover = ({
  anchorEl,
  handlePopoverOpen,
  handlePopoverClose,
}: Props) => {
  const dispatch = useAppDispatch();

  const selectedView = useAppSelector((state) => state.appSlice.selectedView);
  const selectedMedias = useAppSelector(
    (state) => state.explorationMediaSlice.selectedMediaIDs,
  );
  const selectedMediaObjects = useAppSelector(
    (state) => state.explorationMediaObjectsSlice.selectedMediaObjectsIDs,
  );
  const selectedInstances = useAppSelector(
    (state) => state.explorationInstanceSlice.selectedInstanceIDs,
  );

  const renderButtonTitle = () => {
    const selectedItemsLength = getSelectedItemsBasedOnSelectedView(
      selectedView,
      selectedMedias,
      selectedMediaObjects,
      selectedInstances,
    )?.length;

    return (
      <button
        className={`button-layer py-[9.5px]
        ${!_.isNull(anchorEl) ? "button-layer-active" : ""}
        ${selectedItemsLength > 0 && "bg-paletteOrange text-white"}`}
        data-test="selected_number"
        onClick={handlePopoverOpen}
      >
        {`${selectedItemsLength} selected`}
      </button>
    );
  };

  return (
    <StandardPopover
      id="selected_items_popover_id"
      data-test="unselect_all_button"
      anchorEl={anchorEl}
      handlePopoverOpen={handlePopoverOpen}
      handlePopoverClose={handlePopoverClose}
      buttonElement={renderButtonTitle()}
      itemsList={[
        {
          label: "Unselect all",
          onItemClick: () => {
            switch (selectedView) {
              case AnnotatableEnum.Media:
                dispatch(unSelectAllSelectedMediaIDs());
                break;
              case AnnotatableEnum.MediaObject:
                dispatch(unSelectAllSelectedMediaObjectIDs());
                break;
              case AnnotatableEnum.Instance:
                dispatch(unSelectAllSelectedInstanceIDs());
                break;
            }
            handlePopoverClose();
          },
        },
      ]}
    />
  );
};

export default SelectedItemsPopover;

import { NotificationModel } from "models/websockets.model";
import { ReactComponent as NotificationBell } from "assets/notification_bell.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { fetchNotificationsStore } from "store/notificationsSlice";
import NotificationBox from "./NotificationBox";
import _ from "lodash";
import { useCallback, useEffect } from "react";

const AllNotificationList = () => {
  const dispatch = useAppDispatch();
  const { notifications, hasMore, counts, loading } = useAppSelector(
    (state) => state.notificationSlice,
  );

  useEffect(() => {
    if (notifications.length < 7 && notifications.length > 0 && hasMore) {
      dispatch(
        fetchNotificationsStore({
          reqBody: { skip: notifications.length, limit: 10 },
        }),
      );
    }
  }, [notifications]);

  const loadMore = useCallback(() => {
    if (hasMore) {
      dispatch(
        fetchNotificationsStore({
          reqBody: {
            skip: notifications.length,
            limit: 10,
          },
        }),
      );
    }
  }, [dispatch, hasMore, notifications.length]);

  if (loading && notifications.length === 0) {
    return (
      <div className="h-full flex justify-center items-center">Loading...</div>
    );
  }

  if (counts.total === 0) {
    return (
      <div
        className="w-full h-full flex flex-col font-medium justify-center items-center"
        data-test="no_notifications_field"
      >
        <div>Notifications will appear here.</div>
        <NotificationBell className="w-6 h-6 mt-4" />
      </div>
    );
  }

  return (
    <InfiniteScroll
      hasMore={hasMore}
      loader={
        <div className="h-full flex justify-center items-center">
          Loading...
        </div>
      }
      dataLength={notifications.length}
      next={loadMore}
      scrollableTarget="allNotificationList"
    >
      {notifications.map((notification) => (
        <NotificationBox
          key={notification.id}
          notification={notification as NotificationModel}
        />
      ))}
    </InfiniteScroll>
  );
};

export default AllNotificationList;

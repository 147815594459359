import { Popover } from "@mui/material";
import _ from "lodash";
import React from "react";

type IncludingPopoverType = "≤-≥" | "<->";
interface Props {
  anchorEl: HTMLElement | null;
  handlePopoverOpen: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  handlePopoverClose: () => void;
  selectedValue: IncludingPopoverType;
  onClick: (clickedType: IncludingPopoverType) => void;
}

const IncludingPopover = ({
  anchorEl,
  handlePopoverOpen,
  handlePopoverClose,
  selectedValue,
  onClick,
}: Props) => {
  const open = Boolean(anchorEl);

  const renderOpenButton = () => {
    return (
      <div
        className="rounded"
        onClick={(event) => {
          event.stopPropagation();
          handlePopoverOpen(event);
        }}
      >
        {renderButton(selectedValue)}
      </div>
    );
  };

  const renderItems = () => {
    const items: IncludingPopoverType[] = ["≤-≥", "<->"];

    return (
      <div className="flex flex-col bg-transparent">
        {_.map(items, (type) => {
          return (
            <div
              key={type}
              onClick={() => {
                onClick(type);
                handlePopoverClose();
              }}
            >
              {renderButton(type)}
            </div>
          );
        })}
      </div>
    );
  };

  const renderButton = (type: IncludingPopoverType) => {
    return (
      <div
        className="w-[44px] h-[28px] px-[5px] pt-1 pb-[5px] flex border-[1px] border-paletteGray-4
            text-sm cursor-pointer rounded-[inherit] bg-white hover:bg-paletteGray-4"
        data-test="equal_include_button"
      >
        <div className="flex-1 flex justify-center items-center text-paletteGray-10">
          {type}
        </div>
      </div>
    );
  };

  return (
    <div>
      {renderOpenButton()}

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => handlePopoverClose()}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        PaperProps={{
          classes: {
            root: "rounded-lg shadow-dropdown",
          },
        }}
      >
        {renderItems()}
      </Popover>
    </div>
  );
};

export default IncludingPopover;

import { useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import _ from "lodash";
import { ReactComponent as Close } from "assets/close.svg";
import { ReactComponent as PlusIcon } from "assets/plus.svg";
import { TagTypeModel } from "models/global.model";
import {
  InternalQAResultsModel,
  setInternalQaResults,
  statisticsStateTypes,
} from "store/statisticsSlice";
import { StatisticsScreenRouteModel } from "models/routes.model";
import { useParams } from "react-router-dom";
import { FiltersModel, SendFilterModel } from "models/filter.model";
import addSubsetToQuery from "helpers/functions/addSubsetToQuery";
import { fetchMediaObjectsCount } from "helpers/apis/mediaObjects";
import StackedBarChartComp, {
  StackedBarChartCompDataModel,
} from "Pages/Statistics/Charts/StackedBarChartComp";
import { fetchMediasCount } from "helpers/apis/medias";
import { fetchInstancesCount } from "helpers/apis/instances";
import { isAttributeAnnotationAttribute } from "helpers/functions/attributes/attributesHelpers";

interface Props {
  showInputs: boolean;
}

const InternalQAResults = ({ showInputs }: Props) => {
  const params: StatisticsScreenRouteModel = useParams();
  const dispatch = useAppDispatch();

  const allAttributes = useAppSelector((state) =>
    _.filter(state.datasetSlice.attributes, (attributeMeta) =>
      isAttributeAnnotationAttribute(attributeMeta?.attribute_group),
    ),
  );
  const fitlerData = useAppSelector((state) => state.filterDataSlice);

  const tags = useAppSelector((state) =>
    _.filter(
      state.datasetSlice.tags,
      (tag) => tag?.database_object_type === TagTypeModel.Attribute,
    ),
  );
  const chartData = useAppSelector(
    (state) =>
      state.statisticsSlice.charts.internal_qa_on_a_subset_of_the_results.data,
  );

  const mediatype = useAppSelector(
    (state) => state.datasetSlice.activeDataSet?.mediatype,
  );

  const [isLoading, setIsLoading] = useState(false);

  const attributeSelector = (index: number) => {
    return (
      <div
        key={`attribute${index}`}
        className="pl-3 py-1 pr-2 bg-paletteGray-3 flex items-center gap-x-3 rounded"
      >
        {`Attribute${index}`}
        <select
          className="w-full"
          value={chartData?.[index]?.id}
          onChange={(event) => {
            const key = event?.target?.value;
            getTotalCount(key).then((response) => {
              handleAttributeSelectOnChange(index, key, response?.total_count);
            });
          }}
          disabled={isLoading}
        >
          <option value="">--Select attribute--</option>
          {_.map(allAttributes, (attribute) => (
            <option key={attribute?.id} value={attribute?.id}>
              {attribute?.annotatable_type}: {attribute?.name}
            </option>
          ))}
        </select>

        {
          <Close
            width="12px"
            height="12px"
            onClick={() => {
              let newArray: any = [];
              _.map(chartData, (att, attIndex) => {
                if (attIndex !== index) {
                  newArray = [...newArray, att];
                }
              });
              dispatch(setInternalQaResults(newArray));
            }}
          />
        }
      </div>
    );
  };

  const addAttributeSelector = () => {
    return (
      <div
        className="button-layer px-4 py-2 gap-x-3 rounded"
        onClick={() => {
          dispatch(
            setInternalQaResults([
              ...chartData,
              { id: "", results: {}, totalCount: 1 },
            ]),
          );
        }}
      >
        <PlusIcon width="8px" height="8px" />
        {"Add another attribute"}
      </div>
    );
  };

  const getTotalCount = async (attributeID: string) => {
    const sendFilters: SendFilterModel[] = [
      {
        attribute: `attributes.${attributeID}.dataset_id`,
        query_operator: "==",
        value: params.dataset_id,
      },
    ];
    const body = addSubsetToQuery(params.subset_id, sendFilters);
    setIsLoading(true);
    const response = fetchMediaObjectsCount(params?.dataset_id, body, dispatch);
    setIsLoading(false);
    return response;
  };

  const handleAttributeSelectOnChange = (
    index: number,
    key: string,
    totalCount: number,
  ) => {
    // Add attribute id to index location
    let newArray: InternalQAResultsModel = [];
    _.map(chartData, (att, attIndex) => {
      if (attIndex !== index) {
        newArray = [...newArray, att];
      } else {
        newArray = [
          ...newArray,
          {
            id: key,
            results: {},
            totalCount: totalCount,
          },
        ];
      }
    });
    dispatch(setInternalQaResults(newArray));
  };

  const tagSelector = (
    attributeIndex: number,
    attributeID: string,
    tagState: string,
  ) => {
    let filtersOfAttribute: FiltersModel | null = {};
    const attributeType = _.find(allAttributes, {
      id: attributeID,
    })?.annotatable_type;
    if (attributeType === "Media") {
      filtersOfAttribute = fitlerData.mediaFilterData;
    } else if (attributeType === "MediaObject") {
      filtersOfAttribute = fitlerData.annotationFilterData;
    } else if (attributeType === "Instance") {
      filtersOfAttribute = fitlerData.instanceFilterData;
    }
    const tagsFilter = filtersOfAttribute?.[`attributes.${attributeID}.tags`];
    const tagsFilterIDs = _.map(tagsFilter?.buckets, (bucket) => bucket?.[0]);
    const tagsOnAttribute = _.filter(tags, (tag) =>
      _.includes(tagsFilterIDs, tag?.id),
    );
    return (
      <div
        key={`${attributeIndex}_tag_${tagState}`}
        className=" mt-2 pl-3 py-1 pr-2 bg-paletteGray-3 flex items-center justify-between gap-x-3 rounded"
      >
        {`${tagState} tag`}
        <select
          className="w-1/2"
          value={chartData?.[attributeIndex]?.results?.[tagState]?.id || ""}
          onChange={(event) => {
            const tagID = event?.target?.value;
            getTagCount(attributeID, tagID).then((response) => {
              if (!response) return;
              let newArray: InternalQAResultsModel = [];
              _.map(chartData, (att, attIndex) => {
                if (attIndex !== attributeIndex) {
                  newArray = [...newArray, att];
                } else {
                  newArray = [
                    ...newArray,
                    {
                      ...att,
                      results: {
                        ...att?.results,
                        [tagState]: { id: tagID, count: response?.total_count },
                      },
                    },
                  ];
                }
              });
              dispatch(setInternalQaResults(newArray));
            });
          }}
          disabled={isLoading || _.size(chartData?.[attributeIndex]?.id) === 0}
        >
          <option value="">--Select tag--</option>
          {_.map(tagsOnAttribute, (tag) => (
            <option key={tag?.id} value={tag?.id}>
              {tag?.name}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const getTagCount = async (attributeID: string, tagID: string) => {
    const sendFilters: SendFilterModel[] = [
      {
        attribute: `attributes.${attributeID}.tags`,
        query_operator: "in",
        value: [tagID],
      },
    ];
    const body = addSubsetToQuery(params.subset_id, sendFilters);
    // Get the attribute annotatable type
    const annotatableType = _.find(allAttributes, {
      id: attributeID,
    })?.annotatable_type;
    setIsLoading(true);
    // Get the count of medias if the attribute is an media attribute
    if (annotatableType === "Media") {
      const response = fetchMediasCount(params?.dataset_id, body, dispatch);
      setIsLoading(false);
      return response;
    }
    // Get the count of media objects if the attribute is an media object attribute
    else if (annotatableType === "MediaObject") {
      const response = fetchMediaObjectsCount(
        params?.dataset_id,
        body,
        dispatch,
      );
      setIsLoading(false);
      return response;
    }
    // Get the count of instances if the attribute is an instance attribute
    else if (annotatableType === "Instance") {
      const response = fetchInstancesCount(params?.dataset_id, body, dispatch);
      setIsLoading(false);
      return response;
    }
  };

  const renderCharts = () => {
    let data: StackedBarChartCompDataModel = [];

    let maxValue = 1;

    _.map(chartData, (attribute) => {
      let oneAttData = {
        name:
          _.find(allAttributes, (att) => att?.id === attribute?.id)?.name ||
          "Attribute",
        totalCount: attribute?.totalCount,
      };
      let localMax = 0;
      _.map(attribute?.results, (tagVal, tagKey) => {
        localMax += tagVal?.count;
        if (localMax > maxValue) {
          maxValue = localMax;
        }
        oneAttData = { ...oneAttData, [tagKey]: tagVal?.count };
      });
      data = [...data, oneAttData];
    });

    return (
      <StackedBarChartComp
        data={data}
        barDataKeys={["unambiguous", "ambiguous", "inexecutable", "incorrect"]}
        percentage={{ show: true, totalCountKey: "totalCount" }}
        yAxis={{ domain: [0, _.ceil(maxValue * 1.1)] }}
      />
    );
  };

  const chartID: keyof statisticsStateTypes["charts"] =
    "internal_qa_on_a_subset_of_the_results";

  return (
    <div className="w-full h-full flex p-3">
      {showInputs ? (
        <div
          className="w-[300px] h-full text-sm flex flex-col gap-y-3 overflow-y-auto animate-fade"
          style={{
            transition: "width 0.1s",
          }}
        >
          Current dataset
          {_.map(chartData, (attributeVal, attributeIndex) => (
            <div key={attributeIndex} className="pb-2 border-b-2">
              {attributeSelector(attributeIndex)}
              {_.map(
                ["unambiguous", "ambiguous", "inexecutable", "incorrect"],
                (tagState) =>
                  tagSelector(attributeIndex, attributeVal?.id, tagState),
              )}
            </div>
          ))}
          {addAttributeSelector()}
        </div>
      ) : (
        <div
          className="w-[0px]"
          style={{
            transition: "width 0.1s",
          }}
        ></div>
      )}
      <div
        id={chartID}
        className="h-full flex"
        style={{
          width: showInputs ? "calc(100% - 300px)" : "100%",
          transition: "width 0.1s",
        }}
      >
        {renderCharts()}
      </div>
    </div>
  );
};

export default InternalQAResults;

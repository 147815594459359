import { Dialog } from "@mui/material";
import DatasetSelectionTable from "components/Table/DatasetSelectionTable";
import _ from "lodash";
import {
  AttributeGroupEnum,
  AttributeMetadataModel,
} from "models/attributes.model";
import { DatasetModel } from "models/dataset.model";
import { SendFilterModel } from "models/filter.model";
import { AnnotatableEnum } from "models/global.model";
import { useState } from "react";
import {
  addNewTrainingSetTrainingAttribute,
  setNewTrainingSetDataset,
} from "store/aiManagerSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";

export interface AddAttributeDialog {
  show: boolean;
  attribute: AttributeMetadataModel | null;
  dataset: DatasetModel | null;
}

type Props = {
  addAttributeDialog: AddAttributeDialog;
  setAddAttributeDialog: (newState: AddAttributeDialog) => void;
};

const SelectAttributeDialog = ({
  addAttributeDialog,
  setAddAttributeDialog,
}: Props) => {
  const dispatch = useAppDispatch();

  const newTrainingSet = useAppSelector(
    (state) => state.aiManagerSlice.newTrainingSet,
  );

  const [isLoading, setIsLoading] = useState(false);
  const [localDataset, setLocalDataset] = useState<DatasetModel | null>(null);
  const [localAttribute, setLocalAttribute] =
    useState<AttributeMetadataModel | null>(null);

  const renderTable = () => {
    if (newTrainingSet.dataset === null) {
      return renderDatasetAndAttributeSelectionTable();
    }

    return renderAttributeSelectionTable();
  };

  const getAttributeFilters = (): SendFilterModel[] => {
    return [
      {
        attribute: "annotatable_type",
        query_operator: "==",
        value: AnnotatableEnum.MediaObject,
      },
      {
        attribute: "attribute_group",
        query_operator: "in",
        value: [AttributeGroupEnum.ANNOTATION_ATTRIBUTE],
      },
    ];
  };

  const renderDatasetAndAttributeSelectionTable = () => {
    return (
      <DatasetSelectionTable
        firstStep="dataset"
        secondStep="attribute"
        firstStepItem={localDataset}
        onFirstStepRowClick={(firstStepItem) =>
          setLocalDataset(firstStepItem as DatasetModel | null)
        }
        secondStepItem={localAttribute}
        onSecondStepRowClick={(secondStepItem) =>
          handleDatasetAndAttributeSelectionClick({
            clickedDataset: localDataset,
            clickedAttribute: secondStepItem as AttributeMetadataModel | null,
          })
        }
        attributesFilter={getAttributeFilters()}
      />
    );
  };

  const renderAttributeSelectionTable = () => {
    if (newTrainingSet.dataset === null) return null;

    return (
      <DatasetSelectionTable
        firstStep="attribute"
        firstStepItem={localAttribute}
        onFirstStepRowClick={(firstStepItem) =>
          handleAttributeSelectionClick(firstStepItem as AttributeMetadataModel)
        }
        selectedDatasetID={newTrainingSet.dataset?.id}
        firstStepSelectedIDs={_.map(
          newTrainingSet.training_attributes,
          (attribute) => attribute.attribute_id,
        )}
        attributesFilter={getAttributeFilters()}
      />
    );
  };

  const handleDatasetAndAttributeSelectionClick = (params: {
    clickedDataset: DatasetModel | null;
    clickedAttribute: AttributeMetadataModel | null;
  }) => {
    if (!params.clickedDataset || !params.clickedAttribute) return;

    setAddAttributeDialog({
      show: false,
      attribute: params?.clickedAttribute,
      dataset: params?.clickedDataset,
    });

    dispatch(
      addNewTrainingSetTrainingAttribute({
        attribute_id: params?.clickedAttribute?.id,
        dataset_id: params?.clickedDataset?.id,
      }),
    );

    if (newTrainingSet.dataset === null) {
      dispatch(setNewTrainingSetDataset(params?.clickedDataset));
    }
    setLocalDataset(null);
    setLocalAttribute(null);
  };

  const handleAttributeSelectionClick = (
    clickedAttribute: AttributeMetadataModel,
  ) => {
    if (clickedAttribute === null || newTrainingSet.dataset === null) return;

    setAddAttributeDialog({
      show: false,
      attribute: clickedAttribute,
      dataset: newTrainingSet.dataset,
    });

    dispatch(
      addNewTrainingSetTrainingAttribute({
        attribute_id: clickedAttribute?.id,
        dataset_id: newTrainingSet.dataset?.id,
      }),
    );
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={addAttributeDialog.show}
      onClose={() =>
        !isLoading &&
        setAddAttributeDialog({
          ...addAttributeDialog,
          show: false,
        })
      }
      PaperProps={{ style: { borderRadius: "16px" } }}
    >
      <div className="w-full h-[650px] pb-2">{renderTable()}</div>
    </Dialog>
  );
};

export default SelectAttributeDialog;

import TooltipTruncateEllipsis from "components/Tooltips/TooltipTruncateEllipsis";

interface Props {
  label: string | number;
  colorClass?: string;
}

const StatusLabel = ({ label, colorClass = "bg-paletteGray6" }: Props) => {
  return (
    <div className="flex items-center gap-x-2 text-paletteBlack-2 overflow-hidden">
      <div
        className={`w-[10px] h-[10px] rounded-full ${colorClass}`}
        data-test="Status_indicator"
      />
      <TooltipTruncateEllipsis className="pr-3" data-test="Status_value">
        {label}
      </TooltipTruncateEllipsis>
    </div>
  );
};

export default StatusLabel;

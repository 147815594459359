import { useEffect, useState } from "react";
import _ from "lodash";
import SortButton, { SortButtonModel } from "components/Buttons/SortButton";
import Loading from "components/UtilComponents/Loading";
import SearchBar from "components/Inputs/SearchBar";
import TableRow from "components/Table/DataTable/TableRow";

/**
 * @param field - The field to be displayed in the column
 * @param headerName - The name to be displayed in the header
 * @param span - The span of the column (in percentage)
 * @param sortable - Whether the column is sortable or not
 * @param className - The class name to be applied to the column
 * @param headerClassName - The class name to be applied to the header
 * @param cell - The cell to be displayed in the column
 */
export interface DataTableColumn {
  field: string;
  headerName: string;
  span?: number;
  sortable?: boolean;
  className?: string;
  headerClassName?: string;
  cell?: (row: unknown) => JSX.Element;
}

export interface DataTableExpandRow {
  key: string;
  text: string;
  onExpandClick?: (row: unknown) => void;
  body: (row: unknown) => JSX.Element;
}

/**
 * @param rows - The rows to be displayed in the table
 * @param columns - The columns to be displayed in the table
 * @param onRowClick - A callback function to be called when a row is clicked
 * @param defaultSortKey - The default sort key to be used when the table is first rendered
 * @param isLoading - Whether the table is loading or not
 * @param enableSearch - Whether the search bar is enabled or not
 * @param searchPlaceholder - The placeholder to be displayed in the search bar
 * @param searchValue - The value to search for (controlled search value)
 * @param extraKeysToSearch - The extra keys to search for in the rows
 */
interface Props {
  rows: Record<string, unknown>[];
  columns: DataTableColumn[];
  onRowClick?: (row: unknown) => void;
  expandRow?: DataTableExpandRow;
  defaultSort?: SortButtonModel;
  isLoading?: boolean;
  isDisabled?: boolean;
  enableSearch?: boolean;
  searchPlaceholder?: string;
  searchValue?: string;
  extraKeysToSearch?: string[];
  disableReason?: string;
  showHeader?: boolean;
  style?: {
    rowsYGap?: string;
    rowYPadding?: string;
  };
}

const DataTable = ({
  rows,
  columns,
  onRowClick,
  expandRow,
  defaultSort,
  isLoading,
  isDisabled,
  enableSearch,
  searchPlaceholder,
  searchValue,
  extraKeysToSearch,
  disableReason,
  showHeader = true,
  style,
}: Props) => {
  const [sortBy, setSortBy] = useState<SortButtonModel>({
    name: null,
    direction: null,
  });

  const [localSearchValue, setLocalSearchValue] = useState<string>(
    searchValue || "",
  );

  useEffect(() => {
    setLocalSearchValue(searchValue || "");
  }, [searchValue]);

  // Set the default sort
  useEffect(() => {
    defaultSort && setSortBy(defaultSort);
  }, []);

  // Render the header of the table (the column names) and the sort buttons
  const renderHeader = () => {
    return (
      <div className="w-full mb-4 flex py-2 px-4 text-paletteGray-9 bg-paletteGray-3 rounded-lg">
        {_.map(columns, (col, key) => {
          const columnSpan = col?.span || 100 / columns?.length;
          return (
            <div
              key={key}
              className={`flex items-center gap-x-2 span
                 ${col?.headerClassName}`}
              style={{ width: `${columnSpan}%` }}
              data-test={
                col?.headerName && col.headerName.trim() !== ""
                  ? `${col.headerName}_column`
                  : undefined
              }
            >
              {col?.headerName}
              {_.isUndefined(col?.sortable) && renderSortIcon(col)}
            </div>
          );
        })}
      </div>
    );
  };

  // Render the sort button
  const renderSortIcon = (col: DataTableColumn) => {
    return (
      <SortButton
        sortBy={{ name: col?.field, direction: sortBy?.direction }}
        setSortBy={setSortBy}
        selected={sortBy?.name === col?.field}
      />
    );
  };

  // Render the rows of the table (the data)
  const renderRows = () => {
    const filteredRows = _.filter(rows, (row) => {
      const nameMatch = _.includes(
        _.toLower(row?.name as string),
        _.toLower(localSearchValue),
      );

      const extraKeysToSearchMatch = _.some(extraKeysToSearch, (key) => {
        return _.includes(
          _.toLower(row[key] as string),
          _.toLower(localSearchValue),
        );
      });

      return nameMatch || extraKeysToSearchMatch;
    });

    let sortedRows = filteredRows;
    const sortName = sortBy?.name;
    if (!_.isNull(sortName) && !_.isNull(sortBy?.direction)) {
      sortedRows = _.orderBy(
        filteredRows,
        [
          (row) => {
            if (_.isString(row[sortName])) {
              return _.toLower(row[sortName] as string);
            }
            return row[sortName];
          },
        ],
        [sortBy?.direction],
      );
    }

    return _.map(sortedRows, (row) => {
      return (
        <TableRow
          row={row}
          columns={columns}
          onRowClick={onRowClick}
          expandRow={expandRow}
          disableReason={disableReason}
          style={style}
        />
      );
    });
  };

  const renderSearchBar = () => {
    if (enableSearch) {
      return (
        <div className="mb-6">
          <SearchBar
            searchValue={localSearchValue}
            setSearchValue={setLocalSearchValue}
            placeholder={searchPlaceholder}
            autoFocus={true}
          />
        </div>
      );
    }
  };

  const renderDisableTableLayer = () => {
    if (!isDisabled) return;

    return <div className="absolute inset-0 bg-black opacity-5 rounded-lg" />;
  };

  const renderLoadingOverlay = () => {
    if (!isLoading) return;

    return (
      <div className="absolute w-full inset-0 flex items-center justify-center bg-black bg-opacity-20 rounded-lg">
        <Loading />
      </div>
    );
  };
  // Render the body of the table
  const renderBody = () => {
    // If the table is loading, show the loading component
    if (_.isEmpty(rows) && isLoading) {
      return (
        <div className="w-full h-1/2">
          <Loading />
        </div>
      );
    }
    // If the table is not loading and there is no data, show the no data found message
    else if (_.isEmpty(rows)) {
      return (
        <div
          className="text-paletteGray-10 text-center"
          data-test="empty_table"
        >
          No data found
        </div>
      );
    }
    // If the table is not loading and there is data, show the table
    else {
      return (
        <div className="w-full h-full flex flex-col relative">
          {renderSearchBar()}
          {showHeader && renderHeader()}
          <div
            className={`
              flex-1 overflow-auto flex flex-col
              ${style?.rowsYGap || "gap-y-2"}
              `}
            data-test="table_grid"
          >
            {renderRows()}
          </div>
          {renderDisableTableLayer()}
          {renderLoadingOverlay()}
        </div>
      );
    }
  };

  return renderBody();
};

export default DataTable;

import _ from "lodash";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import chartsColor from "Pages/Statistics/chartsColors";

export type StackedBarChartCompDataModel = {
  [key: string]: string | number;
}[];

interface Props {
  data: StackedBarChartCompDataModel;
  barDataKeys: string[];
  width?: number;
  height?: number;
  yAxis?: {
    hide?: boolean;
    domain?: [number, number];
  };
  showLegend?: boolean;
  maxBarSize?: number | undefined;
  percentage?: {
    show: boolean;
    totalCountKey: string;
  };
}

const StackedBarChartComp = ({
  data,
  barDataKeys,
  width = 100,
  height = 100,
  yAxis,
  showLegend = true,
  maxBarSize = 200,
  percentage,
}: Props) => {
  const BarShape = (props: any) => {
    const { fill, x, y, width, height, value } = props;
    const differenceValue = value?.[1] - value?.[0];
    let label = `${differenceValue}`;
    // Append percentage if it's used
    if (
      !_.isUndefined(percentage) &&
      percentage?.show &&
      props?.[percentage?.totalCountKey]
    ) {
      const FNPercentage = _.round(
        (differenceValue * 100) / (props?.[percentage?.totalCountKey] || 1),
        2,
      );
      label = `${differenceValue} (${FNPercentage}%)`;
    }
    return (
      <svg x={x} y={y} width={width} height={height} fill={fill}>
        <g>
          <rect width={width} height={height} rx="5" ry="5" />
          <text
            x="50%"
            y="50%"
            dominantBaseline="middle"
            textAnchor="middle"
            fill="white"
          >
            {label?.toString()}
          </text>
        </g>
      </svg>
    );
  };

  const legendFormatter = (value: string) => {
    // Customize the legend text style here
    return (
      <span style={{ color: "#060606", padding: "8px 16px 8px 4px" }}>
        {value}
      </span>
    );
  };

  return (
    <ResponsiveContainer width={`${width}%`} height={`${height}%`}>
      <BarChart data={data} barCategoryGap="10">
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey="name" />
        <YAxis domain={yAxis?.domain} hide={yAxis?.hide} axisLine={false} />
        <Tooltip />
        {showLegend && <Legend formatter={legendFormatter} />}

        {_.map(barDataKeys, (dataKey, index) => (
          <Bar
            key={`bar_${dataKey}`}
            dataKey={dataKey}
            shape={BarShape}
            stackId="a"
            fill={chartsColor[index] || "#8884d8"}
            maxBarSize={maxBarSize}
          >
            {_.map(data, (entry) => (
              <Cell
                key={`cell_${index}`}
                fill={chartsColor[index] || "#8884d8"}
              />
            ))}
          </Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default StackedBarChartComp;

import {
  APIFetchAxios,
  APIPostWithBodyAxios,
  APIPutWithBodyAxios,
} from "routes/Auth";
import { CroppingParamsModel, TilingParamsModel } from "models/tiling.model";
import { AnonymizationModel } from "models/transformation.model";
import { Dispatch } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import snackbarHelper from "helpers/snackbarHelperFn";
import _ from "lodash";

export const sendTilingJobRequest = async (
  params: { datasetID: string; subsetID: string },
  body: { tilingParams: TilingParamsModel },
  dispatch: Dispatch,
) => {
  const { datasetID, subsetID } = params;
  const { tilingParams } = body;

  snackbarHelper("Creating transformation job. Please wait.", "info");

  const requestBody = {
    ...tilingParams,
    tiles_percentage_overlap: tilingParams?.tiles_percentage_overlap / 100,
  };

  return await APIPostWithBodyAxios(
    `/datasets/${datasetID}/subsets/${subsetID}/tiles`,
    requestBody,
  )
    .then(() => {
      snackbarHelper("Transformation job was created!");
    })
    .catch((error: AxiosError<any>) => {
      const errorDetail = error?.response?.data?.detail;
      snackbarHelper(
        `Creating transformation job failed: ${errorDetail}` ||
          "Something went wrong! Creating transformation job failed.",
        "error",
      );
    });
};

export const sendCropRequest = async (
  params: { datasetID: string; subsetID: string },
  body: { croppingParams: CroppingParamsModel },
  dispatch: Dispatch,
) => {
  const { datasetID, subsetID } = params;
  const { croppingParams } = body;
  await APIPutWithBodyAxios(
    `/crops?dataset_id=${datasetID}&subset_id=${subsetID}`,
    { ...croppingParams },
  )
    .then(() => {
      snackbarHelper("Cropping has been triggered!");
      return Promise.resolve();
    })
    .catch((error: AxiosError<any>) => {
      const errorDetail = error?.response?.data?.detail;
      snackbarHelper(
        `Cropping job failed: ${errorDetail}` ||
          "Something went wrong! Cropping job failed.",
        "error",
      );
      return Promise.reject();
    });
};

export const postAnonymization = async (
  params: { datasetID: string; subsetID: string },
  body: { anonymizationBody: AnonymizationModel; name: string },
  dispatch: Dispatch,
) => {
  const { datasetID, subsetID } = params;
  const { anonymizationBody } = body;
  let bodyObject: any = {
    name: body?.name,
    params: {
      ...anonymizationBody,
    },
  };

  if (!_.isUndefined(subsetID) && subsetID?.length > 0) {
    bodyObject = { ...bodyObject, subset_id: subsetID };
  }
  await APIPostWithBodyAxios(`/datasets/${datasetID}/anonymization`, bodyObject)
    .then(() => {
      snackbarHelper("Anonymization has been triggered!");
      return Promise.resolve();
    })
    .catch((error: AxiosError<any>) => {
      const errorDetail = error?.response?.data?.detail;
      snackbarHelper(
        `Anonymization job failed: ${errorDetail}` ||
          "Something went wrong! Anonymization job failed.",
        "error",
      );
      return Promise.reject();
    });
};

/**
 * An endpoint to trigger a Grafana export job
 * @param datasetID
 * @param subsetID
 * @param dispatch
 * @returns {Promise<void>}
 */
export const postGrafanaExport = async (
  params: { datasetID: string },
  body: { subsetID: string },
  dispatch: Dispatch,
) => {
  const { datasetID } = params;

  const bodyObject: any = body?.subsetID || null;

  await APIPostWithBodyAxios(
    `/datasets/${datasetID}/insights:export`,
    bodyObject,
  )
    .then(() => {
      snackbarHelper("Grafana export has been triggered!");
      return Promise.resolve();
    })
    .catch((error: AxiosError<any>) => {
      const errorDetail = error?.response?.data?.detail;
      snackbarHelper(
        `Grafana export job failed: ${errorDetail}` ||
          "Something went wrong! Grafana export job failed.",
        "error",
      );
      return Promise.reject();
    });
};

export const postThreeDDataLVG = async (
  params: { datasetID: string; subsetID: string },
  body: { name: string },
  dispatch: Dispatch,
) => {
  const { datasetID, subsetID } = params;

  if (body.name.length < 3) {
    snackbarHelper("Name must be at least 3 characters long!", "error");
    return;
  }

  let bodyObject: any = {
    name: body?.name,
  };

  if (subsetID?.length > 0) {
    bodyObject = { ...bodyObject, subset_id: subsetID };
  }
  await APIPostWithBodyAxios(
    `/datasets/${datasetID}/lidar_video_generation`,
    bodyObject,
  )
    .then(() => {
      snackbarHelper("Lidar video generation has been triggered!");
      return Promise.resolve();
    })
    .catch((error: AxiosError<any>) => {
      const errorDetail = error?.response?.data?.detail;
      snackbarHelper(
        `Lidar video generation job failed: ${errorDetail}` ||
          "Something went wrong! Lidar video generation job failed.",
        "error",
      );
      return Promise.reject();
    });
};

export const fetchTransformationJobs = async (dataset_id: string) => {
  return await APIFetchAxios(`/datasets/${dataset_id}/jobs`);
};

export const importJobResults = async (dataset_id: string, jobID: string) => {
  return await APIPostWithBodyAxios(
    `/datasets/${dataset_id}/job:import?job_id=${jobID}`,
    {},
  );
};

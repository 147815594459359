import snackbarHelper from "helpers/snackbarHelperFn";
import { APIFetchAxios } from "routes/Auth";
import convertActiveFilterToSendFilter from "helpers/functions/filters/convertActiveFilterToSendFilter";
import _ from "lodash";
import {
  ExplorationAPIRequestModel,
  MediaModel,
  SortingRequestModel,
} from "models/exploration.model";
import { ActiveFiltersModel, SendFilterModel } from "models/filter.model";
import { MetaDataCountModel } from "models/metaData.model";
import {
  explorationMediaStateTypes,
  fetchMediaData,
  updateMediaExplorationHasMore,
  updateMediaExplorationSkip,
} from "store/explorationMediaSlice";
import { Dispatch } from "@reduxjs/toolkit";

const scrollLoadAmount = Number(
  process.env.REACT_APP_SCROLL_LOAD_AMOUNT ??
    alert("Config value 'scroll load amount' is not defined"),
);

export const fetchMediasCount = async (
  datasetID: string,
  body: SendFilterModel[],
  dispatch?: Dispatch,
): Promise<MetaDataCountModel> => {
  const response = await APIFetchAxios(`/datasets/${datasetID}/medias:count`, {
    query: body,
  }).catch((error) => {
    let msg = error?.details;
    if (_.isUndefined(error?.details)) {
      msg = "Something went wrong! Fetching media count failed!";
    }
    dispatch && snackbarHelper(msg, "error");
  });
  return response?.data;
};

export const fetchMedias = async (
  body: ExplorationAPIRequestModel,
  dispatch?: Dispatch,
): Promise<MediaModel[]> => {
  if (_.isUndefined(body?.projection)) {
    body = { ...body, projection: { media_objects: false } };
  }
  const response = await APIFetchAxios(
    `/datasets/${body.dataset_id}/medias`,
    body,
  ).catch((error) => {
    let msg = error?.details;
    if (_.isUndefined(error?.details)) {
      msg = "Something went wrong! Fetching medias failed!";
    }
    dispatch && snackbarHelper(msg, "error");
  });
  return response?.data;
};

export const fetchMediaDataScroll = (
  dispatch: any,
  explorationMediaData: explorationMediaStateTypes,
  totalCount: number,
  datasetID: string,
  subsetID: string,
  mediaFilterData: ActiveFiltersModel,
  mediaSortData: SortingRequestModel[],
) => {
  if (
    totalCount > 0 &&
    explorationMediaData?.skip + scrollLoadAmount >= totalCount
  ) {
    dispatch(updateMediaExplorationHasMore(false));
    return;
  } else {
    const APIBody: ExplorationAPIRequestModel = {
      dataset_id: datasetID,
      limit: scrollLoadAmount,
      skip: explorationMediaData?.skip + scrollLoadAmount,
      query: convertActiveFilterToSendFilter(mediaFilterData),
      sort: mediaSortData,
    };

    dispatch(
      fetchMediaData({
        runId: datasetID,
        reqBody: APIBody,
        subSetId: subsetID,
        skipLoading: true,
      }),
    );
    dispatch(
      updateMediaExplorationSkip(explorationMediaData?.skip + scrollLoadAmount),
    );
  }
};

// Post request to one media
export const fetchOneMediaAPI = async (
  mediaID: string,
  datasetID: string,
): Promise<MediaModel> => {
  const response = await APIFetchAxios(
    `/datasets/${datasetID}/medias/${mediaID}`,
    { projection: { media_objects: false } },
  );
  return response?.data;
};

import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import _ from "lodash";
import {
  statisticsStateTypes,
  setCredibilityPerAttribute,
  CredibilityPerAttributeModel,
} from "store/statisticsSlice";
import { StatisticsScreenRouteModel } from "models/routes.model";
import { useParams } from "react-router-dom";
import { SendFilterModel } from "models/filter.model";
import addSubsetToQuery from "helpers/functions/addSubsetToQuery";
import { fetchMediaObjectsCount } from "helpers/apis/mediaObjects";
import { fetchMediasCount } from "helpers/apis/medias";
import BarChartComp, {
  BarChartCompDataModel,
} from "Pages/Statistics/Charts/BarChartComp";
import { AnnotatableEnum } from "models/global.model";
import { fetchInstancesCount } from "helpers/apis/instances";
import chartsColor from "Pages/Statistics/chartsColors";
import { isAttributeAnnotationAttribute } from "helpers/functions/attributes/attributesHelpers";

interface Props {
  showInputs: boolean;
}

const CredibilityPerAttribute = ({ showInputs }: Props) => {
  const params: StatisticsScreenRouteModel = useParams();
  const dispatch = useAppDispatch();

  const allAttributes = useAppSelector((state) =>
    _.filter(state.datasetSlice.attributes, (attributeMeta) =>
      isAttributeAnnotationAttribute(attributeMeta?.attribute_group),
    ),
  );
  const dataFilters = {
    ...useAppSelector((state) => state.filterDataSlice.annotationFilterData),
    ...useAppSelector((state) => state.filterDataSlice.instanceFilterData),
    ...useAppSelector((state) => state.filterDataSlice.mediaFilterData),
  };

  const chartData = useAppSelector(
    (state) => state.statisticsSlice.charts.credibility_per_attribute,
  );
  const [selectedAttribute, setSelectedAttribute] = useState<{
    id: string;
    annotatableType: string;
    totalCount: number;
  }>({
    id: chartData?.id,
    annotatableType: chartData?.annotatableType,
    totalCount: chartData?.totalCount,
  });
  const [numberOfBuckets, setNumberOfBuckets] = useState<number>(
    chartData?.numBuckets,
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const key = selectedAttribute?.id;
    const annotatableType = selectedAttribute?.annotatableType;
    const totalCount = selectedAttribute.totalCount;
    let credibilityBuckets: CredibilityPerAttributeModel = Array.from(
      { length: numberOfBuckets + 1 },
      (_, i) => {
        return Object.assign({
          name: (i / numberOfBuckets).toFixed(2).toString(),
          count: 0,
          color: chartsColor[2],
        });
      },
    );
    if (key !== "") {
      const credibilityHistogram =
        dataFilters?.[`attributes.${key}.credibility`]?.histogram;

      _.map(credibilityHistogram, (cred) => {
        if (!(cred?.lower_bound < 0 || cred?.lower_bound > 1)) {
          const bucketIndex = Math.round(
            parseFloat(cred?.lower_bound?.toString()) / (1 / numberOfBuckets),
          );
          credibilityBuckets[bucketIndex] = {
            ...credibilityBuckets[bucketIndex],
            count: credibilityBuckets[bucketIndex]?.count + cred?.freq,
          };
        }
      });

      const valuesCount = _.sumBy(
        credibilityBuckets,
        (bucket) => bucket?.count,
      );
      const missingValuesCount = totalCount - valuesCount;

      if (missingValuesCount > 0) {
        credibilityBuckets.push({
          name: "Insufficient\nData",
          count: missingValuesCount,
          color: chartsColor[1],
        });
      }
    } else {
      credibilityBuckets = [];
    }
    dispatch(
      setCredibilityPerAttribute({
        ...chartData,
        id: key,
        annotatableType: annotatableType,
        numBuckets: numberOfBuckets,
        totalCount: totalCount,
        data: credibilityBuckets,
      }),
    );
  }, [selectedAttribute, numberOfBuckets]);

  const getTotalCount = async (
    attributeID: string,
    annotatableType: string,
  ) => {
    const sendFilters: SendFilterModel[] = [
      {
        attribute: `attributes.${attributeID}.dataset_id`,
        query_operator: "==",
        value: params.dataset_id,
      },
    ];
    const body = addSubsetToQuery(params.subset_id, sendFilters);
    setIsLoading(true);
    let response;
    if (annotatableType === AnnotatableEnum.MediaObject) {
      response = fetchMediaObjectsCount(params?.dataset_id, body, dispatch);
    } else if (annotatableType === AnnotatableEnum.Media) {
      response = fetchMediasCount(params?.dataset_id, body, dispatch);
    } else if (annotatableType === AnnotatableEnum.Instance) {
      response = fetchInstancesCount(params?.dataset_id, body, dispatch);
    } else {
      response = null;
    }
    setIsLoading(false);
    return response;
  };

  const attributeSelector = () => {
    return (
      <div
        key={"attribute"}
        className="pl-3 py-1 pr-2 bg-paletteGray-3 flex items-center gap-x-3 rounded"
      >
        {"Attribute"}
        <select
          className="w-full"
          value={selectedAttribute?.id}
          onChange={(event) => {
            const key = event?.target?.value;
            const annotatableType =
              _.find(allAttributes, { id: key })?.annotatable_type || "";
            if (key === "") {
              setSelectedAttribute({
                id: "",
                annotatableType: "",
                totalCount: 0,
              });
            }
            getTotalCount(key, annotatableType).then((response) => {
              setSelectedAttribute({
                id: key,
                annotatableType: annotatableType,
                totalCount: response?.total_count || 0,
              });
            });
          }}
          disabled={isLoading}
        >
          <option value="">--Select attribute--</option>
          {_.map(allAttributes, (attribute) => (
            <option key={attribute?.id} value={attribute?.id}>
              {attribute?.annotatable_type}: {attribute?.name}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const bucketSelector = () => {
    return (
      <div
        key={"num_buckets"}
        className="pl-3 py-1 pr-2 bg-paletteGray-3 flex items-center gap-x-3 rounded"
      >
        {"Buckets"}
        <select
          className="w-full"
          value={numberOfBuckets}
          onChange={(event) => {
            const key = event?.target?.value;
            setNumberOfBuckets(parseInt(key));
          }}
          disabled={isLoading}
        >
          {_.map([5, 10, 20, 50, 100], (num_buckets) => (
            <option key={num_buckets} value={num_buckets}>
              {num_buckets}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const renderCharts = () => {
    const data: BarChartCompDataModel = chartData?.data;
    const totalCount = chartData?.totalCount;
    return (
      <BarChartComp
        data={data}
        width={95}
        percentage={{
          show: numberOfBuckets <= 5,
          totalCount: totalCount,
        }}
        showLegend={false}
        showLabels={numberOfBuckets <= 10}
      />
    );
  };

  const chartID: keyof statisticsStateTypes["charts"] =
    "credibility_per_attribute";

  return (
    <div className="w-full h-full flex p-3">
      {showInputs ? (
        <div
          className="w-[300px] h-full text-sm flex flex-col gap-y-3 overflow-y-auto animate-fade"
          style={{
            transition: "width 0.1s",
          }}
        >
          Current dataset
          {attributeSelector()}
          {selectedAttribute?.id != ""
            ? `Total ${selectedAttribute?.annotatableType}s: ${selectedAttribute?.totalCount}`
            : null}
          {bucketSelector()}
        </div>
      ) : (
        <div
          className="w-[0px]"
          style={{
            transition: "width 0.1s",
          }}
        ></div>
      )}
      <div
        id={chartID}
        className="h-full flex"
        style={{
          width: showInputs ? "calc(100% - 300px)" : "100%",
          transition: "width 0.1s",
        }}
      >
        {renderCharts()}
      </div>
    </div>
  );
};

export default CredibilityPerAttribute;

import _ from "lodash";
import { AnnotatableEnum, TagModel, TagTypeModel } from "models/global.model";
import { AttributesModel } from "models/attributes.model";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useParams } from "react-router-dom";
import { MediaDetailsScreenRouteModel } from "models/routes.model";
import TagsSelector from "components/Inputs/TagsSelector";
import { postTagAssignOrUnassign } from "helpers/apis/tags";
import { fetchOneMedia } from "store/explorationMediaSlice";
import { fetchOneMediaObject } from "store/explorationMediaObjectsSlice";
import { useState } from "react";
import { fetchTags } from "store/datasetSlice";
import { fetchOneInstance } from "store/explorationInstancesSlice";

interface Props {
  annotationAttributes: AttributesModel;
  selectedItemID: string;
  isTagsExpanded?: boolean;
  setIsTagsExpanded?: (newState: boolean) => void;
  showMediaOrMediaObject?: AnnotatableEnum;
  // The following props are used for tagging media attribute
  //  while in media object details screen
  fetchMediaAndMediaObjects?: (mediaID: string) => void;
  currentMediaID?: string;
  // The following props are used for tagging media object attribute
  //  while in media details screen
  fetchMediaObjectsByMediaID?: () => void;
}

const AttributesTags = ({
  annotationAttributes,
  selectedItemID,
  showMediaOrMediaObject,
  fetchMediaAndMediaObjects,
  currentMediaID,
  fetchMediaObjectsByMediaID,
}: Props) => {
  const dispatch = useAppDispatch();
  const params: MediaDetailsScreenRouteModel = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const selectedView = useAppSelector((state) => state.appSlice.selectedView);
  const tags = useAppSelector((state) =>
    _.keyBy(
      _.filter(
        state.datasetSlice?.tags,
        (tag) => tag?.database_object_type === TagTypeModel.Attribute,
      ),
      "name",
    ),
  );

  const handleAssignAndUnAssign = (
    tagID: string,
    operation: "assign" | "unassign",
  ) => {
    return postTagAssignOrUnassign(
      operation,
      {
        datasetId: params?.id,
        database_object_type: TagTypeModel.Attribute,
        database_object_id: annotationAttributes?.id,
        parent_annotatable_id: annotationAttributes?.annotatable_id,
        tagId: tagID,
      },
      setIsLoading,
    );
  };

  const handleAfterAddOrRemove = () => {
    switch (selectedView) {
      case AnnotatableEnum.Media:
        handleMediaAttributeTagging();
        break;
      case AnnotatableEnum.MediaObject:
        handleMediaObjectAttributeTagging();
        break;
      case AnnotatableEnum.Instance:
        dispatch(
          fetchOneInstance({
            query: { instanceId: selectedItemID, datasetId: params?.id },
            options: { replaceCurrentInstance: true },
          }),
        );
        break;
      default:
        console.error("Unknown view");
        break;
    }
  };

  const handleMediaAttributeTagging = () => {
    // The attribute belong to an media
    if (showMediaOrMediaObject === AnnotatableEnum.Media) {
      dispatch(
        fetchOneMedia({
          query: { mediaId: selectedItemID, datasetId: params?.id },
          options: { replaceCurrentMedia: true },
        }),
      );
    }
    // The attribute belong to an media object
    if (showMediaOrMediaObject === AnnotatableEnum.MediaObject) {
      if (fetchMediaObjectsByMediaID) {
        fetchMediaObjectsByMediaID();
      }
      dispatch(
        fetchOneMediaObject({
          query: { mediaObjectId: selectedItemID, datasetId: params?.id },
        }),
      );
    }
  };

  const handleMediaObjectAttributeTagging = () => {
    // The attribute belong to an media object
    if (showMediaOrMediaObject === AnnotatableEnum.MediaObject) {
      dispatch(
        fetchOneMediaObject({
          query: { mediaObjectId: selectedItemID, datasetId: params?.id },
          options: { replaceCurrentMediaObject: true },
        }),
      );
    }
    // The attribute belong to an media
    else if (showMediaOrMediaObject === AnnotatableEnum.Media) {
      if (fetchMediaAndMediaObjects) {
        fetchMediaAndMediaObjects(currentMediaID || "");
      }
      dispatch(
        fetchOneMedia({
          query: { mediaId: selectedItemID, datasetId: params?.id },
        }),
      );
    }
  };

  const tagSelector = () => (
    <TagsSelector
      key={annotationAttributes?.id as string}
      items={_.pickBy(tags, (tag: TagModel) => {
        return tag?.database_object_type === TagTypeModel.Attribute;
      })}
      selectedItems={_.pickBy(tags, (tag: TagModel) => {
        return _.includes(annotationAttributes?.tags, tag?.id);
      })}
      handleAddTag={(tag) =>
        handleAssignAndUnAssign(tag?.id, "assign").then(() =>
          handleAfterAddOrRemove(),
        )
      }
      handleRemoveTag={(tag) =>
        handleAssignAndUnAssign(tag?.id, "unassign").then(() =>
          handleAfterAddOrRemove(),
        )
      }
      enableCreateNewTag={{
        type: TagTypeModel.Attribute,
        datasetID: params?.id,
        onAddNewItemSuccess: (tag) => {
          handleAssignAndUnAssign(tag, "assign").then(() => {
            handleAfterAddOrRemove();
            dispatch(
              fetchTags({
                query: {
                  parentDataSetID: params?.id,
                },
              }),
            );
          });
        },
      }}
      disabled={isLoading}
    />
  );
  return <div>{tagSelector()}</div>;
};

export default AttributesTags;

import { useAppSelector } from "store/hooks";
import _ from "lodash";
import createFilterString from "helpers/functions/createFilterString";
import { determineActiveFilterKeyFromParam } from "store/filterDataSlice";

interface Props {
  shouldWhiteSpaceNoWrap?: boolean;
}

const FiltersList = ({ shouldWhiteSpaceNoWrap }: Props) => {
  const selectedView = useAppSelector((state) => state.appSlice.selectedView);
  const dataSetData = useAppSelector((state) => state.datasetSlice);
  const filterData = useAppSelector((state) => state.filterDataSlice);

  const renderFilters = () => {
    const filters =
      filterData?.[determineActiveFilterKeyFromParam(selectedView)];

    if (_.isEmpty(filters)) {
      return <div className="pl-2 text-sm">No filters applied</div>;
    }

    return _.map(filters, (filter, key) => {
      if (!_.isEmpty(filter?.value) || !_.isEmpty(filter?.categories_value)) {
        return (
          <div
            className={`button-layer py-[6.5px] text-sm 
                ${shouldWhiteSpaceNoWrap ? "whitespace-nowrap" : ""}
                  ${filter?.is_not && "bg-paletteRed-lighter"}
                `}
            key={key}
            data-test="filter_list_item_exploration"
          >
            {createFilterString(
              filter,
              dataSetData?.attributes,
              dataSetData?.subSets,
              dataSetData?.tags || [],
            )}
          </div>
        );
      }
    });
  };
  return <>{renderFilters()}</>;
};

export default FiltersList;

import { useState } from "react";
import { useAppSelector } from "store/hooks";
import SelectReferenceDataDialog from "components/Dialogs/SelectReferenceDataDialog";
import { Tooltip } from "@mui/material";

const SelectReferenceData = () => {
  const pipelineDesignerData = useAppSelector(
    (state) => state.pipelineDesignerSlice,
  );

  const [showSelectDataDialog, setShowSelectDataDialog] = useState(false);

  const isDataSelected = pipelineDesignerData.dataset !== null;

  const wpRd = pipelineDesignerData.configurationsForm?.wp_rd_tasks
    ?.value as number;
  const isWpRdValid = wpRd > 0;

  const isDisabled = !isDataSelected || !isWpRdValid;

  const renderInfoText = () => {
    return <div className=" text-paletteBlack-2">Reference data</div>;
  };

  // Render select reference data button or selected reference data text
  const renderSelectedReferenceData = () => {
    if (pipelineDesignerData.referenceData === null) {
      return renderSelectReferenceData();
    }

    return renderSelectedReferenceDataText();
  };

  // Render selected reference data text
  const renderSelectedReferenceDataText = () => {
    return (
      <div
        className="cursor-pointer hover:underline"
        onClick={() => setShowSelectDataDialog(true)}
      >
        <div className="flex justify-end font-medium">
          {pipelineDesignerData.referenceData?.name}
        </div>
      </div>
    );
  };

  // Render select reference data button or button with tooltip
  const renderSelectReferenceData = () => {
    if (isDisabled) {
      return renderButtonWithTooltip();
    }
    return renderSelectDataButton();
  };

  // Render select data button
  const renderSelectDataButton = () => {
    return (
      <button
        className="button-layer"
        onClick={() => setShowSelectDataDialog(true)}
        tabIndex={0}
      >
        Select data
        {pipelineDesignerData.validation.isReferenceDataValid !== true && (
          <span className="text-paletteRed">*</span>
        )}
      </button>
    );
  };

  // Render button with tooltip
  const renderButtonWithTooltip = () => {
    const isReferenceDataValid =
      pipelineDesignerData.validation.isReferenceDataValid;
    return (
      <Tooltip placement="left" title={renderTooltipTitle()}>
        <span>
          <button
            className="button-layer"
            data-test="reference_data_pipeline_designer"
            onClick={() => setShowSelectDataDialog(true)}
            disabled={isDisabled}
          >
            Select data
            {isReferenceDataValid === false && (
              <span className="text-paletteRed">*</span>
            )}
          </button>
        </span>
      </Tooltip>
    );
  };

  // Render tooltip title
  const renderTooltipTitle = () => {
    return (
      <div className=" text-sm">
        <div className="text-sm">
          {!pipelineDesignerData.dataset &&
            "- Select data first to select reference data"}
        </div>
        <div className="text-sm">
          {pipelineDesignerData.configurationsForm?.wp_rd_tasks?.value === 0 &&
            `- Reference data tasks" is set to 0 in the configuration, so
            reference data selection is not available.`}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="flex items-center justify-between">
        {renderInfoText()}
        {renderSelectedReferenceData()}
      </div>

      {showSelectDataDialog && (
        <SelectReferenceDataDialog
          openDialog={showSelectDataDialog}
          setOpenDialog={setShowSelectDataDialog}
        />
      )}
    </>
  );
};

export default SelectReferenceData;

import { useState } from "react";
import StatisticsScreenHeader from "Pages/Statistics/Header";
import ChartsMenu from "Pages/Statistics/ChartsMenu";
import ChartsControls from "Pages/Statistics/ChartsControls";

import ResultsPerAttributeChart from "Pages/Statistics/ResultsPerAttribute";
import { statisticsStateTypes } from "store/statisticsSlice";
import CredibilityPerAttribute from "Pages/Statistics/CredibilityPerAttribute";
import ObjectsPerMedia from "Pages/Statistics/ObjectsPerMedia";
import ResultsSummary from "Pages/Statistics/ResultsSummary";
import InternalQAResults from "Pages/Statistics/InternalQAResults";
import MediasAndInstances from "Pages/Statistics/MediasAndObjects";

const StatisticsScreen = () => {
  const [selectedChart, setSelectedChart] = useState<
    keyof statisticsStateTypes["charts"] | null
  >(null);
  const [showInputs, setShowInputs] = useState(true);

  const renderSelectedChart = () => {
    switch (selectedChart) {
      case "credibility_per_attribute":
        return <CredibilityPerAttribute showInputs={showInputs} />;
      case "medias_and_objects":
        return <MediasAndInstances showInputs={false} />;
      case "objects_per_media":
        return <ObjectsPerMedia showInputs={false} />;
      case "qa_results_per_attribute":
        return <ResultsPerAttributeChart showInputs={showInputs} />;
      case "qa_results_summary":
        return <ResultsSummary showInputs={showInputs} />;
      case "internal_qa_on_a_subset_of_the_results":
        return <InternalQAResults showInputs={showInputs} />;
    }
  };

  return (
    <div className="w-full h-full bg-white">
      <StatisticsScreenHeader />
      <div className="flex w-full" style={{ height: "calc(100% - 64px)" }}>
        <div className="w-[290px] h-full bg-white">
          <ChartsMenu
            selectedChart={selectedChart}
            setSelectedChart={setSelectedChart}
          />
        </div>
        <div className="h-full" style={{ width: "calc(100% - 290px)" }}>
          <div className="w-full h-[68px]">
            <ChartsControls
              selectedChart={selectedChart}
              showInputs={showInputs}
              setShowInputs={setShowInputs}
            />
          </div>
          <div className="w-full" style={{ height: "calc(100% - 68px)" }}>
            {renderSelectedChart()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatisticsScreen;

import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import TextInput from "components/Inputs/Form/TextInput";
import SavePipelinePopover from "Pages/PipelineDesigner/SavePipelinePopover";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { getPipelineDesignerPageRoute } from "routes/routesHelper";

import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  resetPipelineDesignerSlice,
  setPipelineName,
} from "store/pipelineDesignerSlice";
import NotificationButton from "components/Buttons/NotificationButton";

const PipelineDesignerHeader = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const pipelineDesignerData = useAppSelector(
    (state) => state.pipelineDesignerSlice,
  );

  const [isCreateNewPipelineDialogOpen, setIsCreateNewPipelineDialogOpen] =
    useState(false);

  const renderPipelineName = () => {
    const pipelineName = pipelineDesignerData.pipelineName;
    return (
      <TextInput
        field={{
          ...pipelineName,
          style: {
            ...pipelineName.style,
            field:
              "py-1 !bg-transparent border-none hover:!bg-paletteGray-3 focus:!bg-paletteGray-3 w-[270px] text-center",
            wrapper: "h-[48px] w-[270px] text-center",
          },
        }}
        value={pipelineName.value}
        handleOnChange={(event, field, isErrored, error) => {
          dispatch(
            setPipelineName({
              ...pipelineName,
              value: event.target.value,
              isErrored: isErrored || false,
              error: error || null,
            }),
          );
        }}
        autoFocus={true}
      />
    );
  };

  const renderCreateNewPipelineButton = () => {
    return (
      <button
        className="button-layer"
        onClick={() => setIsCreateNewPipelineDialogOpen(true)}
      >
        Create new pipeline
      </button>
    );
  };

  return (
    <div className="w-full h-[64px] flex justify-between items-center">
      <div>
        <div className="font-medium">Pipeline Designer</div>
        <div className="text-sm text-paletteGray-11">HARI X-Ray</div>
      </div>
      {renderPipelineName()}
      <div className="flex gap-x-2 items-center">
        {renderCreateNewPipelineButton()}
        <SavePipelinePopover />
        <div className="h-6 w-px bg-paletteGray-4"></div>
        <NotificationButton />
      </div>
      {isCreateNewPipelineDialogOpen && (
        <ConfirmDialog
          isConfirmDialogOpen={isCreateNewPipelineDialogOpen}
          setIsConfirmDialogOpen={setIsCreateNewPipelineDialogOpen}
          title="Create new pipeline"
          confirmButtonText="Confirm"
          text="Are you sure you want to create a new pipeline?"
          handleOnSuccess={() => {
            dispatch(resetPipelineDesignerSlice());
            setIsCreateNewPipelineDialogOpen(false);
            history.push(getPipelineDesignerPageRoute({ pipeline_id: "new" }));
          }}
        />
      )}
    </div>
  );
};

export default PipelineDesignerHeader;

import { useAppSelector } from "store/hooks";
import { Mediatype } from "models/dataset.model";
import { TagModel, TagTypeModel } from "models/global.model";
import _ from "lodash";
import { TagsManagerScreenTabs } from "Pages/TagsManager/index";
import StandardTabs from "components/Tabs/StandardTabs";

type Props = {
  tags: TagModel[] | null;
  selectedTabValue: string;
  onTabClick: (tab: TagsManagerScreenTabs) => void;
};

const TagsTabs = ({ tags, selectedTabValue, onTabClick }: Props) => {
  const mediatype = useAppSelector(
    (state) => state.datasetSlice.activeDataSet?.mediatype,
  );

  const tabs = [
    {
      label: "All",
      value: "all",
      count: tags?.length,
    },
    {
      label: mediatype === Mediatype.video ? "Video tags" : "Media tags",
      value: TagTypeModel.Media,
      count: _.filter(tags, { database_object_type: TagTypeModel.Media })
        ?.length,
    },
    {
      label: "Instance tags",
      value: TagTypeModel.Instance,
      count: _.filter(tags, { database_object_type: TagTypeModel.Instance })
        ?.length,
    },
    {
      label: "Object tags",
      value: TagTypeModel.MediaObject,
      count: _.filter(tags, { database_object_type: TagTypeModel.MediaObject })
        ?.length,
    },
    {
      label: "Attribute tags",
      value: TagTypeModel.Attribute,
      count: _.filter(tags, { database_object_type: TagTypeModel.Attribute })
        ?.length,
    },
  ];

  return (
    <div className="w-full pb-4 pt-4">
      <StandardTabs
        tabs={tabs}
        selectedTab={selectedTabValue}
        setSelectedTab={(tab) => onTabClick(tab as TagsManagerScreenTabs)}
      />
    </div>
  );
};

export default TagsTabs;

import { ObjectFieldTemplateProps } from "@rjsf/utils";
import DescriptionComponent from "components/RJSF/DescriptionComponent";
import RequiredTemplate from "components/RJSF/RequiredTemplate";
import camelCaseToString from "helpers/functions/strings/camelCaseToString";

const ObjectFieldTemplate = (props: ObjectFieldTemplateProps) => {
  return (
    <div
      className={` py-2 px-1 border-b-[1px] border-paletteGray-3
       last:border-b-0 
      ${
        props?.uiSchema?.["ui:showFullBorder"]
          ? "border-2 last:border-b-2 rounded"
          : ""
      }
    `}
    >
      {props?.title && (
        <div className="flex gap-x-1 pl-1 pb-1">
          {camelCaseToString(props?.title)}
          <RequiredTemplate required={props.required} />
          {props?.description && (
            <DescriptionComponent description={props.description} />
          )}
        </div>
      )}

      <div className="ml-1 flex flex-col gap-y-2">
        {props?.properties?.map((element) => {
          // If the element is hidden, return null
          if (element?.hidden) {
            return null;
          }
          // Otherwise, return the element
          return <div key={element?.name}>{element.content}</div>;
        })}
      </div>
    </div>
  );
};
export default ObjectFieldTemplate;

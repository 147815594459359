import { useEffect, useState } from "react";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getDownloadFile, getDownloadFilesList } from "helpers/apis/download";
import { DownloadFileMeta, DownloadFileStatus } from "models/download";
import BasicTable from "components/Table/BasicTable";
import bytesConvertor from "helpers/functions/bytesConvertor";
import { AnnotatableEnum } from "models/global.model";
import RefreshButton from "components/Buttons/refresh";
import AutoIntervalButton from "components/Buttons/AutoIntervalButton";
import { snakeCaseToText } from "components/utilFunctions";
import TooltipTruncateEllipsis from "components/Tooltips/TooltipTruncateEllipsis";

const FilesTable = () => {
  const dispatch = useAppDispatch();
  const dataSetData = useAppSelector((state) => state.datasetSlice);
  const selectedView = useAppSelector((state) => state.appSlice.selectedView);
  // Local States
  const [isLoading, setIsLoading] = useState(false);
  const [filesList, setFilesList] = useState<DownloadFileMeta[]>([]);

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = () => {
    getDownloadFilesList(dataSetData?.activeParentDataset?.id || "").then(
      (response) => setFilesList(response.data),
    );
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      span: 30,
    },
    {
      field: "creation_timestamp",
      headerName: "Created",
      span: 20,
      cell: (file: DownloadFileMeta) => (
        <div className="text-sm">
          {new Date(file?.creation_timestamp).toLocaleString()}
        </div>
      ),
    },
    {
      field: "view_type",
      headerName: "Type",
      span: 12,
      cell: (file: DownloadFileMeta) => (
        <TooltipTruncateEllipsis>
          {file?.view_type === "media_object"
            ? "Object"
            : snakeCaseToText(new String(file?.view_type).toLocaleString())}
        </TooltipTruncateEllipsis>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      span: 10,
      cell: (file: DownloadFileMeta) => (
        <TooltipTruncateEllipsis>
          {_.upperFirst(new String(file?.status).toLocaleString())}
        </TooltipTruncateEllipsis>
      ),
    },
    {
      field: "file_size",
      headerName: "Size",
      span: 15,
      cell: (file: DownloadFileMeta) => (
        <div className="text-sm">{bytesConvertor(file?.file_size)}</div>
      ),
    },
    {
      field: "",
      headerName: "Download",
      sortable: false,
      span: 10,
      cell: (file: DownloadFileMeta) => (
        <div className="w-full h-full flex">
          <button
            className="button-layer w-32 h-[42px]"
            onClick={() => {
              getDownloadFile(
                {
                  datasetID: dataSetData?.activeParentDataset?.id || "",
                  fileID: file?.id,
                },
                dispatch,
                setIsLoading,
              );
            }}
            disabled={
              file?.status === DownloadFileStatus?.Done && !isLoading
                ? false
                : true
            }
          >
            Download
          </button>
        </div>
      ),
    },
  ];

  const selectRows = () => {
    let viewType = "";
    switch (selectedView) {
      case AnnotatableEnum.Media:
        viewType = "media";
        break;
      case AnnotatableEnum.Instance:
        viewType = "instance";
        break;
      case AnnotatableEnum.MediaObject:
        viewType = "media_object";
        break;
      default:
        break;
    }

    // If no view type is selected, return empty array
    if (viewType === "") return [];

    return _.filter(
      filesList,
      (filter: DownloadFileMeta) => filter?.view_type === viewType,
    );
  };

  return (
    <div className="ml-auto pb-6">
      <div className="flex justify-end gap-x-1">
        <RefreshButton callBackFunction={fetchList} />
        <AutoIntervalButton
          functionToCall={fetchList}
          label={"Auto fetching (5s)"}
          delay={5000}
        />
      </div>
      <BasicTable
        rows={selectRows()}
        columns={columns}
        rowUniqueKey="id"
        showHeader={true}
        fontSize="1"
        hover={false}
      />
    </div>
  );
};

export default FilesTable;

import { useAppDispatch, useAppSelector } from "store/hooks";
import { Dialog } from "@mui/material";
import {
  removeNewTrainingSetTrainingAttribute,
  resetNewTrainingSet,
  resetNewTrainingSetDataset,
  setIsCreatingNewTrainingSetModalOpen,
  setNewTrainingSetName,
} from "store/aiManagerSlice";

import { ReactComponent as CloseIcon } from "assets/close.svg";
import TextInput from "components/Inputs/Form/TextInput";
import { useState } from "react";
import SelectAttributeDialog, {
  AddAttributeDialog,
} from "Pages/AIManager/TrainingSetManager/CreateNewTrainingSetDialog/SelectAttributeDialog";
import NewTrainingSetAttributeList from "Pages/AIManager/TrainingSetManager/CreateNewTrainingSetDialog/NewTrainingSetAttributeList";
import NewTrainingSetActions from "Pages/AIManager/TrainingSetManager/CreateNewTrainingSetDialog/NewTrainingSetActions";
import NewTrainingSetHelperText from "Pages/AIManager/TrainingSetManager/CreateNewTrainingSetDialog/NewTrainingSetHelperText";
import AsteriskLabel from "components/Labels/AsteriskLabel";
import { ReactComponent as RemoveIcon } from "assets/close.svg";

const CreateNewTrainingSetDialog = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(
    (state) => state.aiManagerSlice.isCreateNewTrainingSetModalOpen,
  );
  const newTrainingSet = useAppSelector(
    (state) => state.aiManagerSlice.newTrainingSet,
  );

  const [addAttributeDialogOpen, setAddAttributeDialogOpen] =
    useState<AddAttributeDialog>({
      show: false,
      attribute: null,
      dataset: null,
    });

  const [isCreatingNewTrainingSet, setIsCreatingNewTrainingSet] =
    useState(false);

  const renderSelectedDataset = () => {
    if (newTrainingSet.dataset === null) return null;

    const dataset = newTrainingSet.dataset;

    return (
      <div className="pt-3 flex gap-x-2 items-center">
        <div className="label-layer text-paletteBlack-1 bg-paletteGray-3">
          {dataset?.name}
          <button
            className="button-layer p-1 ml-2 text-paletteGray-9 bg-transparent"
            onClick={() => dispatch(resetNewTrainingSetDataset())}
          >
            <RemoveIcon className="w-3 h-3" strokeWidth={2.5} />
          </button>
        </div>
        <div className="text-sm text-paletteGray-9 ">
          {dataset?.num_medias} media · {dataset?.num_media_objects} objects
        </div>
      </div>
    );
  };

  const renderAddAttributeButton = () => {
    const label = newTrainingSet.training_attributes.length
      ? "Add another attribute"
      : "Add attribute";
    return (
      <button
        className="button-dark-gray-layer w-fit"
        onClick={() =>
          setAddAttributeDialogOpen({
            show: true,
            attribute: null,
            dataset: null,
          })
        }
        disabled={isCreatingNewTrainingSet}
        tabIndex={1}
        data-test="add_attribute_button"
      >
        + {label}
      </button>
    );
  };

  const handleCloseDialog = () => {
    if (isCreatingNewTrainingSet) return;
    dispatch(setIsCreatingNewTrainingSetModalOpen(false));
    dispatch(resetNewTrainingSet());
  };

  return (
    <Dialog fullWidth maxWidth="lg" open={isOpen} onClose={handleCloseDialog}>
      <div className="p-6 min-w-[600px] min-h-[600px] h-full flex gap-x-5">
        <div className="w-[350px]">
          <NewTrainingSetHelperText />
        </div>
        <div className=" min-w-0 flex-1 flex flex-col justify-between">
          {/* Header */}
          <div className="flex justify-between">
            <h3>New training set</h3>
            <div className="p-1 cursor-pointer" onClick={handleCloseDialog}>
              <CloseIcon className="w-3 h-3 text-paletteGray-9" />
            </div>
          </div>

          <div className="py-8 flex flex-col gap-y-2">
            <div className=" font-medium">Training set information</div>
            <div className="flex flex-col gap-y-2">
              <TextInput
                field={{
                  ...newTrainingSet.name,
                  disabled: isCreatingNewTrainingSet,
                }}
                value={newTrainingSet.name.value}
                handleOnChange={(e) =>
                  dispatch(setNewTrainingSetName(e.target.value))
                }
                autoFocus
                tabIndex={0}
              />
            </div>
          </div>

          <div className="flex flex-col gap-y-2 min-h-0 flex-1 overflow-y-auto">
            <div>
              Attributes
              <AsteriskLabel />
            </div>
            {renderSelectedDataset()}
            <NewTrainingSetAttributeList
              isCreatingNewTrainingSet={isCreatingNewTrainingSet}
            />
            {renderAddAttributeButton()}
          </div>

          <NewTrainingSetActions
            isCreatingNewTrainingSet={isCreatingNewTrainingSet}
            setIsCreatingNewTrainingSet={setIsCreatingNewTrainingSet}
          />
        </div>
      </div>

      {/* Render add attribute dialog */}
      {addAttributeDialogOpen.show && (
        <SelectAttributeDialog
          addAttributeDialog={addAttributeDialogOpen}
          setAddAttributeDialog={setAddAttributeDialogOpen}
        />
      )}
    </Dialog>
  );
};

export default CreateNewTrainingSetDialog;

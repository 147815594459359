import { AxiosError, AxiosResponse } from "axios";
import { APIFetchAxios, APIPostWithBodyAxios } from "routes/Auth";
import {
  HariCropVisualisationParameters,
  VisualisationConfigurationModel,
} from "models/configurations.model";
import snackbarHelper from "helpers/snackbarHelperFn";

export const getVisualisationConfiguration = async (query: {
  datasetID: string;
}): Promise<VisualisationConfigurationModel[]> => {
  const response = await APIFetchAxios(
    `/datasets/${query?.datasetID}/visualisationConfigs`,
  );
  return response?.data;
};

export const postCreateVisualisationConfiguration = async (
  datasetID: string,
  configName: string,
  configParams: HariCropVisualisationParameters,
): Promise<AxiosResponse<VisualisationConfigurationModel>> => {
  const response = await APIPostWithBodyAxios(
    `/datasets/${datasetID}/visualisationConfigs`,
    {
      name: configName,
      parameters: configParams,
    },
  ).catch((error: AxiosError<any>) => {
    const errorDetail = error?.response?.data?.detail;
    snackbarHelper(
      `${errorDetail}` || "Visualization creation failed!",
      "error",
    );
    return Promise.reject(error);
  });
  return response;
};

import { useState } from "react";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "store/hooks";

import { APIPostWithBodyAxios, APIPutWithBodyAxios } from "routes/Auth";
import convertFilterModelToSendFilterModel from "helpers/functions/filters/convertFilterModelToSendFilterModel";
import { AnnotatableEnum } from "models/global.model";
import CustomCheckbox from "components/Inputs/Checkbox";
import { useParams } from "react-router-dom";
import { ExplorationScreenRouteModel } from "models/routes.model";
import { ReactComponent as SaveIcon } from "assets/save.svg";
import { TextFieldModel } from "models/form.model";
import TextInput from "components/Inputs/Form/TextInput";
import StandardDialog from "components/Dialogs/StandardDialog";
import { determineFilterKeyFromParam } from "store/filterDataSlice";
import snackbarHelper from "helpers/snackbarHelperFn";

interface Props {
  updateFilters: () => void;
}

const SaveFilterDialog = ({ updateFilters }: Props) => {
  const params: ExplorationScreenRouteModel = useParams();
  const dispatch = useAppDispatch();

  const filterData = useAppSelector((state) => state.filterDataSlice);
  const selectedView = useAppSelector((state) => state.appSlice.selectedView);

  const [openSaveFilterDialog, setOpenSaveFilterDialog] = useState(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Create new filter fields
  const [newFilterName, setNewFilterName] = useState<TextFieldModel>({
    type: "text",
    key: "name",
    placeholder: "At least 3 letters",
    value: "",
    settings: {
      minimumTextLength: 3,
    },
  });
  const [globalFilter, setIsGlobalFilter] = useState(false);

  const handleCloseDialog = () => {
    setNewFilterName({
      ...newFilterName,
      value: "",
      isErrored: false,
      error: null,
    });
    setOpenSaveFilterDialog(false);
  };

  const handleSaveFilters = () => {
    let filter_type = "";
    switch (selectedView) {
      case AnnotatableEnum.Media:
        filter_type = "media";
        break;
      case AnnotatableEnum.MediaObject:
        filter_type = "media_object";
        break;
      case AnnotatableEnum.Instance:
        filter_type = "instance";
        break;
    }

    setIsLoading(true);
    APIPostWithBodyAxios(`/datasets/${params?.id}/savedFilters`, {
      name: newFilterName?.value,
      is_global: globalFilter,
      annotatable_type: filter_type,
      dataset_id: params?.id,
      filter: convertFilterModelToSendFilterModel(
        _.filter(
          filterData?.[determineFilterKeyFromParam(selectedView)],
          (f) => f?.is_visible,
        ),
      ),
    })
      .then(() => {
        updateFilters();
        snackbarHelper("Saved filter created successfully!");
        handleCloseDialog();
        setIsLoading(false);
      })
      .catch((error) => {
        const errorDetail =
          error?.response?.data?.detail ||
          "An error occurred while creating a new saved filter!";
        snackbarHelper(errorDetail, "error");
        setNewFilterName({
          ...newFilterName,
          isErrored: true,
          error: errorDetail,
        });
        setIsLoading(false);
      });
  };

  const renderFilterNameField = () => {
    return (
      <div className="pb-6">
        <div className="text-paletteGray-7 py-2">Name your new filter</div>
        <TextInput
          field={{ ...newFilterName, disabled: isLoading }}
          value={newFilterName?.value}
          handleOnChange={(e, field, isErrored, error) =>
            setNewFilterName({
              ...newFilterName,
              value: e.target.value,
              isErrored: isErrored || false,
              error: error || null,
            })
          }
          autoFocus
          tabIndex={0}
        />
      </div>
    );
  };

  const renderGlobalFilterCheckbox = () => {
    return (
      <div className="flex items-center w-5/6 mt-3">
        <CustomCheckbox
          checked={globalFilter}
          onChange={() => {
            setIsGlobalFilter(!globalFilter);
          }}
          tabIndex={0}
        />
        &nbsp; Global Filter - works on all datasets
      </div>
    );
  };

  const renderSaveFiltersButton = () => {
    return (
      <button
        className="button-layer mt-2"
        onClick={() => {
          handleSaveFilters();
        }}
        disabled={
          newFilterName?.isErrored ||
          isLoading ||
          _.isEmpty(newFilterName?.value) ||
          newFilterName?.value?.length < 3
        }
        tabIndex={0}
      >
        Save Filter
      </button>
    );
  };

  return (
    <div>
      <button
        className="button-select-layer w-auto p-[10px] justify-center"
        onClick={() => setOpenSaveFilterDialog(true)}
      >
        <SaveIcon width={20} height={20} />
      </button>

      <StandardDialog
        openDialog={openSaveFilterDialog}
        setOpenDialog={setOpenSaveFilterDialog}
        handleOnDialogClose={handleCloseDialog}
        title="Save new filter"
        actions={renderSaveFiltersButton()}
        loading={{
          isLoading: isLoading,
          loadingText: "Saving filter...",
        }}
      >
        {renderFilterNameField()}
        {renderGlobalFilterCheckbox()}
      </StandardDialog>

      {/* <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={openSaveFilterDialog}
        onClose={handleCloseDialog}
      >
      
            <TextField
              onChange={handleName}
              className="w-5/6"
              label="Name"
              variant="outlined"
              value={name}
              helperText={error}
              error={isError}
            />
           
      </Dialog> */}
    </div>
  );
};

export default SaveFilterDialog;

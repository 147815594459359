import { Dispatch } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import snackbarHelper from "helpers/snackbarHelperFn";
import { APIFetchAxios, APIPutWithBodyAxios } from "routes/Auth";

interface UserResponse {
  keycloak_user: {
    id: string;
    username: string;
    groups: string[];
    roles: string[];
  };
  db_user: {
    id: string;
  };
}

/**
 * An endpoint to get user data.
 * @param userID The id of the user to get data for
 * @param dispatch The dispatch function for the redux store
 */
export const postGetUser = async (
  userID: string,
  dispatch: Dispatch,
): Promise<UserResponse> => {
  const response = await APIFetchAxios("/users/me", {
    user_id: userID,
  }).catch((error: AxiosError<any>) => {
    const errorDetail = error?.response?.data?.detail;
    snackbarHelper(
      `User data: ${errorDetail}` || "User data failed to be retrieved!",
      "error",
    );
    return Promise.reject();
  });
  return response?.data;
};

export const postAcceptLicense = async (
  datasetID: string,
  dispatch: Dispatch,
): Promise<void> => {
  await APIPutWithBodyAxios(`/users/me/licenses/${datasetID}`, {}).catch(
    (error: AxiosError<any>) => {
      const errorDetail = error?.response?.data?.detail;
      snackbarHelper(
        `License: ${errorDetail}` || "License failed to be accepted!",
        "error",
      );
      return Promise.reject();
    },
  );
};

import snackbarHelper from "helpers/snackbarHelperFn";
import {
  NotificationModel,
  UserNotificationMessageModel,
  WebsocketEventEnum,
  WebSocketMessageModel,
} from "models/websockets.model";
import userNotificationToaster from "components/UtilComponents/CustomToaster/userNotificationToaster";
import { addNotification } from "store/notificationsSlice";
import { Dispatch } from "@reduxjs/toolkit";

// A helper function to handle user notification messages.
// Should only display a snackbar message.
export const handleUserNotificationMessage = (
  webSocketMessage: WebSocketMessageModel,
  dispatch: Dispatch,
) => {
  const userNotificationMessage =
    webSocketMessage as UserNotificationMessageModel;

  if (webSocketMessage.type !== "user_notification_message") return;

  const messageContent = userNotificationMessage.content;

  dispatch(addNotification(messageContent as NotificationModel));

  switch (messageContent?.event_type) {
    case WebsocketEventEnum.SUBSET_CREATION: {
      const msg = messageContent?.details
        .replace("<b>", "'")
        .replace("</b>", "'");
      snackbarHelper(msg, messageContent?.severity);
      break;
    }
    default: {
      userNotificationToaster[messageContent?.severity](
        messageContent?.details,
      );
      return;
    }
  }
};

import { useAppDispatch, useAppSelector } from "store/hooks";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import { AnnotatableEnum } from "models/global.model";
import selectMediatype from "helpers/functions/selectMediatype";
import { ReactComponent as FilesIcon } from "assets/files.svg";
import { ReactComponent as BackIcon } from "assets/arrow_small_left.svg";
import { ReactComponent as FilterLinesIcon } from "assets/filter_line.svg";
import { resetStoreOnSubsetChange } from "store/util/resetStore";
import { Tooltip } from "@mui/material";
import { determineCountKeyFromParam } from "store/metaDataSlice";
import { determineActiveFilterKeyFromParam } from "store/filterDataSlice";
import { getExplorationMediaPageRoute } from "routes/routesHelper";
import { getLabelFromSelectedView } from "helpers/functions/selectedViewHelpers";
import TooltipTruncateEllipsis from "components/Tooltips/TooltipTruncateEllipsis";

interface Props {
  children?: React.ReactNode;
  backTo?: { pathname: string; onClick?: () => void };
}

const StandardHeader = ({ children, backTo }: Props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const selectedView = useAppSelector((state) => state.appSlice?.selectedView);
  const datasetSlice = useAppSelector((state) => state.datasetSlice);
  const filterData = useAppSelector((state) => state.filterDataSlice);
  const metaData = useAppSelector((state) => state.metaDataSlice);

  const renderBackButton = () => {
    if (!backTo) return null;

    return (
      <div
        className="px-[10px] flex items-center text-paletteBlack-1 cursor-pointer
          hover:text-paletteGray-10 active:text-paletteBlack-2 
        "
        data-test="exit_detail_view"
        onClick={() => {
          history.push(backTo?.pathname);
          backTo?.onClick && backTo?.onClick();
        }}
      >
        <BackIcon width={20} height={20} />
      </div>
    );
  };

  const renderDatasetName = () => {
    if (!datasetSlice?.activeParentDataset) return null;

    const { name, id } = datasetSlice.activeParentDataset;

    const isInSubset = datasetSlice?.activeDataSet?.name !== name;
    const totalNumber = getDatasetTotalNumber();
    const itemsName = getLabelFromSelectedView(
      selectedView,
      datasetSlice.activeDataSet,
    );

    // If the active dataset is the parent dataset (we are not in a subset),
    // we render the filtered count of items
    let countOfFilteredMedias = null;
    if (isInSubset === false) {
      countOfFilteredMedias = renderCountOfFilteredItems();
    }

    return (
      <div
        className={`max-w-[100%] flex gap-x-2 items-center text-paletteGray-10
        ${
          isInSubset === true &&
          "!max-w-[50%] cursor-pointer hover:text-paletteGray-8"
        }`}
      >
        <FilesIcon width={16} height={16} />
        <div
          className="w-full flex gap-x-1 items-center cursor-pointer"
          onClick={() => {
            const explorationRoute = getExplorationMediaPageRoute({
              id: id,
              subset_id: "main_dataset",
            });
            history.push(explorationRoute);
            isInSubset === true && resetStoreOnSubsetChange(dispatch);
          }}
        >
          <TooltipTruncateEllipsis
            className="font-medium"
            data-test="dataset_name_exploration"
          >
            {name}
          </TooltipTruncateEllipsis>
          <div
            className={`flex rounded font-medium ${
              !isInSubset && "bg-paletteGray-10 text-white"
            }`}
          >
            <div className="pr-[6px] pl-1 flex gap-x-[2px]">
              <Tooltip title={`Total number of ${itemsName} in the dataset`}>
                <div
                  className={`${isInSubset ? "font-normal" : "font-medium"}`}
                  data-test="dataset_size_exploration"
                >
                  {totalNumber}
                </div>
              </Tooltip>
              <div className="font-normal">{itemsName}</div>
            </div>
            {countOfFilteredMedias && (
              <Tooltip
                title={`Total number of filtered ${itemsName} in the dataset`}
              >
                {countOfFilteredMedias}
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderSubsetName = () => {
    if (
      datasetSlice?.activeParentDataset?.name ===
      datasetSlice?.activeDataSet?.name
    )
      return null;
    if (!datasetSlice?.activeDataSet) return null;

    const { name } = datasetSlice?.activeDataSet;

    const totalNumber = getTotalNumber();
    const countOfFilteredMedias = renderCountOfFilteredItems();
    const itemsName = getLabelFromSelectedView(
      selectedView,
      datasetSlice.activeDataSet,
    );

    return (
      <div className="max-w-[50%] flex gap-x-2 items-center text-paletteBlack-2">
        <div className="text-paletteGray-6">/</div>
        <TooltipTruncateEllipsis
          className="font-medium"
          data-test="subset_name_exploration"
        >
          {name}
        </TooltipTruncateEllipsis>
        <div className="flex bg-paletteGray-10 text-white font-medium rounded">
          <div className="px-[6px] flex gap-x-[2px]">
            <Tooltip title={`Total number of ${itemsName} in the subset`}>
              <div className="pr-[2px]" data-test="subset_size_exploration">
                {totalNumber}
              </div>
            </Tooltip>
            <div className="pl-[2px] font-normal">{itemsName}</div>
          </div>
          {countOfFilteredMedias && (
            <Tooltip
              title={`Total number of filtered ${itemsName} in the subset`}
            >
              {countOfFilteredMedias}
            </Tooltip>
          )}
        </div>
      </div>
    );
  };

  const renderCountOfFilteredItems = () => {
    const metaDataKey = determineCountKeyFromParam(selectedView);
    const activeFiltersKey = determineActiveFilterKeyFromParam(selectedView);

    // If there is no metaDataKey or there is no active filters, we don't render the count
    if (!metaDataKey || _.isEmpty(filterData?.[activeFiltersKey])) return null;

    const totalCount = metaData?.[metaDataKey]?.total_count;

    return (
      <div
        className="px-[6px] flex items-center gap-x-1 bg-paletteOrange rounded-r-[inherit]"
        data-test="filtered_items"
      >
        <FilterLinesIcon className="w-3 h-3 " />
        {totalCount}
      </div>
    );
  };

  const getTotalNumber = () => {
    if (!datasetSlice?.activeDataSet) return null;

    const { num_medias, num_instances, num_media_objects } =
      datasetSlice.activeDataSet;

    switch (selectedView) {
      case AnnotatableEnum.Media:
        return num_medias;
      case AnnotatableEnum.Instance:
        return num_instances;
      case AnnotatableEnum.MediaObject:
        return num_media_objects;
      default:
        return null;
    }
  };

  const getDatasetTotalNumber = () => {
    if (!datasetSlice?.activeParentDataset) return null;

    const { num_medias, num_instances, num_media_objects } =
      datasetSlice.activeParentDataset;

    switch (selectedView) {
      case AnnotatableEnum.Media:
        return num_medias;
      case AnnotatableEnum.Instance:
        return num_instances;
      case AnnotatableEnum.MediaObject:
        return num_media_objects;
      default:
        return null;
    }
  };

  return (
    <header className="w-full flex justify-between">
      <div className="flex gap-x-1 h-fit py-[7.5px] flex-1 min-w-0">
        {renderBackButton()}
        {renderDatasetName()}
        {renderSubsetName()}
      </div>
      {children}
    </header>
  );
};

export default StandardHeader;

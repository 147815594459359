import StatusLabel from "components/Labels/StatusLabel";
import { snakeCaseToText } from "components/utilFunctions";
import { AnnotationRunNodeStatusEnum } from "models/annotationRun.model";

type Props = { status: AnnotationRunNodeStatusEnum };

const AnnotationRunNodeStatusChip = ({ status }: Props) => {
  const renderStatusChip = () => {
    let color;
    let text = snakeCaseToText(status);
    switch (status) {
      case AnnotationRunNodeStatusEnum.STARTED:
      case AnnotationRunNodeStatusEnum.ANNOTATION_DONE:
      case AnnotationRunNodeStatusEnum.POST_PROCESSING:
        color = "bg-paletteYellow";
        break;
      case AnnotationRunNodeStatusEnum.CREATION_FAILED:
      case AnnotationRunNodeStatusEnum.AI_ANNOTATION_FAILED:
      case AnnotationRunNodeStatusEnum.POST_PROCESSING_FAILED:
      case AnnotationRunNodeStatusEnum.AUTO_ANNOTATION_FAILED:
        color = "bg-paletteRed";
        break;
      case AnnotationRunNodeStatusEnum.POST_PROCESSING_DONE:
        text = "Done";
        color = "bg-paletteGreen";
        break;
      case AnnotationRunNodeStatusEnum.CREATED:
      default:
        color = "bg-paletteGray6";
    }

    return <StatusLabel label={text} colorClass={color} />;
  };

  return renderStatusChip();
};

export default AnnotationRunNodeStatusChip;

import TextInput from "components/Inputs/Form/TextInput";
import { TextFieldModel } from "models/form.model";
import { TagTypeModel } from "models/global.model";
import { useAppSelector } from "store/hooks";

type Props = {
  tagNameField: TextFieldModel;
  setTagNameField: (tagNameField: TextFieldModel) => void;
};

const TagNameInput = ({ tagNameField, setTagNameField }: Props) => {
  const objectTags = useAppSelector(
    (state) =>
      state.datasetSlice.tags?.filter(
        (tag) => tag.database_object_type === TagTypeModel.MediaObject,
      ),
  );

  const checkIfTagNameExists = (tagName: string) => {
    return objectTags?.some((tag) => tag.name === tagName);
  };

  return (
    <TextInput
      field={tagNameField}
      value={tagNameField?.value}
      handleOnChange={(e, field, isErrored, error) => {
        const isTagNameExists = checkIfTagNameExists(e.target.value);
        setTagNameField({
          ...tagNameField,
          value: e.target.value,
          isErrored: isTagNameExists || isErrored || false,
          error:
            (isTagNameExists && "Tag name already exist!") || error || null,
        });
      }}
    />
  );
};

export default TagNameInput;

import { Dialog } from "@mui/material";
import DatasetSelectionTable from "components/Table/DatasetSelectionTable";

import { TrainingSetModel } from "models/trainingSet.model";
import { setNewAIModelTrainingSet } from "store/aiManagerSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";

type Props = {
  isSelectTrainingSetDialog: boolean;
  setIsSelectTrainingSetDialog: (newState: boolean) => void;
};

const SelectTrainingSetDialog = ({
  isSelectTrainingSetDialog,
  setIsSelectTrainingSetDialog,
}: Props) => {
  const dispatch = useAppDispatch();

  const newAIModel = useAppSelector((state) => state.aiManagerSlice.newAIModel);

  const handleOnTrainingSetRowClick = (trainingSet: TrainingSetModel) => {
    dispatch(setNewAIModelTrainingSet(trainingSet));
    setIsSelectTrainingSetDialog(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isSelectTrainingSetDialog}
      onClose={() => setIsSelectTrainingSetDialog(false)}
      PaperProps={{ style: { borderRadius: "16px" } }}
    >
      <div className="w-full h-[650px] pb-2">
        <DatasetSelectionTable
          firstStep="training_set"
          firstStepItem={newAIModel.training_set}
          onFirstStepRowClick={(firstStepItem) =>
            handleOnTrainingSetRowClick(firstStepItem as TrainingSetModel)
          }
        />
      </div>
    </Dialog>
  );
};

export default SelectTrainingSetDialog;

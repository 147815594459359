import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  NotificationCountsModel,
  NotificationModel,
} from "models/websockets.model";
import _ from "lodash";
import {
  deleteNotification,
  fetchNotificationCounts,
  fetchNotifications,
  fetchUnreadNotifications,
  markAllNotificationsAsRead,
  markNotificationAsRead,
} from "helpers/apis/notifications";
import { NotificationRequestModel } from "models/notification.model";

export const fetchNotificationsStore = createAsyncThunk(
  "notification/fetchNotifications",
  async (meta: { reqBody: NotificationRequestModel; reset?: boolean }) => {
    const response = await fetchNotifications(
      meta?.reqBody.skip,
      meta?.reqBody.limit + 1,
    );
    return { data: response, meta: meta };
  },
);

export const fetchUnreadNotificationsStore = createAsyncThunk(
  "notification/fetchUnreadNotifications",
  async (meta: { reqBody: NotificationRequestModel; reset?: boolean }) => {
    const response = await fetchUnreadNotifications(
      meta?.reqBody.skip,
      meta?.reqBody.limit + 1,
    );
    return { data: response, meta: meta };
  },
);

export const fetchNotificationCountsStore = createAsyncThunk(
  "notification/fetchNotificationCounts",
  async () => {
    return await fetchNotificationCounts();
  },
);

export const markAsReadStore = createAsyncThunk(
  "notification/markNotificationAsRead",
  async (meta: { notification_id: string; read: boolean }) => {
    const response = await markNotificationAsRead(
      meta.notification_id,
      meta?.read,
    );
    return { data: response, meta: meta };
  },
);

export const markAllAsReadStore = createAsyncThunk(
  "notification/markAllAsRead",
  async (meta: { read: boolean }) => {
    const response = await markAllNotificationsAsRead(meta.read);
    return { data: response, meta: meta };
  },
);

export const deleteNotificationStore = createAsyncThunk(
  "notification/archive",
  async (meta: { notification_id: string }) => {
    const response = await deleteNotification(meta.notification_id);
    return { data: response, meta: meta };
  },
);

export interface NotificationStateTypes {
  notifications: NotificationModel[];
  unreadNotifications: NotificationModel[];
  open: boolean;
  hasMore: boolean;
  unreadHasMore: boolean;
  loading: boolean;
  counts: NotificationCountsModel;
}

export const initialState = {
  notifications: [],
  unreadNotifications: [],
  open: false,
  hasMore: true,
  unreadHasMore: true,
  loading: false,
  counts: { unread: 0, total: 0 },
} as NotificationStateTypes;

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    toggleDrawer: (state) => {
      state.open = !state.open;
    },
    addNotification: (state, action) => {
      state.notifications.unshift(action.payload);
      state.unreadNotifications.unshift(action.payload);
      state.counts.unread += 1;
      state.counts.total += 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchNotificationsStore.fulfilled,
      (state: NotificationStateTypes, action) => {
        if (action.payload?.data?.length > action?.meta.arg.reqBody.limit - 1) {
          action.payload?.data?.pop();
          state.hasMore = true;
        } else {
          state.hasMore = false;
        }
        if (action?.meta?.arg?.reset) {
          state.notifications = action.payload?.data;
        } else {
          state.notifications.push(...action.payload?.data);
        }
        state.loading = false;
      },
    );
    builder.addCase(
      fetchNotificationsStore.pending,
      (state: NotificationStateTypes) => {
        state.loading = true;
      },
    );
    builder.addCase(
      fetchNotificationsStore.rejected,
      (state: NotificationStateTypes) => {
        state.loading = false;
      },
    );
    builder.addCase(
      fetchUnreadNotificationsStore.fulfilled,
      (state: NotificationStateTypes, action) => {
        if (action.payload?.data?.length > action?.meta.arg.reqBody.limit - 1) {
          action.payload?.data?.pop();
          state.unreadHasMore = true;
        } else {
          state.unreadHasMore = false;
        }
        if (action?.meta?.arg?.reset) {
          state.unreadNotifications = action.payload?.data;
        } else {
          state.unreadNotifications.push(...action.payload?.data);
        }
        state.loading = false;
      },
    );
    builder.addCase(
      fetchUnreadNotificationsStore.pending,
      (state: NotificationStateTypes) => {
        state.loading = true;
      },
    );
    builder.addCase(
      fetchUnreadNotificationsStore.rejected,
      (state: NotificationStateTypes) => {
        state.loading = false;
      },
    );

    builder.addCase(
      fetchNotificationCountsStore.fulfilled,
      (state: NotificationStateTypes, action) => {
        state.counts = action.payload;
        state.loading = false;
      },
    );
    builder.addCase(
      fetchNotificationCountsStore.pending,
      (state: NotificationStateTypes) => {
        state.loading = true;
      },
    );
    builder.addCase(
      fetchNotificationCountsStore.rejected,
      (state: NotificationStateTypes) => {
        state.loading = false;
      },
    );
    builder.addCase(
      markAsReadStore.fulfilled,
      (state: NotificationStateTypes, action) => {
        const { notification_id, read } = action.meta.arg;

        state.notifications = state.notifications.map((notification) => {
          if (notification.id === notification_id) {
            notification.read_at = read ? new Date().toISOString() : null;
          }
          return notification;
        });

        if (read) {
          state.unreadNotifications = state.unreadNotifications.filter(
            (notification) => notification.id !== notification_id,
          );
          state.counts.unread--;
        } else {
          const unreadNotification = state.notifications.find(
            (notification) => notification.id === notification_id,
          );
          if (unreadNotification) {
            state.unreadNotifications = _.orderBy(
              [...state.unreadNotifications, unreadNotification],
              ["created_at"],
              ["desc"],
            );
          }
          state.counts.unread++;
        }

        state.unreadNotifications.sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
        );
        state.loading = false;
      },
    );
    builder.addCase(
      markAsReadStore.pending,
      (state: NotificationStateTypes) => {
        state.loading = true;
      },
    );
    builder.addCase(
      markAsReadStore.rejected,
      (state: NotificationStateTypes) => {
        state.loading = false;
      },
    );
    builder.addCase(
      markAllAsReadStore.fulfilled,
      (state: NotificationStateTypes) => {
        state.notifications = _.map(state.notifications, (notification) => {
          if (notification.read_at === null) {
            notification.read_at = new Date().toISOString();
          }
          return notification;
        });
        state.unreadNotifications = [];
        state.counts.unread = 0;
        state.loading = false;
      },
    );
    builder.addCase(
      markAllAsReadStore.pending,
      (state: NotificationStateTypes) => {
        state.loading = true;
      },
    );
    builder.addCase(
      markAllAsReadStore.rejected,
      (state: NotificationStateTypes) => {
        state.loading = false;
      },
    );
    builder.addCase(
      deleteNotificationStore.fulfilled,
      (state: NotificationStateTypes, action) => {
        const notificationId = action.meta.arg.notification_id;
        const notificationIndex = state.notifications.findIndex(
          (n) => n.id === notificationId,
        );
        const unreadIndex = state.unreadNotifications.findIndex(
          (n) => n.id === notificationId,
        );

        if (notificationIndex !== -1) {
          state.notifications.splice(notificationIndex, 1);
          state.counts.total--;
        }
        if (unreadIndex !== -1) {
          state.unreadNotifications.splice(unreadIndex, 1);
          state.counts.unread--;
        }

        state.loading = false;
      },
    );
    builder.addCase(
      deleteNotificationStore.pending,
      (state: NotificationStateTypes) => {
        state.loading = true;
      },
    );
    builder.addCase(
      deleteNotificationStore.rejected,
      (state: NotificationStateTypes) => {
        state.loading = false;
      },
    );
  },
});

export const { toggleDrawer, addNotification } = notificationSlice.actions;

export default notificationSlice.reducer;

import StandardTabs from "components/Tabs/StandardTabs";
import _ from "lodash";
import { AIMangerViewEnum, setAIMangerView } from "store/aiManagerSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { checkIfUserHavePermission } from "helpers/keycloakHelpers";
import { useKeycloak } from "@react-keycloak/web";
import { useEffect } from "react";

const AIManagerTabs = () => {
  const dispatch = useAppDispatch();
  const { keycloak } = useKeycloak();
  const aiManagerSlice = useAppSelector((state) => state.aiManagerSlice);

  // TODO: remove once external users can see TrainingSets
  const canSeeTrainingSets = checkIfUserHavePermission(
    keycloak,
    "accessTrainingSets",
  );

  // TODO: remove once external users can see TrainingSets
  const filterTabs = (tabs: typeof AIMangerViewEnum) => {
    return _.filter(tabs, (tab) => {
      if (tab === AIMangerViewEnum.TrainingSets) {
        return canSeeTrainingSets;
      }
      return true;
    });
  };

  // TODO: remove once external users can see TrainingSets
  useEffect(() => {
    if (
      aiManagerSlice.view === AIMangerViewEnum.TrainingSets &&
      !canSeeTrainingSets
    ) {
      dispatch(setAIMangerView(AIMangerViewEnum.AIModels));
    }
  }, [aiManagerSlice.view]);

  return (
    <div className="w-full">
      <StandardTabs
        tabs={_.map(filterTabs(AIMangerViewEnum), (tab) => {
          let count = undefined;
          if (
            tab === AIMangerViewEnum.AIModels &&
            aiManagerSlice.aiModels !== null
          ) {
            count = aiManagerSlice.aiModels.length;
          }
          if (
            tab === AIMangerViewEnum.TrainingSets &&
            aiManagerSlice.trainingSets !== null
          ) {
            count = aiManagerSlice.trainingSets.length;
          }
          if (
            tab === AIMangerViewEnum.AIAnnotationRuns &&
            aiManagerSlice.aiAnnotationRuns !== null
          ) {
            count = aiManagerSlice.aiAnnotationRuns.length;
          }
          return {
            label: tab,
            value: tab,
            count: count,
          };
        })}
        selectedTab={aiManagerSlice.view}
        setSelectedTab={(clickedTab) =>
          dispatch(setAIMangerView(clickedTab as AIMangerViewEnum))
        }
      />
    </div>
  );
};

export default AIManagerTabs;

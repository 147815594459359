import _ from "lodash";

interface Props {
  label: string;
  onClick: () => void;

  className?: string;
  disabled?: boolean;
}

const BasicButton = ({
  label,
  onClick,

  className,
  disabled = false,
}: Props) => {
  return (
    <button
      className={` button-layer
                    ${!_.isUndefined(className) && className}
                    `}
      data-test={`${label?.toLowerCase()}_button`}
      onClick={() => !disabled && onClick()}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default BasicButton;

import { useEffect } from "react";
import { useParams } from "react-router-dom";
import PipelineConfiguration from "Pages/PipelineDesigner/PipelineConfiguration";
import PipelineDesignerHeader from "Pages/PipelineDesigner/PipelineDesignerHeader";
import { PipelineDesignerScreenRouteModel } from "models/routes.model";
import { fetchNodesOfPipeline, fetchPipeline } from "helpers/apis/pipelines";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  resetPipelineDesignerSlice,
  setPipeline,
  setPipelineNode,
} from "store/pipelineDesignerSlice";
import { PipelineModel, VisualisationTypeEnum } from "models/pipelines.model";
import snackbarHelper from "helpers/snackbarHelperFn";
import LivePreview from "Pages/PipelineDesigner/LivePreview";
import NoPreview from "Pages/PipelineDesigner/NoPreview";
import LivePreviewWarning from "Pages/PipelineDesigner/LivePreviewWarning";

const PipelineDesigner = () => {
  const params: PipelineDesignerScreenRouteModel = useParams();
  const dispatch = useAppDispatch();
  const pipelineDesignerState = useAppSelector(
    (state) => state.pipelineDesignerSlice,
  );

  useEffect(() => {
    dispatch(resetPipelineDesignerSlice());
    if (params?.pipeline_id === "new") return;

    fetchPipeline({ pipelineID: params?.pipeline_id }, dispatch).then(
      (response: PipelineModel) => {
        dispatch(setPipeline(response));

        fetchNodesOfPipeline({ pipelineID: response?.id }, dispatch).then(
          (response) => {
            if (response?.length > 1) {
              snackbarHelper(
                "Multi node pipeline is not supported yet!",
                "warning",
              );
            } else {
              dispatch(setPipelineNode(response?.[0]));
            }
          },
        );
      },
    );
  }, [params?.pipeline_id]);

  const renderLeftPanel = () => {
    if (!pipelineDesignerState.isShowingLivePreview) return <NoPreview />;

    const datasetId = pipelineDesignerState.dataset?.id;
    const subsetId = pipelineDesignerState.subset?.id;
    const sampleSize = pipelineDesignerState.configurationsForm?.wp_tasks
      ?.value as number;
    const uiType = pipelineDesignerState.uiType;
    const visualizationType = pipelineDesignerState.configurationsForm
      ?.visualisation_type?.value as VisualisationTypeEnum;
    const colorMap = pipelineDesignerState?.colorMap;
    const guiSettings = pipelineDesignerState?.guiFormData;

    return (
      <LivePreview
        datasetId={datasetId}
        subsetId={subsetId}
        sampleSize={sampleSize}
        uiType={uiType}
        visualizationType={visualizationType}
        colorMap={colorMap}
        guiSettings={guiSettings}
      />
    );
  };

  return (
    <div className="w-full h-full px-4 pb-1 flex flex-col">
      <PipelineDesignerHeader />

      <div className="w-full min-h-0 flex-1 flex">
        <div className="p-14 h-full min-w-0 flex-1 bg-paletteGray-1 rounded-l-lg">
          {renderLeftPanel()}
          <LivePreviewWarning />
        </div>
        <PipelineConfiguration />
      </div>
    </div>
  );
};

export default PipelineDesigner;

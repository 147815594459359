import { useState } from "react";
import { AttributeMetadataModel } from "models/attributes.model";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "store/hooks";
import { deleteAttribute } from "helpers/apis/attributes";
import { AttributeManagementScreenRouteModel } from "models/routes.model";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import EditHideDeletePopover from "components/Popovers/EditHideDeletePopover";

type Props = {
  attribute: AttributeMetadataModel;
  setEditInputID: (id: string | null) => void;
  handleOnSuccess: () => void;
};

const ActionsDropdown = ({
  attribute,
  setEditInputID,
  handleOnSuccess,
}: Props) => {
  const params: AttributeManagementScreenRouteModel = useParams();
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleArchive = () => {
    deleteAttribute(
      { datasetID: params?.dataset_id, attributeID: attribute.id },
      dispatch,
      setIsLoading,
    ).then(() => {
      handleOnSuccess();
      setIsConfirmDialogOpen(false);
      handlePopoverClose();
    });
  };

  return (
    <div className="w-full h-full flex justify-center items-center">
      <EditHideDeletePopover
        anchorEl={anchorEl}
        handlePopoverOpen={handlePopoverOpen}
        handlePopoverClose={handlePopoverClose}
        itemID={attribute.id}
        handleOnEdit={(attributeID) => {
          setEditInputID(attributeID);
          handlePopoverClose();
        }}
        handleOnDelete={() => setIsConfirmDialogOpen(true)}
        isLoading={isLoading}
      />

      <ConfirmDialog
        isConfirmDialogOpen={isConfirmDialogOpen}
        setIsConfirmDialogOpen={setIsConfirmDialogOpen}
        text={`Are you sure you want to archive the attribute "${attribute?.name}"?`}
        subText="All occurrences of attribute will be removed from HARI."
        handleOnSuccess={handleArchive}
        isLoading={isLoading}
      />
    </div>
  );
};

export default ActionsDropdown;

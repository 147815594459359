import StandardHeader from "sections/Headers/StandardHeader";
import ThresholdAnalysis from "Pages/InternalUserToolsPage/ThresholdAnalysis";

const InternalUserToolsPage = () => {
  return (
    <header className="px-[72px] pt-4 mx-auto h-full bg-white flex flex-col self-center">
      <StandardHeader />
      <div className="pt-10 min-h-0 flex-1">
        <ThresholdAnalysis />
      </div>
    </header>
  );
};

export default InternalUserToolsPage;

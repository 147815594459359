import { DescriptionFieldProps } from "@rjsf/utils";
import DescriptionComponent from "components/RJSF/DescriptionComponent";
import _ from "lodash";

const DescriptionFieldTemplate = (props: DescriptionFieldProps) => {
  const DefaultComponent = props.description;
  return props.description && _.isString(props.description) ? (
    <DescriptionComponent description={props.description} />
  ) : (
    <>{DefaultComponent}</>
  );
};

export default DescriptionFieldTemplate;

import { useAppDispatch, useAppSelector } from "store/hooks";
import _ from "lodash";
import { getDownloadFile } from "helpers/apis/download";
import { SubsetsManagerScreenRouteModel } from "models/routes.model";
import { useParams } from "react-router-dom";
import { ReactComponent as DownloadIcon } from "assets/download.svg";
import { ReactComponent as CloseIcon } from "assets/close.svg";
import { markAsSeen } from "store/userManagementSlice";

const NewSubsetFileNotification = () => {
  const params: SubsetsManagerScreenRouteModel = useParams();
  const dispatch = useAppDispatch();

  const userNotifications = useAppSelector(
    (state) => state.userManagementSlice.notifications,
  );

  const renderSubsetCreateExportNotification = () => {
    const subsetCreateExportNotification = _.filter(
      userNotifications,
      (notification) => {
        return (
          notification.type === "subset_create_export" &&
          notification.seen === false
        );
      },
    );

    if (subsetCreateExportNotification.length > 0) {
      return _.map(subsetCreateExportNotification, (notification) => {
        return (
          <div
            key={notification?.message}
            className="flex justify-between p-2 my-1 border-[1px] rounded border-paletteYellow text-sm"
          >
            <div className="flex gap-x-4">
              <div className="flex justify-center items-center ">
                <DownloadIcon />
              </div>
              <div>
                <div className=" ">
                  Download link for{" "}
                  <span className="font-medium">
                    {notification?.data?.dataset_name}
                  </span>{" "}
                  subset is ready.
                </div>
                <div
                  className="underline cursor-pointer"
                  onClick={() =>
                    notification?.data?.file_id &&
                    getDownloadFile(
                      {
                        datasetID: params?.dataset_id,
                        fileID: notification?.data?.file_id,
                      },
                      dispatch,
                    )
                  }
                >
                  Click here to download.
                </div>
              </div>
            </div>

            <div
              className="flex justify-center items-center cursor-pointer"
              onClick={() => dispatch(markAsSeen(notification?.id))}
            >
              <CloseIcon width="16px" height="16px" />
            </div>
          </div>
        );
      });
    }
  };
  return <div className="">{renderSubsetCreateExportNotification()}</div>;
};

export default NewSubsetFileNotification;

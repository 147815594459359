import { useAppSelector } from "store/hooks";
import { FormModel, SelectFieldModel, TextFieldModel } from "models/form.model";
import { AnnotatableEnum } from "models/global.model";
import { useEffect, useState } from "react";
import TagNameInput from "Pages/InternalUserToolsPage/ThresholdAnalysis/TagNameInput";
import AttributeForms from "Pages/InternalUserToolsPage/ThresholdAnalysis/AttributeForms";
import ThresholdAnalysisSelect from "Pages/InternalUserToolsPage/ThresholdAnalysis/ThresholdAnalysisSelect";
import ThresholdAnalysisSubmit from "Pages/InternalUserToolsPage/ThresholdAnalysis/ThresholdAnalysisSubmit";
import ThresholdAnalysisReset from "Pages/InternalUserToolsPage/ThresholdAnalysis/ThresholdAnalysisReset";

const thresholdAnalysisForm: FormModel = {
  attribute: {
    type: "text",
    key: "attribute",
    value: "",
    label: "attribute",
  },
  ambiguity: {
    type: "single_slider",
    key: "ambiguity",
    value: 0,
    label: "Ambiguity Threshold",
    min: 0,
    max: 1,
    required: true,
    settings: {
      renderNumberInput: true,
    },
  },
  answer: {
    type: "select",
    key: "answer",
    options: [],
    value: "",
    label: "Answer Indication Correctness",
    required: true,
  },
};

const ThresholdAnalysis = () => {
  const objectAttributes = useAppSelector(
    (state) =>
      state.datasetSlice.attributes?.filter(
        (attribute) =>
          attribute.annotatable_type === AnnotatableEnum.MediaObject,
      ),
  );

  const [tagName, setTagName] = useState<TextFieldModel>({
    type: "text",
    key: "name",
    label: "Base Tag Name:",
    placeholder: "Enter Tag Name...",
    value: "",
    required: true,
  });

  const [attributeSelection, setAttributeSelection] =
    useState<SelectFieldModel>({
      type: "select",
      key: "attribute",
      value: "",
      label: "Select attribute:",
      placeholder: "Select an attribute...",
      options: [],
    });

  const [attributesList, setAttributesList] = useState<{
    [key: string]: typeof thresholdAnalysisForm;
  }>({});

  // Set options for attribute selection
  useEffect(() => {
    addAllObjectAttributesToAttributeSelection();
  }, []);

  const addAllObjectAttributesToAttributeSelection = () => {
    setAttributeSelection({
      ...attributeSelection,
      value: "",
      options: objectAttributes.map((attribute) => ({
        value: attribute.id,
        label: attribute.name,
      })),
    });
  };

  const handleResetAll = () => {
    setAttributesList({});
    setTagName({ ...tagName, value: "" });
    addAllObjectAttributesToAttributeSelection();
  };

  return (
    <div className="flex flex-col gap-y-2">
      <div className="w-full flex justify-between pb-2">
        <div className="text-3xl">Threshold Analysis</div>
      </div>

      <div className="w-full flex justify-between border-b-2 pb-2">
        <div className="w-1/2">
          <ThresholdAnalysisSelect
            attributeSelection={attributeSelection}
            setAttributeSelection={setAttributeSelection}
            attributesList={attributesList}
            setAttributesList={setAttributesList}
          />
          <TagNameInput tagNameField={tagName} setTagNameField={setTagName} />
        </div>
        <div className="flex flex-col-reverse">
          <div className="flex gap-x-2">
            <ThresholdAnalysisReset handleResetAll={handleResetAll} />
            <ThresholdAnalysisSubmit
              tagName={tagName}
              setTagName={setTagName}
              attributesList={attributesList}
              setAttributesList={setAttributesList}
              handleResetAll={handleResetAll}
            />
          </div>
        </div>
      </div>

      <AttributeForms
        attributesList={attributesList}
        setAttributesList={setAttributesList}
        attributeSelection={attributeSelection}
        setAttributeSelection={setAttributeSelection}
      />
    </div>
  );
};

export default ThresholdAnalysis;

import DetailsDescriptionPurple from "components/Description/DetailsDescriptionPurple";
import { MLAnnotationModel } from "models/mlAnnotationModel.model";
import AutomationCorrectnessCurve from "Pages/AnnotationRunManager/AIModelDialog/AutomationCorrectnessCurve";
import { aiModelDescription } from "text/aiModelText";

type Props = {
  aiModel: MLAnnotationModel;
};

const AIModelOverviewComponent = ({ aiModel }: Props) => {
  return (
    <figure>
      <div className="pb-2" data-test="acc_element">
        <figcaption>Automation Correctness Curve</figcaption>
        <DetailsDescriptionPurple text={aiModelDescription} />
      </div>
      <AutomationCorrectnessCurve
        curve={aiModel?.automation_correctness_curve}
      />
    </figure>
  );
};

export default AIModelOverviewComponent;

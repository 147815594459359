import _ from "lodash";
import {
  InstanceModel,
  MediaModel,
  MediaObjectRawModel,
} from "models/exploration.model";
import {
  AttributeGroupEnum,
  AttributeMetadataModel,
  AttributesModel,
} from "models/attributes.model";
import { useEffect, useRef, useState } from "react";
import sortAttributesArrayAlphabeticallyAndCustom from "helpers/functions/sortAttributesArrayAlphabeticallyAndCustom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  AnnotatableEnum,
  SelectedViewModel,
  TagModel,
  TagTypeModel,
} from "models/global.model";
import TagsSelector from "components/Inputs/TagsSelector";
import { MediaDetailsScreenRouteModel } from "models/routes.model";
import { useParams } from "react-router-dom";
import { fetchTags } from "store/datasetSlice";
import { postTagAssignOrUnassign } from "helpers/apis/tags";
import { fetchOneMedia } from "store/explorationMediaSlice";
import { fetchOneMediaObject } from "store/explorationMediaObjectsSlice";
import selectMediatype from "helpers/functions/selectMediatype";
import getAttributeFromAttributesMeta from "helpers/functions/getAttributeFromAttributesMeta";
import { memoizedFindAppropriateThumbnail } from "components/utilFunctions";
import { ReactComponent as ArrowLeftIcon } from "assets/arrow_small_left.svg";
import InfoCard from "components/Cards/InfoCard";
import AttributesTags from "sections/InfoPanel/AttributesTags";
import MediaIcon from "assets/image.svg";
import InfoPanelTabs from "sections/InfoPanel/Tabs";
import { fetchOneInstance } from "store/explorationInstancesSlice";
import {
  isAttributeAIAnnotationAttributeOnly,
  isAttributeAnnotationAttribute,
} from "helpers/functions/attributes/attributesHelpers";
import { ReactComponent as AiIcon } from "assets/ai.svg";
import SimpleSelect from "components/Inputs/Form/SimpleSelect";
import { SelectFieldIcons, SelectFieldModel } from "models/form.model";
import { determineActiveFilterKeyFromParam } from "store/filterDataSlice";

/**
 * InformationSection component
 * @param showMediaOrMediaObject - The selected view
 * @param currentMedia - The current media
 * @param currentMediaObject - The current media object
 * @param fetchMediaAndMediaObjects -
 *      Update the media and media object by media ID
 *      When the user is in media object exploration and:
 *      - The user assign or unassign a tag to an media
 *      - The user assign or unassign a tag to an media attribute
 * @param fetchMediaObjectsByMediaID -
 *      Update the media objects
 *      When the user is in media exploration and:
 *      - The user assign or unassign a tag to an media object
 *      - The user assign or unassign a tag to an media object attribute
 */
interface Props {
  showMediaOrMediaObject: AnnotatableEnum;
  currentMedia: MediaModel | undefined;
  currentMediaObject: MediaObjectRawModel | undefined;
  currentInstance?: InstanceModel;
  fetchMediaAndMediaObjects?: (mediaID: string) => void;
  fetchMediaObjectsByMediaID?: () => void;
}

const InfoPanel = ({
  showMediaOrMediaObject,
  currentMedia,
  currentMediaObject,
  currentInstance,
  fetchMediaAndMediaObjects,
  fetchMediaObjectsByMediaID,
}: Props) => {
  const dispatch = useAppDispatch();
  const params: MediaDetailsScreenRouteModel = useParams();

  const divRef = useRef<HTMLDivElement>(null);

  const selectedView = useAppSelector((state) => state.appSlice.selectedView);
  const tags = useAppSelector((state) =>
    _.keyBy(state.datasetSlice?.tags, "name"),
  );
  const mediatype = useAppSelector(
    (state) => state.datasetSlice.activeDataSet?.mediatype,
  );
  const attributesMeta = useAppSelector(
    (state) => state.datasetSlice.attributes,
  );
  const filterData = useAppSelector((state) => state.filterDataSlice);

  const [infoView, setInfoView] = useState<AnnotatableEnum>(selectedView);
  const [isMediaDetailsOpen, setIsMediaDetailsOpen] = useState(
    selectedView !== AnnotatableEnum.MediaObject,
  );

  const [mediaInfo, setMediaInfo] = useState<Record<string, any>>();
  const [initMediaInfo, setInitMediaInfo] = useState<Record<string, any>>();
  const [mediaObjectInfo, setMediaObjectInfo] = useState<Record<string, any>>();
  const [initMediaObjectInfo, setInitMediaObjectInfo] =
    useState<Record<string, any>>();
  const [instanceInfo, setInstanceInfo] = useState<Record<string, any>>();
  const [initInstanceInfo, setInitInstanceInfo] =
    useState<Record<string, any>>();

  const [selectedAttributeID, setSelectedAttributeID] = useState<string | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState(false);

  // Get mediaInfo and mediaObjectInfo from the new media or media object,
  //  do sorting on the items.
  useEffect(() => {
    // Map mediaInfo
    const mediaInfoAttributes = _.filter(
      currentMedia?.attributes,
      (attribute) => {
        const attributeMeta = getAttributeFromAttributesMeta(
          attributesMeta,
          attribute?.metadata_id,
        );
        return attributeMeta?.attribute_group === "auto_attribute";
      },
    );
    const sortedMediaInfoAttributes =
      sortAttributesArrayAlphabeticallyAndCustom(
        mediaInfoAttributes,
        attributesMeta,
      );
    const mediaInfoTemp = prepareAttribute(sortedMediaInfoAttributes);
    setMediaInfo({
      Name: currentMedia?.back_reference,
      "Scene ID": currentMedia?.scene_id,
      "Frame Index": currentMedia?.frame_idx,
      "Camera Name": currentMedia?.name,
      //TODO: Include batch name/id in the future
      //'Batch Name': currentMedia?.batchName,
      //'Batch Id': currentMedia?.batchId,
      ...mediaInfoTemp,
    });

    const initMediaInfoAttributes = _.filter(
      currentMedia?.attributes,
      (attribute) => {
        const attributeMeta = getAttributeFromAttributesMeta(
          attributesMeta,
          attribute?.metadata_id,
        );
        return attributeMeta?.attribute_group === "initial_attribute";
      },
    );
    const sortedInitMediaInfoAttributes =
      sortAttributesArrayAlphabeticallyAndCustom(
        initMediaInfoAttributes,
        attributesMeta,
      );
    setInitMediaInfo(prepareAttribute(sortedInitMediaInfoAttributes));

    // Map mediaObjectInfo
    const mediaObjectInfoAttributes = _.filter(
      currentMediaObject?.attributes,
      (attribute) => {
        const attributeMeta = getAttributeFromAttributesMeta(
          attributesMeta,
          attribute?.metadata_id,
        );
        return attributeMeta?.attribute_group === "auto_attribute";
      },
    );
    const sortedMediaObjectInfoAttributes =
      sortAttributesArrayAlphabeticallyAndCustom(
        mediaObjectInfoAttributes,
        attributesMeta,
      );
    const mediaObjectInfoTemp = prepareAttribute(
      sortedMediaObjectInfoAttributes,
    );
    setMediaObjectInfo({
      Name: currentMediaObject?.back_reference,
      ...mediaObjectInfoTemp,
    });

    const initMediaObjectInfoAttributes = _.filter(
      currentMediaObject?.attributes,
      (attribute) => {
        const attributeMeta = getAttributeFromAttributesMeta(
          attributesMeta,
          attribute?.metadata_id,
        );
        return attributeMeta?.attribute_group === "initial_attribute";
      },
    );
    const sortedInitMediaObjectInfoAttributes =
      sortAttributesArrayAlphabeticallyAndCustom(
        initMediaObjectInfoAttributes,
        attributesMeta,
      );

    setInitMediaObjectInfo(
      prepareAttribute(sortedInitMediaObjectInfoAttributes),
    );

    // Map instanceInfo
    const instanceInfoAttributes = _.filter(
      currentInstance?.attributes,
      (attribute) => {
        const attributeMeta = getAttributeFromAttributesMeta(
          attributesMeta,
          attribute?.metadata_id,
        );
        return attributeMeta?.attribute_group === "auto_attribute";
      },
    );
    const sortedInstanceInfoAttributes =
      sortAttributesArrayAlphabeticallyAndCustom(
        instanceInfoAttributes,
        attributesMeta,
      );
    const instanceInfoTemp = prepareAttribute(sortedInstanceInfoAttributes);
    setInstanceInfo({
      Name: currentInstance?.back_reference,
      ...instanceInfoTemp,
    });

    const initInstanceInfoAttributes = _.filter(
      currentInstance?.attributes,
      (attribute) => {
        const attributeMeta = getAttributeFromAttributesMeta(
          attributesMeta,
          attribute?.metadata_id,
        );
        return attributeMeta?.attribute_group === "initial_attribute";
      },
    );
    const sortedInitInstanceInfoAttributes =
      sortAttributesArrayAlphabeticallyAndCustom(
        initInstanceInfoAttributes,
        attributesMeta,
      );

    setInitInstanceInfo(prepareAttribute(sortedInitInstanceInfoAttributes));
  }, [attributesMeta, currentMedia, currentMediaObject, currentInstance]);

  // Change the attributes shape from AttributesModel to a Record.
  // The Record is used in the CollapsibleCardAlt component
  const prepareAttribute = (
    attributesList: AttributesModel[],
  ): Record<string, unknown> => {
    let items = {};
    _.map(attributesList, (info) => {
      const name = _.find(attributesMeta, { id: info?.id })?.name;
      if (!_.isUndefined(info?.value) && !_.isUndefined(name)) {
        let value = info?.value;
        // Include min and max if they exist
        if (!_.isUndefined(info?.max) && !_.isUndefined(info?.min)) {
          value = {
            value: info?.value,
            min: info?.min,
            max: info?.max,
          };
        }
        items = { ...items, [name]: value };
      }
    });
    return items;
  };

  const renderMedia = () => {
    const url = generateThumbnail(currentMedia?.thumbnails);
    return (
      <div
        ref={divRef}
        className="w-full h-[200px] mb-4 mx-auto flex justify-center"
      >
        <img src={url} className="h-full w-auto object-contain" />
      </div>
    );
  };

  const generateThumbnail = (
    thumbnails: Record<string, string> | undefined,
  ) => {
    if (!thumbnails) return MediaIcon;
    const thumbnail =
      currentMedia?.thumbnails?.[
        memoizedFindAppropriateThumbnail(divRef, currentMedia?.thumbnails) ||
          "140x113"
      ];
    return thumbnail;
  };

  const renderMediaCollapse = () => {
    if (selectedView === AnnotatableEnum.Media) return null;
    return (
      <div
        className="w-full flex justify-between text-paletteGray-10 font-medium cursor-pointer"
        onClick={() => setIsMediaDetailsOpen(!isMediaDetailsOpen)}
      >
        Media details
        <ArrowLeftIcon
          width={16}
          height={16}
          className={` transition-all ${
            isMediaDetailsOpen ? "-rotate-90" : "rotate-90"
          }`}
        />
      </div>
    );
  };

  const renderInfo = (view: AnnotatableEnum) => {
    let title;
    let info;
    let initInfo;
    let qmBBMetrics: Record<string, any> = {};

    switch (view) {
      case AnnotatableEnum.Media:
        title = selectMediatype(mediatype);
        info = mediaInfo;
        initInfo = initMediaInfo;
        break;
      case AnnotatableEnum.MediaObject:
        {
          title = "Object";
          info = mediaObjectInfo;
          initInfo = initMediaObjectInfo;
          const geometry = _.last(currentMediaObject?.qm_data);
          if (!_.isUndefined(geometry) && "metrics" in geometry) {
            qmBBMetrics = geometry.metrics as Record<string, any>;
          }
        }
        break;
      case AnnotatableEnum.Instance:
        title = "Instance";
        info = instanceInfo;
        initInfo = initInstanceInfo;
        break;
      default:
        console.error("Info panel: Unknown view");
        break;
    }

    // If there is no info, return null
    if (_.isUndefined(info) || _.isEmpty(info)) {
      return null;
    }

    const filteredKeys = ["Name", "Number of Boxes", "width", "height"];
    let groupedInfo: { [key: string]: any }[] = [
      {
        Name: info?.Name,
        Objects: info?.["Number of Bounding Boxes"],
      },
    ];

    if (info?.width || info?.height) {
      groupedInfo = [
        ...groupedInfo,
        {
          Width: info?.width,
          Height: info?.height,
        },
      ];
    }

    const restOfInfo = _.omit(info, filteredKeys);
    if (!_.isEmpty(restOfInfo)) {
      groupedInfo = [
        ...groupedInfo,
        {
          ..._.omit(info, filteredKeys),
        },
      ];
    }

    if (!_.isEmpty(qmBBMetrics)) {
      groupedInfo = [
        ...groupedInfo,
        {
          ...qmBBMetrics,
        },
      ];
    }

    return (
      <InfoCard
        id="info_data"
        title={`${title} data`}
        data={groupedInfo}
        detailsData={initInfo}
      />
    );
  };

  const renderTagSelector = (type: SelectedViewModel) => {
    let selectedTypeItem:
      | MediaModel
      | MediaObjectRawModel
      | InstanceModel
      | undefined;
    let title;
    let tagType: TagTypeModel | undefined;
    let addOrRemoveTagFunction: (
      tagID: string,
      operation: "assign" | "unassign",
    ) => Promise<void>;

    switch (type) {
      case AnnotatableEnum.Media:
        selectedTypeItem = currentMedia;
        title = "Media tags";
        tagType = TagTypeModel.Media;
        addOrRemoveTagFunction = handleMediaAssignAndUnAssign;
        break;
      case AnnotatableEnum.MediaObject:
        selectedTypeItem = currentMediaObject;
        title = "Object tags";
        tagType = TagTypeModel.MediaObject;
        addOrRemoveTagFunction = handleMediaObjectAssignAndUnAssign;
        break;
      case AnnotatableEnum.Instance:
        selectedTypeItem = currentInstance;
        title = "Instance tags";
        tagType = TagTypeModel.Instance;
        addOrRemoveTagFunction = handleInstanceAssignAndUnAssign;
        break;
      default:
        console.error("Info panel: Unknown view");
        break;
    }

    if (_.isUndefined(selectedTypeItem)) {
      return null;
    }

    let selectedTags: { [name: string]: TagModel } = {};
    _.map(tags, (tag) => {
      if (_.includes(selectedTypeItem?.tags, tag?.id)) {
        selectedTags = { ...selectedTags, [tag?.name]: tag };
      }
    });

    if (_.isUndefined(title) || _.isUndefined(tagType)) {
      return null;
    }
    return (
      <div className="flex flex-col">
        {renderCardTitle(title)}
        <TagsSelector
          items={_.pickBy(tags, (tag: TagModel) => {
            return tag?.database_object_type === tagType;
          })}
          selectedItems={selectedTags}
          handleAddTag={(tag) => addOrRemoveTagFunction(tag?.id, "assign")}
          handleRemoveTag={(tag) => addOrRemoveTagFunction(tag?.id, "unassign")}
          enableCreateNewTag={{
            type: tagType,
            datasetID: params?.id,
            onAddNewItemSuccess: (newTagID) => {
              addOrRemoveTagFunction(newTagID, "assign").then(() => {
                dispatch(
                  fetchTags({
                    query: {
                      parentDataSetID: params?.id,
                    },
                  }),
                );
              });
            },
          }}
          disabled={isLoading}
        />
      </div>
    );
  };

  const renderCardTitle = (title: string) => {
    return (
      <div className="pb-2 pt-1 text-sm text-paletteGray-10 font-medium">
        {title}
      </div>
    );
  };

  const handleMediaAssignAndUnAssign = (
    tagID: string,
    operation: "assign" | "unassign",
  ) => {
    return postTagAssignOrUnassign(
      operation,
      {
        datasetId: params?.id,
        database_object_type: TagTypeModel.Media,
        database_object_id: currentMedia?.id || "",
        tagId: tagID,
      },
      setIsLoading,
    ).then(() => {
      // Media view: assign tag on an media
      if (selectedView === AnnotatableEnum.Media) {
        dispatch(
          fetchOneMedia({
            query: {
              mediaId: currentMedia?.id || "",
              datasetId: params?.id,
            },
            options: {
              replaceCurrentMedia: true,
            },
          }),
        );
      }
      // Media object view: assign tag on an media
      else if (selectedView === AnnotatableEnum.MediaObject) {
        // Fetch the new media only in objects exploration screen
        // Fetch the new media and media object in objects details screen
        if (fetchMediaAndMediaObjects) {
          fetchMediaAndMediaObjects(currentMedia?.id || "");
        }
        dispatch(
          fetchOneMedia({
            query: {
              mediaId: currentMedia?.id || "",
              datasetId: params?.id,
            },
          }),
        );
      }
    });
  };

  const handleMediaObjectAssignAndUnAssign = (
    tagID: string,
    operation: "assign" | "unassign",
  ) => {
    return postTagAssignOrUnassign(
      operation,
      {
        datasetId: params?.id,
        database_object_type: TagTypeModel.MediaObject,
        database_object_id: currentMediaObject?.id || "",
        tagId: tagID,
      },
      setIsLoading,
    ).then(() => {
      // Media object view: assign tag on an media object
      if (selectedView === AnnotatableEnum.MediaObject) {
        dispatch(
          fetchOneMediaObject({
            query: {
              mediaObjectId: currentMediaObject?.id || "",
              datasetId: params?.id,
            },
            options: { replaceCurrentMediaObject: true },
          }),
        );
      }
      // Media view: assign tag on an media object
      else if (selectedView === AnnotatableEnum.Media) {
        // Update local state
        if (fetchMediaObjectsByMediaID) {
          fetchMediaObjectsByMediaID();
        }
        // Update the media object in Redux store
        dispatch(
          fetchOneMediaObject({
            query: {
              mediaObjectId: currentMediaObject?.id || "",
              datasetId: params?.id,
            },
          }),
        );
      }
    });
  };

  const handleInstanceAssignAndUnAssign = (
    tagID: string,
    operation: "assign" | "unassign",
  ) => {
    return postTagAssignOrUnassign(
      operation,
      {
        datasetId: params?.id,
        database_object_type: TagTypeModel.Instance,
        database_object_id: currentInstance?.id || "",
        tagId: tagID,
      },
      setIsLoading,
    ).then(() => {
      if (!currentInstance?.id) return;
      dispatch(
        fetchOneInstance({
          query: {
            instanceId: currentInstance?.id || "",
            datasetId: params?.id,
          },
          options: { replaceCurrentInstance: true },
        }),
      );
    });
  };

  const renderSelectAttributes = () => {
    const selectedTypeItem = selectItemFromType();

    if (_.isUndefined(selectedTypeItem)) {
      return null;
    }

    // Filter for only annotation attributes
    const filteredAttributes = _.filter(
      selectedTypeItem?.attributes,
      (attribute) => {
        const attributeMeta = getAttributeFromAttributesMeta(
          attributesMeta,
          attribute?.metadata_id,
        );
        if (!attributeMeta) return false;
        return isAttributeAnnotationAttribute(attributeMeta?.attribute_group);
      },
    );

    return (
      <div>
        {renderCardTitle("Attributes")}
        {selectAttributesView(filteredAttributes)}
      </div>
    );
  };

  // Render the attributes dropdown or menu
  const selectAttributesView = (filteredAttributes: AttributesModel[]) => {
    // If there are no attributes, render a message
    if (filteredAttributes?.length === 0) {
      return renderNoAttributes();
    }

    // If there are attributes, and nothing is selected, render the attributes menu
    if (_.isNull(selectedAttributeID)) {
      return renderAttributesMenu(filteredAttributes);
    }

    const currentAttributeID = _.find(filteredAttributes, {
      id: selectedAttributeID,
    })?.id;

    // If the current item (annotatable) have the selected attribute,
    //  render the attributes dropdown
    if (currentAttributeID === selectedAttributeID) {
      return renderAttributesDropdown(filteredAttributes);
    }
    // If the current item (annotatable) does not have the selected attribute,
    //  render the attributes menu
    else {
      return renderAttributesMenu(filteredAttributes);
    }
  };

  const renderNoAttributes = () => {
    return (
      <div className="w-full flex items-center justify-center text-paletteGray-6">
        No attributes!
      </div>
    );
  };

  const renderAttributesMenu = (filteredAttributes: AttributesModel[]) => {
    if (filteredAttributes?.length === 0) {
      return renderNoAttributes();
    }
    return (
      <div className="flex flex-col gap-y-1">
        {_.map(
          filteredAttributes,
          (attribute: AttributesModel, key: string) => {
            const attributeMeta = getAttributeFromAttributesMeta(
              attributesMeta,
              attribute?.metadata_id,
            );

            if (!attributeMeta) return null;

            const isAttributeInActiveFiltersVal = isAttributeInActiveFilters(
              attribute?.id,
            );

            return (
              <button
                key={key}
                className={`button-layer justify-start animate-none
                ${
                  isAttributeInActiveFiltersVal
                    ? "border-[1px] border-paletteOrange"
                    : ""
                }
                  `}
                onClick={() => setSelectedAttributeID(attribute?.id)}
                data-test={`expl_info_attribute_list_${attributeMeta?.name}`}
              >
                {attributeMeta?.name}
                {renderAIIcon(attributeMeta)}
              </button>
            );
          },
        )}
      </div>
    );
  };

  const renderAIIcon = (attributeMeta: AttributeMetadataModel | undefined) => {
    if (!attributeMeta) return null;
    if (isAttributeAIAnnotationAttributeOnly(attributeMeta?.attribute_group)) {
      return <AiIcon className="w-4 h-4 ml-1 text-palettePurple" />;
    }
    return null;
  };

  const renderAttributesDropdown = (filteredAttributes: AttributesModel[]) => {
    const selectionField: SelectFieldModel = {
      type: "select",
      key: "id",
      value: selectedAttributeID || "",
      placeholder: "Select user group",
      options: [
        {
          value: null,
          label: "--Select Attribute--",
        },
        ..._.map(filteredAttributes, (attribute: AttributesModel) => {
          const attributeMeta = _.find(attributesMeta, {
            id: attribute?.id,
          });

          const isAttributeInActiveFiltersVal = isAttributeInActiveFilters(
            attribute?.id,
          );
          return {
            value: attribute.id,
            label: attributeMeta?.name || "",
            icon:
              attributeMeta?.attribute_group ===
              AttributeGroupEnum.ML_ANNOTATION_ATTRIBUTE
                ? SelectFieldIcons.AiIcon
                : undefined,
            bgColor: isAttributeInActiveFiltersVal
              ? "border-[1px] border-paletteOrange"
              : undefined,
          };
        }),
      ],
    };
    return (
      <SimpleSelect
        field={selectionField}
        value={selectedAttributeID || ""}
        handleOnChange={(event) => setSelectedAttributeID(event.target.value)}
      />
    );
  };

  const renderAttributeCard = () => {
    const itemsToOmit = [
      "name",
      "annotations",
      "subset_attributes",
      "id",
      "dataset_id",
      "annotatable_id",
      "database_object_type",
      "subset_ids",
      "tags",
    ];

    const selectedTypeItem = selectItemFromType();

    if (_.isUndefined(selectedTypeItem)) {
      return null;
    }

    const attributes = selectedTypeItem.attributes;

    const selectedAttribute = _.find(
      attributes,
      (att) => att?.id === selectedAttributeID,
    );

    const attributeMeta = getAttributeFromAttributesMeta(
      attributesMeta,
      selectedAttribute?.metadata_id || "",
    );

    const filteredKeys = [
      "attribute_type",
      "cant_solves",
      "value",
      "modal",
      "credibility",
      "ambiguity",
      "frequency",
      "archived",
    ];
    let groupedInfo: { [key: string]: any }[] = [
      {
        Question: attributeMeta?.question,
      },
      {
        "Attribute type": attributeMeta?.attribute_type,
        "Can't solve": selectedAttribute?.cant_solves,
        Value: selectedAttribute?.value,
        Modal: selectedAttribute?.modal,
        Credibility: selectedAttribute?.credibility,
        Ambiguity: selectedAttribute?.ambiguity,
      },
    ];

    if (selectedAttribute?.frequency) {
      groupedInfo = [
        ...groupedInfo,
        {
          Frequency: " ",
          ...selectedAttribute?.frequency,
        },
      ];
    }

    const moreInfo = _.omit(selectedAttribute, [
      ...filteredKeys,
      ...itemsToOmit,
    ]);

    if (_.isUndefined(attributeMeta?.question)) {
      return null;
    }

    return (
      <InfoCard
        id="attribute_data"
        title="Parameters"
        data={groupedInfo}
        detailsData={moreInfo as any}
      />
    );
  };

  const renderAttributesTags = () => {
    const selectedTypeItem = selectItemFromType();

    if (_.isUndefined(selectedTypeItem)) {
      return null;
    }

    const attributes = selectedTypeItem?.attributes;
    const selectedItemID = selectedTypeItem?.id;

    const annotationAttributes = _.find(
      attributes,
      (att) => att?.id === selectedAttributeID,
    );
    if (!_.isUndefined(annotationAttributes)) {
      return (
        <div>
          {renderCardTitle("Attribute tags")}
          <AttributesTags
            showMediaOrMediaObject={showMediaOrMediaObject}
            annotationAttributes={annotationAttributes}
            selectedItemID={selectedItemID}
            fetchMediaAndMediaObjects={fetchMediaAndMediaObjects}
            currentMediaID={currentMedia?.id}
            fetchMediaObjectsByMediaID={fetchMediaObjectsByMediaID}
          />
        </div>
      );
    }
  };

  const isAttributeInActiveFilters = (attributeID: string) => {
    const activeFilters =
      filterData?.[determineActiveFilterKeyFromParam(selectedView)];

    const attributeIDinActiveFilters = _.find(activeFilters, (filter) => {
      const attributeIDinActiveFilters = _.split(filter?.key, ".")[1];
      return attributeIDinActiveFilters === attributeID;
    });
    return _.isUndefined(attributeIDinActiveFilters) ? false : true;
  };

  const renderBody = () => {
    switch (showMediaOrMediaObject) {
      case AnnotatableEnum.Media:
        return renderMediaBody();
      case AnnotatableEnum.MediaObject:
        return renderMediaObjectBody();
      case AnnotatableEnum.Instance:
        return renderInstanceBody();
      default:
        return null;
    }
  };

  const renderMediaBody = () => {
    return (
      <>
        {renderMediaCollapse()}
        {isMediaDetailsOpen && renderInfo(AnnotatableEnum.Media)}
        {isMediaDetailsOpen && renderTagSelector(AnnotatableEnum.Media)}
      </>
    );
  };

  const renderMediaObjectBody = () => {
    return (
      <>
        {renderMediaBody()}
        {renderInfo(AnnotatableEnum.MediaObject)}
        {renderTagSelector(AnnotatableEnum.MediaObject)}
      </>
    );
  };

  const renderInstanceBody = () => {
    return (
      <>
        {renderInfo(AnnotatableEnum.Instance)}
        {renderTagSelector(AnnotatableEnum.Instance)}
      </>
    );
  };

  const selectItemFromType = () => {
    switch (showMediaOrMediaObject) {
      case AnnotatableEnum.Media:
        return currentMedia;
      case AnnotatableEnum.MediaObject:
        return currentMediaObject;
      case AnnotatableEnum.Instance:
        return currentInstance;
      default:
        console.error("Info panel: Unknown view");
        break;
    }
  };

  return (
    <section
      aria-label={`${showMediaOrMediaObject} Info`}
      className="w-[364px] h-full flex flex-col pb-3 pr-3 pl-4 bg-white animate-fade"
    >
      {/* Header */}
      <div>
        {showMediaOrMediaObject === AnnotatableEnum.MediaObject &&
          renderMedia()}
        <InfoPanelTabs
          showMediaOrMediaObject={showMediaOrMediaObject}
          selectedTab={infoView}
          setSelectedTab={setInfoView}
          currentMedia={currentMedia}
          currentMediaObject={currentMediaObject}
          currentInstance={currentInstance}
        />
      </div>

      {/* Details */}
      {infoView === AnnotatableEnum.Media ||
      infoView === AnnotatableEnum.MediaObject ||
      infoView === AnnotatableEnum.Instance ? (
        <div className="flex-1 flex flex-col gap-y-2 overflow-y-scroll">
          {renderBody()}
        </div>
      ) : null}

      {/* Attributes */}
      {infoView === AnnotatableEnum.Attribute ? (
        <div className="flex-1 flex flex-col gap-y-2 overflow-y-scroll">
          {renderSelectAttributes()}
          {renderAttributeCard()}
          {renderAttributesTags()}
        </div>
      ) : null}
    </section>
  );
};

export default InfoPanel;

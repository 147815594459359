import { useAppDispatch, useAppSelector } from "store/hooks";
import { setSortData } from "store/sortDataSlice";

import { SortingRequestModel } from "models/exploration.model";
import { OrderSortModel } from "models/global.model";
import _ from "lodash";
import SearchBar from "components/Inputs/SearchBar";
import { useState } from "react";
import { FilterAttributeGroupEnum, FilterModel } from "models/filter.model";
import GroupsList from "sections/SidebarMenu/FiltersDialog/GroupsList";
import SortingPopover from "sections/SidebarMenu/FiltersDialog/SortingTab/SortingPopover";
import { ReactComponent as CloseIcon } from "assets/close.svg";
import { determineFilterKeyFromParam } from "store/filterDataSlice";

const SortingTab = () => {
  const dispatch = useAppDispatch();

  const selectedView = useAppSelector((state) => state.appSlice.selectedView);
  const sortData = useAppSelector((state) => state.sortDataSlice);
  const filterData = useAppSelector((state) => state.filterDataSlice);
  const allAttributes = useAppSelector(
    (state) => state.datasetSlice.attributes,
  );

  const [searchValue, setSearchValue] = useState("");
  const [anchorSortingEl, setAnchorSortingEl] = useState<HTMLElement | null>(
    null,
  );
  const sortingItems = () => {
    // Combine all filters
    const allFilters = {
      ...getAutoAndInitialFilters(),
      ...getAnnotationOrMLAnnotationFilters(
        FilterAttributeGroupEnum.Annotation_Attribute,
      ),
      ...getAnnotationOrMLAnnotationFilters(
        FilterAttributeGroupEnum.ML_Annotation_Attribute,
      ),
    };
    return allFilters;
  };
  const getAutoAndInitialFilters = () => {
    const filters = filterData?.[determineFilterKeyFromParam(selectedView)];

    // Search auto and initial attributes filters and group them
    const autoAndInitialAttributes = _.filter(filters, (filter) => {
      return (
        filter?.attribute_group === FilterAttributeGroupEnum.Auto_Attribute ||
        filter?.attribute_group ===
          FilterAttributeGroupEnum.Initial_Attribute ||
        filter?.attribute_group === FilterAttributeGroupEnum.Text_Filter ||
        filter?.attribute_group === FilterAttributeGroupEnum.Subsets_And_Tags
      );
    });

    const searchedAutoAndInitialAttributes = _.filter(
      autoAndInitialAttributes,
      (filter) => {
        return _.includes(
          _.toLower(filter?.attribute_name),
          _.toLower(searchValue),
        );
      },
    );

    const groupedAutoAndInitialFilters = _.groupBy(
      searchedAutoAndInitialAttributes,
      "attribute_group",
    );
    return groupedAutoAndInitialFilters;
  };

  const getAnnotationOrMLAnnotationFilters = (
    group:
      | FilterAttributeGroupEnum.Annotation_Attribute
      | FilterAttributeGroupEnum.ML_Annotation_Attribute,
  ) => {
    const filters = filterData?.[determineFilterKeyFromParam(selectedView)];
    // Search annotation attributes or ML annotation attributes filters and group them
    const annotationAttributes = _.filter(filters, (filter) => {
      return filter?.attribute_group === group;
    });

    const searchedAnnotatedAttributes = _.filter(
      annotationAttributes,
      (attribute) => {
        const name =
          _.find(allAttributes, ["id", attribute.attribute_id])?.name ||
          attribute?.attribute_name;
        const isNameMatch = _.includes(_.toLower(name), _.toLower(searchValue));
        const isAttributeNameMatch = _.includes(
          _.toLower(attribute?.filter_name),
          _.toLower(searchValue),
        );
        return isNameMatch || isAttributeNameMatch;
      },
    );

    const groupedAnnotatedFilters = _.groupBy(
      searchedAnnotatedAttributes,
      "id",
    );

    return groupedAnnotatedFilters;
  };

  const handleOptionChange = (key: string) => {
    const newSort: SortingRequestModel[] = [
      {
        field: key,
        order: "asc",
      },
    ];

    dispatch(setSortData({ selectedView: selectedView, data: newSort }));
  };

  const handleOrderChange = (value: OrderSortModel) => {
    const selectedFilter = sortData?.[selectedView]?.[0]?.field;
    dispatch(
      setSortData({
        selectedView: selectedView,
        data: [
          {
            field: selectedFilter,
            order: value,
          },
        ],
      }),
    );
  };

  const renderTabContent = () => {
    const currentSort = sortData?.[selectedView]?.[0];

    if (_.isUndefined(currentSort)) {
      return renderSelectSortBy();
    }

    return renderSortingBy();
  };

  const renderSelectSortBy = () => {
    return (
      <>
        <SearchBar searchValue={searchValue} setSearchValue={setSearchValue} />
        <div className="flex-1 min-h-0 overflow-auto">
          <GroupsList
            allFilters={sortingItems()}
            onItemClick={(item) => {
              const clickedFilter = item as FilterModel;
              handleOptionChange(clickedFilter?.key as string);
              setSearchValue("");
            }}
          />
        </div>
      </>
    );
  };

  const renderSortingBy = () => {
    const currentSort = sortData?.[selectedView]?.[0];

    const filters = filterData?.[determineFilterKeyFromParam(selectedView)];
    const currentFilter = filters?.[currentSort?.field];

    let title = "";
    let label = currentFilter?.attribute_name || currentSort?.field;

    switch (currentFilter?.attribute_group) {
      case FilterAttributeGroupEnum.Auto_Attribute:
        title = "Auto attribute";
        break;
      case FilterAttributeGroupEnum.Initial_Attribute:
        title = "Initial attribute";
        break;
      case FilterAttributeGroupEnum.Annotation_Attribute: {
        title = "Attribute";
        const name =
          _.find(allAttributes, ["id", currentFilter?.id])?.name || "";
        label = name + " - " + currentFilter?.filter_name;
        break;
      }
      default:
        break;
    }

    return (
      <div className="border-[1px] border-paletteGray-6 rounded-lg">
        <div className="flex justify-between text-paletteGray-8 px-3 py-[9.5px] border-b-[1px] border-paletteGray-3 ">
          {title}
          <div className="flex gap-x-[12px]">{renderRemoveButton()}</div>
        </div>

        <div className="p-3">
          <div className="button-select-layer bg-paletteGray-2 mb-2">
            {_.upperFirst(label)}
          </div>
          {renderSortingPopover()}
        </div>
      </div>
    );
  };

  const renderRemoveButton = () => {
    return (
      <button
        className="button-select-layer px-1 py-0 text-paletteGray-10"
        onClick={() =>
          dispatch(setSortData({ selectedView: selectedView, data: [] }))
        }
      >
        <CloseIcon width={16} height={16} />
      </button>
    );
  };

  const renderSortingPopover = () => {
    if (_.isUndefined(sortData?.[selectedView]?.[0]?.order)) {
      return null;
    }

    return (
      <div className="w-fit">
        <SortingPopover
          anchorEl={anchorSortingEl}
          handlePopoverOpen={(
            event: React.MouseEvent<HTMLElement, MouseEvent>,
          ) => setAnchorSortingEl(event.currentTarget)}
          handlePopoverClose={() => setAnchorSortingEl(null)}
          selectedValue={sortData?.[selectedView]?.[0]?.order || "asc"}
          onClick={(value) => handleOrderChange(value)}
        />
      </div>
    );
  };

  return (
    <div className="min-h-0 flex-1 w-full p-4 pb-0 flex flex-col gap-y-2">
      {renderTabContent()}
    </div>
  );
};

export default SortingTab;

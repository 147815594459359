import { NotificationManagerScreenTabs } from "sections/NotificationCenter/index";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  fetchNotificationsStore,
  fetchUnreadNotificationsStore,
} from "store/notificationsSlice";
import { useEffect } from "react";
import UnreadNotificationList from "./UnreadNotificationList";
import AllNotificationList from "./AllNotificationList";

interface Props {
  selectedTab: NotificationManagerScreenTabs;
}

const NotificationListContainer = ({ selectedTab }: Props) => {
  const dispatch = useAppDispatch();
  const isUnreadView = selectedTab === NotificationManagerScreenTabs.unread;
  const notificationData = useAppSelector((state) => state.notificationSlice);

  useEffect(() => {
    if (notificationData.unreadNotifications.length < 1) {
      dispatch(
        fetchUnreadNotificationsStore({
          reqBody: { skip: 0, limit: 10 },
          reset: true,
        }),
      );
    }
    if (notificationData.notifications.length < 1) {
      dispatch(
        fetchNotificationsStore({
          reqBody: {
            skip: 0,
            limit: 10,
          },
          reset: true,
        }),
      );
    }
  }, []);

  return (
    <>
      <div
        id="unreadNotificationList"
        className="w-full h-full flex flex-col overflow-y-auto"
        style={{ display: isUnreadView ? "flex" : "none" }}
        data-test="unread_notifications_list"
      >
        <UnreadNotificationList />
      </div>
      <div
        id="allNotificationList"
        className="w-full h-full flex flex-col overflow-y-auto"
        style={{ display: isUnreadView ? "none" : "flex" }}
        data-test="all_notifications_list"
      >
        <AllNotificationList />
      </div>
    </>
  );
};

export default NotificationListContainer;

import { useState } from "react";
import TagsTable from "Pages/TagsManager/TagsTable";
import TagsTabs from "Pages/TagsManager/TagsTabs";
import Header from "sections/Headers/GeneralHeader";
import SearchBar from "components/Inputs/SearchBar";
import { useAppSelector } from "store/hooks";
import { ReactComponent as TagManagerIcon } from "assets/tags_gradient.svg";
import DatasetSubsetCounts from "sections/Headers/DatasetSubsetCounts";

export enum TagsManagerScreenTabs {
  all = "all",
  Media = "Media",
  MediaObject = "MediaObject",
  Attribute = "Attribute",
}

const TagsManager = () => {
  const [selectedTab, setSelectedTab] = useState<TagsManagerScreenTabs>(
    TagsManagerScreenTabs.all,
  );
  const tags = useAppSelector((state) => state.datasetSlice.tags);
  const [searchValue, setSearchValue] = useState<string>("");

  // ------------------------------------------- Render Component ------------------------------------
  return (
    <div className="mx-auto full-height-with-margin bg-white flex flex-col self-center border-[1px] border-paletteGray-5 rounded-xl m-1 mr-1">
      <Header
        title={<DatasetSubsetCounts />}
        icon={<TagManagerIcon className="w-5 h-5" />}
        iconBgcolor="bg-[#feeee4]"
      ></Header>
      <div className="px-[24px] min-h-0 py-4 flex-1 flex flex-col">
        <div className="w-full flex justify-between">
          <div className="w-fit">
            <TagsTabs
              tags={tags}
              selectedTabValue={selectedTab}
              onTabClick={setSelectedTab}
            />
          </div>

          <div className="min-w-0 flex-1 flex flex-row-reverse items-center gap-x-2">
            <div className="w-[500px]">
              <SearchBar
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
            </div>
          </div>
        </div>
        <div className="w-full flex-1 min-h-0">
          <TagsTable selectedTab={selectedTab} searchValue={searchValue} />
        </div>
      </div>
    </div>
  );
};

export default TagsManager;

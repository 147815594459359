import { APIFetchAxios, APIPostWithBodyAxios } from "routes/Auth";
import { SendFilterModel } from "models/filter.model";
import { SortingRequestModel } from "models/exploration.model";
import { AxiosResponse } from "axios";
import { DownloadFileMeta } from "models/download";
import { AnnotatableEnum, SelectedViewModel } from "models/global.model";
import { Dispatch } from "@reduxjs/toolkit";
import snackbarHelper from "helpers/snackbarHelperFn";

// Post request to export a JSON file, used to export data from DB
// It starts a background task and save the file on S3
// /dataset/${datasetID}/medias:export
// /dataset/${datasetID}/media_objects:export
export const postExportFile = async (
  type: SelectedViewModel,
  datasetID: string,
  subsetID: string | undefined,
  query: SendFilterModel[],
  sort: SortingRequestModel[],
  options: {
    includeAnnotations?: boolean;
    provideFullSubsetList?: boolean;
    mediasTags?: string[];
    mediasSubsets?: string[];
    mediaObjectsTags?: string[];
    mediaObjectsSubsets?: string[];
    attributesTags?: string[];
    attributes?: string[];
  },
): Promise<AxiosResponse<any, any>> => {
  let endpoint;
  if (type === AnnotatableEnum.Media) {
    endpoint = `/datasets/${datasetID}/medias:export`;
  } else {
    endpoint = `/datasets/${datasetID}/media_objects:export`;
  }
  const response = await APIPostWithBodyAxios(endpoint, {
    subset_id: subsetID,
    query: query,
    sort: sort,
    include_annotations: options?.includeAnnotations,
    provide_full_subset_list: options?.provideFullSubsetList,
    medias_tags: options?.mediasTags,
    medias_subsets: options?.mediasSubsets,
    media_objects_tags: options?.mediaObjectsTags,
    media_objects_subsets: options?.mediaObjectsSubsets,
    attributes_tags: options?.attributesTags,
    attributes: options?.attributes,
  });
  return response;
};

export const getDownloadFilesList = async (
  datasetID: string,
): Promise<AxiosResponse<DownloadFileMeta[]>> => {
  const response = await APIFetchAxios(`/datasets/${datasetID}/exports`);
  return response;
};

/**
 * An endpoint to download a file from S3 through a signed URL
 * @param query
 * @param query.fileID The file ID of the export file
 * @param query.datasetID The dataset ID of the export file
 * @param dispatch Dispatch function from Redux
 * @param setIsLoading Set loading state
 */
export const getDownloadFile = async (
  query: { fileID: string; datasetID: string },
  dispatch: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
) => {
  const { datasetID, fileID } = query;

  setIsLoading && setIsLoading(true);
  await APIFetchAxios(`/datasets/${datasetID}/exports/${fileID}`)
    .then((responseLink) => {
      const newWnd = window.open(
        responseLink?.data,
        "_blank",
        "noopener,noreferrer",
      );
      if (newWnd) {
        newWnd.opener = null;
      }
      setIsLoading && setIsLoading(false);
      return Promise.resolve();
    })
    .catch((error) => {
      const errorDetail = error?.response?.data?.detail;
      snackbarHelper(
        `Export file error: ${errorDetail}` || "Export file error!",
        "error",
      );
      setIsLoading && setIsLoading(false);
      return Promise.reject();
    });
};

export const postCreateSubsetExportFile = async (
  query: {
    datasetID: string;
    subsetID: string;
  },
  dispatch: Dispatch,
) => {
  const { datasetID, subsetID } = query;

  snackbarHelper(
    "Creating subset export file... Please check the progress in the export list.",
  );
  await APIPostWithBodyAxios(`/subsets/${subsetID}/export`, datasetID)
    .then(() => {
      return Promise.resolve();
    })
    .catch((error) => {
      const errorDetail = error?.response?.data?.detail;
      snackbarHelper(
        `Export file error: ${errorDetail}` || "Export file error!",
        "error",
      );
      return Promise.reject();
    });
};

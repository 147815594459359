import { Dialog } from "@mui/material";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { ReactComponent as LaunchIcon } from "assets/launch.svg";
import TextInput from "components/Inputs/Form/TextInput";
import {
  pipelineDesignerStateTypes,
  setAnnotationRunName,
} from "store/pipelineDesignerSlice";
import PipelineNodeSummary from "sections/PipelineNodeSummary";
import {
  PipelineModel,
  PipelineNodeModel,
  PipelineRevisionModel,
} from "models/pipelines.model";
import { DatasetModel } from "models/dataset.model";
import { getEstimateNumberNanoTasks } from "helpers/functions/pipelines/pipelinesHelpers";

type Props = {
  viewMode: "onlyView" | "viewAndLaunch";
  isConfirmDialogOpen: boolean;
  setIsConfirmDialogOpen: (isOpen: boolean) => void;
  pipeline: Partial<PipelineModel>;
  pipelineRevision?: PipelineRevisionModel;
  subset: DatasetModel | pipelineDesignerStateTypes["subset"];
  annotationRunName?: string;
  handleOnConfirm?: () => void;
  isLoading?: boolean;
};

const LaunchAnnotationRunDialog = ({
  viewMode = "onlyView",
  isConfirmDialogOpen,
  setIsConfirmDialogOpen,
  pipeline,
  pipelineRevision,
  subset,
  annotationRunName,
  handleOnConfirm,
  isLoading,
}: Props) => {
  const dispatch = useAppDispatch();
  const pipelineSlice = useAppSelector((state) => state.pipelineDesignerSlice);

  const renderAnnotationRunName = () => {
    if (viewMode === "onlyView")
      return (
        <div className="w-full flex">
          <div className="w-1/2 text-paletteGray-10">Annotation run name</div>
          <div className="w-1/2 text-paletteBlack-2">{annotationRunName}</div>
        </div>
      );

    const editableAnnotationRunName = pipelineSlice.annotationRunName;
    return (
      <TextInput
        field={editableAnnotationRunName}
        value={editableAnnotationRunName.value}
        handleOnChange={(event, field, isErrored, error) => {
          dispatch(
            setAnnotationRunName({
              ...editableAnnotationRunName,
              value: event.target.value,
              isErrored: isErrored || false,
              error: error || null,
            }),
          );
        }}
      />
    );
  };

  const renderRows = (key: string, value: string) => {
    return (
      <div className="w-full flex">
        <div className="w-1/2 text-paletteGray-10">{key}</div>
        <div
          className="w-1/2 text-paletteBlack-2"
          data-test={`annotation_run_overview_${key}_value`}
        >
          {value}
        </div>
      </div>
    );
  };

  const renderDataRow = () => {
    return (
      <div className="w-full flex">
        <div className="w-1/2 text-paletteGray-10">Data</div>
        <div className="w-1/2  gap-x-2">
          <div className="flex" data-test="annotation_run_overview_subset_name">
            {subset?.name}
          </div>
          <div
            className="flex items-end text-sm text-paletteGray-11"
            data-test="annotation_run_overview_media_objects_value"
          >
            {subset?.num_medias} medias - {subset?.num_media_objects} objects
          </div>
        </div>
      </div>
    );
  };

  const renderActions = () => {
    if (viewMode === "onlyView") return null;

    return (
      <div className="p-6 flex justify-end items-center border-t-[1px] border-paletteGray-4">
        <div className="flex gap-x-2">
          <button
            className="button-select-layer w-auto"
            data-test="cancel_annotation_run_launch_button"
            onClick={() => !isLoading && setIsConfirmDialogOpen(false)}
          >
            Cancel
          </button>
          <button
            className="button-dark-gray-layer"
            data-test="launch_annotation_run_button"
            disabled={isLoading || pipelineSlice.annotationRunName.isErrored}
            onClick={handleOnConfirm}
          >
            <LaunchIcon width={18} height={18} />
            Confirm and launch
          </button>
        </div>
      </div>
    );
  };
  const node = pipelineRevision
    ? pipelineRevision.node_revisions?.[0]
    : pipeline.nodes?.[0];

  return (
    <Dialog
      maxWidth="md"
      open={isConfirmDialogOpen}
      onClose={() => !isLoading && setIsConfirmDialogOpen(false)}
    >
      <div className="w-[600px] max-h-[850px] flex flex-col">
        {/* Title */}
        <div className="p-6 pb-[20px] border-b-[1px] border-paletteGray-4">
          <div className="text-xl mb-1 text-paletteBlack-2">
            Annotation run overview
          </div>
          {viewMode === "viewAndLaunch" && (
            <div className="text-paletteGray-9">
              Confirm configurations and launch an annotation run.
            </div>
          )}
        </div>

        {/* Content */}
        <div className=" min-h-0 flex-1 overflow-y-auto">
          <div className="w-full p-6 flex flex-col gap-y-3">
            {renderAnnotationRunName()}
            {renderRows("Pipeline name", pipeline.name as string)}
            {renderDataRow()}
            {renderRows("Number of nodes", "1")}
            {renderRows(
              "Number of repeats",
              `${node?.config?.min_repeats} - ${node?.config?.max_repeats}`,
            )}
            {renderRows(
              "Est. number of Nano-Tasks",
              getEstimateNumberNanoTasks(
                node?.config?.min_repeats,
                node?.config?.max_repeats,
                subset as DatasetModel,
              ),
            )}
          </div>

          {/* Node Summary */}
          {pipeline && (
            <div className="w-full p-6">
              <PipelineNodeSummary node={node as PipelineNodeModel} />
            </div>
          )}
        </div>

        {/* Actions */}
        {renderActions()}
      </div>
    </Dialog>
  );
};

export default LaunchAnnotationRunDialog;

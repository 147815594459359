import { useState } from "react";
import EditHideDeletePopover from "components/Popovers/EditHideDeletePopover";
import { TagModel } from "models/global.model";

type Props = {
  tag: TagModel;
  setEditInputID: (id: string | null) => void;
};

const TagsTableActionsDropdown = ({ tag, setEditInputID }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="w-full h-full flex justify-center items-center">
      <EditHideDeletePopover
        anchorEl={anchorEl}
        handlePopoverOpen={handlePopoverOpen}
        handlePopoverClose={handlePopoverClose}
        itemID={tag?.id}
        handleOnEdit={(tagID: string) => {
          setEditInputID(tagID);
          handlePopoverClose();
        }}
      />
    </div>
  );
};

export default TagsTableActionsDropdown;

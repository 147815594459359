import _ from "lodash";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import { ReactComponent as CloseIcon } from "assets/close.svg";
import Loading from "components/UtilComponents/Loading";
import { postAcceptLicense } from "helpers/apis/user";
import { resetStoreOnSubsetChange } from "store/util/resetStore";
import { useAppDispatch } from "store/hooks";
import { DatasetModel } from "models/dataset.model";
import { ReactComponent as License } from "assets/license.svg";
import StandardDialog from "components/Dialogs/StandardDialog";

type Props = {
  openDialog: boolean;
  setOpenDialog: (value: boolean) => void;
  dataset: DatasetModel;
  targetPath: string;
  handleOnDialogClose?: () => void;
  title?: string;
  loading?: {
    isLoading: boolean;
    loadingText: string;
  };
};

const LicenseDialog = ({
  openDialog,
  setOpenDialog,
  dataset,
  targetPath,
  handleOnDialogClose,
  title,
  loading,
}: Props) => {
  const dispatch = useAppDispatch();

  // Render loading
  const renderLoading = () => {
    if (_.isUndefined(loading) || !loading?.isLoading) return null;

    return (
      <div className="my-2 flex gap-x-3 justify-center text-2xl text-paletteGray-7">
        {loading?.loadingText || "Loading..."}
        <div>
          <Loading size={20} />
        </div>
      </div>
    );
  };

  const handleAcceptLicense = () => {
    postAcceptLicense(dataset?.id, dispatch).then(() => {
      setOpenDialog(false);
      resetStoreOnSubsetChange(dispatch);
      window.location.href = targetPath;
    });
  };

  const handleCloseDialog = () => {
    if (loading?.isLoading) return;
    setOpenDialog(false);
    handleOnDialogClose && handleOnDialogClose();
  };

  const renderContent = () => {
    return (
      <div>
        <hr className="solid w-[109.2%] ml-[-5%]" />
        <div className="flex pt-5">
          <div className="pr-4">
            <div className="px-[6px]">
              <License />
            </div>
          </div>
          <div>
            <p>Dear User,</p>
            <br></br>
            <p>
              As you engage with "{dataset?.name}" we want to inform you about
              its licensing terms. Ensure you review the complete license
              agreement for comprehensive details.
            </p>
            <br></br>
            <a
              href={dataset?.license}
              target={"_blank"}
              rel={"noopener noreferrer"}
              className="underline"
            >
              Read full license information
            </a>
            <br></br>
            <br></br>
            <p>
              By using "{dataset?.name}" you agree to adhere to these terms.
              Should you have any inquiries or require clarification, please
              reach out to our support team.
            </p>
            <br></br>
            <p>Thank you for choosing Quality Match!</p>
            <br></br>
          </div>
        </div>
        <hr className="solid w-[109.2%] ml-[-5%]" />
      </div>
    );
  };

  const renderAcceptLicenseButton = () => {
    return (
      <button
        className="button-dark-gray-layer w-fit"
        onClick={() => handleAcceptLicense()}
      >
        Ok
      </button>
    );
  };

  return (
    <StandardDialog
      openDialog={openDialog}
      title={title}
      loading={loading}
      setOpenDialog={setOpenDialog}
      actions={renderAcceptLicenseButton()}
    >
      {renderContent()}
    </StandardDialog>
  );
};

export default LicenseDialog;

import CreatedAtPopover from "sections/ManagerFilters/CreatedAtPopover";
import StandardPopover from "components/Popovers/StandardPopover";
import _ from "lodash";
import { useState } from "react";
import { ReactComponent as FilterIcon } from "assets/filter.svg";
import { ReactComponent as CloseIcon } from "assets/close.svg";
import { ManagerFiltersModel } from "models/global.model";

interface Props {
  appliedFilters: ManagerFiltersModel;
  setCreatedDateFilter: (newValue: ManagerFiltersModel["createdAt"]) => void;
}

function ManagerFilters({ appliedFilters, setCreatedDateFilter }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const renderTitleButton = () => {
    const numberOfAppliedFilters = _.filter(
      appliedFilters,
      (filter) => filter !== null,
    ).length;
    return (
      <button
        className={`button-layer bg-transparent gap-x-[6px] text-paletteGray-12 ${
          !_.isNull(anchorEl) ? "bg-paletteGray-5" : ""
        }`}
        onClick={(event) => {
          event.stopPropagation();
          setAnchorEl(event.currentTarget);
        }}
        data-test="filter_button"
      >
        <FilterIcon className="w-4 h-4" />
        Filter
        {numberOfAppliedFilters > 0 && (
          <span
            className="bg-paletteGray-4 px-1 rounded"
            data-test="number_of_filters"
          >
            {" "}
            {numberOfAppliedFilters}
          </span>
        )}
        {numberOfAppliedFilters > 0 && renderClearButton()}
      </button>
    );
  };

  const renderClearButton = () => {
    return (
      <div
        className="hover:bg-paletteGray-6 p-1 rounded"
        onClick={(e) => {
          e.stopPropagation();
          setCreatedDateFilter(null);
        }}
        data-test="clear_filters_button"
      >
        <CloseIcon className="w-3 h-3" />
      </div>
    );
  };

  const renderFilters = () => {
    return (
      <div>
        <CreatedAtPopover
          setParentPopoverAnchorEl={setAnchorEl}
          selectedDates={appliedFilters?.createdAt}
          setSelectedDates={setCreatedDateFilter}
        />
      </div>
    );
  };

  return (
    <div>
      <StandardPopover
        id="manager-filter-popover"
        anchorEl={anchorEl}
        handlePopoverOpen={(event) => setAnchorEl(event.currentTarget)}
        handlePopoverClose={() => setAnchorEl(null)}
        buttonElement={renderTitleButton()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        offSetPopover={{
          top: 4,
        }}
      >
        <div>{renderFilters()}</div>
      </StandardPopover>
    </div>
  );
}

export default ManagerFilters;

import _ from "lodash";
import BackToButton from "components/Buttons/BackToButton";
import { UploadManagementScreenTabs } from "Pages/UploadManagement";
import RefreshButton from "components/Buttons/refresh";
import AutoIntervalButton from "components/Buttons/AutoIntervalButton";
import NotificationButton from "components/Buttons/NotificationButton";

interface Props {
  selectedTab: UploadManagementScreenTabs;
  setSelectedTab: (newState: UploadManagementScreenTabs) => void;
  refreshData: () => void;
}

const UploadManagementScreenHeader = ({
  selectedTab,
  setSelectedTab,
  refreshData,
}: Props) => {
  const renderTabs = () => {
    return _.map(UploadManagementScreenTabs, (tab, index) => {
      const label = tab as string;
      return (
        <button
          key={index}
          className={`button-layer ${
            tab === selectedTab && "bg-black text-white"
          }`}
          onClick={() => setSelectedTab(tab)}
        >
          {label}
        </button>
      );
    });
  };

  return (
    <div className="w-full h-[64px]">
      <div className="w-full h-full p-4 flex gap-x-4 items-center bg-white">
        <div className="w-1/4 flex items-center gap-x-2">
          <BackToButton URL={"/"} />
          <div className="label-layer">Upload manager</div>
        </div>
        <div
          className="w-2/4 flex gap-x-1 flex-grow justify-center"
          data-test="uploads_import_tabs"
        >
          {renderTabs()}
        </div>
        <div className="w-1/4 flex items-center gap-x-2 justify-end">
          <RefreshButton callBackFunction={refreshData} />
          <AutoIntervalButton functionToCall={refreshData} />
          <div className="h-6 w-px bg-paletteGray-4"></div>
          <NotificationButton />
        </div>
      </div>
    </div>
  );
};

export default UploadManagementScreenHeader;

import GroupsList from "sections/SidebarMenu/FiltersDialog/GroupsList";
import groupMLAttributesOrAnnotatedAttributes from "helpers/functions/filters/groupMLAttributesOrAnnotatedAttributes";
import _, { filter } from "lodash";
import {
  FilterAttributeGroupEnum,
  FilterCategoryEnum,
  FilterModel,
  SavedFilterModel,
} from "models/filter.model";
import {
  determineFilterKeyFromParam,
  determineSavedFilterKeyFromParam,
} from "store/filterDataSlice";
import { useAppSelector } from "store/hooks";

interface Props {
  searchValue: string;
  onItemClick: (
    filter: FilterModel | SavedFilterModel,
    selectedFilterCategory: FilterCategoryEnum,
  ) => void;
}

const FiltersSearchList = ({ searchValue, onItemClick }: Props) => {
  const selectedView = useAppSelector((state) => state.appSlice.selectedView);
  const filterData = useAppSelector((state) => state.filterDataSlice);
  const allAttributes = useAppSelector(
    (state) => state.datasetSlice.attributes,
  );

  const getFilterGroups = () => {
    // Search saved filters
    const savedFilters =
      filterData?.[determineSavedFilterKeyFromParam(selectedView)];

    const searchedSavedFilters = _.filter(savedFilters, (filter) => {
      return _.includes(_.toLower(filter?.name), _.toLower(searchValue));
    });

    // Search auto and initial attributes filters as well as text filters and subsets and tags and group them
    const filters = filterData?.[determineFilterKeyFromParam(selectedView)];

    const autoAndInitialAttributes = _.filter(filters, (filter) => {
      return (
        filter?.attribute_group === FilterAttributeGroupEnum.Auto_Attribute ||
        filter?.attribute_group ===
          FilterAttributeGroupEnum.Initial_Attribute ||
        filter?.attribute_group === FilterAttributeGroupEnum.Text_Filter ||
        filter?.attribute_group === FilterAttributeGroupEnum.Subsets_And_Tags
      );
    });

    const searchedAutoAndInitialAttributes = _.filter(
      autoAndInitialAttributes,
      (filter) => {
        return _.includes(
          _.toLower(filter?.attribute_name),
          _.toLower(searchValue),
        );
      },
    );

    const groupedAutoAndInitialFilters = _.groupBy(
      searchedAutoAndInitialAttributes,
      "attribute_group",
    );

    // Search annotation attributes filters and group them
    const annotatedAttributes = groupMLAttributesOrAnnotatedAttributes(
      filters ?? {},
      FilterCategoryEnum.Annotation_Attribute,
    );

    const searchedAnnotatedAttributes = _.filter(
      annotatedAttributes,
      (attribute) => {
        const name =
          _.find(allAttributes, ["id", attribute.attribute_id])?.name ||
          attribute?.attribute_name;
        return _.includes(_.toLower(name), _.toLower(searchValue));
      },
    );

    // Search ml annotated attributes filters and group them
    const mlAnnotatedAttributes = groupMLAttributesOrAnnotatedAttributes(
      filters ?? {},
      FilterCategoryEnum.ML_Annotation_Attribute,
    );

    const searchedMlAnnotatedAttributes = _.filter(
      mlAnnotatedAttributes,
      (attribute) => {
        const name =
          _.find(allAttributes, ["id", attribute.attribute_id])?.name ||
          attribute?.attribute_name;
        return _.includes(_.toLower(name), _.toLower(searchValue));
      },
    );

    // Combine all filters
    const allFilters = {
      ...groupedAutoAndInitialFilters,
      [FilterCategoryEnum.Annotation_Attribute]: searchedAnnotatedAttributes,
      [FilterCategoryEnum.ML_Annotation_Attribute]:
        searchedMlAnnotatedAttributes,
      [FilterCategoryEnum.Saved_Filter]: searchedSavedFilters,
    };

    return allFilters;
  };

  return (
    <GroupsList
      allFilters={getFilterGroups()}
      onItemClick={(
        filter: FilterModel | SavedFilterModel,
        selectedCategory: FilterCategoryEnum,
      ) => {
        selectedCategory && onItemClick(filter as any, selectedCategory);
      }}
    />
  );
};

export default FiltersSearchList;

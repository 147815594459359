import { Dialog } from "@mui/material";
import {
  AnnotationRunModel,
  AnnotationRunNodeModel,
  AnnotationRunNodeStatusEnum,
  AnnotationRunStatusEnum,
} from "models/annotationRun.model";
import { ReactComponent as CloseIcon } from "assets/close.svg";
import AnnotationRunStatusChip from "Pages/AnnotationRunManager/AnnotationRunStatusChip";
import { useEffect, useState } from "react";
import { DatasetModel } from "models/dataset.model";
import { getDataset } from "helpers/apis/datasets";
import _ from "lodash";
import { fetchAnnotationRun } from "helpers/apis/annotationRun";
import { useAppDispatch } from "store/hooks";
import Loading from "components/UtilComponents/Loading";
import {
  PipelineModel,
  PipelineNodeModel,
  PipelineRevisionModel,
} from "models/pipelines.model";
import { fetchPipeline, fetchPipelineRevision } from "helpers/apis/pipelines";
import DataTable, { DataTableColumn } from "components/Table/DataTable";
import AnnotationRunNodeStatusChip from "Pages/AnnotationRunManager/NodesTable/AnnotationRunNodeStatusChip";
import TooltipTruncateEllipsis from "components/Tooltips/TooltipTruncateEllipsis";
import PipelineNodeSummary from "sections/PipelineNodeSummary";
import { AttributeMetadataModel } from "models/attributes.model";
import { fetchAttributesMetaData } from "helpers/apis/attributes";
import { getRouteFromSubsetType } from "helpers/functions/selectedViewHelpers";
import { ReactComponent as OpenIcon } from "assets/open.svg";

export interface AnnotationRunOverviewDialogState {
  isOpen: boolean;
  annotationRun: AnnotationRunModel | null;
}

type Props = {
  overviewDialog: AnnotationRunOverviewDialogState;
  setOverviewDialog: (newState: AnnotationRunOverviewDialogState) => void;
};

const AnnotationRunOverview = ({
  overviewDialog,
  setOverviewDialog,
}: Props) => {
  const dispatch = useAppDispatch();

  const [annotationRunWithNodes, setAnnotationRunWithNodes] =
    useState<AnnotationRunModel | null>(null);

  const [dataset, setDataset] = useState<DatasetModel | null>(null);
  const [subset, setSubset] = useState<DatasetModel | null>(null);
  const [metadataAttributes, setMetadataAttributes] = useState<
    AttributeMetadataModel[] | null
  >(null);

  const [pipelineRevision, setPipelineRevision] =
    useState<PipelineRevisionModel | null>(null);
  const [pipeline, setPipeline] = useState<PipelineModel | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (overviewDialog.annotationRun !== null) {
      setIsLoading(true);
      Promise.all([
        fetchAnnotationRun(
          {
            annotationRunID: overviewDialog.annotationRun.id,
          },
          dispatch,
        ).then((data) => {
          setAnnotationRunWithNodes(data);
        }),
        getDataset({ datasetID: overviewDialog.annotationRun.dataset_id }).then(
          (data) => {
            setDataset(data);
          },
        ),
        getDataset({ datasetID: overviewDialog.annotationRun.subset_id }).then(
          (data) => {
            setSubset(data);
          },
        ),
        fetchAttributesMetaData({
          datasetID: overviewDialog.annotationRun.dataset_id,
        }).then((data) => {
          setMetadataAttributes(data);
        }),
        fetchPipelineRevision(
          {
            pipelineRevisionID:
              overviewDialog.annotationRun.pipeline_revision_id,
          },
          dispatch,
        ).then((pipelineRevision) => {
          setPipelineRevision(pipelineRevision);
        }),
      ]).finally(() => setIsLoading(false));
    }
  }, [overviewDialog.annotationRun]);

  useEffect(() => {
    if (pipelineRevision !== null) {
      fetchPipeline(
        {
          pipelineID: pipelineRevision.pipeline_id,
        },
        dispatch,
      ).then((pipeline) => {
        setPipeline(pipeline);
      });
    }
  }, [pipelineRevision]);

  const renderIsLoading = () => {
    return (
      <div className="w-full max-h-[850px] p-8 flex">
        <Loading />
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="w-full max-h-[850px] flex flex-col">
        {/* Header */}
        <header className="p-4 flex justify-between">
          <div className="text-xl text-paletteBlack-2">
            Annotation run overview
          </div>
          <div className="p-1 cursor-pointer" onClick={handleCloseDialog}>
            <CloseIcon className="w-3 h-3 text-paletteGray-9" />
          </div>
        </header>

        {/* Content */}
        <article className="px-2 min-h-0 flex-1 overflow-y-auto">
          <div className="w-full p-6 flex flex-col gap-y-3">
            {renderAnnotationRunDetails()}
            {dataset !== null && renderRow("Dataset", dataset?.name)}
            {renderDataRow()}
            {renderRow("Pipeline name", pipeline?.name || "-")}
          </div>
        </article>

        {renderNodes()}
      </div>
    );
  };

  const renderAnnotationRunDetails = () => {
    if (annotationRunWithNodes === null) {
      return null;
    }
    const date = new Date(annotationRunWithNodes.created_at);

    const shouldRenderExploreButton =
      annotationRunWithNodes?.status === AnnotationRunStatusEnum.DONE &&
      !annotationRunWithNodes.is_multinode;
    const firstNode = annotationRunWithNodes.nodes?.[0];

    return (
      <>
        {/* Name and status */}
        <div className="flex justify-between items-center">
          <div className="label-layer w-fit">
            {annotationRunWithNodes?.name}
          </div>
          <div className="flex gap-x-2">
            {shouldRenderExploreButton &&
              renderExploreAnnotationRunButton(firstNode)}
            <AnnotationRunStatusChip status={annotationRunWithNodes?.status} />
          </div>
        </div>

        {/* Annotation run fields */}
        {renderRow("Created at", date.toLocaleString())}
        {renderRow("Team", annotationRunWithNodes.user_group || "-")}
        {renderRow(
          "Number of nodes",
          annotationRunWithNodes.nodes?.length.toString(),
        )}
      </>
    );
  };

  const renderExploreAnnotationRunButton = (
    annotationRunNode: AnnotationRunNodeModel,
  ) => {
    return (
      <button
        onClick={(e) => {
          if (!annotationRunWithNodes || !subset) {
            return;
          }

          e.stopPropagation();
          const url_prefix = getRouteFromSubsetType(subset?.subset_type);
          // AI attributes with no goliat project have the ann run node ID as attr ID
          const attributeID =
            annotationRunNode.goliat_node_id || annotationRunNode.id;
          const exploreUrl = `main${url_prefix}/${annotationRunWithNodes.dataset_id}/main_dataset?sel_attr__attributes.${attributeID}.dataset_id="${annotationRunWithNodes.dataset_id}"`;
          const newWnd = window.open(
            exploreUrl,
            "_blank",
            "noopener,noreferrer",
          );
          if (newWnd) {
            newWnd.opener = null;
          }
        }}
        className="button-layer mr-2"
      >
        <OpenIcon className="w-[18px] h-[18px]" />
        Explore results
      </button>
    );
  };

  const renderRow = (key: string, value: string) => {
    return (
      <div className="w-full flex">
        <div className="w-1/2 text-paletteGray-10">{key}</div>
        <div className="w-1/2 text-paletteBlack-2">{value}</div>
      </div>
    );
  };

  const renderDataRow = () => {
    if (dataset === null || subset === null) {
      return null;
    }

    return (
      <div className="w-full flex">
        <div className="w-1/2 text-paletteGray-10">Data</div>
        <div className="w-1/2  gap-x-2">
          <div className="flex">{subset?.name}</div>
          <div className="flex items-end text-sm text-paletteGray-11">
            {subset?.num_medias} medias - {subset?.num_media_objects} objects
          </div>
        </div>
      </div>
    );
  };

  const nodeTableColumns: DataTableColumn[] = [
    {
      field: "name",
      headerName: "Node name",
      span: 40,
      cell: (row) => {
        const annotationRunNode = row as AnnotationRunNodeModel;
        return (
          <TooltipTruncateEllipsis className="pr-3">
            {annotationRunNode.name}
          </TooltipTruncateEllipsis>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Created at",
      span: 20,
      cell: (row) => {
        const annotationRunNode = row as AnnotationRunNodeModel;
        const date = new Date(annotationRunNode?.created_at);
        return (
          <TooltipTruncateEllipsis className="pr-3">
            {date.toLocaleString()}
          </TooltipTruncateEllipsis>
        );
      },
    },
    {
      field: "status",
      headerName: "",
      sortable: false,
      span: 15,
      cell: (row) => {
        const annotationRunNode = row as AnnotationRunNodeModel;
        return (
          <div className="w-full flex">
            <AnnotationRunNodeStatusChip status={annotationRunNode.status} />
          </div>
        );
      },
    },
    {
      field: "",
      headerName: "",
      sortable: false,
      span: 25,
      cell: (row) => {
        if (annotationRunWithNodes?.is_multinode === false) {
          return <div />;
        }

        const annotationRunNode = row as AnnotationRunNodeModel;
        const shouldRenderExploreButton =
          annotationRunNode.status ===
          AnnotationRunNodeStatusEnum.POST_PROCESSING_DONE;

        return (
          <div className="w-full flex justify-end">
            {shouldRenderExploreButton &&
              renderExploreAnnotationRunButton(annotationRunNode)}
          </div>
        );
      },
    },
  ];

  const renderNodes = () => {
    if (annotationRunWithNodes === null) {
      return null;
    }

    return (
      <div className="m-4 rounded">
        <DataTable
          rows={annotationRunWithNodes.nodes as []}
          columns={nodeTableColumns}
          expandRow={{
            key: "pipeline_node_revision_id",
            text: "Show node details",
            body: (row) => {
              const annotationRunNode = row as AnnotationRunNodeModel;
              return renderNodeDetails(annotationRunNode);
            },
          }}
        />
      </div>
    );
  };

  const renderNodeDetails = (annotationRunNode: AnnotationRunNodeModel) => {
    const pipelineRevisionNode = pipelineRevision?.node_revisions?.find(
      (nodeRevision) =>
        nodeRevision.id === annotationRunNode.pipeline_node_revision_id,
    );

    if (pipelineRevisionNode === undefined) {
      return <div>Node not found</div>;
    }

    const initialAttribute = metadataAttributes?.find(
      (attribute) =>
        attribute.id === annotationRunNode.config.initial_attribute_id,
    );
    return (
      <div className="w-full px-4 py-2 flex flex-col items-center justify-center gap-y-3">
        {renderRow("Initial attribute", initialAttribute?.name || "-")}
        <PipelineNodeSummary node={pipelineRevisionNode as PipelineNodeModel} />
      </div>
    );
  };

  const handleCloseDialog = () => {
    setOverviewDialog({ isOpen: false, annotationRun: null });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={overviewDialog.isOpen}
      onClose={handleCloseDialog}
    >
      {isLoading ? renderIsLoading() : renderBody()}
    </Dialog>
  );
};

export default AnnotationRunOverview;

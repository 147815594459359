import validator from "@rjsf/validator-ajv8";
import Form from "@rjsf/core";

import { UiSchema } from "@rjsf/utils";

import ObjectFieldTemplate from "components/RJSF/ObjectFieldTemplate";
import ArrayFieldTemplate from "components/RJSF/ArrayFieldTemplate";
import DescriptionFieldTemplate from "components/RJSF/DescriptionFieldTemplate";
import ErrorListTemplate from "components/RJSF/ErrorListTemplate";

import baseUiSchema from "schemas/UserInterfaceBaseSchema";
import { setGUIFormData } from "store/pipelineDesignerSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AnnotationUi_2_0_0_Deref } from "@quality-match/shared-types";
import { annotationUIs } from "schemas/annotationUIs";
import { Mediatype } from "models/dataset.model";
import SelectGUI from "Pages/PipelineDesigner/PipelineConfiguration/Configuration/UserInterfaceConfigurations/SelectGUI";
import SelectedGUI from "Pages/PipelineDesigner/PipelineConfiguration/Configuration/UserInterfaceConfigurations/SelectedGUI";
import CustomFieldTemplate from "components/RJSF/FieldTemplate";
import FieldErrorTemplate from "components/RJSF/FieldErrorTemplate";

const UserInterfaceConfigurations = () => {
  const dispatch = useAppDispatch();
  const pipelineSlice = useAppSelector((state) => state.pipelineDesignerSlice);

  const constructUiSchemaObject = () => {
    const selectedUiSchema =
      annotationUIs[pipelineSlice.uiType as keyof typeof annotationUIs]
        ?.uiSchema;

    const uiSchema: UiSchema = {
      ...baseUiSchema,
      inputType: {
        ...baseUiSchema?.inputType,
        BoundingBox2DPainter: {
          ...baseUiSchema?.inputType?.BoundingBox2DPainter,
          ...selectedUiSchema?.inputType?.BoundingBox2DPainter,
          // Hide videoPlayerSettings if media_type is not video
          videoPlayerSettings: handleEnableVideoPlayerSettings(),
        },
        Viewer3D: {
          ...baseUiSchema?.inputType?.Viewer3D,
          ...selectedUiSchema?.inputType?.Viewer3D,
        },
      },
    };
    return uiSchema;
  };

  const handleEnableVideoPlayerSettings = () => {
    if (
      pipelineSlice.configurationsForm?.media_type?.value === Mediatype.video
    ) {
      return {
        ...baseUiSchema?.inputType?.BoundingBox2DPainter?.videoPlayerSettings,
      };
    }

    return { "ui:widget": "hidden" };
  };

  return (
    <div className="h-full p-2 pr-0 flex flex-col gap-y-4">
      {pipelineSlice.isEditingUIConfig ? (
        <SelectGUI tabIndex={0} />
      ) : (
        <SelectedGUI />
      )}
      {!pipelineSlice.isEditingUIConfig && (
        <Form
          schema={AnnotationUi_2_0_0_Deref as any}
          validator={validator}
          uiSchema={constructUiSchemaObject()}
          formData={pipelineSlice.guiFormData}
          className="min-h-0 flex-1 overflow-auto pr-2 "
          templates={{
            ObjectFieldTemplate,
            ArrayFieldTemplate,
            DescriptionFieldTemplate,
            ErrorListTemplate,
            FieldTemplate: CustomFieldTemplate,
            FieldErrorTemplate: FieldErrorTemplate,
          }}
          experimental_defaultFormStateBehavior={{
            emptyObjectFields: "populateRequiredDefaults",
            allOf: "populateDefaults",
          }}
          onChange={(e) => dispatch(setGUIFormData(e.formData))}
          liveValidate={true}
          showErrorList={false}
        />
      )}
    </div>
  );
};

export default UserInterfaceConfigurations;

import { useState } from "react";
import { filter, isString, isUndefined, map, orderBy } from "lodash";
import Skeleton from "@mui/material/Skeleton";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";

import TableRow from "./TableRow";

interface Props {
  rows: any[];
  columns:
    | [
        {
          field: string;
          headerName: string;
          span?: number;
          sortable?: boolean;
          className?: string;
          headerClassName?: string;
          cell?: any;
        },
      ]
    | any;
  // rowUniqueKey is used to handle changing the children rows sort (to keep the child state connected to the row)
  rowUniqueKey: string | number;
  onRowClick?: (row: any) => any;
  subRowsKey?: string;
  subColumns?: any;
  showHeader?: boolean;
  fontSize?: "0" | "1" | "2" | "3" | "4";
  hover?: boolean;
  noDataPlaceholder?: string;
  loading?: boolean;
  defaultSortKey?: string;
}

const BasicTable = ({
  rows,
  columns,
  rowUniqueKey = "id",
  onRowClick,
  subRowsKey = "",
  subColumns,
  showHeader = true,
  fontSize = "1",
  hover = true,
  noDataPlaceholder = "No Data",
  loading = false,
  defaultSortKey = "",
}: Props) => {
  const [sortBy, setSortBy] = useState<{
    name: string;
    direction: "asc" | "desc";
  }>({ name: defaultSortKey, direction: "asc" });

  const classes = {
    skeleton: {
      width: "100%",
      height: "45px",
      margin: "8px 0px",
      borderRadius: "12px",
    },
  };

  // Skeleton used when waiting for data
  const loadingUI = (
    <div className="">
      <Skeleton
        variant="rectangular"
        animation="wave"
        sx={classes.skeleton}
        style={{ marginTop: "100px" }}
      />
      <Skeleton variant="rectangular" animation="wave" sx={classes.skeleton} />
      <Skeleton variant="rectangular" animation="wave" sx={classes.skeleton} />
    </div>
  );

  const renderHeader = () => {
    return (
      <div
        className="w-full flex p-5 my-1 italic text-lg py-4 border-b-2"
        style={{ color: "#BBBBBB" }}
      >
        {map(columns, (col: any, key) => {
          return (
            <div
              key={key}
              className={`${col?.headerClassName}`}
              style={{ width: `${col?.span}%` }}
            >
              {col?.headerName}
              {isUndefined(col?.sortable) || col?.sortable
                ? renderSortIcon(col)
                : null}
            </div>
          );
        })}
      </div>
    );
  };

  const renderSortIcon = (col: any) => {
    // Default (ideal) state
    if (sortBy?.name === col?.field && sortBy?.direction === "asc") {
      return (
        <ArrowUpward
          className="text-paletteGray-9 ml-2"
          onClick={() => setSortBy({ name: col?.field, direction: "desc" })}
        />
      );
    }
    // Desc state
    else if (sortBy?.name === col?.field && sortBy?.direction === "desc") {
      return (
        <ArrowDownward
          className="text-paletteGray-9 ml-2"
          onClick={() => setSortBy({ name: "", direction: "asc" })}
        />
      );
    }
    // Asc state
    else {
      return (
        <ArrowUpward
          className="text-transparent hover:text-paletteGray-6 ml-2"
          onClick={() => setSortBy({ name: col?.field, direction: "asc" })}
        />
      );
    }
  };

  const renderDataSets = () => {
    // Remove subsets
    const filtered_rows = filter(rows, (row: any) => !row[subRowsKey]);
    // Sort by header key
    const sorted_rows = orderBy(
      filtered_rows,
      [
        (row: any) =>
          isString(row[sortBy?.name])
            ? row[sortBy?.name]?.toLowerCase()
            : row[sortBy?.name],
      ],
      [sortBy?.direction],
    );
    // Render Rows
    return map(sorted_rows, (row: any) => {
      return (
        <TableRow
          key={row[rowUniqueKey]}
          row={row}
          columns={columns}
          onRowClick={onRowClick}
          subRows={filter(rows, (sub: any) => sub[subRowsKey] === row?.id)}
          subColumns={subColumns}
          fontSize={fontSize}
          hover={hover}
        />
      );
    });
  };

  // Check first if the data is still loading.
  // If there is no data to view noDataPlaceholder is viewed
  // If there is data it's rendered
  return loading ? (
    loadingUI
  ) : rows?.length === 0 ? (
    <div className="w-full h-full pr-2 flex justify-center font-bold text-lg">
      {noDataPlaceholder}
    </div>
  ) : (
    <div className="w-full h-full pr-2">
      {showHeader ? renderHeader() : null}
      <div className="w-full h-full overflow-y-auto mr-2">
        {renderDataSets()}
      </div>
    </div>
  );
};

export default BasicTable;

import { useAppDispatch } from "store/hooks";
import { ReactComponent as Plus } from "assets/plus.svg";
import { setIsCreatingNewTrainingSetModalOpen } from "store/aiManagerSlice";

const CreateNewTrainingSetButton = () => {
  const dispatch = useAppDispatch();

  return (
    <div
      className="button-dark-gray-layer w-fit"
      onClick={() => dispatch(setIsCreatingNewTrainingSetModalOpen(true))}
      data-test="create_new_training_set_button"
    >
      <Plus className={"w-4 h-4"} />
      Create new training set
    </div>
  );
};

export default CreateNewTrainingSetButton;

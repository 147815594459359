import { useState } from "react";
import _ from "lodash";
import { useAppSelector, useAppDispatch } from "store/hooks";
import convertActiveFilterToSendFilter from "helpers/functions/filters/convertActiveFilterToSendFilter";
import SwitchComp from "components/Filters/Switch";
import { postExportFile } from "helpers/apis/download";
import { TagTypeModel } from "models/global.model";
import {
  AttributeGroupEnum,
  AttributeMetadataModel,
} from "models/attributes.model";
import TagsSelector from "components/Inputs/TagsSelector";
import selectMediatype from "helpers/functions/selectMediatype";
import CustomCheckbox from "components/Inputs/Checkbox";
import { determineActiveFilterKeyFromParam } from "store/filterDataSlice";
import { Mediatype } from "models/dataset.model";
import { ReactComponent as DownloadIcon } from "assets/download.svg";
import { isAttributeAnnotationAttribute } from "helpers/functions/attributes/attributesHelpers";
import { transformString } from "components/utilFunctions";
import snackbarHelper from "helpers/snackbarHelperFn";

interface CreateNewFileFormModel {
  options: {
    includeAnnotations: boolean;
    provideFullSubsetList: boolean;
  };
  selectors: {
    mediasTags: string[];
    mediasSubsets: string[];
    mediaObjectsTags: string[];
    mediaObjectsSubsets: string[];
    attributesTags: string[];
  };
  attributes: {
    media: string[];
    object: string[];
  };
}

const CreateNewFile = () => {
  const dispatch = useAppDispatch();

  const selectedView = useAppSelector((state) => state.appSlice.selectedView);
  const dataSetData = useAppSelector((state) => state.datasetSlice);
  const filterData = useAppSelector((state) => state.filterDataSlice);
  const subsets = useAppSelector((state) =>
    _.filter(
      state.datasetSlice?.subSets,
      (subset) => subset?.subset_type !== "attribute",
    ),
  );
  const tags = useAppSelector((state) => state.datasetSlice?.tags);
  const attributesMeta = useAppSelector(
    (state) => state.datasetSlice.attributes,
  );
  const sortData = useAppSelector((state) => state.sortDataSlice?.activeMedia);

  // Local States
  const [isLoading, setIsLoading] = useState(false);

  const [form, setForm] = useState<CreateNewFileFormModel>({
    options: {
      includeAnnotations: false,
      provideFullSubsetList: true,
    },
    selectors: {
      mediasTags: [],
      mediasSubsets: [],
      mediaObjectsTags: [],
      mediaObjectsSubsets: [],
      attributesTags: [],
    },
    attributes: {
      media: [],
      object: [],
    },
  });

  const handleCreateNewFile = () => {
    const query = [
      ...convertActiveFilterToSendFilter(
        filterData?.[determineActiveFilterKeyFromParam(selectedView)],
      ),
    ];

    let subsetID = undefined;
    if (!_.isNull(dataSetData.activeDataSet?.parent_dataset)) {
      subsetID = dataSetData.activeDataSet?.id;
    }

    let attributesIDsList: string[] = [];

    const mediaAttributes = _.filter(attributesMeta, {
      annotatable_type: "Media",
    });
    if (!_.isEmpty(form.attributes.media)) {
      attributesIDsList = [...attributesIDsList, ...form.attributes.media];
    } else {
      attributesIDsList = [
        ...attributesIDsList,
        ..._.map(mediaAttributes, (att) => att?.id),
      ];
    }

    const mediaObjectAttributes = _.filter(attributesMeta, {
      annotatable_type: "MediaObject",
    });
    if (!_.isEmpty(form.attributes.object)) {
      attributesIDsList = [...attributesIDsList, ...form.attributes.object];
    } else {
      attributesIDsList = [
        ...attributesIDsList,
        ..._.map(mediaObjectAttributes, (att) => att?.id),
      ];
    }

    setIsLoading(true);
    postExportFile(
      selectedView,
      dataSetData?.activeParentDataset?.id || "",
      subsetID,
      query,
      sortData,
      { ...form?.options, ...form?.selectors, attributes: attributesIDsList },
    )
      .then(() => {
        snackbarHelper(
          "Export process started! This might take some time. Press 'Show export files table' to check the status!",
          "success",
          8000,
        );
        setIsLoading(false);
      })
      .catch((err) => {
        snackbarHelper(
          err.detail || "Failed to create an export file!",
          "error",
        );
        setIsLoading(false);
      });
  };

  const renderSelector = (
    key: keyof CreateNewFileFormModel["selectors"],
    type: "subsets" | "tags",
    tagType?: TagTypeModel,
  ) => {
    // The tags/subset list
    const selectedItems = form?.selectors?.[key];

    // For tags
    const objectOfTags = _.keyBy(
      _.filter(tags, (tag) => tag?.database_object_type === tagType),
      "name",
    );
    const selectedTags = _.pickBy(objectOfTags, (tag) => {
      return _.includes(selectedItems, tag?.id);
    });

    // For subsets
    const objectOfSubset = _.keyBy(subsets, "name");
    const selectedSubsets = _.pickBy(objectOfSubset, (tag) => {
      return _.includes(selectedItems, tag?.id);
    });

    return (
      <div className="w-full flex flex-col py-2 border-b-2 border-b-slate-200">
        {type === "subsets"
          ? "Subsets to include in subset_ids field:"
          : "Tags to include in tags field:"}
        <div className="w-full">
          <TagsSelector
            items={type === "subsets" ? objectOfSubset : objectOfTags}
            selectedItems={type === "subsets" ? selectedSubsets : selectedTags}
            addLabel={`Add ${type}`}
            disabled={isLoading}
            placeholder={"Search"}
            handleAddTag={(tag) =>
              setForm({
                ...form,
                selectors: {
                  ...form?.selectors,
                  [key]: [...selectedItems, tag?.id as string],
                },
              })
            }
            handleRemoveTag={(tag) => {
              const oldTagList = [...selectedItems];
              const newTagList = _.remove(oldTagList, (i) => i !== tag?.id);
              setForm({
                ...form,
                selectors: {
                  ...form?.selectors,
                  [key]: newTagList,
                },
              });
            }}
          />
        </div>
      </div>
    );
  };

  const renderSwitch = (
    key: keyof CreateNewFileFormModel["options"],
    label: string,
  ) => {
    const value = form?.options?.[key];
    return (
      <div className="flex items-center my-2">
        <div>
          <SwitchComp
            checked={value}
            onChange={() =>
              setForm({ ...form, options: { ...form?.options, [key]: !value } })
            }
            size="sm"
          />
        </div>
        <div className="mx-2">{label}</div>
      </div>
    );
  };

  const renderAttributes = (
    key: keyof CreateNewFileFormModel["attributes"],
    listOfAttributes: AttributeMetadataModel[],
  ) => {
    const autoAttributes = _.filter(listOfAttributes, {
      attribute_group: AttributeGroupEnum.AUTO_ATTRIBUTE,
    });
    const annotationAttributes = _.filter(listOfAttributes, (attributeMeta) =>
      isAttributeAnnotationAttribute(attributeMeta?.attribute_group),
    );
    return (
      <div
        className="w-2/5 p-4 rounded-xl shadow-around"
        data-test="export_attributes"
      >
        <div className="font-bold">{_.upperFirst(key)} attributes</div>
        <div className="flex items-center gap-x-2 border-b-2 py-1">
          <CustomCheckbox
            checked={
              _.size(listOfAttributes) === _.size(form.attributes?.[key])
            }
            onChange={(e) => {
              const newVal = e.target.checked;
              if (newVal === true) {
                setForm({
                  ...form,
                  attributes: {
                    ...form?.attributes,
                    [key]: _.map(listOfAttributes, (att) => att?.id),
                  },
                });
              } else {
                setForm({
                  ...form,
                  attributes: {
                    ...form?.attributes,
                    [key]: [],
                  },
                });
              }
            }}
          />
          Select all
        </div>

        <div className="border-b-[1px]">
          {_.map(autoAttributes, (attribute, index) => {
            return (
              <div key={index} className="pl-2 py-1 flex items-center gap-x-2">
                {renderCheckBox(key, attribute?.id)}
                {transformString(attribute?.name)}
              </div>
            );
          })}
        </div>

        {_.map(annotationAttributes, (attribute, index) => {
          return (
            <div key={index} className="pl-2 py-1 flex items-center gap-x-2">
              {renderCheckBox(key, attribute?.id)}
              {attribute?.name}
            </div>
          );
        })}
      </div>
    );
  };

  const renderCheckBox = (
    key: keyof CreateNewFileFormModel["attributes"],
    attributeID: string,
  ) => {
    const checkedState = _.includes(form.attributes?.[key], attributeID);
    return (
      <CustomCheckbox
        checked={checkedState}
        onChange={(e) => {
          const newVal = e.target.checked;
          if (newVal === true) {
            setForm({
              ...form,
              attributes: {
                ...form?.attributes,
                [key]: [...form.attributes?.[key], attributeID],
              },
            });
          } else {
            const newArr = _.filter(
              form.attributes?.[key],
              (attID) => attributeID !== attID,
            );
            setForm({
              ...form,
              attributes: {
                ...form?.attributes,
                [key]: newArr,
              },
            });
          }
        }}
      />
    );
  };

  const renderInfo = (label: string) => {
    return (
      <div
        className="flex items-center p-2 my-2 rounded 
        bg-paletteYellow-light border-2 border-paletteYellow"
      >
        <div
          className="w-[30px] h-[30px] flex justify-center items-center
           border-2 border-paletteYellow rounded-full mx-2 text-paletteYellow"
        >
          ?
        </div>
        {label}
      </div>
    );
  };

  return (
    <div className="w-full flex flex-col justify-center">
      {/* Switches */}
      <div
        className="w-full p-4 rounded-xl shadow-around"
        data-test="main_options"
      >
        <div className="font-semibold">Main options:</div>
        {renderSwitch(
          "includeAnnotations",
          "Include annotation (raw annotations)",
        )}
        {renderSwitch(
          "provideFullSubsetList",
          "Include all subsets/tags in reference list",
        )}
      </div>

      {/* Selectors */}
      <div className="flex gap-x-4 my-4 justify-around">
        {/* Medias */}
        <div className="w-1/3 p-4 rounded-xl shadow-around">
          <div className="font-semibold">{`Media tags/subsets:`}</div>
          {renderSelector("mediasTags", "tags", TagTypeModel.Media)}
          {renderSelector("mediasSubsets", "subsets")}
        </div>
        {/* Media objects */}
        {dataSetData?.activeDataSet?.mediatype === Mediatype.image && (
          <div className="w-1/3 p-4 rounded-xl shadow-around">
            <div className="font-semibold">{`Object tags/subsets:`}</div>
            {renderSelector(
              "mediaObjectsTags",
              "tags",
              TagTypeModel.MediaObject,
            )}
            {renderSelector("mediaObjectsSubsets", "subsets")}
          </div>
        )}
        {/* Attributes */}
        <div className="w-1/3 p-4 rounded-xl shadow-around">
          <div className="font-semibold">Attribute tags:</div>
          {renderSelector("attributesTags", "tags", TagTypeModel.Attribute)}
        </div>
      </div>

      {/*  Info */}
      {renderInfo(`If no specific subsets/tags are selected in the selector, all the
        subsets/tags would be included in that section(selector)!`)}

      {/* Attributes selection */}
      <div className="w-full flex justify-around">
        {renderAttributes(
          "media",
          _.filter(attributesMeta, { annotatable_type: "Media" }),
        )}
        {renderAttributes(
          "object",
          _.filter(attributesMeta, { annotatable_type: "MediaObject" }),
        )}
      </div>
      {/*  Info */}
      {renderInfo(
        "If no attributes are selected, all the attributes would be included in the export!",
      )}

      {/* Create button */}
      <div className="w-full flex justify-end">
        <button
          onClick={handleCreateNewFile}
          className={"button-layer"}
          data-test="create_new_export_configuration"
          disabled={isLoading}
        >
          <DownloadIcon className="w-5 h-5" />
          Create new export configuration
        </button>
      </div>
    </div>
  );
};

export default CreateNewFile;

import { useState } from "react";
import {
  DataTableColumn,
  DataTableExpandRow,
} from "components/Table/DataTable";
import TooltipTruncateEllipsis from "components/Tooltips/TooltipTruncateEllipsis";
import _ from "lodash";
import { ReactComponent as ArrowDownIcon } from "assets/arrow_filled_down.svg";

type Props = {
  row: Record<string, unknown>;
  columns: DataTableColumn[];
  onRowClick?: (row: Record<string, unknown>) => void;
  expandRow?: DataTableExpandRow;
  disableReason?: string;
  style?: {
    rowYPadding?: string;
  };
};

const TableRow = ({
  row,
  columns,
  onRowClick,
  expandRow,
  disableReason,
  style,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Render the cells of the table (the data) for each row
  const renderRowCells = () => {
    return _.map(columns, (col, key) => {
      const columnSpan = col?.span || 100 / columns?.length;
      return (
        <div
          key={key}
          className={`flex items-center gap-x-2 ${col?.headerClassName}`}
          style={{ width: `${columnSpan}%` }}
        >
          {renderCellValue(row, col)}
        </div>
      );
    });
  };

  const renderCellValue = (
    row: Record<string, unknown>,
    col: DataTableColumn,
  ) => {
    // If the cell is a custom cell, use the cell function to render the cell
    // Else, use the field to render the cell
    const cellValue = col?.cell ? col?.cell(row) : _.get(row, col?.field);
    if (_.isElement(cellValue)) {
      return cellValue;
    }

    if (_.isString(cellValue)) {
      return (
        <TooltipTruncateEllipsis className="pr-3 whitespace-pre">
          {cellValue}
        </TooltipTruncateEllipsis>
      );
    }

    return cellValue;
  };

  const renderExpandRow = () => {
    return (
      <div
        role="button"
        className="w-fit pl-5 flex gap-x-1 items-center text-paletteGray-9 text-sm cursor-pointer"
        onClick={(event) => {
          event.stopPropagation();
          if (!isExpanded && expandRow?.onExpandClick) {
            expandRow.onExpandClick(row);
          }
          setIsExpanded(!isExpanded);
        }}
      >
        {expandRow?.text}
        <ArrowDownIcon
          className={`w-4 h-4 ${isExpanded ? "rotate-180" : ""}`}
        />
      </div>
    );
  };

  const renderExpandRowBody = () => {
    if (!isExpanded) return null;

    return (
      <div className="w-full flex text-paletteGray-9 text-sm cursor-pointer">
        {expandRow?.body(row)}
      </div>
    );
  };

  const isDisabled = row.disabled;
  const shouldRenderExpandedRow = expandRow && _.get(row, expandRow.key);

  return (
    <div
      role="button"
      key={_.get(row, "id", Math.random()) as string}
      title={isDisabled ? disableReason : ""}
      className={`w-full rounded-xl text-lg
      border-paletteGray-4 hover:border-paletteGray-5 
      ${style?.rowYPadding || "py-[13.5px]"}
      ${
        isDisabled
          ? "bg-gray-300 cursor-not-allowed opacity-50"
          : onRowClick
            ? "cursor-pointer hover:bg-blue-100"
            : ""
      }
      ${isExpanded ? "bg-paletteGray-1" : ""}
      `}
      onClick={(e) => {
        if (!isDisabled && onRowClick) {
          e.preventDefault();
          onRowClick(row);
        }
      }}
    >
      <div className="w-full flex px-4">{renderRowCells()}</div>
      {shouldRenderExpandedRow && renderExpandRow()}
      {shouldRenderExpandedRow && renderExpandRowBody()}
    </div>
  );
};

export default TableRow;

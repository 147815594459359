import { ReactComponent as ArrowLongLeftIcon } from "assets/arrow_long_left.svg";
import { useHistory } from "react-router-dom";

type Props = { URL: string };

const BackToButton = ({ URL }: Props) => {
  const history = useHistory();

  return (
    <div
      className="icon-button-layer"
      data-test="back_to_button"
      onClick={() => URL && history.push(URL)}
    >
      <ArrowLongLeftIcon />
    </div>
  );
};

export default BackToButton;

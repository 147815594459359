import _ from "lodash";
import { SelectFieldModel } from "models/form.model";
import SimpleSelect from "components/Inputs/Form/SimpleSelect";
import { useAppSelector } from "store/hooks";

type Props = {
  selectedUserGroup: string;
  onChanges: (newUserGroup: string) => void;
};

const UserGroupSelection = ({ selectedUserGroup, onChanges }: Props) => {
  const userGroups = useAppSelector(
    (state) => state.userManagementSlice.availableUserGroups,
  );

  const selectionField: SelectFieldModel = {
    type: "select",
    key: "user_groups",
    value: selectedUserGroup || "",
    placeholder: "Select user group",
    options: [
      {
        value: null,
        label: "--No Group--",
      },
      ..._.map(userGroups, (group) => {
        return {
          value: group,
          label: group,
        };
      }),
    ],
  };

  return (
    <div className="w-full" onClick={(e) => e.stopPropagation()}>
      <SimpleSelect
        field={selectionField}
        value={selectionField?.value}
        handleOnChange={(event) => onChanges(event.target.value)}
      />
    </div>
  );
};

export default UserGroupSelection;

import { Dialog } from "@mui/material";
import DatasetSelectionTable from "components/Table/DatasetSelectionTable";
import { AttributeMetadataModel } from "models/attributes.model";
import { DatasetModel } from "models/dataset.model";
import { useState } from "react";
import { setNewAIAnnotationRunDatasetAndSubset } from "store/aiManagerSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";

export interface AddAttributeDialog {
  show: boolean;
  attribute: AttributeMetadataModel | null;
  dataset: DatasetModel | null;
}

type Props = {
  isSelectDatasetAndSubsetDialogOpen: boolean;
  setIsSelectDatasetAndSubsetDialogOpen: (newState: boolean) => void;
};

const SelectDatasetAndSubsetDialog = ({
  isSelectDatasetAndSubsetDialogOpen,
  setIsSelectDatasetAndSubsetDialogOpen,
}: Props) => {
  const dispatch = useAppDispatch();

  const newTrainingSet = useAppSelector(
    (state) => state.aiManagerSlice.newTrainingSet,
  );

  const [isLoading, setIsLoading] = useState(false);
  const [localDataset, setLocalDataset] = useState<DatasetModel | null>(null);
  const [localSubset, setLocalSubset] = useState<DatasetModel | null>(null);

  const handleDatasetAndSubsetSelectionClick = (params: {
    clickedDataset: DatasetModel | null;
    clickedSubset: DatasetModel | null;
  }) => {
    if (!params.clickedDataset || !params.clickedSubset) return;

    dispatch(
      setNewAIAnnotationRunDatasetAndSubset({
        dataset: params.clickedDataset,
        subset: params.clickedSubset,
      }),
    );

    setIsSelectDatasetAndSubsetDialogOpen(false);
    setLocalDataset(null);
    setLocalSubset(null);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isSelectDatasetAndSubsetDialogOpen}
      onClose={() => !isLoading && setIsSelectDatasetAndSubsetDialogOpen(false)}
      PaperProps={{ style: { borderRadius: "16px" } }}
    >
      <div className="w-full h-[650px] pb-2">
        <DatasetSelectionTable
          firstStep="dataset"
          secondStep="subset"
          firstStepItem={localDataset}
          onFirstStepRowClick={(firstStepItem) =>
            setLocalDataset(firstStepItem as DatasetModel | null)
          }
          secondStepItem={localSubset}
          onSecondStepRowClick={(secondStepItem) =>
            handleDatasetAndSubsetSelectionClick({
              clickedDataset: localDataset,
              clickedSubset: secondStepItem as DatasetModel | null,
            })
          }
        />
      </div>
    </Dialog>
  );
};

export default SelectDatasetAndSubsetDialog;

import { useAppDispatch, useAppSelector } from "store/hooks";
import { Dialog } from "@mui/material";
import DatasetSelectionTable from "components/Table/DatasetSelectionTable";
import { addNewTrainingSetTrainingAttributeSubset } from "store/aiManagerSlice";
import { DatasetModel } from "models/dataset.model";
import { TrainingSetAttribute } from "models/trainingSet.model";
import { getTrainingSetSubsetQuery } from "helpers/apis/trainingSet";

type Props = {
  isSubsetDialogOpen: boolean;
  setIsSubsetDialogOpen: (newState: boolean) => void;
  selectedAttribute: TrainingSetAttribute | null;
};

const NewTrainingSetAttributeSubsetSelections = ({
  isSubsetDialogOpen,
  setIsSubsetDialogOpen,
  selectedAttribute,
}: Props) => {
  const dispatch = useAppDispatch();

  const selectedDataset = useAppSelector(
    (state) => state.aiManagerSlice.newTrainingSet.dataset,
  );

  const subsetIDsQuery =
    selectedAttribute && getTrainingSetSubsetQuery(selectedAttribute);
  const subsetIDs = (subsetIDsQuery?.value as string[]) || null;
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isSubsetDialogOpen}
      onClose={() => setIsSubsetDialogOpen(false)}
      PaperProps={{ style: { borderRadius: "16px" } }}
    >
      <div className="h-[650px]">
        <DatasetSelectionTable
          firstStep="subset"
          firstStepItem={null}
          firstStepSelectedIDs={subsetIDs}
          onFirstStepRowClick={(firstStepItem) => {
            if (selectedAttribute === null) return;

            const subset = firstStepItem as DatasetModel;
            setIsSubsetDialogOpen(false);
            dispatch(
              addNewTrainingSetTrainingAttributeSubset({
                attributeID: selectedAttribute?.attribute_id,
                subset: subset,
              }),
            );
          }}
          selectedDatasetID={selectedDataset?.id}
        />
      </div>
    </Dialog>
  );
};

export default NewTrainingSetAttributeSubsetSelections;

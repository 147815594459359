import _ from "lodash";

export interface StandardTabModel {
  label: string;
  value: string;
  onClick?: () => void;
  count?: number;
  required?: boolean;
}
interface Props {
  tabs: StandardTabModel[];
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
}

const StandardTabs = ({ tabs, selectedTab, setSelectedTab }: Props) => {
  const renderTab = (tab: StandardTabModel) => {
    return (
      <button
        key={tab?.value}
        className={`button-layer w-full py-[6.5px] border-[1px] whitespace-nowrap  ${
          tab?.value === selectedTab
            ? "bg-white text-paletteBlack-2 drop-shadow font-medium border-paletteGray-5 hover:bg-white"
            : "border-transparent "
        }`}
        onClick={() => {
          setSelectedTab(tab?.value);
          tab?.onClick && tab?.onClick();
        }}
      >
        {tab?.label}
        {tab?.required && <span className="pl-1 text-paletteRed">*</span>}
        {!_.isUndefined(tab?.count) && renderCount(tab)}
      </button>
    );
  };

  const renderCount = (tab: StandardTabModel) => {
    if (_.isUndefined(tab?.count)) return null;

    return (
      <div
        className={`px-1 rounded text-sm font-medium ${
          selectedTab === tab?.value
            ? "text-white bg-paletteBlack-1"
            : "bg-paletteGray-6"
        }`}
        data-test="tab_count"
      >
        {tab?.count}
      </div>
    );
  };

  return (
    <nav
      className="h-fit p-[2px] flex gap-x-[2px] bg-paletteGray-2 rounded-lg "
      data-test="tab_selection"
    >
      {_.map(tabs, (tab) => renderTab(tab))}
    </nav>
  );
};

export default StandardTabs;

import { StatisticsScreenRouteModel } from "models/routes.model";
import { useParams } from "react-router-dom";
import { useAppSelector } from "store/hooks";

import BackToButton from "components/Buttons/BackToButton";
import { getExplorationURLWithSearchParams } from "helpers/functions/filters/filtersHelpers";

const StatisticsScreenHeader = () => {
  const params: StatisticsScreenRouteModel = useParams();
  const activeDataSet = useAppSelector(
    (state) => state.datasetSlice.activeDataSet,
  );
  const selectedView = useAppSelector((state) => state.appSlice.selectedView);
  const filterData = useAppSelector((state) => state.filterDataSlice);
  const sortData = useAppSelector((state) => state.sortDataSlice);

  return (
    <div className="w-full h-[64px] border-b-[1px]">
      <div className="w-full h-full p-4 flex gap-x-4 items-center bg-white">
        <BackToButton
          URL={getExplorationURLWithSearchParams(
            selectedView,
            filterData,
            sortData,
            {
              id: params?.dataset_id,
              subset_id: params?.subset_id,
            },
          )}
        />
        {activeDataSet?.name}
      </div>
    </div>
  );
};

export default StatisticsScreenHeader;

import { useState, useEffect, useRef, RefObject } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  fetchMediaData,
  setMediaExplorationCurrentMedia,
  updateMediaExplorationHasMore,
} from "store/explorationMediaSlice";
import _ from "lodash";

import {
  ExplorationAPIRequestModel,
  MediaModel,
} from "models/exploration.model";

import InfiniteScroll from "react-infinite-scroll-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import { fetchMediaDataScroll } from "helpers/apis/medias";
import CircularProgress from "@mui/material/CircularProgress";
import ThumbnailCard from "Pages/Exploration/ThumbnailCard";
import { fetchOneMediaAPI } from "helpers/apis/medias";
import { AnnotatableEnum, VisibilityStatus } from "models/global.model";
import ExplorationHeader from "Pages/Exploration/ExplorationHeader";
import { ExplorationScreenRouteModel } from "models/routes.model";
import InfoPanel from "sections/InfoPanel";
import { memoizedFindAppropriateThumbnail } from "components/utilFunctions";
import convertActiveFilterToSendFilter from "helpers/functions/filters/convertActiveFilterToSendFilter";
import { fetchSelectedViewCount } from "helpers/functions/datasets/datasetHelpers";
import { searchParamsToSort } from "helpers/functions/filters/filtersHelpers";
import { SingleSliderFieldModel } from "models/form.model";
import SubsetCreationLoading from "Pages/Exploration/SubsetCreationLoading";
import useSelectWithShiftKey from "helpers/hooks/useSelectWithShiftKey";
import { handleOnCheckboxClick } from "helpers/functions/exploration/explorationHelper";

const scrollLoadAmount = Number(
  process.env.REACT_APP_SCROLL_LOAD_AMOUNT ??
    alert("Config value 'scroll load amount' is not defined"),
);

const ExplorationMedia = () => {
  const params: ExplorationScreenRouteModel = useParams();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const explorationMediaData = useAppSelector(
    (state) => state.explorationMediaSlice,
  );
  const activeMediaFilter = useAppSelector(
    (state) => state.filterDataSlice.activeMediaFilter,
  );
  const selectedView = useAppSelector((state) => state.appSlice.selectedView);
  const filterData = useAppSelector((state) => state.filterDataSlice);

  const totalCount = useAppSelector(
    (state) => state.metaDataSlice?.mediaCount?.total_count,
  );
  const mediaFilterData = useAppSelector(
    (state) => state.filterDataSlice.activeMediaFilter,
  );
  const mediaSortData = useAppSelector(
    (state) => state.sortDataSlice?.activeMedia,
  );
  const dataSetData = useAppSelector((state) => state.datasetSlice);

  const lastSelectedMediaID = explorationMediaData.lastSelectedMediaID;
  const lastSelectedItemIndex = explorationMediaData.data.findIndex(
    (media) => media.id === lastSelectedMediaID,
  );

  // Selecting multiple medias
  const { isSelectingMultiple } = useSelectWithShiftKey();

  const [numOfItemPerRow, setNumOfItemPerRow] =
    useState<SingleSliderFieldModel>({
      type: "single_slider",
      key: "sampleSize",
      value: 5,
      min: 4,
      max: 10,
      settings: {
        step: 1,
        stepInteger: true,
        hideUnderSliderLabels: true,
      },
    });

  const currentMediaRef = useRef<HTMLDivElement>(null);
  const firstMediaRef = useRef<HTMLDivElement>(null);
  const [hoveredIndex, setHoveredIndex] = useState(0);
  const [pinnedIndex, setPinnedIndex] = useState<null | number>(null);

  useEffect(() => {
    currentMediaRef.current?.scrollIntoView();
  }, [currentMediaRef]);

  useEffect(() => {
    if (
      explorationMediaData?.skip + scrollLoadAmount >= totalCount ||
      totalCount === 0
    ) {
      dispatch(updateMediaExplorationHasMore(false));
    }
  }, [explorationMediaData?.data, totalCount]);

  // Fetch medias data for exploration on initial load
  // Update when: The URL id or subset_id change
  useEffect(() => {
    if (
      _.isEmpty(explorationMediaData?.data) &&
      // Need to check if the data is not loading
      // Fix for issue when the data is empty and then apply filter function triggers a fetch for
      //  new data and also update active filter.
      //  The new active filter will trigger this useEffect.
      //  If the previous data was 0, then this will trigger simultaneously with the fetch from the apply filter.
      explorationMediaData?.loading === false &&
      !_.isEmpty(filterData.mediaFilterData)
    ) {
      const APIBody: ExplorationAPIRequestModel = {
        dataset_id: params.id,
        limit: scrollLoadAmount,
        skip: 0,
        query: convertActiveFilterToSendFilter(activeMediaFilter),
        sort: searchParamsToSort(),
      };
      dispatch(
        fetchMediaData({
          runId: params.id,
          reqBody: APIBody,
          subSetId: params?.subset_id,
        }),
      );

      fetchSelectedViewCount(
        {
          selectedView: selectedView,
          filterData: filterData,
          runID: params.id,
          subset_id: params?.subset_id,
        },
        dispatch,
      );
    }
  }, [params?.id, params?.subset_id, activeMediaFilter]);

  const fetchMediaByMediaName = async (mediaID: string) => {
    const response = await fetchOneMediaAPI(mediaID, params?.id);
    return response;
  };

  const onCheckboxClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    media: MediaModel,
  ) => {
    handleOnCheckboxClick(
      event,
      {
        selectedView,
        isSelectingMultiple,
        lastSelectedItemID: explorationMediaData.lastSelectedMediaID,
        data: explorationMediaData.data,
        clickedItem: media,
        alreadySelectedItems: explorationMediaData.selectedMediaIDs,
      },
      dispatch,
    );
  };

  const handleOnExpandButtonClick = (media: MediaModel) => {
    if (_.isUndefined(params?.id) || _.isUndefined(params?.subset_id))
      throw new Error("Media or params is undefined");

    dispatch(setMediaExplorationCurrentMedia(media));
    const pushURL = `/main/${params.id}/${params.subset_id}/media/${media.id}`;
    history.push(pushURL);
  };
  if (
    dataSetData.activeDataSet?.visibility_status ===
    VisibilityStatus.CreatingSubset
  ) {
    return <SubsetCreationLoading subset={dataSetData.activeDataSet} />;
  }

  return (
    <div className="flex h-full">
      <div className="min-w-0 flex-1 h-full flex flex-col gap-y-4">
        {/* Header section */}
        <ExplorationHeader
          numOfItemPerRow={numOfItemPerRow}
          setNumOfItemPerRow={(newVal) => setNumOfItemPerRow(newVal)}
        />

        {/* Infinite Scroll */}
        <div
          className="flex-1 min-h-0 overflow-y-auto"
          id="medias-scrollable-div"
          data-test="media_scroll_element"
        >
          {explorationMediaData.loading && explorationMediaData?.hasMore ? (
            <div className="h-full flex justify-center items-center">
              <CircularProgress size={60} />
            </div>
          ) : (
            <InfiniteScroll
              dataLength={explorationMediaData?.data?.length || 0}
              next={() =>
                fetchMediaDataScroll(
                  dispatch,
                  explorationMediaData,
                  totalCount,
                  params?.id,
                  params?.subset_id,
                  mediaFilterData,
                  mediaSortData,
                )
              }
              hasMore={explorationMediaData?.hasMore}
              loader={
                <div className="h-full flex justify-center items-center">
                  Loading...
                </div>
              }
              initialScrollY={Math.floor(hoveredIndex / 5) * 262}
              scrollThreshold={0.5}
              scrollableTarget="medias-scrollable-div"
            >
              <div
                className={`h-full grid gap-[1px] grid-cols-${numOfItemPerRow?.value} pr-2`}
                data-test="media_expl_grid"
              >
                {_.map(explorationMediaData?.data, (media, key: number) => {
                  const isSelected = _.includes(
                    explorationMediaData.selectedMediaIDs,
                    media?.id,
                  );
                  const isMediaBetweenLastSelectedAndHovered =
                    isSelectingMultiple &&
                    ((key > lastSelectedItemIndex && key <= hoveredIndex) ||
                      (key < lastSelectedItemIndex && key >= hoveredIndex));

                  let url;
                  const thumbnailDimensions = _.keys(media?.thumbnails);
                  if (thumbnailDimensions?.length === 1) {
                    url =
                      media?.thumbnails?.[
                        thumbnailDimensions?.[thumbnailDimensions?.length - 1]
                      ];
                  } else {
                    url =
                      media?.thumbnails?.[
                        memoizedFindAppropriateThumbnail(
                          currentMediaRef,
                          media?.thumbnails,
                        ) ?? "140x113"
                      ];
                  }
                  let ref: RefObject<HTMLDivElement> | null;
                  if (key === 0) {
                    ref = firstMediaRef;
                  } else {
                    media?.id === explorationMediaData.currentMedia?.id
                      ? (ref = currentMediaRef)
                      : (ref = null);
                  }

                  return (
                    <div key={media?.id} ref={ref}>
                      <ThumbnailCard
                        type={AnnotatableEnum.Media}
                        item={media}
                        url={url}
                        itemIndex={key}
                        isSelected={isSelected}
                        hoveredIndex={hoveredIndex}
                        setHoveredIndex={setHoveredIndex}
                        pinnedIndex={pinnedIndex}
                        setPinnedIndex={setPinnedIndex}
                        onExpandClick={() => handleOnExpandButtonClick(media)}
                        onCheckboxClick={(event) =>
                          onCheckboxClick(event, media)
                        }
                        onItemURLExpire={fetchMediaByMediaName}
                        isItemBeingMultiSelected={
                          isMediaBetweenLastSelectedAndHovered
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </InfiniteScroll>
          )}
        </div>
      </div>

      <InfoPanel
        showMediaOrMediaObject={AnnotatableEnum.Media}
        currentMedia={
          explorationMediaData?.data?.[
            !_.isNull(pinnedIndex) ? pinnedIndex : hoveredIndex
          ]
        }
        currentMediaObject={undefined}
      />
    </div>
  );
};

export default ExplorationMedia;

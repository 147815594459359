import { NotificationManagerScreenTabs } from "sections/NotificationCenter";
import StandardTabs from "components/Tabs/StandardTabs";
import { useAppSelector } from "store/hooks";

type Props = {
  selectedTabValue: string;
  onTabClick: (tab: NotificationManagerScreenTabs) => void;
};

const NotificationTabs = ({ selectedTabValue, onTabClick }: Props) => {
  const notificationCounts = useAppSelector(
    (state) => state.notificationSlice.counts,
  );

  const tabs = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Unread",
      value: "unread",
      count: notificationCounts?.unread,
    },
  ];

  return (
    <div className="w-full">
      <StandardTabs
        tabs={tabs}
        selectedTab={selectedTabValue}
        setSelectedTab={(tab) =>
          onTabClick(tab as NotificationManagerScreenTabs)
        }
      />
    </div>
  );
};

export default NotificationTabs;

import { ReactComponent as NotificationIcon } from "assets/notification_bell.svg";
import { ReactComponent as NotificationRungIcon } from "assets/notification_bell_ring.svg";
import { toggleDrawer } from "store/notificationsSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";

const NotificationButton = () => {
  const dispatch = useAppDispatch();
  const notificationData = useAppSelector((state) => state.notificationSlice);

  return (
    <button
      className="hover:bg-transparent"
      aria-label="Notifications"
      onClick={() => dispatch(toggleDrawer())}
      data-test="notifications_button"
    >
      {notificationData.counts.unread !== 0 ? (
        <NotificationRungIcon width={24} height={24} />
      ) : (
        <NotificationIcon width={24} height={24} />
      )}
    </button>
  );
};

export default NotificationButton;

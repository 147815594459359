import { useState } from "react";
import { useAppDispatch } from "store/hooks";
import BasicButton from "components/Buttons/BasicButton";

import { postThreeDDataLVG } from "helpers/apis/transformations";
import TextInput from "components/Inputs/Form/TextInput";
import { TextFieldModel } from "models/form.model";

interface Props {
  datasetID: string;
  subsetID: string;
  isAnonymized: boolean | undefined;
}

const LidarVideoGenerationTab = ({
  datasetID,
  subsetID,
  isAnonymized,
}: Props) => {
  const dispatch = useAppDispatch();

  const [name, setName] = useState<TextFieldModel>({
    label: "Name",
    type: "text",
    key: "name",
    placeholder: "At least 3 letters",
    value: "",
    settings: {
      minimumTextLength: 3,
    },
  });

  const renderSubmitButton = () => {
    const disabled =
      datasetID === "" ||
      subsetID === "" ||
      name.value === "" ||
      isAnonymized === undefined ||
      !isAnonymized;
    return (
      <div className="flex justify-end mt-4">
        <BasicButton label="Send" onClick={handleSubmit} disabled={disabled} />
      </div>
    );
  };

  const handleSubmit = () => {
    postThreeDDataLVG(
      { datasetID: datasetID, subsetID: subsetID },
      { name: name.value },
      dispatch,
    );
  };

  const renderIsDatasetAnonymized = () => {
    return (
      datasetID && (
        <div
          className={`my-2 ${
            isAnonymized ? "text-green-500" : "text-paletteRed"
          }`}
        >
          {isAnonymized
            ? "Dataset is anonymized."
            : "Dataset is not anonymized yet. Please anonymize it in the Anonymization tab."}
        </div>
      )
    );
  };

  return (
    <div className="w-1/3 my-2">
      {renderIsDatasetAnonymized()}

      <TextInput
        field={{ ...name }}
        value={name?.value}
        handleOnChange={(e, field, isErrored, error) =>
          setName({
            ...name,
            value: e.target.value,
            isErrored: isErrored || false,
            error: error || null,
          })
        }
      />

      {renderSubmitButton()}
    </div>
  );
};

export default LidarVideoGenerationTab;

import {
  validateNumberInput,
  validateSelectInput,
  validateSingleSelectInput,
  validateTextInput,
} from "components/Inputs/Form/formInputsHelpers";
import _, { set } from "lodash";
import { FormModel } from "models/form.model";

/**
 * A function to handle the form submission
 * @param formData The form data to submit
 * @param setFormData The function to set the form data
 * @param handleOnSubmit The function to handle the form submission
 * @param initFormData The initial form data state to reset to
 */
export const submitForm = (
  formData: FormModel,
  setFormData: (formData: FormModel) => void,
  initFormData: FormModel,
  handleOnSubmit?: <Type>(formData: Type) => void,
) => {
  const fromValidation = validateForm(formData);
  setFormData(fromValidation?.newFormState);
  if (!fromValidation.isFormValid) return;

  disableForm(formData, setFormData);
  handleOnSubmit && handleOnSubmit(formData);
  resetForm(initFormData, setFormData);
};

/**
 * A function to validate the form data and set the form data state
 * Set the isErrored and error properties of the form data state
 * @param formData The form data to validate
 * @returns An object containing the form data state and a boolean indicating if the form is valid
 */
export const validateForm = (formData: FormModel) => {
  let isFormValid = true;
  let newFormState = { ...formData };
  _.map(formData, (field) => {
    // TODO: Simplify this switch statement by using one function to validate all inputs types
    switch (field?.type) {
      case "text":
        if (validateTextInput(field) !== null) {
          newFormState = {
            ...newFormState,
            [field?.key]: {
              ...newFormState[field?.key],
              isErrored: true,
              error: validateTextInput(field),
            },
          };
          isFormValid = false;
        }
        break;
      case "number":
        if (validateNumberInput(field) !== null) {
          newFormState = {
            ...newFormState,
            [field?.key]: {
              ...newFormState[field?.key],
              isErrored: true,
              error: validateNumberInput(field),
            },
          };
          isFormValid = false;
        }
        break;
      case "single_slider":
        if (validateSingleSelectInput(field) !== null) {
          newFormState = {
            ...newFormState,
            [field?.key]: {
              ...newFormState[field?.key],
              isErrored: true,
              error: validateSingleSelectInput(field),
            },
          };
          isFormValid = false;
        }
        break;
      case "select":
        if (validateSelectInput(field) !== null) {
          newFormState = {
            ...newFormState,
            [field?.key]: {
              ...newFormState[field?.key],
              isErrored: true,
              error: validateSelectInput(field),
            },
          };
          isFormValid = false;
        }
        break;
      default:
        break;
    }
  });

  return { isFormValid: isFormValid, newFormState: newFormState };
};

/**
 * A function to reset the form data state to the initial state
 * @param initFormData The initial form data state to reset to
 * @param setFormData The function to set the form data
 */
export const resetForm = (
  initFormData: FormModel,
  setFormData: (formData: FormModel) => void,
) => {
  setFormData({ ...initFormData });
};

/**
 * A function to disable the form data state
 * @param formData The form data to disable
 * @param setFormData The function to set the form data
 */
export const disableForm = (
  formData: FormModel,
  setFormData: (formData: FormModel) => void,
) => {
  let newFormState = { ...formData };
  _.map(formData, (field) => {
    newFormState = {
      ...newFormState,
      [field?.key]: {
        ...newFormState[field?.key],
        disabled: true,
      },
    };
  });
  setFormData(newFormState);
};

import { CallMade, ArrowBackIos } from "@mui/icons-material";

import { useHistory } from "react-router-dom";

import { gaDisallow, mcDisallow } from "helpers/analytics/cookieAcceptance";

const classes = {
  column: "flex-1 px-4",
  title: "mt-8 mb-4 text-inherit",
  text: "my-4",
};

const PrivacyPolicy = () => {
  const history = useHistory();

  return (
    <article className="h-full w-full overflow-auto">
      <div className="flex flex-col w-4/5 px-8 mx-auto pt-6">
        <div
          onClick={() => {
            history.goBack();
          }}
          className="text-lg font-bold self-start cursor-pointer mb-[16px]"
        >
          <ArrowBackIos
            style={{ width: "24px", height: "24px", marginRight: "0.5rem" }}
          />
          Back
        </div>
        <h2 className="font-bold py-6">DATENSCHUTZERKLÄRUNG</h2>

        <article className="flex">
          <article id="GermanLangDiv" className={classes.column}>
            <h2 className={classes.title}>HINWEISE ZUM DATENSCHUTZ</h2>
            <div className={classes.text}>
              Mit dieser Datenschutzerklärung möchten wir Sie darüber
              informieren, wie wir personenbezogene Daten verarbeiten und Sie
              über Ihre Rechte aufklären. Wir sind uns bewusst, welche Bedeutung
              die Verarbeitung personenbezogener Daten für Sie als betroffener
              Nutzer hat und beachten dementsprechend alle einschlägigen
              gesetzlichen Vorgaben. Dabei ist der Schutz Ihrer Privatsphäre für
              uns von höchster Bedeutung. Die Verarbeitung Ihrer
              personenbezogenen Daten durch uns erfolgt unter Einhaltung der
              Datenschutzgrundverordnung, sowie der landesrechtlichen
              Datenschutzbestimmungen.
            </div>
            <div className={classes.text}>
              Namen und Kontaktdaten des Verantwortlichen sowie seines
              Vertreters
            </div>
            <div className={classes.text}>
              Quality Match GmbH, Häusserstraße 36, 69115 Heidelberg,
              <br></br> GF Dr. Daniel Kondermann<br></br> Legal Amtsgericht
              Mannheim, HRB: 738316
            </div>
            <div className={classes.text}>
              Kontaktdaten des Datenschutzbeauftragten:<br></br>
              <a href={"mailto:privacy@quality-match.com"}>
                privacy@quality-match.com
              </a>
            </div>

            <h2 className={classes.title}>BEGRIFFSBESTIMMUNGEN</h2>
            <div className={classes.text}>
              Diese Datenschutzerklärung bedient sich der Begrifflichkeiten der
              Datenschutzgrundverordnung (DSGVO).
            </div>
            <div className={classes.text}>
              „PERSONENBEZOGENE DATEN“ sind alle Informationen, die sich auf
              eine identifizierte oder identifizierbare natürliche Person (im
              Folgenden „betroffene Person“) beziehen; als identifizierbar wird
              eine natürliche Person angesehen, die direkt oder indirekt,
              insbesondere mittels Zuordnung zu einer Kennung wie einem Namen,
              zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung
              oder zu einem oder mehreren besonderen Merkmalen identifiziert
              werden kann, die Ausdruck der physischen, physiologischen,
              genetischen, psychischen, wirtschaftlichen, kulturellen oder
              sozialen Identität dieser natürlichen Person sind.
            </div>
            <div className={classes.text}>
              „VERARBEITUNG“ ist jeder mit oder ohne Hilfe automatisierter
              Verfahren ausgeführter Vorgang oder jede solche Vorgangsreihe im
              Zusammenhang mit personenbezogenen Daten wie das Erheben, das
              Erfassen, die Organisation, das Ordnen, die Speicherung, die
              Anpassung oder Veränderung, das Auslesen, das Abfragen, die
              Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder
              eine andere Form der Bereitstellung, den Abgleich oder die
              Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.
            </div>
            <div className={classes.text}>
              „EINSCHRÄNKUNG DER VERARBEITUNG“ ist die Markierung gespeicherter
              personenbezogener Daten mit dem Ziel, ihre künftige Verarbeitung
              einzuschränken.
            </div>
            <div className={classes.text}>
              „PROFILING“ ist jede Art der automatisierten Verarbeitung
              personenbezogener Daten, die darin besteht, dass diese
              personenbezogenen Daten verwendet werden, um bestimmte persönliche
              Aspekte, die sich auf eine natürliche Person beziehen, zu
              bewerten, insbesondere um Aspekte bezüglich Arbeitsleistung,
              wirtschaftliche Lage, Gesundheit, persönliche Vorlieben,
              Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder
              Ortswechsel dieser natürlichen Person zu analysieren oder
              vorherzusagen.
            </div>
            <div className={classes.text}>
              „PSEUDONYMISIERUNG“ ist die Verarbeitung personenbezogener Daten
              in einer Weise, dass die personenbezogenen Daten ohne Hinzuziehung
              zusätzlicher Informationen nicht mehr einer spezifischen
              betroffenen Person zugeordnet werden können, sofern diese
              zusätzlichen Informationen gesondert aufbewahrt werden und
              technischen und organisatorischen Maßnahmen unterliegen, die
              gewährleisten, dass die personenbezogenen Daten nicht einer
              identifizierten oder identifizierbaren natürlichen Person
              zugewiesen werden.
            </div>
            <div className={classes.text}>
              „DATEISYSTEM“ ist jede strukturierte Sammlung personenbezogener
              Daten, die nach bestimmten Kriterien zugänglich sind, unabhängig
              davon, ob diese Sammlung zentral, dezentral oder nach funktionalen
              oder geografischen Gesichtspunkten geordnet geführt wird;
            </div>
            <div className={classes.text}>
              „VERANTWORTLICHER“ ist die natürliche oder juristische Person,
              Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam
              mit anderen über die Zwecke und Mittel der Verarbeitung von
              personenbezogenen Daten entscheidet; sind die Zwecke und Mittel
              dieser Verarbeitung durch das Unionsrecht oder das Recht der
              Mitgliedstaaten vorgegeben, so können der Verantwortliche
              beziehungsweise die bestimmten Kriterien seiner Benennung nach dem
              Unionsrecht oder dem Recht der Mitgliedstaaten vorgesehen werden.
            </div>
            <div className={classes.text}>
              „AUFTRAGSVERARBEITER“ ist eine natürliche oder juristische Person,
              Behörde, Einrichtung oder andere Stelle, die personenbezogene
              Daten im Auftrag des Verantwortlichen verarbeitet.
            </div>
            <div className={classes.text}>
              „EMPFÄNGER“ ist eine natürliche oder juristische Person, Behörde,
              Einrichtung oder andere Stelle, denen personenbezogene Daten
              offengelegt werden, unabhängig davon, ob es sich bei ihr um einen
              Dritten handelt oder nicht. Behörden, die im Rahmen eines
              bestimmten Untersuchungsauftrags nach dem Unionsrecht oder dem
              Recht der Mitgliedstaaten möglicherweise personenbezogene Daten
              erhalten, gelten jedoch nicht als Empfänger; die Verarbeitung
              dieser Daten durch die genannten Behörden erfolgt im Einklang mit
              den geltenden Datenschutzvorschriften gemäß den Zwecken der
              Verarbeitung.
            </div>
            <div className={classes.text}>
              „DRITTER“ ist eine natürliche oder juristische Person, Behörde,
              Einrichtung oder andere Stelle, außer der betroffenen Person, dem
              Verantwortlichen, dem Auftragsverarbeiter und den Personen, die
              unter der unmittelbaren Verantwortung des Verantwortlichen oder
              des Auftragsverarbeiters befugt sind, die personenbezogenen Daten
              zu verarbeiten.
            </div>
            <div className={classes.text}>
              „EINWILLIGUNG“ ist die der betroffenen Person freiwillig für den
              bestimmten Fall, in informierter Weise und unmissverständlich
              abgegebene Willensbekundung in Form einer Erklärung oder einer
              sonstigen eindeutigen bestätigenden Handlung, mit der die
              betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung
              der sie betreffenden personenbezogenen Daten einverstanden ist.
            </div>

            <h2 className={classes.title}>VERARBEITUNGSVORGÄNGE</h2>
            <div className={classes.text}>
              Wir erheben und verarbeiten die folgenden personenbezogenen Daten
              über Sie:
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                Kontakt -und Adressinformationen, sofern Sie uns Ihre
                Kontaktinformationen übermittelt oder sich auf unserer Seite
                registriert haben,
              </div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                Online-Identifikatoren (z.B. Ihre IP-Adresse, Browsertyp und
                Browserversion, das verwandte Betriebssystem, die Referrer-URL,
                die IP-Adresse, den Dateinamen, den Zugriffsstatus, die
                übertragene Datenmenge, das Datum und die Uhrzeit der
                Serveranfrage),
              </div>
            </div>

            <h2 className={classes.title}>VERARBEITUNGSZWECKE</h2>
            <div className={classes.text}>
              Wir verarbeiten Ihre Daten zu den folgenden Zwecken:
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                für die von Ihnen gewünschte Kontaktaufnahme,
              </div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                zur Vertragsabwicklung, insb. zur Bearbeitung von Bestellungen
                und zur Rechnungserstellung,
              </div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>zu Werbezwecken,</div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>zur Qualitätssicherung und</div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>für unsere Statistik.</div>
            </div>

            <h2 className={classes.title}>
              RECHTSGRUNDLAGE DER DATENVERARBEITUNG
            </h2>
            <div className={classes.text}>
              Die Verarbeitung Ihrer Daten geschieht auf den folgenden
              Rechtsgrundlagen:
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                Ihrer Einwilligung nach Art. 6 Abs. 1 lit. a) DSGVO,
              </div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                zur Durchführung eines Vertrags mit Ihnen nach Art. 6 Abs. 1
                lit. b) DSGVO,
              </div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                zur Erfüllung gesetzlicher Pflichten nach Art. 6 Abs. 1 lit. c)
                DSGVO oder
              </div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                aus einem berechtigten Interesse nach Art. 6 Abs. 1 lit. f)
                DSGVO.
              </div>
            </div>

            <h2 className={classes.title}>BERECHTIGTE INTERESSEN</h2>
            <div className={classes.text}>
              Sofern wir die Verarbeitung Ihrer personenbezogenen Daten auf
              BERECHTIGTE INTERESSEN iSv. Art. 6 Abs. 1 lit. f) DS-GVO stützen,
              sind solche
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                die Verbesserung unseres Angebots
              </div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>der Schutz vor Missbrauch und</div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>die Führung unserer Statistik.</div>
            </div>

            <h2 className={classes.title}>DATENQUELLEN</h2>
            <div className={classes.text}>
              Wir erhalten die Daten von Ihnen (einschließlich über die von
              Ihnen verwendeten Geräte). Sollten wir die personenbezogenen Daten
              nicht direkt von Ihnen erheben, so teilen wir Ihnen zusätzlich
              mit, aus welcher Quelle die personenbezogenen Daten stammen und
              gegebenenfalls ob sie aus öffentlich zugänglichen Quellen stammen.
            </div>

            <h2 className={classes.title}>
              EMPFÄNGER ODER KATEGORIEN VON EMPFÄNGERN DER PERSONENBEZOGENEN
              DATEN
            </h2>
            <div className={classes.text}>
              Bei der Verarbeitung Ihrer Daten arbeiten wir mit den folgenden
              Dienstleistern zusammen, die Zugriff auf Ihre Daten haben:
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>Anbieter von Webanalyse-Tools,</div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>Web-Hosting-Anbieter,</div>
            </div>
            <div className={classes.text}>
              Es findet idR keine Datenübertragung in Drittländer außerhalb der
              Europäischen Union statt. Sollte dies doch geschehen, erfolgt
              diese auf der Grundlage gesetzlich vorgesehener vertraglicher
              Regelungen, die einen angemessenen Schutz Ihrer Daten
              sicherstellen sollen und die Sie auf Anfrage einsehen können.
            </div>

            <h2 className={classes.title}>SPEICHERDAUER</h2>
            <div className={classes.text}>
              Wir speichern Ihre personenbezogenen Daten nur solange, wie es für
              die Erreichung des Verarbeitungszweckes nötig ist oder die
              Speicherung einer gesetzlichen Aufbewahrungsfrist unterliegt.
            </div>
            <div className={classes.text}>Wir speichern Ihre Daten,</div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                wenn Sie in die Verarbeitung eingewilligt haben höchstens
                solange, bis Sie Ihre Einwilligung widerrufen,
              </div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                wenn wir die Daten zur Durchführung eines Vertrags benötigen
                höchstens solange, wie das Vertragsverhältnis mit Ihnen besteht
                oder gesetzliche Aufbewahrungsfristen laufen,
              </div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                wenn wir die Daten auf der Grundlage eines berechtigten
                Interesses verwenden höchstens solange, wie Ihr Interesse an
                einer Löschung oder Anonymisierung nicht überwiegt
              </div>
            </div>

            <h2 className={classes.title}>IHRE RECHTE</h2>
            <div className={classes.text}>
              Sie haben – teilweise unter bestimmten Voraussetzungen – das
              Recht,
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                unentgeltlich AUSKUNFT über die Verarbeitung Ihrer Daten zu
                verlangen, sowie den Erhalt einer KOPIE Ihrer personenbezogenen
                Daten. Auskunft können Sie u.a. verlangen über die Zwecke der
                Verarbeitung, die Kategorien der personenbezogenen Daten die
                verarbeitet werden, die Empfänger der Daten (sofern eine
                Weitergabe erfolgt), die Dauer der Speicherung oder die
                Kriterien für die Festlegung der Dauer;
              </div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                Ihre Daten zu BERICHTIGEN. Sollten Ihre personenbezogenen Daten
                unvollständig sein, so haben Sie, unter Berücksichtigung der
                Verarbeitungszwecke, das Recht die Daten zu vervollständigen;
              </div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                Ihre Daten LÖSCHEN oder SPERREN zu lassen. Gründe für das
                Bestehen eines Löschungs-/Sperrungsanspruchs können u.a. sein
                der Widerruf der Einwilligung auf dem die Verarbeitung fußt, die
                betroffene Person legt Widerspruch gegen die Verarbeitung ein,
                die personenbezogenen Daten wurden unrechtmäßig verarbeitet;
              </div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                die Verarbeitung EINSCHRÄNKEN ZU LASSEN;
              </div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                der Verarbeitung Ihrer Daten zu WIDERSPRECHEN;
              </div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                Ihre Einwilligung zur Verarbeitung Ihrer Daten für die Zukunft
                zu WIDERRUFEN und
              </div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                sich bei der zuständigen Aufsichtsbehörde über eine unzulässige
                Datenverarbeitung zu BESCHWEREN.
              </div>
            </div>

            <h2 className={classes.title}>
              ERFORDERNIS ODER VERPFLICHTUNG ZUR BEREITSTELLUNG VON DATEN
            </h2>
            <div className={classes.text}>
              Soweit dies bei der Erhebung nicht ausdrücklich angegeben ist, ist
              die Bereitstellung von Daten nicht erforderlich oder
              verpflichtend. Eine solche Verpflichtung kann sich aus
              gesetzlichen Vorgaben oder vertraglichen Regelungen ergeben.
            </div>

            <h2 className={classes.title}>
              WEITERE INFORMATIONEN ZUM DATENSCHUTZ
            </h2>
            <div className={classes.text}>LOGFILES</div>
            <div className={classes.text}>
              Bei jedem Zugriff auf unsere Internet-Seiten werden Nutzungsdaten
              durch den jeweiligen Internetbrowser übermittelt und in
              Protokolldateien, den sogenannten Server-Logfiles, gespeichert.
              Die dabei gespeicherten Datensätze enthalten die folgenden Daten:
              Domain, von der aus der Nutzer Zugriff auf die Webseite nimmt,
              Datum und Uhrzeit des Abrufs IP-Adresse des zugreifenden Rechners
              Webseite(n), die der Nutzer im Rahmen des Angebots besucht,
              übertragene Datenmenge, Browsertyp und -version, verwendetes
              Betriebssystem, Name des Internet-Service-Providers, Meldung, ob
              der Abruf erfolgreich war. Diese Logfile-Datensätze werden in
              anonymisierter Form ausgewertet, um das Angebot zu verbessern und
              nutzerfreundlicher zu gestalten, Fehler zu finden und zu beheben
              und die Auslastung von Servern zu steuern.
            </div>
            <div className={classes.text}>COOKIES</div>
            <div className={classes.text}>
              Diese Website verwendet sog. Cookies. Ein Cookie ist eine
              Textdatei mit einer Identifikationsnummer, die bei der Nutzung der
              Website zusammen mit den anderen, eigentlich angeforderten Daten
              an den Computer des Nutzers übermittelt und dort hinterlegt wird.
              Die Datei wird dort für einen späteren Zugriff bereitgehalten und
              dient zur Authentifizierung des Nutzers. Da Cookies nur einfache
              Dateien und keine ausführbaren Programme sind, geht von ihnen
              keine Gefahr für den Computer aus. Je nach gewählter Einstellung
              des Internetbrowsers durch den Nutzer akzeptiert dieser Cookies
              automatisch. Diese Einstellung kann aber geändert und die
              Speicherung von Cookies deaktiviert oder dergestalt eingestellt
              werden, dass der Nutzer benachrichtigt wird, sobald ein Cookie
              gesetzt wird. Im Falle einer Deaktivierung der Cookienutzung
              stehen allerdings ggf. einige Funktionen der Webseite nicht oder
              nur eingeschränkt zur Verfügung. Sie können die Setzung von
              Cookies durch unsere Internetseite jederzeit mittels einer
              entsprechenden Einstellung des genutzten Internetbrowsers
              verhindern und damit der Setzung von Cookies dauerhaft
              widersprechen. Bereits aktive Cookies können jederzeit über einen
              Internetbrowser oder andere Softwareprogramme gelöscht werden. Wir
              arbeiten unter Umständen mit Werbepartnern zusammen, die uns
              helfen, unser Internetangebot für Sie interessanter zu gestalten.
              Zu diesem Zweck werden für diesen Fall bei Ihrem Besuch unserer
              Website auch Cookies von Partnerunternehmen auf Ihrer Festplatte
              gespeichert (Cookies von Drittanbietern). Wenn wir mit
              vorbenannten Werbepartnern zusammenarbeiten, werden Sie über den
              Einsatz derartiger Cookies und den Umfang der jeweils erhobenen
              Informationen innerhalb der nachstehenden Absätze individuell und
              gesondert informiert.
            </div>
            <div className={classes.text}>
              Folgende ARTEN VON COOKIES werden unterschieden:
            </div>
            <div className={classes.text}>
              ERSTANBIETER-COOKIES: Erstanbieter-Cookies werden von der gerade
              besuchten Plattform übertragen.
            </div>
            <div className={classes.text}>
              DRITTANBIETER-COOKIES (SOG. THIRD PARTY COOKIES):
              Drittanbieter-Cookies sind Cookies, die von einem anderen Anbieter
              als dem der vom Nutzer besuchten Plattform übertragen werden. Wenn
              ein Nutzer eine Plattform aufruft und eine andere Einheit durch
              diese Plattform einen Cookie überträgt, handelt es sich um einen
              Drittanbieter-Cookie.
            </div>
            <div className={classes.text}>
              UNBEDINGT NOTWENDIGE COOKIES: Diese Cookies sind notwendig, damit
              Sie durch die Plattform navigieren und ihre Funktionen, wie z. B.
              den Zugriff auf sichere Bereiche der Plattform, nutzen können.
              Ohne diese können bestimmte Services nicht bereitgestellt werden,
              wie z. B. die Anzeige von für Ihren Computer bzw. Ihr Gerät
              angepasster Inhalte.
            </div>
            <div className={classes.text}>
              PPERFORMANCE-COOKIES: Diese Cookies erheben Informationen darüber,
              wie Besucher die Plattform verwenden, z. B. welche Seiten am
              häufigsten aufgerufen werden und ob sie Fehlermeldungen von
              Webseiten erhalten. Diese Cookies sammeln jedoch keine
              Informationen zur Identität des Besuchers. Alle Informationen, die
              diese Cookies sammeln, werden zusammengefasst und sind daher
              anonym. Sie werden nur verwendet, um die Plattform zu optimieren.
            </div>
            <div className={classes.text}>
              FUNKTIONALITÄTS-COOKIES: Diese Cookies ermöglichen der Plattform,
              von Ihnen getroffene Entscheidungen zu speichern (z. B.
              Spracheinstellungen und Ihre Region) und Ihnen verbesserte,
              persönlichere Funktionen zu bieten. Sie können auch dazu verwendet
              werden, Ihre Einstellungen hinsichtlich der Textgröße, Schriftart
              und anderer individualisierbarer Teile der Website zu speichern.
              Sie können ebenfalls dazu verwendet werden, um von Ihnen
              gewünschte Services bereitzustellen wie z. B. die Anzeige eines
              Videos oder die Kommentarfunktion innerhalb eines Blogs. Die
              Informationen, die diese Cookies sammeln, können anonymisiert
              werden. Ihre Surfaktivitäten können auf anderen Plattformen nicht
              nachverfolgt werden.
            </div>
            <div className={classes.text}>
              SOCIAL-MEDIA-COOKIES: Diese Cookies werden verwendet, wenn Sie auf
              der Plattform eine Schaltfläche zum Teilen von Informationen auf
              sozialen Medien anklicken. Das soziale Netzwerk zeichnet diese
              Aktion auf und kann sie zu Marketing- oder Werbezwecken verwenden.
            </div>
            <div className={classes.text}>
              MMARKETING-/WERBE-COOKIES: Diese Cookies werden verwendet, um
              Ihnen Werbeeinblendungen anzuzeigen, die aufgrund Ihrer Interessen
              für Sie relevant sein könnten. Sie werden auch dazu verwendet, um
              die Häufigkeit zu begrenzen, mit der Ihnen eine bestimmte Werbung
              gezeigt wird. Wir setzen Cookies ebenfalls ein um zu messen, wie
              effektiv eine Werbekampagne ist. Sie werden üblicherweise mit
              unserer Erlaubnis von Werbenetzwerken platziert und zeichnen Ihren
              Besuch auf einer Plattform auf. Diese Information wird an andere
              Organisationen wie z. B. Werbeanbieter weitergegeben. Oftmals sind
              Marketing- und Werbe-Cookies an Plattformfunktionen gekoppelt.
              Unsere Werbe-Cookies ermöglichen Ihnen auch, an Kundenumfragen
              teilzunehmen und uns so Ihr Feedback zukommen zu lassen, damit wir
              Ihr Nutzererlebnis bei uns verbessern können.
            </div>

            <h2 className={classes.title}>GOOGLE ANALYTICS</h2>
            <div className={classes.text}>
              Wir nutzen den Dienst Google Analytics der Google Inc. Dieser
              Dienst ermöglicht eine Analyse der Benutzung unserer
              Internetseiten und verwendet dazu Cookies. Zu diesem Zweck werden
              die durch das Cookie erzeugten Informationen wie Ihre
              anonymisierte IP-Adresse in unserem Auftrag an einen Server von
              Google Inc. in den USA übertragen, dort gespeichert und
              ausgewertet. Denn auf dieser Webseite wurde Google Analytics um
              den Code „gat._anonymizeIp();“ erweitert. Hierdurch ist eine
              anonymisierte Erfassung von IP-Adressen sichergestellt. Die
              Anonymisierung Ihrer IP-Adresse erfolgt in der Regel durch Kürzung
              Ihrer IP-Adresse durch Google Inc. innerhalb der Europäischen
              Union oder in anderen Vertragsstaaten des Europäischen
              Wirtschaftsraumes (EWR). In Ausnahmefällen wird Ihre IP-Adresse an
              einen Server der Google Inc. in den USA übertragen und erst dort
              anonymisiert. Ihre hierbei übermittelte IP-Adresse wird nicht mit
              anderen Daten von Google Inc. zusammengeführt. Im Rahmen der
              Google Analytics-Werbefunktion werden das Remarketing und die
              Berichte zur Leistung nach demografischen Merkmalen und Interessen
              genutzt. Diese Verfahren haben den Zweck, mit Hilfe der
              Informationen über das Nutzerverhalten die Werbemaßnahmen stärker
              an den Interessen der jeweiligen Nutzer auszurichten. Im Rahmen
              des Remarketings können auf Basis des Surfverhaltens des Users auf
              dieser Webseite personalisierte Werbemaßnahmen auf anderen
              Internetseiten geschaltet werden. Dabei können die Werbemittel
              Produkte enthalten, die sich der User zuvor auf der Webseite
              angeschaut hat. Sofern Sie zugestimmt haben, dass ihr Web- und
              App-Browserverlauf von Google mit ihrem Google-Konto verknüpft
              wird und Informationen aus ihrem Google-Konto zum Personalisieren
              von Anzeigen verwendet werden, verwendet Google diese Daten für
              ein geräteübergreifendes Remarketing. Der Erfassung Ihrer Daten
              durch Google Analytics können Sie jederzeit widersprechen. Dazu
              stehen Ihnen folgende Möglichkeiten zur Verfügung: Die meisten
              Browser akzeptieren Cookies automatisch. Sie können die Verwendung
              von Cookies jedoch durch Einstellung Ihres Browsers verhindern; in
              diesem Fall können aber ggf. nicht sämtliche Funktionen der
              Internetseite genutzt werden. Die Einstellungen müssen Sie für
              jeden Browser, den Sie nutzen, gesondert vornehmen.
            </div>
            <div className={classes.text}>
              Sie können darüber hinaus die Erfassung sowie die Verarbeitung
              dieser Daten durch Google Inc. verhindern, indem Sie das unter dem
              folgenden Link verfügbare Browser-Add-On herunterladen und
              installieren:{" "}
              <a href="https://tools.google.com/dlpage/gaoptout?hl=de">
                https://tools.google.com/dlpage/gaoptout?hl=de
              </a>
            </div>
            <div className={classes.text}>
              Alternativ dazu oder innerhalb von Browsern auf mobilen Geräten
              klicken Sie bitte auf folgenden Link:{" "}
              <a href="#" onClick={() => gaDisallow()}>
                Google Analytics deaktivieren
              </a>{" "}
              Dabei wird ein Opt-Out-Cookie auf Ihrem Gerät für unsere
              Internetseiten mit Wirkung für Ihren derzeit verwendeten Browser
              abgelegt. Löschen Sie Ihre Cookies in diesem Browser, müssen Sie
              erneut auf diesen Link klicken. Nähere Informationen zu
              Nutzungsbedingungen und Datenschutz finden Sie unter<br></br>
              <br></br>
              <a href="https://www.google.com/analytics/terms/de.html">
                https://www.google.com/analytics/terms/de.html
              </a>
              <br></br>
              <br></br>bzw. unter<br></br>
              <br></br>
              <a href="https://policies.google.com/?hl=de">
                https://policies.google.com/?hl=de
              </a>
            </div>

            <h2 className={classes.title}>GOOGLE REMARKETING</h2>
            <div className={classes.text}>
              Mittels der Remarketing Technologie von Google Ireland Limited
              (Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
              Irland) werden Nutzer, die unsere Website bereits besuchten, durch
              interessenbasierte Werbung auf den Seiten des Google Partner
              Netzwerks erneut angesprochen. Die Werbeanzeigen können dabei die
              Produkte enthalten, die sich der User zuvor auf unserer Webseite
              angeschaut hat (Remarketing). Zu diesem Zweck sind auf dieser
              Seite die Google Remarketing Tracking-Pixel integriert, welche das
              User-Verhalten auf der Website (z. B. angesehene Berater), die
              technischen Merkmale des Webseiten-Besuchs (z. B. der verwendete
              Browser, die IP-Adresse) sowie das Kauf-Verhalten (z. B. zuletzt
              besuchte Berater) ohne Personenbezug erfasst und an Google
              übermittelt. Für die Zwecke der Wiedererkennung und Zuordnung der
              User setzt Google Cookies ein. Sofern Nutzer zugestimmt haben,
              dass ihr Web- und App-Browserverlauf von Google mit ihrem
              Google-Konto verknüpft wird und Informationen aus dem Google-Konto
              von uns zum Personalisieren von Anzeigen verwendet werden, die sie
              im Web sehen, verwendet Google Daten dieser angemeldeten Nutzer
              zusammen mit eigenen erhoben Daten, um Zielgruppenlisten für
              geräteübergreifendes Remarketing zu erstellen und zu definieren.
              Zur Unterstützung dieser Funktion werden von Google Analytics
              authentifizierte IDs dieser Nutzer erfasst. Diese
              personenbezogenen Daten von Google werden vorübergehend mit Google
              Analytics-Daten von uns verknüpft, um Zielgruppen zu bilden.
            </div>
            <div className={classes.text}>
              Mehr Informationen und Möglichkeiten zur Deaktivierung dieser
              Anzeigenschaltung finden sich unter<br></br>
              <br></br>
              <a href="https://www.google.com/settings/u/0/ads/anonymous?hl=de">
                https://www.google.com/settings/u/0/ads/anonymous?hl=de
              </a>
            </div>

            <h2 className={classes.title}>GOOGLE-TAG-MANAGER</h2>
            <div className={classes.text}>
              Diese Website benutzt den Google Tag Manager. Google Tag Manager
              ist eine Lösung, mit der Vermarkter Website-Tags über eine
              Oberfläche verwalten können. Das Tool Tag Manager selbst (das die
              Tags implementiert) ist eine cookielose Domain und erfasst keine
              personenbezogenen Daten. Das Tool sorgt für die Auslösung anderer
              Tags, die ihrerseits unter Umständen Daten erfassen. Google Tag
              Manager greift nicht auf diese Daten zu. Wenn auf Domain- oder
              Cookie-Ebene eine Deaktivierung vorgenommen wurde, bleibt diese
              für alle Tracking-Tags bestehen, die mit Google Tag Manager
              implementiert werden.
            </div>

            <h2 className={classes.title}>MICROSOFT CLARITY</h2>
            <div className={classes.text}>
              Auf dieser Website werden über den Dienst „Microsoft Clarity“ der
              Microsoft Corporation, One Microsoft Way, Redmond, WA 98052-6399
              USA (nachfolgend „Microsoft“) zur statistischen Analyse des
              Nutzerverhaltens und zu Optimierungs- und Marketingzwecken
              verschiedene Nutzerinformationen gesammelt und gespeichert. Zu
              diesen Informationen, bei denen ein Personenbezug stets
              ausgeschlossen ist, gehören unter anderem Zeitzoneneinstellung,
              Betriebssystem und -plattform, die geographische Herkunft des
              Seitenaufrufs, bei Weiterleitung auf unsere Seite der
              Weiterleitungsursprung, die Dauer von Besuchen bestimmter Seiten
              sowie Informationen über Webseite-Interaktion (z. B. Scrollen,
              Klicks und Mouse-Overs). Aus diesen Daten können zum selben Zweck
              pseudonymisierte Nutzungsprofile erstellt und ausgewertet werden.
              Zur Erhebung und Auswertung werden Cookies eingesetzt. Bei Cookies
              handelt es sich um kleine Textdateien, die lokal im
              Zwischenspeicher des Internet-Browsers des Seitenbesuchers
              gespeichert werden. Die Cookies ermöglichen unter anderem die
              Wiedererkennung des Internet-Browsers. Die mit den
              Microsoft-Technologien erhobenen Daten werden ohne die gesondert
              erteilte Zustimmung des Betroffenen nicht dazu benutzt, den
              Besucher dieser Website persönlich zu identifizieren und sie
              werden nicht mit personenbezogenen Daten über den Träger des
              Pseudonyms zusammengeführt. Erhobene Informationen werden
              gegebenenfalls an Server von Microsoft in die USA übermittelt und
              dort gespeichert. Wir haben mit Microsoft einen
              Auftragsverarbeitungsvertrag abgeschlossen, mit dem wir Microsoft
              verpflichten, die Daten unserer Kunden zu schützen und sie nicht
              an Dritte weiterzugeben. Weitere Informationen darüber, wie
              Microsoft Ihre Daten sammelt und verwendet, finden Sie in der{" "}
              <a href="https://learn.microsoft.com/en-us/clarity/setup-and-installation/privacy-disclosure">
                Microsoft-Datenschutzerklärung
              </a>
              .
            </div>
            <div className={classes.text}>
              Alle oben beschriebenen Verarbeitungen, insbesondere das Setzen
              von Cookies für das Auslesen von Informationen auf dem verwendeten
              Endgerät, werden nur dann vollzogen, wenn Sie uns gemäß Art. 6
              Abs. 1 lit. a DSGVO dazu Ihre ausdrückliche Einwilligung erteilt
              haben. Ohne diese Einwilligungserteilung unterbleibt der Einsatz
              von Microsoft Clarity während Ihres Seitenbesuchs. Sie können Ihre
              erteilte Einwilligung jederzeit mit Wirkung für die Zukunft
              widerrufen:{" "}
              <a href="#" onClick={() => mcDisallow()}>
                Zustimmung widerrufen
              </a>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                STAND DIESER DATENSCHUTZERKLÄRUNG:<br></br>
                <br></br>SEPTEMBER 2020
              </div>
            </div>
            <div className={classes.text}>
              WIR BEHALTEN UNS VOR, DIESE DATENSCHUTZERKLÄRUNG JEDERZEIT MIT
              WIRKUNG FÜR DIE ZUKUNFT ZU ÄNDERN.
            </div>
          </article>

          <article
            id="EnglishLangDiv"
            className={`${classes.column} text-qmorange-500`}
          >
            <h2 className={classes.title}>INFORMATION ON DATA PROTECTION</h2>
            <div className={classes.text}>
              With this data protection declaration we would like to inform you
              about the processing of personal data in our company and the
              rights to which you are entitled. We are distinctly aware of the
              importance to users of how personal data are handled and
              accordingly observe all relevant legal requirements. Protecting
              your privacy is of utmost importance to us. We, therefore, process
              your personal data in accordance with the General Data Protection
              Regulation and the data protection regulations of the German
              federal states.
            </div>
            <div className={classes.text}>
              Name and contact details of the person in charge and their
              representative
            </div>
            <div className={classes.text}>
              Quality Match GmbH, Häusserstraße 36, 69115 Heidelberg,
              <br></br> Managing Director: Dr. Daniel Kondermann<br></br> Legal:
              Amtsgericht Mannheim, HRB: 738316
            </div>
            <div className={classes.text}>
              Contact details of the data protection officer:<br></br>
              <a href={"mailto:privacy@quality-match.com"}>
                privacy@quality-match.com
              </a>
            </div>

            <h2 className={classes.title}>DEFINITIONS</h2>
            <div className={classes.text}>
              This data protection declaration uses the definitions, concepts,
              and terminology of the General Data Protection Regulation (GDPR).
            </div>
            <div className={classes.text}>
              PERSONAL DATA: any information relating to an identified or
              identifiable natural person (hereinafter referred to as "data
              subject"); an identifiable natural person is one who can be
              identified, directly or indirectly, in particular by reference to
              an identifier such as a name, an identification number, a location
              data, an online identifier, or one or more factors specific to the
              physical, physiological, genetic, mental, economic, cultural or
              social identity of that natural person.
            </div>
            <div className={classes.text}>
              PROCESSING: any operation or set of operations which is performed
              on personal data, whether or not by automated means, such as
              collection, recording, organization, structuring, storage,
              adaptation or alteration, retrieval, consultation, use, disclosure
              by transmission, dissemination or otherwise making available,
              alignment or combination, restriction, erasure or destruction.
            </div>
            <div className={classes.text}>
              RESTRICTION OF PROCESSING: the marking of stored personal data
              with the aim of limiting their processing in the future.
            </div>
            <div className={classes.text}>
              PROFILING: any form of automated processing of personal data
              consisting of the use of personal data to evaluate certain
              personal aspects relating to a natural person, in particular to
              analyze or predict aspects concerning that natural person’s
              performance at work, economic situation, health, personal
              preferences, interests, reliability, behavior, location or
              movement.
            </div>
            <div className={classes.text}>
              PSEUDONYMIZATION: the processing of personal data in such a manner
              that the personal data can no longer be attributed to a specific
              data subject without the use of additional information, as long as
              this additional information is kept separately and technical and
              organizational measures to ensure that the personal data are not
              attributed to an identified or identifiable natural person.
            </div>
            <div className={classes.text}>
              FILING SYSTEM: any structured collection of personal data
              accessible according to specific criteria, whether centralized,
              decentralized or organized according to functional or geographical
              criteria.
            </div>
            <div className={classes.text}>
              DATA CONTROLLER: is the natural or legal person, public authority,
              agency or any other body which alone, or jointly with others,
              determines the purposes and means of the processing of personal
              data; where the purposes and means of such processing are
              determined by the Union or national law, the controller or the
              specific criteria for its nomination may be provided for by Union
              or national law.
            </div>
            <div className={classes.text}>
              DATA PROCESSOR: a natural or legal person, public authority,
              agency or other body which processes personal data on behalf of
              the controller.
            </div>
            <div className={classes.text}>
              RECIPIENT: any natural or legal person, public authority, agency
              or other body to whom personal data are disclosed, whether or not
              that person is a third party. However, authorities which may
              receive personal data in the context of a specific investigation
              task under Union or Member State law shall not be considered as
              recipients; the processing of such data by those authorities shall
              be carried out in accordance with the applicable data protection
              rules and in accordance with the purposes of the processing.
            </div>
            <div className={classes.text}>
              THIRD PARTY: any natural or legal person, public authority, agency
              or any other body, other than the data subject, the controller,
              the processor and the persons who, under the direct authority of
              the controller or the processor, are authorized to process the
              pearsonal data.
            </div>
            <div className={classes.text}>
              CONSENT: any freely given, specific, informed and unambiguous
              indication of the individual’s wishes by which the data subject,
              either by statement or by a clear affirmative action, signifies
              agreement to personal data relating to them being processed.
            </div>

            <h2 className={classes.title}>PROCESSING OPERATIONS</h2>
            <div className={classes.text}>
              We collect and process the following personal data about you:
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                Contact and address information, if you have submitted your
                contact information or registered on our site,
              </div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                Online identifiers (e.g., your IP address, browser type and
                version, the operating system used, the referrer URL, the IP
                address, the file name, the access status, the amount of data
                transferred, the date and time of the server request).
              </div>
            </div>

            <h2 className={classes.title}>PROCESSING PURPOSES</h2>
            <div className={classes.text}>
              We process your data for the following purposes:
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                For the contact you wish to make,
              </div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                For the processing of contracts, in particular for the
                processing of orders and for invoicing,
              </div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>For advertising purposes,</div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>For quality assurance and</div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                For the management of our statistics
              </div>
            </div>

            <h2 className={classes.title}>LEGAL BASIS FOR DATA PROCESSING</h2>
            <div className={classes.text}>
              Your data are processed in compliance with the following legal
              basis:
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                Your consent pursuant to art. 6 par. 1 lit. a) GDPR,
              </div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                To execute a contract with you pursuant to art. 6 par. 1 lit. b)
                GDPR,
              </div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                To fulfill legal duties pursuant to art. 6 par. 1 lit. c) GDPR
                or
              </div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                Because of a legitimate interest pursuant to art. 6 par. 1 lit.
                f) GDPR.
              </div>
            </div>

            <h2 className={classes.title}>LEGITIMATE INTERESTS</h2>
            <div className={classes.text}>
              Insofar as we restrict the processing of your personal data to
              LEGITIMATE INTERESTS as defined by the GDPR art. 6 par. 1 letter
              f), such interests are:
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                The improvement of our services,
              </div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                The protection against abuse and
              </div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                The performance of our statistical operations
              </div>
            </div>

            <h2 className={classes.title}>DATA SOURCES</h2>
            <div className={classes.text}>
              We receive the data from you (including data about the devices you
              use). If we do not collect the personal data directly from you, we
              will inform you about the origin of the personal data and, where
              applicable, whether we obtained it from publicly accessible
              sources.
            </div>

            <h2 className={classes.title}>
              RECIPIENTS OR CATEGORIES OF RECIPIENTS OF PERSONAL DATA
            </h2>
            <div className={classes.text}>
              When processing your data, we work with the following service
              providers who have access to your data:
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                Providers of web analysis tools
              </div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>Web hosting providers</div>
            </div>
            <div className={classes.text}>
              There is usually no data transfer to third countries outside the
              European Union. Should this happen, however, it takes place on the
              basis of legally prescribed contractual rules, which are intended
              to ensure adequate protection for your data and which you may
              inspect on request.
            </div>

            <h2 className={classes.title}>DURATION OF STORAGE</h2>
            <div className={classes.text}>
              We only store your personal data for as long as it is necessary to
              achieve the purpose of the processing or as long as the storage is
              subject to a legal retention period.
            </div>
            <div className={classes.text}>We store your data,</div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                If you have given your consent to the processing, at most until
                you revoke your consent,
              </div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                If we need the data to execute a contract, at most for as long
                as the contractual relationship with you exists or if legal
                retention periods apply
              </div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                If we use the data on the basis of a legitimate interest, at
                most for as long as your interest in deletion or anonymization
                does not outweigh our own
              </div>
            </div>

            <h2 className={classes.title}>YOUR RIGHTS</h2>
            <div className={classes.text}>
              In certain circumstances, you have the following rights
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                To request INFORMATION about the processing of your data free of
                charge and to receive a COPY OF your personal data. You may
                request information about, among other things, the purposes of
                the processing, the categories of personal data processed, the
                recipients of the data (if the data are disclosed), the duration
                of the storage or the criteria for determining the duration,
              </div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                To RECTIFY your data. If your personal data are incomplete you
                have the right to make your data complete, having regard to the
                purposes of processing,
              </div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                To HAVE your data ERASED or to HAVE IT BLOCKED. Reasons for a
                claim to erase or block could be, among other things, the
                withdrawal of the consent underlying the processing, the data
                subject objects to the processing, the personal data have been
                processed unlawfully,
              </div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                To HAVE the processing RESTRICTED,
              </div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                To OBJECT TO the processing of your data,
              </div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                To WITHDRAW your consent from the processing of your data in the
                future and
              </div>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                To COMPLAIN to the relevant regulatory authority about
                unauthorized data processing
              </div>
            </div>

            <h2 className={classes.title}>
              REQUIREMENT OR OBLIGATION TO PROVIDE DATA
            </h2>
            <div className={classes.text}>
              Unless this is expressly specified at collection, the provision of
              data is not required or obligatory. Such an obligation may arise
              by reason of legal requirements or contractual regulations.
            </div>

            <h2 className={classes.title}>
              FURTHER INFORMATION ON DATA PROTECTION
            </h2>
            <div className={classes.text}>LOG FILES</div>
            <div className={classes.text}>
              During every access of our internet sites, user data is
              transmitted by the relevant internet browser and stored in
              protocol files, so-called server log files. The datasets stored in
              this way contain the following data: domain from which the user
              accesses the website, date and time of access, IP address of the
              accessing computer, website(s) visited by the user, the
              transmitted data volume, browser type and version, operating
              system used, name of the internet service provider, report as to
              success of the call-up. These log file data records are evaluated
              in an anonymized form in order to improve services and make them
              more user-friendly, to find and rectify errors and to regulate
              server capacity.
            </div>
            <div className={classes.text}>COOKIES</div>
            <div className={classes.text}>
              This website uses so-called cookies. A cookie is a text file with
              an identification number which, together with the data actually
              requested, is transmitted to and placed on the user's computer
              during the use of the website. The file is kept there in readiness
              for subsequent access and is used to authenticate the user. As
              cookies are just simple files and not executable programs, they
              pose no danger to the computer. Depending on the setting chosen by
              the user, the internet browser automatically accepts cookies.
              However, the settings can be changed and the storage of cookies
              can be disabled or adjusted so that the user is notified once a
              cookie is placed. If the use of cookies is disabled, however, some
              website functions may not be available or restricted. You can at
              any time prevent the setting of cookies by our internet site by
              adjusting the settings accordingly on your web browser and forbid
              the placement of cookies permanently. Cookies that are already
              active can be deleted at any time by your web browser or other
              software programs. We may work with advertising partners who help
              us make our website more interesting for you. In this case,
              cookies from partner companies are also stored for this purpose on
              your hard drive when you visit our website (cookies from third
              parties). If we work with these partners, you will be informed
              individually and separately about the use of such cookies and the
              scope of the information collected in each case within the
              following paragraphs.
            </div>
            <div className={classes.text}>
              The following TYPES OF COOKIES are distinguished:
            </div>
            <div className={classes.text}>
              FIRST-PARTY COOKIES: First-party cookies are transferred from the
              platform you are currently visiting.
            </div>
            <div className={classes.text}>
              THIRD-PARTY COOKIES: Third-party cookies are created by domains
              that are not the domain you are visiting. When a user visits a
              website and another entity transmits cookies through that website,
              that would be a case of third-party cookies.
            </div>
            <div className={classes.text}>
              STRICTLY NECESSARY COOKIES: These cookies are necessary to allow
              for a user to navigate through the website and use its functions,
              such as access to secure areas of the website. Without them,
              certain services cannot be provided, such as the display of
              content customized for your computer or device.
            </div>
            <div className={classes.text}>
              PERFORMANCE COOKIES: These cookies gather information about how
              visitors use a website, e.g., which pages are visited most or if
              they get error messages on pages. However, these cookies do not
              collect identifiable information on visitors. All information
              collected by these cookies is aggregated and therefore anonymous
              and only used to improve the functionality of a website.
            </div>
            <div className={classes.text}>
              FUNCTIONALITY COOKIES: These cookies allow the website to remember
              the choices you have made (e.g., language settings and your
              location) and to provide improved, more personalized features.
              They may also be used to store your settings regarding text size,
              font and other customizable parts of the website and to provide
              services you request, such as viewing a video or commenting on a
              blog. The information collected by these cookies can be made
              anonymous. Your browsing activities cannot be tracked across other
              websites.
            </div>
            <div className={classes.text}>
              SOCIAL MEDIA COOKIES: These cookies are used when you click a
              button on the platform to share information on social media. The
              social network records this action and may use it for marketing or
              advertising purposes.
            </div>
            <div className={classes.text}>
              MARKETING/ADVERTISING COOKIES: These cookies are used to deliver
              ads that may be relevant to you based on your interests. They are
              also used to limit the frequency in which particular ads are shown
              to you. We also use cookies to evaluate the effectiveness of
              advertising campaigns. They are usually placed by advertising
              networks with our permission and record your visits to a website.
              This information is shared with other organizations, such as
              advertising agencies. Marketing and advertising cookies are often
              tied to platform features. Our advertising cookies also allow you
              to participate in customer surveys and provide us with feedback so
              that we can improve your user experience with us.
            </div>

            <h2 className={classes.title}>GOOGLE ANALYTICS</h2>
            <div className={classes.text}>
              We use the service Google Analytics of Google Inc. This service
              allows us to analyze the use of our internet sites and therefore
              deploys cookies. For this purpose, the information generated by
              the cookie such as your anonymized IP address, is transmitted on
              our behalf to a Google Inc. server in the USA, where it is stored
              and analyzed. On our website Google Analytics was extended by the
              code "gat._anonymizeIp();" to ensure an anonymized record of IP
              addresses. Google Inc. usually achieves the anonymization of your
              IP address by recording it in shortened form within the member
              countries of the European Union or in other contracting states of
              the European Economic Area (EEA). Merely in exceptional cases,
              your IP address will be transmitted entirely to a Google Inc.
              server in the USA and only shortened there. If your IP address has
              been transmitted in this way, it will be kept separately from
              other Google Inc. data. The Google Analytics advertising function
              uses remarketing and reports on performance by demographic
              characteristics and interests. These processes are intended to
              align advertising measures more closely to the interests of the
              relevant user with the help of information about user behavior.
              For remarketing, personalized advertising measures can be placed
              on other websites based on the user's surfing behavior on this
              website. The advertising media may contain products that the user
              has previously viewed on the website. If you have agreed that your
              Google web and app browser history is connected with your Google
              account and information from your Google account is used to
              personalize ads, Google will use this data for remarketing across
              all devices. You can object to the recording of your data by
              Google Analytics at any time. You have the following options in so
              doing: Most browsers automatically accept cookies. However, you
              can prevent the use of cookies by adjusting your browser settings.
              If you do this, however, you may not be able to use all functions
              of the website. You will have to change the settings separately on
              every browser you use.
            </div>
            <div className={classes.text}>
              Furthermore, you can prevent Google Inc. from collection and
              processing this data by downloading and installing the browser
              add-on available at the following link:
              <br></br>
              <a href="https://tools.google.com/dlpage/gaoptout?hl=de">
                https://tools.google.com/dlpage/gaoptout?hl=de.
              </a>
            </div>
            <div className={classes.text}>
              Alternatively, or for browsers on mobile devices, please click on
              the following link:{" "}
              <a href="#" onClick={() => gaDisallow()}>
                Deactivate Google Analytics
              </a>{" "}
              An opt-out cookie will be placed on your device for our internet
              sites with effect for your browser in use at the time. If you
              delete your cookies in that browser, you need to click on this
              link again. You can find more detailed information about the
              conditions of use and data protection at:<br></br>
              <br></br>
              <a href="https://marketingplatform.google.com/about/analytics/terms/us/">
                https://marketingplatform.google.com/about/analytics/terms/us/
              </a>
              <br></br>
              <br></br>or<br></br>
              <br></br>
              <a href="https://policies.google.com">
                https://policies.google.com
              </a>
            </div>

            <h2 className={classes.title}>GOOGLE REMARKETING</h2>
            <div className={classes.text}>
              Using the remarketing technology of Google Ireland Limited (Google
              Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland),
              users who have already visited our website are addressed again
              through interest-based ads on the pages of the Google Partner
              Network. The ads may include products that the user has previously
              viewed on our website (remarketing). For this purpose, the Google
              Remarketing tracking pixels are placed on this page to monitor the
              user activities on the website (e.g., viewed content), the
              technical characteristics of the website visit (e.g., the browser
              used, the IP address) and the purchasing behavior (e.g., most
              recently visited content) without personal reference and transmits
              them to Google. Google uses cookies for the purpose of user
              recognition and allocation. If users have agreed that their web
              and app browsing history is linked by Google to their Google
              account and information from the Google account is used by us to
              personalize ads they see on the web, Google will use data from
              these registered users together with its own collected data to
              create and define target group lists for remarketing across all
              devices. To support this feature, Google Analytics collects
              authenticated IDs of these users. This personal data from Google
              is temporarily linked with Google Analytics data from us to form
              target groups.
            </div>
            <div className={classes.text}>
              More information and options to opt-out can be found at<br></br>
              <br></br>
              <a href="https://www.google.com/settings/u/0/ads/anonymous?Hl=De">
                https://www.google.com/settings/u/0/ads/anonymous?hl=de
              </a>
            </div>

            <h2 className={classes.title}>GOOGLE-TAG-MANAGER</h2>
            <div className={classes.text}>
              This website uses the Google Tag Manager. Google Tag Manager is a
              solution that allows advertisers to manage website tags through
              one interface. The Tag Manager tool itself (which implements the
              tags) is a cookie-less domain and does not collect any personally
              identifiable information. The tool triggers other tags, which in
              turn may collect data. Google Tag Manager does not access this
              data. If disabled at the domain or cookie level, it will remain
              disabled for all tracking tags implemented with Google Tag
              Manager.
            </div>

            <h2 className={classes.title}>MICROSOFT CLARITY</h2>
            <div className={classes.text}>
              On this website, various user information is collected and stored
              for statistical analysis of user behavior and for optimization and
              marketing purposes through the service "Microsoft Clarity" by
              Microsoft Corporation, One Microsoft Way, Redmond, WA 98052-6399
              USA (hereinafter "Microsoft"). This information, which always
              excludes personal identification, includes but is not limited to
              time zone settings, operating system and platform, the
              geographical origin of the page view, the source of redirection to
              our page, the duration of visits to specific pages, and
              information about website interaction (e.g., scrolling, clicks,
              and mouse-overs). From this data, pseudonymized usage profiles may
              be created and evaluated for the same purposes. Cookies are used
              for the collection and analysis of this data. Cookies are small
              text files that are stored locally in the cache of the visitor's
              internet browser. Among other things, cookies enable the
              recognition of the internet browser. The data collected with
              Microsoft technologies will not be used to personally identify the
              visitor of this website without their explicit consent, nor will
              it be merged with personal data about the bearer of the pseudonym.
              Collected information may be transferred to and stored on
              Microsoft servers in the USA. We have entered into a data
              processing agreement with Microsoft, obliging them to protect our
              customers' data and not to disclose it to third parties. Further
              information on how Microsoft collects and uses your data can be
              found in the{" "}
              <a href="https://learn.microsoft.com/en-us/clarity/setup-and-installation/privacy-disclosure">
                Microsoft privacy policy
              </a>
              .
            </div>
            <div className={classes.text}>
              All the above-described processing, especially the setting of
              cookies for reading information on the used device, will only be
              carried out if you have given us your express consent in
              accordance with Art. 6 (1) lit. a GDPR. Without this consent,
              Microsoft Clarity will not be deployed during your page visit. You
              can revoke your consent at any time with effect for the future:{" "}
              <a href="#" onClick={() => mcDisallow()}>
                Withdraw consent
              </a>
            </div>
            <div className="flex flex-row">
              <CallMade
                fontSize="small"
                style={{ marginRight: "0.75em", marginTop: "0.2em" }}
              />
              <div className={classes.text}>
                STATUS OF THIS PRIVACY POLICY<br></br>
                <br></br>SEPTEMBER 2020
              </div>
            </div>
            <div className={classes.text}>
              We reserve the right to change this privacy policy at any time
              with effect for the future.
            </div>
          </article>
        </article>
      </div>
    </article>
  );
};

export default PrivacyPolicy;

import snackbarHelper from "helpers/snackbarHelperFn";
import { APIFetchAxios } from "routes/Auth";
import convertActiveFilterToSendFilter from "helpers/functions/filters/convertActiveFilterToSendFilter";
import _ from "lodash";
import {
  ExplorationAPIRequestModel,
  MediaObjectRawModel,
  SortingRequestModel,
} from "models/exploration.model";
import { ActiveFiltersModel, SendFilterModel } from "models/filter.model";
import {
  InstancesPerMediaModel,
  MetaDataCountModel,
} from "models/metaData.model";
import {
  explorationMediaObjectsStateTypes,
  fetchMediaObjectsData,
  updateMediaObjectsExplorationHasMore,
  updateMediaObjectsExplorationSkip,
} from "store/explorationMediaObjectsSlice";

const scrollLoadAmount = Number(
  process.env.REACT_APP_SCROLL_LOAD_AMOUNT ??
    alert("Config value 'scroll load amount' is not defined"),
);

// Post request to media objects, used to fetch media objects with a query
export const fetchMediaObjects = async (
  body: ExplorationAPIRequestModel,
): Promise<MediaObjectRawModel[]> => {
  if (_.isUndefined(body?.projection)) {
    body = { ...body, projection: null };
  }
  const response = await APIFetchAxios(
    `/datasets/${body.dataset_id}/mediaObjects`,
    body,
  );
  return response?.data;
};

// Post request to media objects, used to fetch one media object
export const fetchOneMediaObjectAPI = async (
  mediaObjectId: string,
  datasetID: string,
): Promise<MediaObjectRawModel> => {
  const response = await APIFetchAxios(
    `/datasets/${datasetID}/mediaObjects/${mediaObjectId}`,
    { projection: null },
  );
  return response?.data;
};

export const fetchMediaObjectsCount = async (
  datasetID: string,
  body: SendFilterModel[],
  dispatch?: any,
): Promise<MetaDataCountModel> => {
  const response = await APIFetchAxios(
    `/datasets/${datasetID}/mediaObjects:count`,
    { query: body },
  ).catch((error) => {
    let msg = error?.details;
    if (_.isUndefined(error?.details)) {
      msg = "Something went wrong! Fetching media objects count failed!";
    }
    snackbarHelper(msg, "error");
  });
  return response?.data;
};

export const fetchMediaObjectsDataScroll = (
  dispatch: any,
  explorationMediaObjectsData: explorationMediaObjectsStateTypes,
  totalCount: number,
  datasetID: string,
  subsetID: string,
  mediaObjectsFilterData: ActiveFiltersModel,
  mediaObjectsSortData: SortingRequestModel[],
) => {
  if (
    totalCount > 0 &&
    explorationMediaObjectsData?.skip + scrollLoadAmount >= totalCount
  ) {
    dispatch(updateMediaObjectsExplorationHasMore(false));
    return;
  }
  const APIBody: ExplorationAPIRequestModel = {
    dataset_id: datasetID,
    limit: scrollLoadAmount,
    skip: explorationMediaObjectsData?.skip + scrollLoadAmount,
    query: convertActiveFilterToSendFilter(mediaObjectsFilterData),
    sort: mediaObjectsSortData,
  };

  dispatch(
    fetchMediaObjectsData({
      runId: datasetID,
      reqBody: APIBody,
      subSetId: subsetID,
      skipLoading: true,
    }),
  );
  dispatch(
    updateMediaObjectsExplorationSkip(
      explorationMediaObjectsData?.skip + scrollLoadAmount,
    ),
  );
};

export const fetchMediaObjectsFrequency = async (
  datasetID: string,
  subsetID: string,
  dispatch?: any,
): Promise<InstancesPerMediaModel> => {
  let url = `/datasets/${datasetID}/medias/mediaObjectsFrequency`;

  if (subsetID !== "main_dataset") {
    url = url + `?subset_id=${subsetID}`;
  }
  const response = await APIFetchAxios(url).catch((error) => {
    let msg = error?.details;
    if (_.isUndefined(error?.details)) {
      msg = "Something went wrong! Fetching media objects frequency failed!";
      console.error(msg);
    }
    snackbarHelper(msg, "error");
  });
  return response?.data;
};

import { FieldErrorProps } from "@rjsf/utils";
import _ from "lodash";

const FieldErrorTemplate = (props: FieldErrorProps) => {
  const { errors } = props;

  const renderErrors = () => {
    const errorsList = errors as string[];

    // Remove duplicates
    const uniqueErrorsSet = new Set(errorsList);
    const uniqueErrors = Array.from(uniqueErrorsSet);

    return _.map(uniqueErrors, (error, i) => {
      return (
        <div key={i} className="text-sm text-paletteRed">
          {error}
        </div>
      );
    });
  };

  return <div className="flex flex-col gap-y-1">{renderErrors()}</div>;
};
export default FieldErrorTemplate;

import Drawer from "@mui/material/Drawer";
import NotificationTabs from "sections/NotificationCenter/NotificationTabs";
import { useState } from "react";
import NotificationListContainer from "sections/NotificationCenter/NotificationListContainer";
import CloseButton from "components/Buttons/CloseButton";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { markAllAsReadStore, toggleDrawer } from "store/notificationsSlice";

export enum NotificationManagerScreenTabs {
  all = "all",
  unread = "unread",
}

const NotificationCenter = () => {
  const [selectedTab, setSelectedTab] = useState<NotificationManagerScreenTabs>(
    NotificationManagerScreenTabs.all,
  );
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => state.notificationSlice.open);

  const Header = () => {
    return (
      <div className="flex my-8 w-full justify-between">
        <h1 className="text-2xl">Notifications</h1>
        <CloseButton onClick={() => dispatch(toggleDrawer())} />
      </div>
    );
  };

  const MarkAsReadButton = () => {
    return (
      <button
        className="flex justify-center items-center cursor-pointer text-[#31ade6] hover:bg-transparent hover:text-[#31ade6]"
        onClick={() => dispatch(markAllAsReadStore({ read: true }))}
        data-test="mark_all_as_read_button"
      >
        Mark all as read
      </button>
    );
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={() => dispatch(toggleDrawer())}
      PaperProps={{
        style: {
          width: "500px",
        },
      }}
      slotProps={{
        backdrop: {
          style: { backgroundColor: "transparent" },
        },
      }}
    >
      <section
        className="w-full flex flex-col overflow-hidden h-full"
        aria-label="Notification Center"
      >
        <div className="px-6">
          <Header />
          <nav className="flex justify-between gap-y-2 my-8">
            <div className="w-2/3">
              <NotificationTabs
                selectedTabValue={selectedTab}
                onTabClick={setSelectedTab}
              />
            </div>
            <MarkAsReadButton />
          </nav>
        </div>
        <NotificationListContainer selectedTab={selectedTab} />
      </section>
    </Drawer>
  );
};

export default NotificationCenter;

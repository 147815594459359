import { ReactComponent as CloseIcon } from "assets/close.svg";

interface props {
  onClick: () => void;
}
const CloseButton = ({ onClick }: props) => {
  return (
    <button
      className=" hover:bg-transparent"
      aria-label="Close"
      onClick={onClick}
    >
      <CloseIcon width={16} height={16} />
    </button>
  );
};

export default CloseButton;

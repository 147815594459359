import PopulatedDatasetTable from "Pages/Datasets/PopulatedDatasetTable";
import { useHistory } from "react-router";
import { checkIfUserHavePermission } from "helpers/keycloakHelpers";
import { useKeycloak } from "@react-keycloak/web";
import { ReactComponent as UploadIcon } from "assets/upload.svg";
import { useAppSelector } from "store/hooks";
import NotificationButton from "components/Buttons/NotificationButton";

const DatasetManager = () => {
  const history = useHistory();
  const { keycloak } = useKeycloak();
  const dashboardData = useAppSelector((state) => state.dashboardSlice.data);

  // ------------------------------------------- Render Component ------------------------------------
  return (
    <div className="px-[72px] pt-4 mx-auto h-full bg-white flex flex-col self-center">
      <div className="h-[90%] pt-[44px] flex-1 flex flex-col">
        <header className="flex justify-between">
          <div className="flex text-3xl items-center">
            My datasets{" "}
            <div
              className="h-fit text-sm text-white bg-paletteBlack-1 rounded-[4px] ml-3 px-[7px] py-[1.5px]"
              data-test="number_of_datasets_dashboard"
            >
              {Object.keys(dashboardData).length}
            </div>
          </div>
          <div className="flex items-center gap-x-3">
            {checkIfUserHavePermission(keycloak, "accessUploadPage") && (
              <button
                data-test="upload_manager"
                className="button-layer px-4 py-[9.5px] text-paletteBlack-2"
                onClick={() => history.push("/uploads")}
              >
                <UploadIcon></UploadIcon>
                Upload manager
              </button>
            )}
            <div className="h-6 w-px bg-paletteGray-4"></div>
            <NotificationButton />
          </div>
        </header>
        <section className="w-full h-[85%] pt-6">
          <PopulatedDatasetTable />
        </section>
      </div>
    </div>
  );
};

export default DatasetManager;

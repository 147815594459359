import StatusLabel from "components/Labels/StatusLabel";
import { snakeCaseToText } from "components/utilFunctions";
import { AnnotationRunStatusEnum } from "models/annotationRun.model";

type Props = { status: AnnotationRunStatusEnum };

const AnnotationRunStatusChip = ({ status }: Props) => {
  const renderStatusChip = () => {
    let color;
    switch (status) {
      case AnnotationRunStatusEnum.STARTED:
      case AnnotationRunStatusEnum.ANNOTATION_DONE:
      case AnnotationRunStatusEnum.PARTIALLY_DONE:
      case AnnotationRunStatusEnum.POST_PROCESSING:
        color = "bg-paletteYellow";
        break;
      case AnnotationRunStatusEnum.CREATION_FAILED:
      case AnnotationRunStatusEnum.AI_ANNOTATION_FAILED:
      case AnnotationRunStatusEnum.POST_PROCESSING_FAILED:
        color = "bg-paletteRed";
        break;
      case AnnotationRunStatusEnum.DONE:
        color = "bg-paletteGreen";
        break;
      case AnnotationRunStatusEnum.CREATED:
      default:
        color = "bg-paletteGray6";
    }

    return <StatusLabel label={snakeCaseToText(status)} colorClass={color} />;
  };

  return renderStatusChip();
};

export default AnnotationRunStatusChip;

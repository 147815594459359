import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { APIFetchAxios } from "../routes/Auth";
import {
  ActiveFiltersModel,
  FilterModel,
  FiltersModel,
  ResponseFilterModel,
  SavedFilterModel,
} from "../models/filter.model";
import {
  buildFiltersObject,
  createNewFakeAnnotationAttributeFilter,
  createNewFakeBackReferenceFilter,
  createNewFakeCameraNameFilter,
  createNewFakeFrameIndexFilter,
  createNewFakeIDsFilter,
  createNewFakeInstancesGeometryTypeFilter,
  createNewFakeMLAnnotationAttributeFilter,
  createNewFakeMediaObjectGeometryTypeFilter,
  createNewFakeMediaTypeFilter,
  createNewFakeSceneIDFilter,
  createNewFakeSourceFilter,
  createNewFakeSubsetsFilter,
  createNewFakeTagsFilter,
} from "helpers/functions/filters/buildFilterObjects";
import _ from "lodash";
import convertFiltersModelToActiveFiltersModel from "helpers/functions/filters/convertFilterModelToActiveFilterModel";
import { AnnotatableEnum, SelectedViewModel } from "models/global.model";
import { searchParamsToFilters } from "helpers/functions/filters/filtersHelpers";

// Fetch filters and store them in redux
export const fetchFilterData = createAsyncThunk(
  "filterData/fetchFilterData",
  async (meta: {
    query: { dataset_id: string; subset_id: string };
    type: SelectedViewModel;
    reset?: boolean;
    skipLoading?: boolean;
  }) => {
    if (
      _.isUndefined(meta?.query?.dataset_id) ||
      _.isUndefined(meta?.query?.subset_id) ||
      _.isUndefined(meta?.type)
    ) {
      throw new Error("Missing query parameters for fetchFilterData");
    }

    let endpoint: string = "";
    if (meta?.type === AnnotatableEnum.Media) {
      endpoint = `/datasets/${meta.query.dataset_id}/medias/histograms`;
    } else if (meta?.type === AnnotatableEnum.Instance) {
      endpoint = `/datasets/${meta.query.dataset_id}/instances/histograms`;
    } else if (meta?.type === AnnotatableEnum.MediaObject) {
      endpoint = `/datasets/${meta.query.dataset_id}/mediaObjects/histograms`;
    }

    let params = {};
    if (meta.query.subset_id !== "main_dataset") {
      params = { ...params, subset_id: meta.query.subset_id };
    }

    const response = await APIFetchAxios(endpoint, params);
    return { data: response?.data, meta: meta };
  },
);

// Fetch saved filters and store them in redux
export const fetchSavedFilterData = createAsyncThunk(
  "filterData/fetchSavedFilterData",
  async (meta: {
    query: {
      dataset_id: string;
      type: SelectedViewModel;
    };
    reset?: boolean;
    skipLoading?: boolean;
  }) => {
    if (
      _.isUndefined(meta?.query?.type) ||
      _.isUndefined(meta?.query?.dataset_id)
    ) {
      throw new Error("Missing query parameters for fetchSavedFilterData");
    }

    const type = meta.query.type;
    let typeParam = "";
    if (type === AnnotatableEnum.Media) {
      typeParam = "media";
    } else if (type === AnnotatableEnum.Instance) {
      typeParam = "instance";
    } else if (type === AnnotatableEnum.MediaObject) {
      typeParam = "media_object";
    }

    const response = await APIFetchAxios(
      `/datasets/${meta?.query.dataset_id}/savedFilters`,
      {
        annotatable_type: typeParam,
      },
    );
    return { data: response?.data, meta: meta };
  },
);

export interface filterDataStateTypes {
  mediaFilterData: FiltersModel | null;
  savedMediaFilterData: SavedFilterModel[] | null;
  activeMediaFilter: ActiveFiltersModel;
  instanceFilterData: FiltersModel | null;
  savedInstanceFilterData: SavedFilterModel[] | null;
  activeInstanceFilter: ActiveFiltersModel;
  annotationFilterData: FiltersModel | null;
  savedAnnotationFilterData: SavedFilterModel[] | null;
  activeMediaObjectsFilter: ActiveFiltersModel;
  loading: boolean;
  error: { message: string };
}

const initialState = {
  mediaFilterData: null,
  savedMediaFilterData: null,
  activeMediaFilter: {},
  instanceFilterData: null,
  savedInstanceFilterData: null,
  activeInstanceFilter: {},
  annotationFilterData: null,
  savedAnnotationFilterData: null,
  activeMediaObjectsFilter: {},
  loading: false,
  error: { message: "" },
} as filterDataStateTypes;

export const filterDataSlice = createSlice({
  name: "filterData",
  initialState,
  reducers: {
    setFiltersData: (
      state,
      action: PayloadAction<{
        data: FiltersModel;
        type: SelectedViewModel;
      }>,
    ) => {
      const reduxKey = determineFilterKeyFromParam(action.payload.type);
      state[reduxKey] = action?.payload?.data;
    },

    updateFilterData: (
      state,
      action: PayloadAction<{
        data: FilterModel;
        type: AnnotatableEnum;
      }>,
    ) => {
      const reduxKey =
        state[determineFilterKeyFromParam(action?.payload?.type)];
      if (reduxKey === null) {
        return;
      }
      reduxKey[action?.payload?.data?.key] = action?.payload?.data;
    },

    setActiveFilters: (
      state,
      action: PayloadAction<{
        data: FilterModel[];
        type: SelectedViewModel;
      }>,
    ) => {
      const reduxKey = determineActiveFilterKeyFromParam(action?.payload?.type);
      const newActiveFilters: ActiveFiltersModel =
        convertFiltersModelToActiveFiltersModel(action?.payload?.data);
      state[reduxKey] = newActiveFilters;
    },
    resetActiveFilterDataSlice: (
      state,
      action: PayloadAction<{
        type: SelectedViewModel;
      }>,
    ) => {
      const reduxKey = determineActiveFilterKeyFromParam(action?.payload?.type);
      state[reduxKey] = {};
    },
    resetFilterDataSlice: () => initialState,
  },
  extraReducers: (builder) => {
    // fetchFilterData()
    builder.addCase(
      fetchFilterData.pending,
      (state: filterDataStateTypes, action) => {
        if (!action?.meta?.arg?.skipLoading) {
          state.loading = true;
        }
      },
    );
    builder.addCase(
      fetchFilterData.fulfilled,
      (state: filterDataStateTypes, action) => {
        // Inject fake dataset id filter for each annotation attribute used to select the
        //  the annotation attribute as a filter
        const attributeGroupList = _.groupBy(
          action?.payload?.data,
          "attribute_group",
        );
        // Annotation attributes
        const annotationAttributesList = _.map(
          _.groupBy(attributeGroupList?.annotation_attribute, "attribute_id"),
          (attributeList, attributeID) => {
            return {
              name: attributeList?.[0]?.attribute_name,
              id: attributeID,
            };
          },
        );

        const annotationAttributesSelectFilter = _.map(
          annotationAttributesList,
          (attribute) =>
            createNewFakeAnnotationAttributeFilter(
              attribute?.name,
              attribute?.id,
            ),
        );

        // ML Annotation attributes
        const mlAnnotationAttributesList = _.map(
          _.groupBy(
            attributeGroupList?.ml_annotation_attribute,
            "attribute_id",
          ),
          (attributeList, attributeID) => {
            return {
              name: attributeList?.[0]?.attribute_name,
              id: attributeID,
            };
          },
        );
        const mlAnnotationAttributesSelectFilter = _.map(
          mlAnnotationAttributesList,
          (attribute) =>
            createNewFakeMLAnnotationAttributeFilter(
              attribute?.name,
              attribute?.id,
            ),
        );

        // Build the filter object (used in frontend) from the request filter
        const responseFilters: ResponseFilterModel[] = [
          ...annotationAttributesSelectFilter,
          ...mlAnnotationAttributesSelectFilter,
          ...appendFakeFilters(action?.meta?.arg?.type),
          ...action?.payload?.data,
        ];

        const newFilters = buildFiltersObject(responseFilters);

        const filtersFromParams = searchParamsToFilters(
          newFilters,
          action?.payload?.meta?.type,
        );
        const newReduxFilters = { ...newFilters, ...filtersFromParams };

        const filterReduxKey = determineFilterKeyFromParam(
          action?.payload?.meta?.type,
        );
        const activeFilterReduxKey = determineActiveFilterKeyFromParam(
          action?.payload?.meta?.type,
        );

        state[filterReduxKey] = newReduxFilters;
        state[activeFilterReduxKey] = convertFiltersModelToActiveFiltersModel(
          _.map(filtersFromParams, (f) => f),
        );
        state.loading = false;
      },
    );
    builder.addCase(
      fetchFilterData.rejected,
      (state: filterDataStateTypes, action) => {
        const responseFilters: ResponseFilterModel[] = [
          ...appendFakeFilters(action?.meta?.arg?.type),
        ];
        const newFilters = buildFiltersObject(responseFilters);

        const reduxKey = determineFilterKeyFromParam(action.meta.arg.type);
        state[reduxKey] = newFilters;

        state.loading = false;
        state.error.message = action.error.message || "No error provided";
      },
    );

    // fetchSavedFilterData()
    builder.addCase(
      fetchSavedFilterData.pending,
      (state: filterDataStateTypes, action) => {
        if (!action?.meta?.arg?.skipLoading) {
          state.loading = true;
        }
      },
    );
    builder.addCase(
      fetchSavedFilterData.fulfilled,
      (state: filterDataStateTypes, action) => {
        const reduxKey = determineSavedFilterKeyFromParam(
          action?.payload?.meta?.query?.type,
        );

        const savedFilters = _.map(
          _.filter(action.payload?.data, ["subset_id", null]),
          (i) => ({
            ...i,
            group: "Saved Filters",
            group_name: "Saved Filters",
            key: i?.name,
            FE_filter_type: "saved_filter",
          }),
        );
        state[reduxKey] = savedFilters;
        state.loading = false;
      },
    );
    builder.addCase(
      fetchSavedFilterData.rejected,
      (state: filterDataStateTypes, action) => {
        state.loading = false;
        state.error.message = action.error.message || "No error provided";
      },
    );
  },
});

export const {
  setFiltersData,
  updateFilterData,
  setActiveFilters,
  resetActiveFilterDataSlice,
  resetFilterDataSlice,
} = filterDataSlice.actions;
export default filterDataSlice.reducer;

// Helper functions

// A helper function to append fake filters (not coming from the backend) to the filter data
const appendFakeFilters = (type: AnnotatableEnum): ResponseFilterModel[] => {
  let feFilers: ResponseFilterModel[] = [
    createNewFakeSubsetsFilter(),
    createNewFakeTagsFilter(),
    createNewFakeIDsFilter(),
    createNewFakeBackReferenceFilter(),
    createNewFakeSceneIDFilter(),
    createNewFakeCameraNameFilter(),
    createNewFakeFrameIndexFilter(),
  ];
  if (type === AnnotatableEnum.MediaObject) {
    feFilers = [
      ...feFilers,
      createNewFakeSourceFilter(),
      createNewFakeMediaObjectGeometryTypeFilter(),
    ];
  }
  if (type === AnnotatableEnum.Media) {
    feFilers = [
      ...feFilers,
      createNewFakeMediaTypeFilter(),
      createNewFakeCameraNameFilter(),
    ];
  }
  if (type === AnnotatableEnum.Instance) {
    feFilers = [...feFilers, createNewFakeInstancesGeometryTypeFilter()];
  }
  return feFilers;
};

// Determine which filter data key to use based on the type param
export const determineFilterKeyFromParam = (
  type: AnnotatableEnum | undefined,
): "mediaFilterData" | "annotationFilterData" | "instanceFilterData" => {
  if (type === AnnotatableEnum.Media) {
    return "mediaFilterData";
  } else if (type === AnnotatableEnum.MediaObject) {
    return "annotationFilterData";
  } else if (type === AnnotatableEnum.Instance) {
    return "instanceFilterData";
  }

  return "mediaFilterData";
};

// Determine which saved filter data key to use based on the type param
export const determineSavedFilterKeyFromParam = (
  type: AnnotatableEnum | undefined,
):
  | "savedMediaFilterData"
  | "savedAnnotationFilterData"
  | "savedInstanceFilterData" => {
  if (type === AnnotatableEnum.Media) {
    return "savedMediaFilterData";
  } else if (type === AnnotatableEnum.MediaObject) {
    return "savedAnnotationFilterData";
  } else if (type === AnnotatableEnum.Instance) {
    return "savedInstanceFilterData";
  }
  return "savedMediaFilterData";
};

// Determine which active filter data key to use based on the type param
export const determineActiveFilterKeyFromParam = (
  type: AnnotatableEnum | undefined,
):
  | "activeMediaFilter"
  | "activeMediaObjectsFilter"
  | "activeInstanceFilter" => {
  if (type === AnnotatableEnum.Media) {
    return "activeMediaFilter";
  } else if (type === AnnotatableEnum.MediaObject) {
    return "activeMediaObjectsFilter";
  } else if (type === AnnotatableEnum.Instance) {
    return "activeInstanceFilter";
  }
  return "activeMediaFilter";
};

import { useState } from "react";
import { useAppDispatch } from "store/hooks";
import _ from "lodash";
import BasicButton from "components/Buttons/BasicButton";

import { AnonymizationModel } from "models/transformation.model";
import NumberInput from "components/Inputs/NumberInput";
import { postAnonymization } from "helpers/apis/transformations";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import { patchDatasetMeta } from "helpers/apis/datasets";

interface Props {
  datasetID: string;
  subsetID: string;
  isAnonymized: boolean | undefined;
  updateDashboard: () => void;
}

interface NumberInputModel {
  key: keyof AnonymizationModel;
  type: string;
  defaultValue: number;
  min?: number;
  max?: number;
  tooltip?: string;
}

const anonymizationForm: { [key: string]: NumberInputModel } = {
  face_threshold: {
    key: "face_threshold",
    type: "number",
    defaultValue: 0.3,
    min: 0.001,
    max: 1,
    tooltip:
      "Confidence threshold for face detection. " +
      "Lower values will increase the sensitivity to face detection, leading to more false positives. " +
      "Higher values decrease sensitivity, leading to more false negatives. " +
      "Must be in [0.001, 1.0]. The default is 0.3.",
  },
  plate_threshold: {
    key: "plate_threshold",
    type: "number",
    defaultValue: 0.3,
    min: 0.001,
    max: 1,
    tooltip:
      "Confidence threshold for license plate detection. " +
      "Lower values increase the sensitivity to plate detection, leading to more false positives. " +
      "Higher values decrease sensitivity, leading to more false negatives. " +
      "Must be in [0.001, 1.0]. The default is 0.3.",
  },
  kernel_size: {
    key: "kernel_size",
    type: "number",
    defaultValue: 21,
    tooltip:
      "Size in pixels of the kernel used for blurring the detected faces and license plates. " +
      "A larger kernel size produces a stronger blur. " +
      "Must be a positive, odd integer. The default is 21.",
  },
  sigma: {
    key: "sigma",
    type: "number",
    defaultValue: 2,
    tooltip:
      "Standard deviation for the Gaussian kernel. " +
      "This parameter determines the amount of blur. The larger the value, the more blur. " +
      "The default is 2.",
  },
  box_kernel_size: {
    key: "box_kernel_size",
    type: "number",
    defaultValue: 9,
    tooltip:
      "Size of the kernel used for creating a bounding box around detected faces and license plates. " +
      "This helps to create a region of interest for further processing. " +
      "Must be a positive odd integer. The default is 9.",
  },
};

const AnonymizationTab = ({
  datasetID,
  subsetID,
  isAnonymized,
  updateDashboard,
}: Props) => {
  const dispatch = useAppDispatch();

  const [name, setName] = useState("");
  const [anonymizationParams, setAnonymizationParams] = useState<{
    [key in keyof AnonymizationModel]: { value: number; error: string | null };
  }>({
    face_threshold: {
      value: anonymizationForm.face_threshold?.defaultValue,
      error: null,
    },
    plate_threshold: {
      value: anonymizationForm.plate_threshold?.defaultValue,
      error: null,
    },
    kernel_size: {
      value: anonymizationForm.kernel_size?.defaultValue,
      error: null,
    },
    sigma: { value: anonymizationForm.sigma?.defaultValue, error: null },
    box_kernel_size: {
      value: anonymizationForm.box_kernel_size?.defaultValue,
      error: null,
    },
  });

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const renderSubmitButton = () => {
    const disabled =
      _.some(anonymizationParams, (param) => {
        return !_.isNull(param.error);
      }) || datasetID === "";
    return (
      <div className="flex justify-end mt-4">
        <BasicButton label="Send" onClick={handleSubmit} disabled={disabled} />
      </div>
    );
  };

  const handleSubmit = () => {
    let anonymizationBody = {};
    _.forEach(anonymizationParams, (param, key) => {
      anonymizationBody = {
        ...anonymizationBody,
        [key]: param.value,
      };
    });
    postAnonymization(
      { datasetID: datasetID, subsetID: subsetID },
      {
        anonymizationBody: anonymizationBody as AnonymizationModel,
        name: name,
      },
      dispatch,
    );
  };

  const renderAnonymizationForm = () => {
    return _.map(anonymizationForm, (numericInput) => {
      return renderNumberInput(numericInput);
    });
  };

  const renderNumberInput = (numericInput: NumberInputModel) => {
    const { key, type, min, max } = numericInput;
    if (type === "number") {
      return (
        <NumberInput
          name={key}
          value={anonymizationParams[key]?.value}
          min={min}
          max={max}
          tooltip={numericInput?.tooltip}
          handleOnChange={(value, error) =>
            setAnonymizationParams({
              ...anonymizationParams,
              [key]: {
                ...anonymizationParams[key],
                value: value,
                error: error,
              },
            })
          }
        />
      );
    }
  };

  const renderIsDatasetAnonymized = () => {
    return (
      datasetID && (
        <button
          className={`button-layer text-white my-2 ${
            isAnonymized ? "bg-green-500" : "bg-paletteRed"
          }`}
          onClick={() => setIsConfirmDialogOpen(true)}
        >
          {isAnonymized
            ? "Dataset is already anonymized. Click to mark as not anonymized."
            : "Dataset is not anonymized yet. Click to mark as anonymized."}
        </button>
      )
    );
  };

  const handleAnonymization = () => {
    setIsConfirmDialogOpen(false);
    patchDatasetMeta(
      { datasetID: datasetID, dataset: { is_anonymized: !isAnonymized } },
      dispatch,
    ).then(() => {
      updateDashboard();
    });
  };

  const dialogTextWhenDatasetIsNotAnonymized =
    "This will NOT trigger an anonymization job. It will only mark the dataset as anonymized. Only use this" +
    " if you are sure that the data is already anonymized, e.g. because the customer has already done this. Please" +
    " be aware that sharing non-anonymized data with annotation crowds can have serious legal consequences.";
  const dialogTextWhenDatasetIsAnonymized =
    "This will NOT trigger an anonymization job. It will only mark the dataset as NOT anonymized. This will prevent" +
    " it from being shared with annotation crowds. Please be aware that sharing non-anonymized data with" +
    " annotation can have serious legal consequences.";

  return (
    <div className="w-1/3 my-2">
      {renderIsDatasetAnonymized()}
      <div className="flex justify-between">
        <label className="">Name</label>
        <input
          type="text"
          value={name}
          className="input-text w-1/2"
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <span className="text-xs text-grey-500">
        Beware: Changing the default parameters can have negative effects on the
        quality of the anonymization. Only change them if you really know what
        they are doing. You can hover over each parameter's name to get more
        information.
      </span>
      {renderAnonymizationForm()}
      {renderSubmitButton()}

      <ConfirmDialog
        isConfirmDialogOpen={isConfirmDialogOpen}
        setIsConfirmDialogOpen={setIsConfirmDialogOpen}
        title={`Are you sure you want to mark this dataset as ${
          isAnonymized ? "NOT" : ""
        }  anonymized?`}
        text={
          isAnonymized
            ? dialogTextWhenDatasetIsAnonymized
            : dialogTextWhenDatasetIsNotAnonymized
        }
        confirmButtonText={`Mark dataset as ${
          isAnonymized ? "NOT" : ""
        } anonymized`}
        handleOnSuccess={handleAnonymization}
      />
    </div>
  );
};

export default AnonymizationTab;

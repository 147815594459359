import StandardTabs, { StandardTabModel } from "components/Tabs/StandardTabs";
import { PipelineDesignerConfigurationTabsEnum } from "models/pipelines.model";
import { useAppSelector } from "store/hooks";

type Props = {
  selectedTab: PipelineDesignerConfigurationTabsEnum;
  setSelectedTab: (tab: PipelineDesignerConfigurationTabsEnum) => void;
};

const PipelineConfigurationTabs = ({ selectedTab, setSelectedTab }: Props) => {
  const validations = useAppSelector(
    (state) => state.pipelineDesignerSlice.validation,
  );

  const tabs: StandardTabModel[] = [
    {
      label: PipelineDesignerConfigurationTabsEnum.UserInterface,
      value: PipelineDesignerConfigurationTabsEnum.UserInterface,
      required:
        validations?.isUiTypeSelected === false ||
        validations?.isGUISettingsFormValid === false,
    },
    {
      label: PipelineDesignerConfigurationTabsEnum.Configuration,
      value: PipelineDesignerConfigurationTabsEnum.Configuration,
    },
  ];
  return (
    <StandardTabs
      tabs={tabs}
      selectedTab={selectedTab}
      setSelectedTab={(tab) =>
        setSelectedTab(tab as PipelineDesignerConfigurationTabsEnum)
      }
    />
  );
};

export default PipelineConfigurationTabs;

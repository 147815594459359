import { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useParams } from "react-router-dom";

import { AttributeMetadataModel } from "models/attributes.model";
import { AttributeManagementScreenRouteModel } from "models/routes.model";
import DataTable, { DataTableColumn } from "components/Table/DataTable";
import { patchRenameAttribute } from "helpers/apis/attributes";
import ActionsDropdown from "Pages/AttributeManagement/ActionsDropdown";
import { fetchAttributes } from "store/datasetSlice";
import selectMediatype from "helpers/functions/selectMediatype";
import EditName from "components/Inputs/EditName";
import AttributeTabs from "Pages/AttributeManagement/AttributeTabs";
import { isAttributeAnnotationAttribute } from "helpers/functions/attributes/attributesHelpers";
import { lowerCamelCase } from "components/utilFunctions";
import TooltipTruncateEllipsis from "components/Tooltips/TooltipTruncateEllipsis";
import Header from "sections/Headers/GeneralHeader";
import SearchBar from "components/Inputs/SearchBar";
import { ReactComponent as AttributeManagerIcon } from "assets/attributes_gradient.svg";
import DatasetSubsetCounts from "sections/Headers/DatasetSubsetCounts";

export enum AttributeManagementScreenTabs {
  All = "all",
  Media = "Media",
  MediaObject = "Object",
}

const AttributesManagement = () => {
  const params: AttributeManagementScreenRouteModel = useParams();
  const dispatch = useAppDispatch();

  const inputRefArray = useRef<{ [key: string]: HTMLInputElement }>({});

  const attributesMeta = useAppSelector(
    (state) => state.datasetSlice.attributes,
  );

  const mediatype = useAppSelector(
    (state) => state.datasetSlice.activeDataSet?.mediatype,
  );

  const [isRenamingLoading, setIsRenamingLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState<AttributeManagementScreenTabs>(
    AttributeManagementScreenTabs.All,
  );
  const [editInputID, setEditInputID] = useState<string | null>(null);
  const [searchValue, setSearchValue] = useState<string>("");

  // focus on the input field when the editInputID changes
  useEffect(() => {
    if (editInputID) {
      inputRefArray.current?.[editInputID]?.focus();
    }
  }, [editInputID]);

  const updateAttributes = () => {
    dispatch(fetchAttributes({ query: { datasetID: params?.dataset_id } }));
  };

  const handleOnSuccess = async () => {
    updateAttributes();
  };

  const columns: DataTableColumn[] = [
    {
      field: "name",
      headerName: "Name",
      span: 30,
      cell: (row) => renderEditInput(row as AttributeMetadataModel),
    },
    {
      field: "annotatable_type",
      headerName: "Annotatable type",
      span: 15,
      cell: (row) => renderAnnotatableType(row as AttributeMetadataModel),
    },
    {
      field: "timestamp",
      headerName: "Created",
      span: 15,
      cell: (row) => {
        const rowTyped = row as AttributeMetadataModel;
        const date = new Date(rowTyped?.timestamp);
        return <div className="flex">{date.toLocaleString()}</div>;
      },
    },
    {
      field: "attribute_type",
      headerName: "Attribute type",
      cell: (row) => {
        const attrType = row as AttributeMetadataModel;
        const attrTypeValue = attrType.attribute_type;
        return (
          <TooltipTruncateEllipsis>
            {attrTypeValue === null ? "-" : lowerCamelCase(attrTypeValue)}
          </TooltipTruncateEllipsis>
        );
      },
      span: 35,
    },
    {
      field: "",
      headerName: "",
      sortable: false,
      span: 5,
      cell: (row) => (
        <ActionsDropdown
          attribute={row as AttributeMetadataModel}
          setEditInputID={setEditInputID}
          handleOnSuccess={handleOnSuccess}
        />
      ),
    },
  ];

  const filterAttributes = () => {
    let filteredAttributes = _.filter(attributesMeta, function (attribute) {
      return (
        !attribute.archived &&
        isAttributeAnnotationAttribute(attribute.attribute_group)
      );
    });
    if (selectedTab && selectedTab !== AttributeManagementScreenTabs.All) {
      filteredAttributes = _.filter(attributesMeta, function (attribute) {
        return (
          !attribute.archived &&
          isAttributeAnnotationAttribute(attribute.attribute_group) &&
          attribute.annotatable_type === selectedTab
        );
      });
    }
    return filteredAttributes;
  };

  const renderAttributes = () => {
    // Filter the attributes based on the selected tab
    const filteredAttributes = filterAttributes();
    return (
      <DataTable
        rows={filteredAttributes as []}
        columns={columns}
        isLoading={isRenamingLoading}
        searchValue={searchValue}
      />
    );
  };

  const renderEditInput = (attribute: AttributeMetadataModel) => {
    return (
      <EditName
        item={attribute as Record<string, any>}
        editInputID={editInputID}
        setEditInputID={setEditInputID}
        inputRefArray={inputRefArray}
        handleRename={(newName: string) =>
          handleAfterOnBlur(attribute, newName)
        }
      />
    );
  };

  const handleAfterOnBlur = (
    attribute: AttributeMetadataModel,
    newName: string,
  ) => {
    // If the value is not the same as the attribute name, rename the attribute
    patchRenameAttribute(
      {
        datasetID: params?.dataset_id,
        attributeID: attribute?.id,
        attributeNewName: newName,
      },
      dispatch,
      setIsRenamingLoading,
    )
      .then(() => {
        handleOnSuccess();
        setEditInputID(null);
      })
      .catch(() => {
        setEditInputID(null);
      });
  };

  const renderAnnotatableType = (attribute: AttributeMetadataModel) => {
    let label = attribute?.annotatable_type as string;
    if (mediatype === "video" && label === "Media") {
      label = `${selectMediatype(mediatype)}`;
    } else if (label === "MediaObject") {
      label = `Object`;
    }
    return <span>{label}</span>;
  };

  return (
    <div className="mx-auto full-height-with-margin bg-white flex flex-col self-center border-[1px] border-paletteGray-5 rounded-xl m-1 mr-1">
      <Header
        title={<DatasetSubsetCounts />}
        icon={<AttributeManagerIcon className="w-5 h-5" />}
        iconBgcolor="bg-[#feeee4]"
      />
      <div className="px-[24px] min-h-0 py-4 flex-1 flex flex-col">
        <div className="w-full flex justify-between">
          <div className="w-fit">
            <AttributeTabs
              attributes={attributesMeta}
              selectedTabValue={selectedTab}
              onTabClick={setSelectedTab}
            />
          </div>

          <div className="min-w-0 flex-1 flex flex-row-reverse items-center gap-x-2">
            <div className="w-[500px]">
              <SearchBar
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
            </div>
          </div>
        </div>
        <div className="w-full flex-1 min-h-0">{renderAttributes()}</div>
      </div>
    </div>
  );
};
export default AttributesManagement;

import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { SendFilterModel } from "models/filter.model";
import convertActiveFilterToSendFilter from "helpers/functions/filters/convertActiveFilterToSendFilter";
import {
  fetchTagFrequency,
  removeSelectedTagFrequency,
} from "store/tagFrequenciesSlice";
import { TagModel } from "models/global.model";
import EditName from "components/Inputs/EditName";
import DataTable, { DataTableColumn } from "components/Table/DataTable";
import TagsTableActionsDropdown from "Pages/TagsManager/ActionsDropdown";
import { postUpdateTag } from "helpers/apis/tags";
import { fetchTags } from "store/datasetSlice";
import { useKeycloak } from "@react-keycloak/web";
import { TagsManagerScreenTabs } from "Pages/TagsManager";
import { TagsManagerScreenRouteModel } from "../../models/routes.model";
import { determineActiveFilterKeyFromParam } from "store/filterDataSlice";
import { determineCountKeyFromParam } from "store/metaDataSlice";
import TooltipTruncateEllipsis from "components/Tooltips/TooltipTruncateEllipsis";

interface Props {
  selectedTab: TagsManagerScreenTabs;
  searchValue?: string;
}

const TagsTable = ({ selectedTab, searchValue }: Props) => {
  const dispatch = useAppDispatch();
  const params: TagsManagerScreenRouteModel = useParams();
  const { keycloak } = useKeycloak();
  const dataSetData = useAppSelector((state) => state.datasetSlice);

  const selectedView = useAppSelector((state) => state.appSlice.selectedView);
  const selectedTagsFrequencies = useAppSelector(
    (state) => state.tagFrequenciesSlice,
  );

  const filterData = useAppSelector((state) => state.filterDataSlice);
  const metaData = useAppSelector((state) => state.metaDataSlice);

  const inputRefArray = useRef<{ [key: string]: HTMLInputElement }>({});
  const [editInputID, setEditInputID] = useState<string | null>(null);

  // focus on the input field when the editInputID changes
  useEffect(() => {
    if (editInputID) {
      inputRefArray.current?.[editInputID]?.focus();
    }
  }, [editInputID]);

  const columns: DataTableColumn[] = [
    {
      field: "name",
      headerName: "Name",
      span: 35,
      className: "font-bold",
      cell: (row) => {
        const tag = row as TagModel;
        return (
          <div className="w-full h-full flex items-center gap-x-4">
            <div
              className="w-4 h-4 rounded my-auto"
              style={{ backgroundColor: tag?.color }}
            ></div>

            <EditName
              item={row as Record<string, any>}
              inputRefArray={inputRefArray}
              editInputID={editInputID}
              setEditInputID={setEditInputID}
              handleRename={(newName: string) => {
                handleRenameTag(tag?.id, newName);
              }}
            />
          </div>
        );
      },
    },
    {
      field: "",
      headerName: "",
      span: 15,
      sortable: false,
    },

    {
      field: "database_object_type",
      headerName: "Type",
      span: 55,
      className: "font-bold",
      cell: (row) => {
        const tag = row as TagModel;
        const tagType = tag?.database_object_type;
        return (
          <TooltipTruncateEllipsis data-test="Type_value">
            {tagType === undefined
              ? "-"
              : tagType === "MediaObject"
                ? "Object"
                : tagType}
          </TooltipTruncateEllipsis>
        );
      },
    },
    {
      field: "",
      headerName: "",
      sortable: false,
      span: 5,
      cell: (row) => {
        return (
          <TagsTableActionsDropdown
            tag={row as TagModel}
            setEditInputID={setEditInputID}
          />
        );
      },
    },
  ];

  const handleSelectTagFrequency = (
    event: React.ChangeEvent<HTMLInputElement>,
    tag: TagModel,
  ) => {
    if (event?.target?.checked === true) {
      const filters =
        filterData?.[determineActiveFilterKeyFromParam(selectedView)];

      let APIBody: SendFilterModel[] = convertActiveFilterToSendFilter(filters);
      APIBody = [
        ...APIBody,
        {
          attribute: "tags",
          query_operator: "all",
          value: [tag?.id],
        },
      ];

      const totalCountKey = determineCountKeyFromParam(selectedView);
      if (_.isUndefined(totalCountKey)) return;

      dispatch(
        fetchTagFrequency({
          type: selectedView,
          datasetID: params?.dataset_id,
          APIBody: APIBody,
          tag: {
            id: tag?.id,
            name: tag?.name,
            color: tag?.color,
          },
          totalCount: metaData?.[totalCountKey]?.total_count,
        }),
      );
    } else {
      dispatch(
        removeSelectedTagFrequency({
          idOfTagToRemove: tag?.id,
          type: selectedView,
        }),
      );
    }
  };

  const handleRenameTag = (tagID: string, newName: string) => {
    postUpdateTag(
      { datasetID: params?.dataset_id, tagID: tagID, name: newName },
      dispatch,
    )
      // Update tags after renaming
      .then(() => {
        dispatch(
          fetchTags({
            query: {
              parentDataSetID: params?.dataset_id,
            },
          }),
        );
      });
  };

  const filterTags = () => {
    let filteredTags = dataSetData?.tags;
    if (selectedTab && selectedTab !== TagsManagerScreenTabs.all) {
      filteredTags = _.filter(dataSetData?.tags, {
        database_object_type: selectedTab as any,
      });
    }
    return filteredTags;
  };

  return (
    <DataTable
      rows={filterTags() as []}
      columns={columns}
      defaultSort={{ name: "database_object_type", direction: "asc" }}
      searchValue={searchValue}
    />
  );
};

export default TagsTable;

import { useState } from "react";
import { useAppSelector } from "store/hooks";
import _ from "lodash";
import FilterCategories from "sections/SidebarMenu/FiltersDialog/FiltersTab/FilterCategories";
import FiltersMenuList from "sections/SidebarMenu/FiltersDialog/FiltersTab/FiltersMenuList";
import FiltersSearchList from "sections/SidebarMenu/FiltersDialog/FiltersTab/FiltersSearchList";
import {
  FilterCategoryEnum,
  FilterModel,
  SavedFilterModel,
} from "models/filter.model";
import { ReactComponent as PlusIcon } from "assets/plus.svg";
import { ReactComponent as ResetIcon } from "assets/reset.svg";
import { ReactComponent as BackIcon } from "assets/arrow_small_left.svg";

import FilterComponentList from "sections/SidebarMenu/FiltersDialog/FiltersTab/FilterComponent";
import EditFiltersComponent from "sections/SidebarMenu/FiltersDialog/FiltersTab/EditFiltersComponent";
import SaveFilterDialog from "sections/SidebarMenu/FiltersDialog/FiltersTab/SaveFilterDialog";
import SearchBar from "components/Inputs/SearchBar";
import { determineFilterKeyFromParam } from "store/filterDataSlice";

interface Props {
  handleFilterClick: (
    item: FilterModel | SavedFilterModel,
    selectedFilterCategory: FilterCategoryEnum,
  ) => void;
  updateFilters: () => void;
  handleResetChanges: () => void;
}

const FiltersTab = ({
  handleFilterClick,
  updateFilters,
  handleResetChanges,
}: Props) => {
  const selectedView = useAppSelector((state) => state.appSlice.selectedView);
  const filterData = useAppSelector((state) => state?.filterDataSlice);

  const searchParams = new URLSearchParams(window.location.search);
  const searchParamsSize = Array.from(searchParams).length;

  const [isUserAddingFilter, setIsUserAddingFilter] = useState<boolean>(
    _.isEmpty(getFilters()) && searchParamsSize === 0,
  );
  const [selectedFilterCategory, setSelectedFilterCategory] =
    useState<FilterCategoryEnum | null>(null);
  const [searchValue, setSearchValue] = useState("");

  const renderFiltersTab = () => {
    if (isUserAddingFilter) {
      return renderAddingFilterSection();
    }

    return renderActiveFiltersSection();
  };

  // -- Add filter section --
  const renderAddingFilterSection = () => {
    return (
      <div className="h-full w-full flex flex-col gap-y-4 p-4 pb-0 pr-[13px]">
        {renderReturn()}
        {renderSearchBar()}
        <div className="min-h-0 flex-1 overflow-y-auto">
          {_.size(searchValue) > 0
            ? renderSearchList()
            : renderSelectFilterSection()}
        </div>
      </div>
    );
  };

  const renderReturn = () => {
    if (_.isEmpty(getFilters())) {
      return null;
    }

    return (
      <div className="flex items-center gap-x-2 text-paletteGray-10">
        <div
          className="cursor-pointer"
          onClick={() => setIsUserAddingFilter(false)}
        >
          <BackIcon width={16} height={16} />
        </div>
        Return
      </div>
    );
  };

  const renderSelectFilterSection = () => {
    if (selectedFilterCategory === null) {
      return (
        <FilterCategories setSelectedCategory={setSelectedFilterCategory} />
      );
    } else {
      return (
        <FiltersMenuList
          selectedCategory={selectedFilterCategory}
          setSelectedCategory={setSelectedFilterCategory}
          onItemClick={(item, selectedFilterCategory) => {
            handleFilterClick(item, selectedFilterCategory);
            setIsUserAddingFilter(false);
            setSearchValue("");
          }}
        />
      );
    }
  };

  const renderSearchBar = () => {
    return (
      <SearchBar searchValue={searchValue} setSearchValue={setSearchValue} />
    );
  };

  const renderSearchList = () => {
    return (
      <FiltersSearchList
        searchValue={searchValue}
        onItemClick={(item, selectedFilterCategory) => {
          handleFilterClick(item, selectedFilterCategory);
          setIsUserAddingFilter(false);
          setSearchValue("");
        }}
      />
    );
  };

  // -- Display active filters section --
  const renderActiveFiltersSection = () => {
    return (
      <div
        className="h-full w-full flex flex-col"
        data-test="filter_menu_full_area"
      >
        <div className="flex gap-x-1 p-4 border-b-[1px] border-paletteGray-3">
          {renderAddFilterButton()}
          <div className="flex">
            {renderResetFiltersButton()}
            {renderEditFiltersButton()}
            {renderSaveFiltersButton()}
          </div>
        </div>
        <div
          className="min-h-0 flex-1 overflow-y-scroll p-4 flex flex-col gap-y-4"
          data-test="applied_filter_list_in_menu"
        >
          {renderFilters()}
        </div>
      </div>
    );
  };

  const renderAddFilterButton = () => {
    return (
      <button
        className="button-layer w-full px-4 py-[9.5px]"
        onClick={() => {
          setIsUserAddingFilter(true);
          setSelectedFilterCategory(null);
        }}
        data-test="add_filter_button"
      >
        <PlusIcon width={16} height={16} />
        Add filter
      </button>
    );
  };

  const renderEditFiltersButton = () => {
    return <EditFiltersComponent updateFilters={updateFilters} />;
  };

  const renderSaveFiltersButton = () => {
    return <SaveFilterDialog updateFilters={updateFilters} />;
  };

  const renderResetFiltersButton = () => {
    return (
      <button
        className="button-select-layer w-auto p-[10px] justify-center"
        onClick={handleResetChanges}
        data-test="reset_filters_button"
      >
        <ResetIcon width={20} height={20} />
      </button>
    );
  };

  const renderFilters = () => {
    const groups = getFilters();
    return _.map(groups, (group: FilterModel[]) => {
      return (
        <div key={group?.[0]?.attribute_id}>
          <FilterComponentList group={group} />
        </div>
      );
    });
  };

  function getFilters() {
    const filters = filterData?.[determineFilterKeyFromParam(selectedView)];

    const groups = _.chain(filters)
      .filter("is_visible")
      .groupBy("attribute_id")
      .value();
    return groups;
  }

  return (
    <div className="min-h-0 flex-1 w-full flex flex-col">
      {renderFiltersTab()}
    </div>
  );
};

export default FiltersTab;

import { useEffect, useState } from "react";
import CreateSubsetPopover from "components/Popovers/CreateSubsetPopover";
import SubsetsTable from "Pages/SubsetsManager/SubsetsTable";
import { SubsetsManagerScreenRouteModel } from "models/routes.model";
import { useParams } from "react-router-dom";
import {
  fetchDataset,
  fetchParentDataset,
  fetchSubsets,
} from "store/datasetSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { ReactComponent as SubsetManagerIcon } from "assets/subsets_gradient.svg";

import SubsetTabs from "Pages/SubsetsManager/SubsetTabs";
import NewSubsetFileNotification from "Pages/SubsetsManager/NewSubsetFileNotification";
import { VisibilityStatus } from "models/global.model";
import Header from "sections/Headers/GeneralHeader";
import SearchBar from "components/Inputs/SearchBar";
import DatasetSubsetCounts from "sections/Headers/DatasetSubsetCounts";

export enum SubsetsManagerScreenTabs {
  all = "all",
  media = "media",
  mediaObject = "media_object",
}

const SubsetsManager = () => {
  const [selectedTab, setSelectedTab] = useState<SubsetsManagerScreenTabs>(
    SubsetsManagerScreenTabs.all,
  );
  const [selectedItemsAnchorEl, setSelectedItemsAnchorEl] =
    useState<HTMLElement | null>(null);
  const [searchValue, setSearchValue] = useState<string>("");

  const params: SubsetsManagerScreenRouteModel = useParams();
  const dispatch = useAppDispatch();

  const datasetSlice = useAppSelector((state) => state.datasetSlice);

  useEffect(() => {
    dispatch(
      fetchSubsets({
        query: { parentDataSetID: params?.dataset_id },
        visibility_statuses: [
          VisibilityStatus.Visible,
          VisibilityStatus.CreatingSubset,
        ],
      }),
    );

    dispatch(fetchParentDataset({ query: { dataSetID: params?.dataset_id } }));

    let id = params?.dataset_id;
    if (params?.subset_id !== "main_dataset") {
      id = params?.subset_id;
    }
    dispatch(fetchDataset({ query: { dataSetID: id } }));
  }, [params?.dataset_id]);

  return (
    <div className="mx-auto full-height-with-margin bg-white flex flex-col self-center border-[1px] border-paletteGray-5 rounded-xl m-1 mr-1">
      <Header
        title={<DatasetSubsetCounts />}
        icon={<SubsetManagerIcon className="w-5 h-5" />}
        iconBgcolor="bg-[#feeee4]"
      >
        <CreateSubsetPopover
          anchorEl={selectedItemsAnchorEl}
          handlePopoverOpen={(event) =>
            setSelectedItemsAnchorEl(event.currentTarget)
          }
          handlePopoverClose={() => setSelectedItemsAnchorEl(null)}
        />
      </Header>
      <NewSubsetFileNotification />
      <div className="px-[24px] min-h-0 py-4 flex-1 flex flex-col">
        <div className="w-full flex justify-between">
          <div className="w-fit">
            <SubsetTabs
              subsets={datasetSlice?.subSets}
              selectedTabValue={selectedTab}
              onTabClick={setSelectedTab}
            />
          </div>

          <div className="min-w-0 flex-1 flex flex-row-reverse items-center gap-x-2">
            <div className="w-[500px]">
              <SearchBar
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
            </div>
          </div>
        </div>
        <div className="w-full flex-1 min-h-0">
          <SubsetsTable selectedTab={selectedTab} searchValue={searchValue} />
        </div>
      </div>
    </div>
  );
};
export default SubsetsManager;
